import { Organizations } from 'pages/auth-as-moderator/organizations/organizations'
import { PublicProfileModeratorPage } from 'pages/public-profile-moderator/public-profile'
import SinginModeratorPage from 'pages/SinginModerator/SinginModerator'
import { SuperadminUsersPage } from 'pages/SuperadminUsers/SuperadminUsers'
import { Route } from './routes-def'
import { initialWebRoles, PagesRoutes } from './routes-utils'
import {WorkersPage} from "../../pages/auth-as-moderator/workers/workers";
import {PublicWorkerProfilePage} from "../../pages/public-worker-profile/public-worker-profile";

export const moderatorRoutes: Route[] = [
	//Модератор
	{
		title: 'Вход',
		path: '/signin-moderator',
		component: <SinginModeratorPage />,
		navBar: false
	},
	{
		title: 'Профиль организации',
		path: '/moderation/:orgID?/:key?',
		component: <PublicProfileModeratorPage />,
		navBar: false
	},
	{
		title: 'Организации',
		path: '/organizations',
		component: <Organizations />,
		icon: 'format_list_bulleted',
		navBar: true,
		availableRoles: ['moderator']
	},
	{
		title: 'Профиль организации',
		path: '/organizations/organization-profile/:orgID?/:key?',
		component: <PublicProfileModeratorPage />,
		navBar: false,
		icon: 'home',
		availableRoles: ['moderator']
	},
	{
		title: 'Пользователи',
		path: '/organizations' + PagesRoutes.USERS + '/:searchID',
		component: <SuperadminUsersPage />,
		navBar: false,
		icon: 'manage_accounts',
		availableRoles: ['moderator']
	},
	{
		title: 'Архив пользователей',
		path: '/organizations' + PagesRoutes.USERS + PagesRoutes.ARCHIVE + '/:searchID',
		component: <SuperadminUsersPage />,
		navBar: false,
		availableRoles: ['moderator']
	},
	{
	    title: 'Работники',
	    path: '/mworkers',
	    component: <WorkersPage/>,
	    icon: 'groups',
	    navBar: true,
	    availableRoles: ['moderator']
	},
	{
		title: 'Профиль организации',
		path: '/mworkers/organization-profile/:orgID?/:key?',
		component: <PublicProfileModeratorPage />,
		navBar: false,
		icon: 'home',
		availableRoles: ['moderator']
	},
	{
		title: 'Публичный профиль',
		path: '/mworkers' + PagesRoutes.PUBLIC_PROFILE + '/:searchID?/:recordID?',
		component: <PublicWorkerProfilePage />,
		navBar: false,
		availableRoles: [...initialWebRoles, 'moderator']
	},
]
