export const getOption = (value: string,) => {
    let options = [
        {value: null, label: ""},
        // {value: "deleted_user", label: "Профиль удален"},
        {value: "pending", label: "Ожидает мобилизации"},
        {value: "mobilized", label: "Мобилизован"},
        {value: "demobilized", label: "Демобилизован"},
        {value: 1, label: '№1 - обычный'},
        {value: 2, label: '№2 - сокращённый'},
        {value: 3, label: '№3 - сменный'},
        {value: 4, label: '№4 - сменный'},
        {value: 5, label: '№5 - вахта'}
    ]
    return options.find(item => item.value === value)
}

export const newDateValues = (values: string[] | null) => {
    let newValues = []
    // @ts-ignore
    if ((values !== null || values !== ['', '']) && Array.isArray(values)) {
        newValues[0] = [values[0].split('.')[2], values[0].split('.')[1], values[0].split('.')[0]].join('-')
        newValues[1] = [values[1].split('.')[2], values[1].split('.')[1], values[1].split('.')[0]].join('-')
    }
    else {
        newValues = ['','']
    }
    return newValues
}