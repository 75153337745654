import BannerScr from "../../misc/banner-big.svg";
import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import {Button} from "@rmwc/button";
import {formatSecondsToTime, req} from "../../global/common";
import {SpanButtons} from "components";
import NumberFormat from 'react-number-format'
import {InterfaceContext} from "../../global/context.interface";
import {UserContext} from "../../global/context.user";
import {useTranslation} from "react-i18next";
import {User} from "../../global/definitions/definitions";
import {AuthRequest, AuthValidate} from "./Signin.types";
import {useHistory} from "react-router";
import './Signin.style.sass'
import {Checkbox} from "@rmwc/checkbox";
import {ChatContext} from "../../global/context.chat";
import {sendMyTrackerMetric} from "../../global/metrics";


export function Signin() {
    const userCTX = React.useContext(UserContext)
    const chatCTX = React.useContext(ChatContext);
    const interfaceCTX = React.useContext(InterfaceContext)
    const history = useHistory()
    const {t} = useTranslation('common')
    const [mode, setMode] = React.useState('phone')
    const [checkboxValue, setCheckboxValue] = useState(false)
    const [timer, setTimer] = React.useState<any>(null)
    const [state, setState] = React.useState<any>({
        phone: '',
        formattedPhone: '',
        code: '',
        remainResends: null
    })
    const isRegisterWorkerPage = history.location.pathname.includes('/worker/register')

    const formSubmit = React.useCallback(
        (e: BaseSyntheticEvent) => {
            e.preventDefault()

            switch (mode) {
                case 'phone': {
                    // Уточняем, есть ли в базе данный номер телефона. Если есть, отправляем запрос
                    req.post('/auth/check-profile', {phone: state.phone})
                        .then(({data}) => {
                            if (data.exists && data.confirmed && !isRegisterWorkerPage) {
                                // if ((data.roles.includes('worker') || data.roles.length === 0) && data.exists) {
                                //     interfaceCTX.showMessage({
                                //         title: 'Ошибка',
                                //         body: 'Для авторизации воспользуйтесь мобильным приложением'
                                //     })
                                //     return
                                // }
                                // отправляем запрос на получение кода, в случае удачи меняем режим
                                req.post('/auth/request', {phone: state.phone})
                                    .then(({data}: { data: AuthRequest }) => {
                                        setTimer(data.nextResendAfterMs / 1000)
                                        setState({
                                            ...state,
                                            remainResends: data.remainResends
                                        })
                                        setMode('code')
                                    })
                                    .catch((e) => {
                                        interfaceCTX.showMessage({
                                            title: 'Ошибка',
                                            body: t('error.' + e.response?.data ?? 'internet')
                                        })
                                    })

                            } else if ((!data.exists || !data.confirmed) && isRegisterWorkerPage) {
                                req.post('/auth/request', {phone: state.phone})
                                    .then(({data}: { data: AuthRequest }) => {
                                        setTimer(data.nextResendAfterMs / 1000)
                                        setState({
                                            ...state,
                                            remainResends: data.remainResends
                                        })
                                        setMode('code')
                                    })
                                    .catch((e) => {
                                        interfaceCTX.showMessage({
                                            title: 'Ошибка',
                                            body: t('error.' + e.response?.data ?? 'internet')
                                        })
                                    })
                            } else if (data.exists && !data.confirmed && !isRegisterWorkerPage) {
                                req.post('/auth/request-auth', {phone: state.phone})
                                    .then(({data}: { data: AuthRequest }) => {
                                        setTimer(data.nextResendAfterMs / 1000)
                                        setState({
                                            ...state,
                                            remainResends: data.remainResends
                                        })
                                        setMode('code')
                                    })
                                    .catch((e) => {
                                        interfaceCTX.showMessage({
                                            title: 'Ошибка',
                                            body: t('error.' + e.response?.data ?? 'internet')
                                        })
                                    })
                            } else if (!data.exists) {
                                interfaceCTX.showMessage({
                                    body: 'Пользователь с таким номером телефона не существует. Пожалуйста, зарегистрируйтесь',
                                    icon: 'report'
                                })
                            } else if (data.exists) {
                                interfaceCTX.showMessage({
                                    body: 'Пользователя с таким номером телефона уже существует',
                                    icon: 'report'
                                })
                            } else {
                                interfaceCTX.showMessage({
                                    body: 'Для авторизации в системе воспользуйтесь мобильным приложением',
                                    icon: 'report'
                                })
                            }
                        })
                        .catch((e) => {
                            interfaceCTX.showMessage({
                                title: 'Ошибка',
                                body: t('error.' + e.response?.data ?? 'internet')
                            })
                        })
                    break
                }

                case 'code': {
                    // если всё верно, устанавливаем данные о токене в localStorage, обработка идёт в index.ts.tsx
                    req.post('/auth/validate', {code: state.code, phone: state.phone})
                        .then(({data}: { data: AuthValidate }) => {
                            localStorage.setItem('tokenDeathDate', String(Date.now() + data.tokenDurationMs))
                            localStorage.setItem('token', data.token)
                            localStorage.setItem('refreshToken', data.refreshToken)
                            req.get('/profile/get')
                                .then(({data}) => {
                                    if (data?.currentRole === 'foreman' && data.roles.length > 1) {
                                        const newRole = data.roles.filter((item: string) => item !== 'foreman')
                                        req.post('/profile/peek-role', {
                                            newRole: newRole[0]
                                        }).then(() => {
                                            const newUserData = new User(data)
                                            // Отключено для тестового запуска
                                            //chatCTX.connect()
                                            userCTX.setUser({...newUserData, currentRole: newRole[0], roles: newRole})
                                        })
                                    } else {
                                        // Отключено для тестового запуска
                                        //chatCTX.connect()
                                        userCTX.setUser(new User(data))
                                    }
                                })
                        })
                        .catch((e) => {
                            interfaceCTX.showMessage({
                                title: 'Ошибка',
                                body: t('error.' + e.response.data)
                            })
                        })
                    break
                }
            }
        },
        [mode, state]
    )

    React.useEffect(() => {
        if (mode !== 'code') return
        const interval = setInterval(() => {
            setTimer((prev: number) => {
                if (prev - 1 === 0) clearInterval(interval)
                return prev - 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [mode])

    useEffect(() => {
        sendMyTrackerMetric({
            type: 'reachGoal', goal: 'login_screen',
        })
    }, [])

    const newCode = () => {
    }

    return (
        <div className="signin-page">
            <h1>{isRegisterWorkerPage ? "Регистрация работника" : "Вход (тестовая)"}</h1>
            <img src={BannerScr} alt="pragma"
                 style={{
                     height: '70px',
                     margin: '145px 0 30px 0'
                 }}
            />
            {/* <TabBar className="tabs"
                activeTabIndex={activeTab}
                onActivate={(evt) => setActiveTab(evt.detail.index.ts)}
            >
                <Tab>Я работодатель</Tab>
                <Tab>Я работник</Tab>
            </TabBar> */}
            <form onSubmit={formSubmit} className="container">


                <div className="container-body">

                    <div className="message">

                        {mode === 'code' &&
                            <>
                                Мы выслали код на номер<br/>
                                {state.formattedPhone}
                            </>
                        }
                    </div>

                    {mode === 'phone' &&
                        //@ts-ignore
                        <NumberFormat required
                                      className={'false-mdc-input-field'}
                                      name="mblz-phone"
                                      type="tel"
                                      placeholder="+7 (000) 000 00 00"
                                      format="+7 (###) ### ## ##" mask="-"
                                      onValueChange={v => setState({
                                          ...state,
                                          phone: v.value ? '8' + v.value : v.value,
                                          formattedPhone: v.formattedValue
                                      })}
                        />
                    }
                    {mode === 'code' &&
                        //@ts-ignore
                        <NumberFormat required
                                      className={'false-mdc-input-field'}
                                      placeholder="0000"
                                      format="####" mask="-"
                                      defaultValue={state.code}
                                      onValueChange={v => setState({
                                          ...state,
                                          code: v.value,
                                      })}
                        />

                    }
                    <Button raised
                            style={{color: '#FFF'}}
                            disabled={mode === 'code' ? state.code.length < 4 || state.remainResends === 0 : false || mode === 'phone' && isRegisterWorkerPage && !checkboxValue}>
                        {mode === 'code' ? 'Проверить' : !isRegisterWorkerPage ? 'Войти' : "Отправить код"}
                    </Button>
                </div>


                <div className="footer-message">
                    {!(mode === 'code' && timer !== null)
                        ? null
                        : timer! > 0
                            ? state.remainResends
                                ? <span>
                                    Получить новый код можно через {formatSecondsToTime(timer as unknown as string)}
                                </span>

                                : <span>
                                    Вы исчерпали количество попыток ввода кода.<br/>
                                    Повторный запрос кода будет доступен через 1 час.
                                </span>

                            : <SpanButtons
                                data={[
                                    {label: 'Получить новый код', onClick: newCode, tag: 'span'}
                                ]}
                            />
                    }
                    {mode === 'phone' && isRegisterWorkerPage &&
                        <div className='consent_to_processing'>
                            <Checkbox
                                onChange={(e) => setCheckboxValue(e.currentTarget.checked)}
                                checked={checkboxValue}
                            />
                            <SpanButtons
                                data={[
                                    {
                                        label: 'Я согласен (-а) на обработку персональных данных',
                                        href: `https://pragma.info/workforce/policy`,
                                        tag: 'span',
                                        class: "support",
                                        isBlank: true
                                    }
                                ]}
                            />
                        </div>
                    }
                    {mode === 'code' || isRegisterWorkerPage &&
                        <SpanButtons
                            data={[
                                {
                                    label: 'Вернуться на экран авторизации',
                                    href: `/sign-in`,
                                    tag: 'span',
                                    class: "support"
                                }
                            ]}
                        />
                    }
                    {mode === 'phone' && !isRegisterWorkerPage &&
                        <SpanButtons
                            data={[
                                {
                                    label: 'Регистрация организации',
                                    href: `/organization-signin`,
                                    tag: 'span',
                                    class: "support"
                                }
                            ]}
                        />
                    }
                    {/*{!isRegisterWorkerPage &&*/}
                    {/*    <SpanButtons*/}
                    {/*        data={[*/}
                    {/*            { label: 'Зарегистрироваться как работник', href: `/worker/register`, tag: 'span', class: "support" }*/}
                    {/*        ]}*/}
                    {/*    />*/}
                    {/*}*/}
                    <SpanButtons
                        data={[
                            {
                                label: 'Связаться с техподдержкой',
                                href: `${'mailto:' + interfaceCTX.getMailToArr()}`,
                                tag: 'span',
                                class: "support"
                            }
                        ]}
                    />
                </div>
            </form>
        </div>
    )
}