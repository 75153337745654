import {IColumnSettings, IHeaderCell, IWorker} from "./WorkersList.types";
import * as XLSX from "sheetjs-style";

export const columns: IHeaderCell[] = [
    {
        id: 0,
        sortOrder: 0,
        title: "№",
        isVisible: true,
        minWidth: '50px',
        columnType: 'number',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 1,
        sortOrder: 1,
        title: "ФИО",
        isVisible: true,
        minWidth: '250px',
        columnType: 'workerFullName',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 2,
        sortOrder: 2,
        title: "Статус",
        isVisible: true,
        minWidth: '250px',
        columnType: 'mobilizationStatus',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 3,
        sortOrder: 3,
        title: "Потребность",
        isVisible: true,
        minWidth: '250px',
        columnType: 'requestNumber',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 4,
        sortOrder: 4,
        title: "График работ",
        isVisible: true,
        minWidth: '250px',
        columnType: 'schedule',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 5,
        sortOrder: 5,
        title: "Базовая профессия",
        isVisible: true,
        minWidth: '250px',
        columnType: 'mainProfession',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 6,
        sortOrder: 6,
        title: "Табельный номер",
        isVisible: true,
        minWidth: '250px',
        columnType: 'employeeIDNumber',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 7,
        sortOrder: 7,
        title: "Дата начала договора",
        isVisible: true,
        minWidth: '250px',
        columnType: 'contractBeginDate',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 8,
        sortOrder: 8,
        title: "Дата окончания договора",
        isVisible: true,
        minWidth: '250px',
        columnType: 'contractEndDate',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 9,
        sortOrder: 9,
        title: "Адрес места жительства",
        isVisible: true,
        minWidth: '250px',
        columnType: 'workerActualAddress',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 10,
        sortOrder: 10,
        title: "Объект",
        isVisible: true,
        minWidth: '250px',
        columnType: 'objectName',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 11,
        sortOrder: 11,
        title: "Бригада",
        isVisible: true,
        minWidth: '250px',
        columnType: 'brigadeName',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 12,
        sortOrder: 12,
        title: "Ответственный",
        isVisible: true,
        minWidth: '240px',
        columnType: 'foremanFullName',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 13,
        sortOrder: 13,
        title: "Организатор",
        isVisible: true,
        minWidth: '250px',
        columnType: 'organizerFullName',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 14,
        sortOrder: 14,
        title: "Email",
        isVisible: true,
        minWidth: '250px',
        columnType: 'contactEmail',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 15,
        sortOrder: 15,
        title: "Отработано смен по договору",
        isVisible: true,
        minWidth: '250px',
        columnType: 'countShifts',
        sortType: 'NONE',
        filterType: null
    },
    {
        id: 16,
        sortOrder: 16,
        title: "Отработано часов по договору",
        isVisible: true,
        minWidth: '250px',
        columnType: 'countTime',
        sortType: 'NONE',
        filterType: null
    }
]

export const settings: IColumnSettings[] = [
    {
        id: 0,
        sortOrder: 0,
        visibility: true,
        columnType: 'number',
        sortType: 'NONE',
        filterType: null,
        startOrder: 0
    },
    {
        id: 1,
        sortOrder: 1,
        visibility: true,
        columnType: 'workerFullName',
        sortType: 'ASC',
        filterType: null,
        startOrder: 1
    },
    {
        id: 2,
        sortOrder: 2,
        visibility: true,
        columnType: 'mobilizationStatus',
        sortType: 'NONE',
        filterType: null,
        startOrder: 2
    },
    {
        id: 3,
        sortOrder: 3,
        visibility: true,
        columnType: 'requestNumber',
        sortType: 'NONE',
        filterType: null,
        startOrder: 3
    },
    {
        id: 4,
        sortOrder: 4,
        visibility: true,
        columnType: 'schedule',
        sortType: 'NONE',
        filterType: null,
        startOrder: 4
    },
    {
        id: 5,
        sortOrder: 5,
        visibility: true,
        columnType: 'mainProfession',
        sortType: 'NONE',
        filterType: null,
        startOrder: 5
    },
    {
        id: 6,
        sortOrder: 6,
        visibility: true,
        columnType: 'employeeIDNumber',
        sortType: 'NONE',
        filterType: null,
        startOrder: 6
    },
    {
        id: 7,
        sortOrder: 7,
        visibility: true,
        columnType: 'contractBeginDate',
        sortType: 'NONE',
        filterType: null,
        startOrder: 7
    },
    {
        id: 8,
        sortOrder: 8,
        visibility: true,
        columnType: 'contractEndDate',
        sortType: 'NONE',
        filterType: null,
        startOrder: 8
    },
    {
        id: 9,
        sortOrder: 9,
        visibility: true,
        columnType: 'workerActualAddress',
        sortType: 'NONE',
        filterType: null,
        startOrder: 9
    },
    {
        id: 10,
        sortOrder: 10,
        visibility: true,
        columnType: 'objectName',
        sortType: 'NONE',
        filterType: null,
        startOrder: 10
    },
    {
        id: 11,
        sortOrder: 11,
        visibility: true,
        columnType: 'brigadeName',
        sortType: 'NONE',
        filterType: null,
        startOrder: 11
    },
    {
        id: 12,
        sortOrder: 12,
        visibility: true,
        columnType: 'foremanFullName',
        sortType: 'NONE',
        filterType: null,
        startOrder: 12
    },
    {
        id: 13,
        sortOrder: 13,
        visibility: true,
        columnType: 'organizerFullName',
        sortType: 'NONE',
        filterType: null,
        startOrder: 13
    },
    {
        id: 14,
        sortOrder: 14,
        visibility: true,
        columnType: 'contactEmail',
        sortType: 'NONE',
        filterType: null,
        startOrder: 14
    },
    {
        id: 15,
        sortOrder: 15,
        visibility: true,
        columnType: 'countShifts',
        sortType: 'NONE',
        filterType: null,
        startOrder: 15,
    },
    {
        id: 16,
        sortOrder: 16,
        visibility: true,
        columnType: 'countTime',
        sortType: 'NONE',
        filterType: null,
        startOrder: 16
    }
]

export const headerTableTitleStyle = {
    font: {
        name: "arial",
        sz: 16,
        bold: true
    },
    fill: {
        fgColor: {
            rgb: "FFFFFF"
        }
    },
    alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: '1',
    },
    border: {
        right: {
            style: "medium",
            color: "000000"
        },
        left: {
            style: "thin",
            color: "000000"
        },
        bottom: {
            style: "medium",
            color: "000000"
        }
    }
}

export const settingsStyle = {
    font: {
        name: "arial",
        sz: 11,
    },
    fill: {
        fgColor: {
            rgb: "FFFFFF"
        }
    },
    alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: '1',
    },
    border: {
        right: {
            style: "medium",
            color: "000000"
        },
        left: {
            style: "thin",
            color: "000000"
        },
        bottom: {
            style: "medium",
            color: "000000"
        },
        top: {
            style: "medium",
            color: "000000"
        }
    }
}

export const headerTableStyle = {
    font: {
        name: "arial",
        sz: 12,
        bold: true
    },
    fill: {
        fgColor: {
            rgb: "DEE7EF"
        }
    },
    alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: '1',
    },
    border: {
        right: {
            style: "medium",
            color: "000000"
        },
        left: {
            style: "thin",
            color: "000000"
        },
        bottom: {
            style: "medium",
            color: "000000"
        },
        top: {
            style: "medium",
            color: "000000"
        }
    }
}

export const bodyTableStyle = {
    font: {
        name: "arial",
        sz: 12,
    },
    fill: {
        fgColor: {
            rgb: "FFFFFF"
        }
    },
    alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: '1',
    },
    border: {
        right: {
            style: "thin",
            color: "000000"
        },
        left: {
            style: "thin",
            color: "000000"
        },
        bottom: {
            style: "thin",
            color: "000000"
        }
    }
}

export const schedules = [
    {value: 1, label: '№1 - обычный'},
    {value: 2, label: '№2 - сокращённый'},
    {value: 3, label: '№3 - сменный'},
    {value: 4, label: '№4 - сменный'},
    {value: 5, label: '№5 - вахта'}]

export const downloadTable = (workers: IWorker[], columnSettings: IColumnSettings[], orderedColumns: IHeaderCell[], t: any) => {
    const thereIs = (value: any) => {
        if (!!value && value !== 0) {
            return value
        } else if (value === 0) {
            return "0"
        } else return ' '
    }

    const workersTable = workers.map((worker, index) => {
        const newWorker: IWorker = {} as IWorker
        columnSettings.forEach((column) => {
            if (column.columnType === 'number' && column.visibility) {
                return newWorker[column.columnType] = index + 1
            } else if (column.columnType === 'schedule' && column.visibility) {
                return newWorker[column.columnType] = thereIs("№ " + worker.schedule.id + " - " + worker.schedule.shortName)
            } else if (column.columnType === 'countTime' && column.visibility) {
                return newWorker[column.columnType] = thereIs(worker.countTime.hours)
            } else if (column.columnType === 'workerActualAddress' && column.visibility) {
                return newWorker[column.columnType] = thereIs(worker.workerActualAddress?.address)
            } else if (column.columnType === 'employeeIDNumber' && column.visibility) {
                return newWorker[column.columnType] = thereIs(worker.employeeIDNumber)
            } else if (column.columnType === 'mobilizationStatus' && column.visibility) {
                return newWorker[column.columnType] = thereIs((worker.isDeleted ? t("mobilizationStatus.deleted") : worker.mobilizationStatus && t("mobilizationStatus." + worker.mobilizationStatus)))
            } else if (column.columnType === 'mainProfession' && column.visibility) {
                return newWorker[column.columnType] = thereIs(worker.mainProfession.name)
            } else if (column.columnType && column.visibility) {
                // @ts-ignore
                return newWorker[column.columnType] = thereIs(worker[column.columnType])
            }
        })
        return newWorker
    })

    const tableSettings = () => {
        let resultFilter = ['Отфильтровано по: ']
        let resultSort = ['Сортировано по: ']
        columnSettings.map((setting, index) => {
            if(setting.filterType) {
                // @ts-ignore
                resultFilter.push(orderedColumns.find(item => item.columnType === setting.columnType).title + ', ')
            }
            if (setting.sortType !== 'NONE') {
                // @ts-ignore
                resultSort.push(orderedColumns.find(item => item.columnType === setting.columnType).title)
            }
        })
        return [resultFilter.join(''),'','', resultSort.join('')]
    }

    const columnsTable: IHeaderCell[] = []
    orderedColumns.forEach(orderedColumn => {
        if (orderedColumn.isVisible) return columnsTable.push(orderedColumn)
    })

    var workbook = XLSX.utils.book_new()
    var worksheet = XLSX.utils.aoa_to_sheet(
        [
            ['Работники'],
            tableSettings(),
            columnsTable.map((c: any, i) => c.title),
            ...workersTable.map((c: any) => Object.values(c))
        ]
    )
    for (let i in worksheet) {
        if (typeof (worksheet[i]) != "object") continue;
        if ((/^[A-Z]1+$\b/).test(i) && i.length === 2) {
            worksheet[i].s = headerTableTitleStyle
        } else if ((/^[A-Z]2+$\b/).test(i) && i.length === 2) {
            //Стили заголовков
            worksheet[i].s = settingsStyle
        } else if ((/^[A-Z]3+$\b/).test(i) && i.length === 2) {
            //Стили заголовков
            worksheet[i].s = headerTableStyle
        } else {
            //Стили остальной части
            worksheet[i].s = bodyTableStyle
        }

    }
    worksheet["!cols"] = columns.map((c: any, i) => {
        if (i === 0) {
            return {wch: 5}
        } else {
            return  {wch: 30}
        }
    })
    worksheet["!rows"] = columns.map((c: any, i) => {
        if (i === 0) {
            return {hpt: 50}
        } else if (i === 1) {
            return {hpt: 60}
        } else {
            return  {hpt: 30}
        }
    })
    worksheet['!merges'] = [{s:{c: 0, r: 0},e:{c: 1, r: 0}},{s:{c: 0, r: 1},e:{c: 2, r: 1}}]
    XLSX.utils.book_append_sheet(workbook, worksheet, "Работники")
    let data = XLSX.writeFile(workbook, 'Работники.xlsx', {bookType: 'xlsx', cellStyles: true})
}
