import {PagesRoutes} from 'global/routes';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import {TableCell} from "../../../components/Table/TableCell/TableCell";
import {IOrganization} from "./organizations-def";
import {getOrganizationStatusColor, organizationSettings} from "./organizations-utils";
import './organizations.sass';
import {req} from "../../../global/common";
import {
    ExtendedCustomTable,
    IColumnSettings,
    IExtendedCustomTableApiURLS,
    ISortingAndFilteringArray
} from "../../../components";

export const Organizations = () => {
    const {t} = useTranslation('common')
    const history = useHistory()
    const apiURLS: IExtendedCustomTableApiURLS = {
        getTableValues: '/moderator/organisation/list',
        getUniqueColumnValues: '/moderator/organisation/unique',
        getTableSettings: '/moderator/organisation/table/settings/get',
        saveTableSettings: '/moderator/organisation/table/settings/set'
    }

    const renderFilterValue = (
        filterValue: ISortingAndFilteringArray['filterType'],
        columnType: IColumnSettings['columnType']
    ) => {
        let values: string = ''
        if (!!filterValue) {
            if (columnType === 'moderationStatus') {
                values = filterValue.map(item => t('extended_custom_table.' + item)).join(', ')
                return values
            } else if (columnType.toLowerCase().includes('date')) {
                values = filterValue.join(' - ')
            } else {
                values = filterValue.join(', ')
            }
            return values
        }
        return values
    }

    const getOrganizationKey = (id: number) => {
        req.post(`/moderator/organisation/key?organisationId=${id}`)
            .then(({data}) => {
                history.push({
                    pathname: `/organizations/organization-profile/${data.organisationId}/${data.key}`,
                    state: history.location.state
                })
            })
    }

    const renderRow = (tableValue: any, columnSettings: IColumnSettings[], cellIsActive: (columnType: string, columnSettings: IColumnSettings[]) => string,) => {
        return columnSettings.map((column, index) => {
            if (column.columnType === 'moderationStatus' && column.visibility) {
                return (
                    <TableCell key={column.columnType + index} devider
                               style={{
                                   minWidth: column.minWidth,
                                   background: cellIsActive(column.columnType, columnSettings),
                                   height: '100%'
                               }}>
                        <div className={'organization-status'}>
                            <div className={'organization-status-color'}
                                 style={{backgroundColor: getOrganizationStatusColor(tableValue.moderationStatus)}}/>
                            <div className={'organization-status-text'}>
                                {t('extended_custom_table.' + tableValue.moderationStatus)}
                            </div>
                        </div>
                    </TableCell>
                )
            } else if (column.visibility && column.columnType === 'fullName') {
                return (
                    <TableCell key={column.columnType + index} devider
                               style={{
                                   minWidth: column.minWidth,
                                   background: cellIsActive(column.columnType, columnSettings),
                                   height: '100%'
                               }}>
                        <div className={'organization-name'}
                             style={{cursor: 'pointer'}}
                             onClick={() => {
                                 getOrganizationKey(tableValue.id)
                             }}
                        >{tableValue[column.columnType]}</div>
                    </TableCell>
                )
            } else if (column.visibility && column.columnType === 'countActiveUsers') {
                return (
                    <TableCell key={column.columnType + index} devider
                               style={{
                                   minWidth: column.minWidth,
                                   background: cellIsActive(column.columnType, columnSettings),
                                   height: '100%'
                               }}>
                        <Link className={'organization-name'}
                              to={{
                                  pathname: '/organizations' + PagesRoutes.USERS + `/${tableValue.id}`,
                                  state: history.location.state
                              }}>{tableValue[column.columnType]}</Link>
                    </TableCell>
                )
            } else if (column.columnType && column.visibility) {
                return (
                    <TableCell key={column.columnType + index} devider
                               style={{
                                   minWidth: column.minWidth,
                                   background: cellIsActive(column.columnType, columnSettings),
                                   height: '100%'
                               }}>
                        {tableValue[column.columnType]}
                    </TableCell>
                )
            } else return <></>
        })
    }

    const reorderData = (columnSettings: IColumnSettings[], thereIs: (value: any) => any, tableValues: any[]) => {
        return tableValues.map((value: IOrganization, index) => {
            const item: IOrganization = {} as IOrganization
            columnSettings.forEach((column) => {
                if (column.columnType === 'moderationStatus' && column.visibility) {
                    return item[column.columnType] = t('extended_custom_table.' + value.moderationStatus)
                } else if (column.columnType && column.visibility) {
                    //@ts-ignore
                    return item[column.columnType] = value[column.columnType]
                }
            })
            return item
        })
    }

    return (

        <>
            <ExtendedCustomTable
                rootClassName={'organizations-page'}
                renderFilterValue={renderFilterValue}
                renderRow={renderRow}
                apiURLS={apiURLS}
                settings={organizationSettings}
                pageTitle={'Организации'}
                formattedTableValuesForEXEL={reorderData}
            />
        </>
    )
}
