import { Params } from '../../public-profile-def'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import React from 'react'
import { InterfaceContext } from '../../../../global/context.interface'
import { req } from '../../../../global/common'
import { Icon } from '@rmwc/icon'
import { SimpleDialog } from '@rmwc/dialog'
import { CompanyAvatarProps } from './company-avatar-def'
import { AvatarEditor } from '../../../../components/AvatarEditor/AvatarEditor'

export function CompanyAvatar({ image, viewMode }: CompanyAvatarProps) {
	const { t } = useTranslation('common')
	const { searchID } = useParams() as Params
	const interfaceCTX = React.useContext(InterfaceContext)
	const [imageState, setImageState] = React.useState<string | undefined>(image)
	const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false)
	const [openUploadDialog, setOpenUploadDialog] = React.useState(false)
	const [renderToPortal, setRenderToPortal] = React.useState(true)

	React.useEffect(() => {
		req
			.get(`/organisation/${searchID}/get`)
			.then(({ data }) => {
				setImageState(data.logo)
			})
			.catch(() =>
				interfaceCTX.showMessage({
					body: 'Не удалось загрузить лого компании',
					icon: 'report'
				})
			)
	}, [searchID])

	const onImageUpdate = (file: Blob) => {
		const params = new FormData()
		params.append('file', file)

		req
			.post(`/organisation/${searchID}/upload-logo`, params)
			.then(({ data }) => {
				setImageState(data.logo)
			})
			.then(() => interfaceCTX.refreshTopBar())
			.catch(e =>
				interfaceCTX.showMessage({
					body: 'Не удается загрузить логотип',
					icon: 'report'
				})
			)
	}

	const onImageSave = (file: any) => {
		const params = new FormData()
		params.append('file', file)

		req
			.post(`/organisation/${searchID}/upload-logo`, params)
			.then(({ data }) => {
				setImageState(data.logo)
				setOpenUploadDialog(false)
				interfaceCTX.refreshTopBar()
			})
			.catch(e =>
				interfaceCTX.showMessage({
					body: 'Не удается загрузить логотип',
					icon: 'report'
				})
			)
	}

	const onImageDelete = () => {
		req
			.delete(`/organisation/${searchID}/delete-logo`)
			.then(() => {
				setImageState(undefined)
				interfaceCTX.refreshTopBar()
				interfaceCTX.showMessage({
					body: 'Логотип удален',
					icon: 'done'
				})
			})
			.catch(e => {
				interfaceCTX.showMessage({
					title: 'Ошибка',
					body: t('error.' + e.response.data)
				})
			})
	}

	return (
		<div className={'avatar-container'}>
			<div
				className='image-input avatar-block--contain'
				style={{
					backgroundImage: `url(${imageState})`,
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundSize: 'contain'
				}}
			>
				{!viewMode && !imageState && (
					<div className='avatar-upload' onClick={() => setOpenUploadDialog(true)}>
						<Icon className='add-photo' icon='add_a_photo' onClick={() => interfaceCTX.openInput()} />
					</div>
				)}
			</div>
			{!viewMode && imageState && <Icon className='cancel' icon='cancel' onClick={() => setOpenRemoveDialog(true)} />}
			<AvatarEditor
				onImageSave={onImageSave}
				aspectRatio={0}
				isOpen={openUploadDialog}
				setIsOpen={setOpenUploadDialog}
			/>
			<SimpleDialog
				renderToPortal={renderToPortal}
				open={openRemoveDialog}
				body={
					<div>
						Логотип будет удален безвозвратно
						<br />
						Вы уверены?
					</div>
				}
				cancelLabel={'Нет'}
				acceptLabel={'Да'}
				onClosed={e => {
					e.preventDefault()
					if (e.detail.action === 'accept') {
						onImageDelete()
						setOpenRemoveDialog(false)
					} else {
						setOpenRemoveDialog(false)
					}
				}}
			/>
		</div>
	)
}
