import {LinearProgress} from "@rmwc/linear-progress";
import React from "react";
import {useTranslation} from "react-i18next";
import "./Need.style.sass";
import {INeedCard} from "./Need.types";



const statusesToShow = [
    "pending",
    "documents_preparation",
    "documents_undelivered",
    "signature_awaiting",
    "signed",
    "accepted",
    "documents_awaiting",
    "documents_confirmed"
];

export function authorNameShort(author: any) {
    let lastName = author!.lastName && author!.lastName + " ";
    let firstName = author!.firstName && author!.firstName[0] + ". ";
    let middleName = author!.middleName && author!.middleName[0];
    return lastName + firstName + middleName;
}

export function NeedCard(props: INeedCard) {
    const {t} = useTranslation("common");

    React.useEffect(() => {
    }, []);

    return (
        <div className="need-wrapper" onClick={props.onClick}>
            <div className="need-params-wrapper">
                <div className="date-and-status-wrapper need-detail  gray">
                    <div>{t("request.state." + props.need.state)}</div>
                    <div className={"bold"}>{props.need.publicationDate ? props.need.publicationDate : props.need.date}</div>
                </div>
                {/* <div className="initiator-wrapper"> */}
                <div className="initiator need-detail gray">
                    <div className={"title"}>Инициатор</div>
                    <div className={"text bold"}>
                        {authorNameShort(props.need!.author)}
                    </div>
                </div>
                <div className="number need-detail gray">
                    <div className={"title"}>Номер заявки</div>
                    <div className={"text bold"}>
                        {props.need.number}
                    </div>
                </div>

                <div className="position need-detail gray">
                    <div className={"title"}>Должность кандидата</div>
                    <div className={"text bold"}>
                        {props.need!.position ? props.need!.position : "Не указано"}
                    </div>
                </div>
                {/* </div> */}
            </div>
            <div className="quantity-total need-row bold">
                Вакансий в заявке {props.need!.quantity ? props.need!.quantity : 0}
            </div>
            <div className="fill-progress need-row">
                <div className="title">
                    Заполненность{" "}
                    {props.need!.quantity
                        ? (
                            (Number(props.need.responses.accepted) /
                                Number(props.need!.quantity)) *
                            100
                        ).toFixed()
                        : 0}
                    %
                </div>
                <LinearProgress
                    progress={
                        Number(props.need.responses.accepted) / Number(props.need!.quantity)
                    }
                />
            </div>
            <div className="response-statuses-wrapper">
                {statusesToShow.map((status) => {
                    return props.need.responses[status] ? (
                        <div className={`${status} response-status`} key={status}>
                            <div className={"title"}>{t("response." + status)}</div>
                            <div className={"count"}>{props.need.responses[status]}</div>
                        </div>
                    ) : (
                        <div className={`${status} response-status`} key={status}>
                            <div className={"title"}>{t("response." + status)}</div>
                            <div className={"count"}>{0}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
