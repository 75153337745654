import '@rmwc/typography/styles'
import {PageTitle, ProgressPage, FixedButtons} from 'components'
import {profileApi, UserProfileUpdateRequest} from 'global/api'
import {InterfaceContext} from 'global/context.interface'
import {UserContext} from 'global/context.user'
import {reformatToMask} from 'pages/auth-as-worker/auth-as-worker-utils'
import React, {MutableRefObject, useContext, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ProfileForm} from './components'
import {ProfileState} from './profile-def'
import './profile.sass'
import {Elevation} from "@rmwc/elevation";
import Reviews from "../auth-as-worker/profile/subPages/reviews/reviews";
import {PendingReview, PendingReviewContextProvider} from "../auth-as-worker/profile/subPages/pending-review";
import {useReviewsOptions} from "../auth-as-worker/profile/worker-profile-utils";
import {Tab, TabBar} from "@rmwc/tabs";

export default function ProfilePage() {
    const userCTX = useContext(UserContext)
    const {refreshTopBar, showMessage} = useContext(InterfaceContext)
    const formRef = useRef() as MutableRefObject<HTMLFormElement>
    const [loaded, setLoaded] = useState<boolean | null>(null)
    const [mode, setMode] = useState('view')
    const [state, setState] = useState<any>({} as ProfileState)
    const {t} = useTranslation('common')
    const [activeTab, setActiveTab] = useState(0)
    const reviews = useReviewsOptions()
    const interfaceCTX = useContext(InterfaceContext)

    React.useEffect(() => {
        refreshTopBar()
    }, [state])

    React.useEffect(() => {
        profileApi.getProfile().then(response => {
            const user = response.data

            setState({
                lastName: user.lastName,
                firstName: user.firstName,
                middleName: user.middleName,
                contactEmail: user.contactEmail,
                contactPhone: user.contactPhone,
                birthDate: user.birthDate,
                phone: user.phone
            })
            setLoaded(true)
        })
    }, [])

    const onSubmit = (data: UserProfileUpdateRequest) => {
        profileApi
            .updateProfile(data)
            .then(() => {
                setMode('view')
                setState(data)
            })
            .then(() => {
                showMessage({
                    body: 'Данные успешно сохранены',
                    icon: 'done'
                })
            })
            .catch(e => {
                showMessage({
                    title: 'Ошибка',
                    body: t('error.' + e.response.data)
                })
            })
    }

    return (
        <ProgressPage
            className='profile-page'
            state={loaded}
            render={() => (
                <div className='profile-page-wrapper'>
                    <PageTitle
                        title={
                            <div className={'page-title'}>
                                <div>{'Профиль пользователя'}</div>
                                &nbsp;
                                <div>{state.phone && reformatToMask(state.phone)}</div>
                            </div>
                        }
                    />
                    <ProfileForm formRef={formRef} mode={mode === 'view'} profile={state} onSubmit={onSubmit}/>
                    <FixedButtons
                        length={3}
                        buttons={[
                            {
                                label: 'Редактировать',
                                primary: true,
                                onClick: () => setMode('edit'),
                                hide: mode === 'edit'
                            },
                            {
                                label: 'Сохранить',
                                primary: true,
                                onClick: () => formRef.current.dispatchEvent(new Event('submit', {
                                    cancelable: true,
                                    bubbles: true
                                })),
                                hide: mode === 'view'
                            }
                        ]}
                    />
                    {userCTX.user?.currentRole === 'organizer' &&
                        <Elevation
                            z={3}
                            // className={openEditWorkerProfileForm === 'worker-profile' ? 'tabs-button opacity noSelect' : 'tabs-button'}
                            style={{marginTop: '20px'}}
                        >
                            <TabBar
                                style={{background: '#E1E1E7', minHeight: '64px'}}
                                activeTabIndex={activeTab}
                                onActivate={(e) => {
                                    if (interfaceCTX.isFormChanged.review) {
                                        if (window.confirm('Все несохраненные данные будут удалены. Вы уверены?')) {
                                            setActiveTab(e.detail.index)
                                            interfaceCTX.checkIsFormChanged(false, 'review')
                                        }
                                    } else {
                                        setActiveTab(e.detail.index)
                                    }
                                }}
                            >
                                {['Отзывы', 'Ожидающие отзыва'].map((name, i) => (
                                    <Tab
                                        className='tab'
                                        key={name}
                                        style={{
                                            background: activeTab === i ? '#FFFFFF' : '#E1E1E7',
                                            maxWidth: i === 0 ? '250px' : '300px'
                                        }}
                                        iconIndicator=' '
                                    >
                                        <h4>{name}</h4>
                                    </Tab>
                                ))}
                            </TabBar>

                            {activeTab === 0 &&
                                (reviews?.length ? (
                                    <Reviews reviews={reviews}/>
                                ) : (
                                    <div className='page-default-reviews'>Список пуст</div>
                                ))}
                            {activeTab === 1 && (
                                <PendingReviewContextProvider>
                                    <PendingReview/>
                                </PendingReviewContextProvider>
                            )}
                        </Elevation>
                    }
                </div>
            )}
        />
    )
}
