import { CircularProgress } from '@rmwc/circular-progress'
import FullpageAlert from '../FullpageAlert/FullpageAlert'
import '@rmwc/circular-progress/styles'
import { ProgressPageProps } from './ProgressPage.types'
import { FC } from 'react'

export const ProgressPage: FC<ProgressPageProps> = ({ className, alertTitle, state, render }): JSX.Element => {
	let rt: JSX.Element

	if (state === null) rt = <CircularProgress className='circular-progress--center' />
	else if (state === false) rt = <FullpageAlert label={alertTitle} />
	else rt = render()

	if (!className) return rt
	return <div className={className}>{rt}</div>
}
