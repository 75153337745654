import './documents.sass'
import {Documents, DocumentsUpdated} from "../../components/documents-component/documents-component";
import {InfoBlock} from "../../../../components/InfoBlock/InfoBlock";
import {IDocumentStatus, Props} from './documents-def'
import {req} from "../../../../global/common";
import React, {useContext, useEffect, useState} from "react";
import {Icon} from "@rmwc/icon";
import {Button} from "@rmwc/button";
import {EAcceptValues} from "../../dump/dump";
import {UserContext} from "../../../../global/context.user";


export function DocumentsSubPage({
                                     workerProfile,
                                     workerId,
                                     isVisibleProfileUpdate,
                                     versionProfile,
                                     acceptChangeResponse,
                                     toggleAcceptValueInUserDumpProfile
                                 }: Props) {
    const [documentStatus, setDocumentStatus] = useState<IDocumentStatus>({
        userId: Number(workerId)
    })
    const userCTX = useContext(UserContext)

    const updateDocumentStatus = (key: string, value: boolean) => {
        let newState: IDocumentStatus = {
            ...documentStatus,
            [key]: value
        } as IDocumentStatus
        setDocumentStatus(newState)
        saveDocumentsStatus(newState)
    }

    const saveDocumentsStatus = (newDocumentsStatus: IDocumentStatus) => {
        req.post(`/profile/worker/documents/additional/check-user-documents`, {...newDocumentsStatus})
            .then(({data}) => {
            })
    }

    useEffect(() => {
        req.get(`/profile/worker/documents/additional/get-documents-status?userId=${workerId}`)
            .then(({data}) => {
                setDocumentStatus({...data, userId: data.userId ? data.userId : Number(workerId)})
            })
    }, [])

    return (
        <div className="documents-subpage-container">
            {workerProfile.personalInfo.citizenship || workerProfile.documents.inn || workerProfile.documents.snils ?
                <div className="section">
                    {(workerProfile.documents.inn || workerProfile.documents.snils || workerProfile.documents.passport) && userCTX.user?.currentRole !== 'organizer' && userCTX.user?.currentRole !== 'moderator' &&
                        <Button className='documents-status-btn'
                                raised={true}
                                onClick={() => {
                                    const newDocumentStatus = {
                                        userId: Number(workerId),
                                    } as IDocumentStatus
                                    if (workerProfile.documents.inn) {
                                        newDocumentStatus.inn = true
                                    }
                                    if (workerProfile.documents.snils) {
                                        newDocumentStatus.snils = true
                                    }
                                    if (workerProfile.documents.passport) {
                                        newDocumentStatus.passport = true
                                    }
                                    setDocumentStatus(newDocumentStatus)
                                    saveDocumentsStatus(newDocumentStatus)
                                }}
                                label='Все реквизиты прошли проверку'>
                        </Button>
                    }
                    <div className="info-item email" style={{marginBottom: '16px'}}>
                        <InfoBlock
                            title="Контактный e-mail"
                            content={
                                <>
                                    <a className="text email"
                                       href={`mailto:${workerProfile.personalInfo.contactEmail}`}>
                                        {workerProfile.personalInfo.contactEmail}
                                    </a>
                                    {workerProfile.personalInfo.contactEmailConfirmed
                                        ? <div className='contactEmailConfirmed'>
                                            <Icon className='check_circle' icon={"check_circle"}/>
                                            <div className='confirmed'>Е-mail подтвержден</div>
                                        </div>
                                        : <div className='contactEmailConfirmed'>
                                            <Icon className='info' icon={"info"}/>
                                            <div className='confirmed'>Е-mail не подтвержден</div>
                                        </div>
                                    }
                                </>
                            }
                        />
                        {!isVisibleProfileUpdate && acceptChangeResponse?.contactEmail === EAcceptValues.AWAIT_ACCEPT &&
                            <Icon className='edit_note'
                                  icon={"edit_note"}/>}
                        {isVisibleProfileUpdate && versionProfile && acceptChangeResponse?.contactEmail === EAcceptValues.AWAIT_ACCEPT &&
                            <>
                                <Icon className='keyboard_double_arrow_right'
                                      icon={"keyboard_double_arrow_right"}/>
                                <div>
                                    <InfoBlock
                                        title="Контактный e-mail"
                                        content={
                                            <>
                                                <a className="text email info-item-version"
                                                   href={`mailto:${versionProfile.personalInfo.contactEmail}`}>
                                                    {versionProfile.personalInfo.contactEmail}
                                                </a>
                                                {versionProfile.personalInfo.contactEmailConfirmed
                                                    ? <div className='contactEmailConfirmed'>
                                                        <Icon className='check_circle'
                                                              icon={"check_circle"}/>
                                                        <div className='confirmed'>Е-mail подтвержден
                                                        </div>
                                                    </div>
                                                    : <div className='contactEmailConfirmed'>
                                                        <Icon className='info' icon={"info"}/>
                                                        <div className='confirmed'>Е-mail не
                                                            подтвержден
                                                        </div>
                                                    </div>
                                                }
                                                {userCTX.user?.currentRole !== 'organizer' &&
                                                    <div className='version-buttons' style={{display: 'flex'}}>
                                                        <Icon className='done' icon={"done"}
                                                              onClick={() => {
                                                                  toggleAcceptValueInUserDumpProfile && toggleAcceptValueInUserDumpProfile({contactEmail: EAcceptValues.ACCEPTED})
                                                              }}
                                                        />
                                                        <Icon className='close' icon={"close"}
                                                              onClick={() => {
                                                                  toggleAcceptValueInUserDumpProfile && toggleAcceptValueInUserDumpProfile({contactEmail: EAcceptValues.REFUSED})
                                                              }}
                                                        />
                                                    </div>
                                                }
                                            </>
                                        }
                                    />
                                </div>
                            </>
                        }
                    </div>
                    {workerProfile.personalInfo.citizenship &&
                        <div className="citizenship">
                            <InfoBlock
                                title="Гражданство"
                                content={workerProfile.personalInfo.citizenship}
                            />
                        </div>
                    }
                    <div className="documents-list">
                        {workerProfile.documents.inn &&
                            <div className='documents-list-versions'>
                                <Documents
                                    docName="ИНН"
                                    docRegNum={workerProfile.documents.inn}
                                    pathPreview={workerProfile.documents.media?.filter((el: any) => el.type === 'inn')[0] && workerProfile.documents.media.filter((el: any) => el.type === 'inn')[0].pathPreview}
                                    path={workerProfile.documents.media?.filter((el: any) => el.type === 'inn')[0] && workerProfile.documents.media.filter((el: any) => el.type === 'inn')[0].path}
                                    isDocumentStatus={documentStatus.inn}
                                    updateDocumentStatus={updateDocumentStatus}
                                    documentType={'inn'}
                                    isVisibleProfileUpdate={isVisibleProfileUpdate !== undefined && !isVisibleProfileUpdate && acceptChangeResponse?.inn === EAcceptValues.AWAIT_ACCEPT}
                                />
                                {isVisibleProfileUpdate && versionProfile && acceptChangeResponse?.inn === EAcceptValues.AWAIT_ACCEPT &&
                                    <>
                                        <Icon className='keyboard_double_arrow_right'
                                              style={{margin: "auto 8px auto 0"}}
                                              icon={"keyboard_double_arrow_right"}/>
                                        <DocumentsUpdated
                                            docName="ИНН"
                                            docRegNum={versionProfile.documents.inn}
                                            pathPreview={versionProfile.documents.media?.filter((el: any) => el.type === 'inn')[0] && versionProfile.documents.media.filter((el: any) => el.type === 'inn')[0].pathPreview}
                                            path={versionProfile.documents.media?.filter((el: any) => el.type === 'inn')[0] && versionProfile.documents.media.filter((el: any) => el.type === 'inn')[0].path}
                                            documentType={'inn'}
                                            toggleAcceptValueInUserDumpProfile={toggleAcceptValueInUserDumpProfile}
                                            fieldName={'inn'}
                                        />
                                    </>
                                }
                            </div>
                        }
                        {workerProfile.documents.snils &&
                            <div className='documents-list-versions'>
                                <Documents
                                    docName="СНИЛС"
                                    docRegNum={workerProfile.documents.snils}
                                    pathPreview={workerProfile.documents.media?.filter((el: any) => el.type === 'snils')[0] && workerProfile.documents.media.filter((el: any) => el.type === 'snils')[0].pathPreview}
                                    path={workerProfile.documents.media?.filter((el: any) => el.type === 'snils')[0] && workerProfile.documents.media.filter((el: any) => el.type === 'snils')[0].path}
                                    isDocumentStatus={documentStatus.snils}
                                    updateDocumentStatus={updateDocumentStatus}
                                    documentType={'snils'}
                                    isVisibleProfileUpdate={isVisibleProfileUpdate !== undefined && !isVisibleProfileUpdate && acceptChangeResponse?.snils === EAcceptValues.AWAIT_ACCEPT}
                                />
                                {isVisibleProfileUpdate && versionProfile && acceptChangeResponse?.snils === EAcceptValues.AWAIT_ACCEPT &&
                                    <>
                                        <Icon className='keyboard_double_arrow_right'
                                              style={{margin: "auto 8px auto 0"}}
                                              icon={"keyboard_double_arrow_right"}/>
                                        <DocumentsUpdated
                                            docName="СНИЛС"
                                            docRegNum={versionProfile.documents.snils}
                                            pathPreview={versionProfile.documents.media?.filter((el: any) => el.type === 'snils')[0] && versionProfile.documents.media.filter((el: any) => el.type === 'snils')[0].pathPreview}
                                            path={versionProfile.documents.media?.filter((el: any) => el.type === 'snils')[0] && versionProfile.documents.media.filter((el: any) => el.type === 'snils')[0].path}
                                            documentType={'snils'}
                                            toggleAcceptValueInUserDumpProfile={toggleAcceptValueInUserDumpProfile}
                                            fieldName={'snils'}
                                        />
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
                :
                ''
            }

            {workerProfile.documents.passport &&
                <div className="passport-container section">
                    <div style={{flex: "0 0 45%"}}>
                        <div className="info-wrapper">
                            <InfoBlock
                                title="Документ, удостоверяющий личность"
                                content={workerProfile.personalInfo.citizenship}
                            />
                        </div>

                        <div className="passport-serial-number-wrapper">
                            <div className="info-wrapper">
                                <InfoBlock
                                    title="Серия"
                                    content={workerProfile.documents.passport && workerProfile.documents.passport.series}
                                />
                            </div>
                            <div className="info-wrapper">
                                <InfoBlock
                                    title="Номер"
                                    content={workerProfile.documents.passport && workerProfile.documents.passport.number}
                                />
                            </div>
                        </div>

                        <div className="info-wrapper">
                            <InfoBlock
                                title="Дата выдачи"
                                content={workerProfile.documents.passport && workerProfile.documents.passport.date}
                            />
                        </div>

                        <div className="info-wrapper">
                            <InfoBlock
                                title="Дата рождения"
                                content={workerProfile.personalInfo && workerProfile.personalInfo.birthDate}
                            />
                        </div>

                        <div className="info-wrapper">
                            <InfoBlock
                                title="Кем выдан"
                                content={workerProfile.documents.passport && workerProfile.documents.passport.issuedBy}
                            />
                        </div>

                        {workerProfile.documents.passport && workerProfile.documents.passport.subdivisionCode &&
                            <div className="info-wrapper">
                                <InfoBlock
                                    title="Код подразделения"
                                    content={workerProfile.documents.passport.subdivisionCode}
                                />
                            </div>
                        }

                        {workerProfile.registrationAddress &&
                            <div className="info-wrapper">
                                <InfoBlock
                                    title="Адрес регистрации"
                                    content={workerProfile.registrationAddress.address && workerProfile.registrationAddress.address}
                                />
                            </div>
                        }

                        {workerProfile.registrationAddress &&
                            <div className="info-wrapper">
                                <InfoBlock
                                    title="Индекс адреса регистрации"
                                    content={workerProfile.registrationAddress.index && workerProfile.registrationAddress.index}
                                />
                            </div>
                        }

                        {workerProfile.documents.passport &&
                            <div className="documents-list">
                                {workerProfile.documents.media?.map((el: any) => {
                                        if (el.type == 'passport') {
                                            return (
                                                <Documents
                                                    pathPreview={el.pathPreview}
                                                    path={el.path}
                                                    key={el.path}
                                                    isDocumentStatus={documentStatus.passport}
                                                    updateDocumentStatus={updateDocumentStatus}
                                                    documentType={el.type}
                                                />
                                            )
                                        }
                                    }
                                )}
                            </div>
                        }

                        {workerProfile.actualAddress && workerProfile.actualAddress.adress != '' && workerProfile.actualAddress.address !== workerProfile.registrationAddress.address &&
                            <div className="info-wrapper living-adress">
                                <InfoBlock
                                    title="Адрес проживания"
                                    content={workerProfile.actualAddress && workerProfile.actualAddress.address}
                                />
                                {workerProfile.actualAddress && workerProfile.actualAddress.index != '' &&
                                    <InfoBlock
                                        title="Индекс адреса проживания"
                                        content={workerProfile.actualAddress.index}
                                    />
                                }
                            </div>
                        }
                        {(workerProfile.documents.passport && !workerProfile.documents.media) && userCTX.user?.currentRole !== 'organizer' && userCTX.user?.currentRole !== 'moderator' &&
                            <div className='document-status' style={{width: '150px'}}>
                                {documentStatus.passport === true ?
                                    <div className='document-status-item'>
                                        <Icon icon={'check_circle'} className="check_circle"
                                              style={{color: '#00E23F'}}/>
                                        <div>
                                            Прошел проверку
                                        </div>
                                    </div>
                                    : <div className='document-status-item document-status-pointer'
                                           onClick={() => {
                                               updateDocumentStatus('passport', true)
                                           }}
                                    >
                                        <div>
                                            Подтвердить
                                        </div>
                                    </div>
                                }
                                {documentStatus.passport === false ?
                                    <div className='document-status-item'>
                                        <Icon icon={'info'} className="info" style={{color: '#ED0000'}}/>
                                        <div>
                                            Не прошел проверку
                                        </div>
                                    </div>
                                    : <div className='document-status-item document-status-pointer'
                                           onClick={() => {
                                               updateDocumentStatus('passport', false)
                                           }}
                                    >
                                        <div>
                                            Отклонить
                                        </div>
                                    </div>
                                }
                            </div>}
                    </div>
                    {isVisibleProfileUpdate !== undefined && !isVisibleProfileUpdate && acceptChangeResponse?.passport === EAcceptValues.AWAIT_ACCEPT &&
                        <Icon className='edit_note'
                              icon={"edit_note"}/>}
                    {isVisibleProfileUpdate && versionProfile && acceptChangeResponse?.passport === EAcceptValues.AWAIT_ACCEPT &&
                        <>
                            <Icon className='keyboard_double_arrow_right'
                                  style={{margin: "auto 8px auto 8px"}}
                                  icon={"keyboard_double_arrow_right"}/>
                            <div style={{flex: "0 0 45%", position: 'relative'}}>
                                <div className="info-wrapper"
                                     style={versionProfile.personalInfo.citizenship === workerProfile.personalInfo.citizenship ? {color: '#FFFFFF'} : {}}>
                                    <InfoBlock
                                        title="Документ, удостоверяющий личность"
                                        className={versionProfile.personalInfo.citizenship === workerProfile.personalInfo.citizenship ? 'noSelect' : ''}
                                        content={!!workerProfile.personalInfo.citizenship && !versionProfile.personalInfo.citizenship ? 'Пользователь удалил информацию' : versionProfile.personalInfo.citizenship}
                                        viewMode={versionProfile.personalInfo.citizenship === workerProfile.personalInfo.citizenship}
                                        style={versionProfile.personalInfo.citizenship === workerProfile.personalInfo.citizenship ? {marginBottom: '4px'} : {}}
                                    />
                                </div>

                                <div className="passport-serial-number-wrapper">
                                    <div className="info-wrapper"
                                         style={versionProfile.documents.passport.series === workerProfile.documents.passport.series ? {color: '#FFFFFF'} : {}}>
                                        <InfoBlock
                                            title="Серия"
                                            className={versionProfile.documents.passport.series === workerProfile.documents.passport.series ? 'noSelect' : ''}
                                            content={!!workerProfile.documents.passport.series && !versionProfile.documents.passport.series ? 'Пользователь удалил информацию' : versionProfile.documents.passport.series}
                                            viewMode={versionProfile.documents.passport.series === workerProfile.documents.passport.series}
                                            style={versionProfile.documents.passport.series === workerProfile.documents.passport.series ? {marginBottom: '4px'} : {}}
                                        />
                                    </div>
                                    <div className="info-wrapper"
                                         style={versionProfile.documents.passport.number === workerProfile.documents.passport.number ? {color: '#FFFFFF'} : {}}>
                                        <InfoBlock
                                            title="Номер"
                                            className={versionProfile.documents.passport.number === workerProfile.documents.passport.number ? 'noSelect' : ''}
                                            content={!!workerProfile.documents.passport.number && !versionProfile.documents.passport.number ? 'Пользователь удалил информацию' : versionProfile.documents.passport.number}
                                            viewMode={versionProfile.documents.passport.number === workerProfile.documents.passport.number}
                                            style={versionProfile.documents.passport.number === workerProfile.documents.passport.number ? {marginBottom: '4px'} : {}}
                                        />
                                    </div>
                                </div>

                                <div className="info-wrapper"
                                     style={versionProfile.documents.passport.date === workerProfile.documents.passport.date ? {color: '#FFFFFF'} : {}}>
                                    <InfoBlock
                                        title="Дата выдачи"
                                        className={versionProfile.documents.passport.date === workerProfile.documents.passport.date ? 'noSelect' : ''}
                                        content={!!workerProfile.documents.passport.date && !versionProfile.documents.passport.date ? 'Пользователь удалил информацию' : versionProfile.documents.passport.date}
                                        viewMode={versionProfile.documents.passport.date === workerProfile.documents.passport.date}
                                        style={versionProfile.documents.passport.date === workerProfile.documents.passport.date ? {marginBottom: '4px'} : {}}
                                    />
                                </div>

                                <div className="info-wrapper"
                                     style={versionProfile.documents.passport.issuedBy === workerProfile.documents.passport.issuedBy ? {color: '#FFFFFF'} : {}}>
                                    <InfoBlock
                                        title="Кем выдан"
                                        className={versionProfile.documents.passport.issuedBy === workerProfile.documents.passport.issuedBy ? 'noSelect' : ''}
                                        content={!!workerProfile.documents.passport.issuedBy && !versionProfile.documents.passport.issuedBy ? 'Пользователь удалил информацию' : versionProfile.documents.passport.issuedBy}
                                        viewMode={versionProfile.documents.passport.issuedBy === workerProfile.documents.passport.issuedBy}
                                        style={versionProfile.documents.passport.issuedBy === workerProfile.documents.passport.issuedBy ? {marginBottom: '4px'} : {}}
                                    />
                                </div>

                                {versionProfile.documents.passport && versionProfile.documents.passport.subdivisionCode &&
                                    <div className="info-wrapper"
                                         style={versionProfile.documents.passport.subdivisionCode === workerProfile.documents.passport.subdivisionCode ? {color: '#FFFFFF'} : {}}>
                                        <InfoBlock
                                            title="Код подразделения"
                                            className={versionProfile.documents.passport.subdivisionCode === workerProfile.documents.passport.subdivisionCode ? 'noSelect' : ''}
                                            content={!!workerProfile.documents.passport.subdivisionCode && !versionProfile.documents.passport.subdivisionCode ? 'Пользователь удалил информацию' : versionProfile.documents.passport.subdivisionCode}
                                            viewMode={versionProfile.documents.passport.subdivisionCode === workerProfile.documents.passport.subdivisionCode}
                                            style={versionProfile.documents.passport.subdivisionCode === workerProfile.documents.passport.subdivisionCode ? {marginBottom: '4px'} : {}}
                                        />
                                    </div>
                                }

                                {versionProfile.registrationAddress &&
                                    <div className="info-wrapper"
                                         style={versionProfile.registrationAddress.address === workerProfile.registrationAddress.address ? {color: '#FFFFFF'} : {}}>
                                        <InfoBlock
                                            title="Адрес регистрации"
                                            className={versionProfile.registrationAddress.address === workerProfile.registrationAddress.address ? 'noSelect' : ''}
                                            content={!!workerProfile.registrationAddress.address && !versionProfile.registrationAddress.address ? 'Пользователь удалил информацию' : versionProfile.registrationAddress.address}
                                            viewMode={versionProfile.registrationAddress.address === workerProfile.registrationAddress.address}
                                            style={versionProfile.registrationAddress.address === workerProfile.registrationAddress.address ? {marginBottom: '4px'} : {}}
                                        />
                                    </div>
                                }

                                {versionProfile.registrationAddress &&
                                    <div className="info-wrapper"
                                         style={versionProfile.registrationAddress.index === workerProfile.registrationAddress.index ? {color: '#FFFFFF'} : {}}>
                                        <InfoBlock
                                            title="Индекс адреса регистрации"
                                            className={versionProfile.registrationAddress.index === workerProfile.registrationAddress.index ? 'noSelect' : ''}
                                            content={!!workerProfile.registrationAddress.index && !versionProfile.registrationAddress.index ? 'Пользователь удалил информацию' : versionProfile.registrationAddress.index}
                                            viewMode={versionProfile.registrationAddress.index === workerProfile.registrationAddress.index}
                                            style={versionProfile.registrationAddress.index === workerProfile.registrationAddress.index ? {marginBottom: '4px'} : {}}
                                        />
                                    </div>
                                }

                                {versionProfile.documents.passport &&
                                    <div className="documents-list">
                                        {versionProfile.documents.media?.map((el: any) => {
                                                if (el.type == 'passport') {
                                                    return (
                                                        <Documents
                                                            pathPreview={el.pathPreview}
                                                            path={el.path}
                                                            key={el.path}
                                                            isDocumentStatus={documentStatus.passport}
                                                            documentType={el.type}
                                                        />
                                                    )
                                                }
                                            }
                                        )}
                                    </div>
                                }

                                {versionProfile.actualAddress !== versionProfile.registrationAddress &&
                                    <>
                                        {!!workerProfile.actualAddress && !versionProfile.actualAddress
                                            ? <div className="info-wrapper living-adress">
                                                <InfoBlock
                                                    title="Адрес проживания"
                                                    content={'Пользователь удалил информацию'}
                                                />
                                                <InfoBlock
                                                    title="Индекс адреса проживания"
                                                    content={'Пользователь удалил информацию'}
                                                />
                                            </div>
                                            :
                                            <div className="info-wrapper living-adress noSelect"
                                                 style={workerProfile.actualAddress && versionProfile.actualAddress && versionProfile.actualAddress.address === workerProfile.actualAddress.address &&
                                                 versionProfile.actualAddress.index === workerProfile.actualAddress.index
                                                     ? {color: '#FFFFFF'} : {}}>
                                                <InfoBlock
                                                    title="Адрес проживания"
                                                    className={workerProfile.actualAddress && versionProfile.actualAddress && versionProfile.actualAddress.address === workerProfile.actualAddress.address ? 'noSelect' : ''}
                                                    content={workerProfile.actualAddress && !versionProfile.actualAddress.address ? 'Пользователь удалил информацию' : versionProfile.actualAddress.address}
                                                    viewMode={workerProfile.actualAddress && versionProfile.actualAddress && versionProfile.actualAddress.address === workerProfile.actualAddress.address}
                                                    style={workerProfile.actualAddress && versionProfile.actualAddress && versionProfile.actualAddress.address === workerProfile.actualAddress.address ? {marginBottom: '4px'} : {}}
                                                />
                                                <InfoBlock
                                                    title="Индекс адреса проживания"
                                                    className={workerProfile.actualAddress && versionProfile.actualAddress && versionProfile.actualAddress.index === workerProfile.actualAddress.index ? 'noSelect' : ''}
                                                    content={workerProfile.actualAddress && !versionProfile.actualAddress.index ? 'Пользователь удалил информацию' : versionProfile.actualAddress.index}
                                                    viewMode={workerProfile.actualAddress && versionProfile.actualAddress && versionProfile.actualAddress.index === workerProfile.actualAddress.index}
                                                    style={workerProfile.actualAddress && versionProfile.actualAddress && versionProfile.actualAddress.index === workerProfile.actualAddress.index ? {marginBottom: '4px'} : {}}
                                                />
                                            </div>
                                        }
                                    </>
                                }
                                {userCTX.user?.currentRole !== 'organizer' &&
                                    <div className='version-buttons'>
                                        <Icon className='done' icon={"done"}
                                              onClick={() => {
                                                  // @ts-ignore
                                                  toggleAcceptValueInUserDumpProfile && toggleAcceptValueInUserDumpProfile({passport: EAcceptValues.ACCEPTED})
                                              }}
                                        />
                                        <Icon className='close' icon={"close"}
                                              onClick={() => {
                                                  // @ts-ignore
                                                  toggleAcceptValueInUserDumpProfile && toggleAcceptValueInUserDumpProfile({passport: EAcceptValues.REFUSED})
                                              }}
                                        />
                                    </div>
                                }
                            </div>
                        </>}
                </div>
            }
        </div>
    )
}