import {IColumnSettings} from "../../../components/ExtendedCustomTable/ExtendedCustomTable.types";

export const organizationSettings: IColumnSettings[] = [
    {
        id: 0,
        sortOrder: 0,
        visibility: true,
        minWidth: '250px',
        columnType: 'fullName',
        sortType: 'ASC',
        filterType: null,
        columnLang: false,
        title: "Наименование организации",
    },
    {
        id: 1,
        sortOrder: 1,
        visibility: true,
        minWidth: '250px',
        columnType: 'inn',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "ИНН организации",
    },
    {
        id: 2,
        sortOrder: 2,
        visibility: true,
        minWidth: '250px',
        columnType: 'countActiveRequests',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Количество активных потребностей",
    },
    {
        id: 3,
        sortOrder: 3,
        visibility: true,
        minWidth: '250px',
        columnType: 'countOpenVacancies',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Количество открытых вакансий",
    },
    {
        id: 4,
        sortOrder: 4,
        visibility: true,
        minWidth: '250px',
        columnType: 'countCurrentContracts',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Количество действующих договоров",
    },
    {
        id: 5,
        sortOrder: 5,
        visibility: true,
        minWidth: '250px',
        columnType: 'countActiveUsers',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Количество активных пользователей",
    },
    {
        id: 6,
        sortOrder: 6,
        visibility: true,
        minWidth: '250px',
        columnType: 'registrationDate',
        sortType: 'NONE',
        filterType: null,
        columnLang: false,
        title: "Дата регистрации организации",
    },
    {
        id: 7,
        sortOrder: 7,
        visibility: true,
        minWidth: '250px',
        columnType: 'moderationStatus',
        sortType: 'NONE',
        filterType: null,
        columnLang: true,
        title: "Статус модерации",
    },
]

export const getOrganizationStatusColor = (status: string) => {
    switch (status) {
        case 'expectation':
            return '#FFA800'
        case 'rejected':
            return '#FF0000'
        case 'accepted':
            return '#00E23F'
        case 're_moderation':
            return '#00E0FF'
        default:
            return '#FF0000'
    }
}
