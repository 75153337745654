import React, {useState} from "react";
import {Elevation} from "@rmwc/elevation";

import {Props} from "./TabButtons.types";
import "./TabButtons.style.sass";
import {Icon} from "@rmwc/icon";

export function TabButtons({
                                       staticHeader,
                                       data,
                                       onTabSwitch,
                                       embed,
                                       open,
                                       elevation = 3,
                                       isDisabled,
                                       isUpdatedTabs
                                   }: Props) {
    const [activeTab, setActiveTab] = useState(open ?? 0);

    const activateTab = (event: React.MouseEvent<HTMLElement>) => {
        const tab = Number((event.target as any).id ?? activeTab);
        setActiveTab(tab);
        //callback for handling external logic without using events
        if (onTabSwitch) onTabSwitch(tab);

        // event for future purposes if needed
        /*  const tabSwitchEvent = new CustomEvent('tabSwitched', { detail: tab });
          window.dispatchEvent(tabSwitchEvent); */
    };

    return (
        <>
            <Elevation className={"tabs"} z={elevation}>
                <div
                    className={"tab-btns"}
                    onClick={isDisabled ? undefined : (e) => activateTab(e)}
                >
                    {Object.keys(data).map((k, i) => (
                        <h4
                            id={String(i)}
                            key={i}
                            className={"tab-btn" + (i === activeTab ? " active" : "")}
                        >
                            <div
                                id={String(i)}
                                className='tab-btn-title'
                            >
                                {k}
                            </div>
                            {isUpdatedTabs && isUpdatedTabs[i] &&
                                <Icon className='tab-edit_note' icon={"edit_note"}/>}
                        </h4>
                    ))}
                </div>
                {staticHeader}

                {embed?.[activeTab] && Object.values(data)[activeTab]()}
            </Elevation>

            {!embed?.[activeTab] && Object.values(data)[activeTab]()}
        </>
    );
}
