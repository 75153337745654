import { Icon } from '@rmwc/icon'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { ChatContext } from '../../../../global/context.chat'
import { IColumnSettings, IHeaderCell, IWorker } from '../../WorkersList.types'
import { IDndTableBody } from './DndTableBody.types'
import './DndTableBody.style.sass'
import { PagesRoutes } from 'global/routes'
import { TableCell } from 'components/Table/TableCell/TableCell'
import {TableRow} from "../../../../components/Table/TableRow/TableRow";

export const DndTableBody: React.FC<IDndTableBody> = ({ orderedColumns, columnSettings, workers }) => {
	const [isOpenDialog, setIsOpenDialog] = useState<{ workerId: number; contractId: number } | null>()
	const chatCTX = useContext(ChatContext)
	const { t } = useTranslation('common')
	const history = useHistory()

	const cellIsActive = (isDelete: boolean, columnType: string, columnSettings: IColumnSettings[]) => {
		let color = '#FFFFFF'
		if (isDelete) {
			color = '#eeeeee'
		}
		const activeElement = columnSettings.find(item => {
			return item.columnType === columnType && (item.sortType !== 'NONE' || item.filterType)
		})
		if (!!activeElement) {
			color = '#F2F5F9'
		}
		return color
	}

	React.useEffect(() => {
		function closeSearch(e: any) {
			if (document.activeElement?.tagName == 'BODY') {
				setIsOpenDialog(null)
			}
		}

		document.getElementsByTagName('body')[0].addEventListener('click', closeSearch)
		return () => {
			document.getElementsByTagName('body')[0].removeEventListener('click', closeSearch)
		}
	}, [])

	const renderRow = (worker: IWorker, orderedColumns: IHeaderCell[], workerIndex: number) => {
		return columnSettings.map((column, index) => {
			if (column.columnType === 'workerFullName' && column.visibility) {
				return (
					<div
						key={column.columnType + index}
						className='dnd-first-cell devider'
						style={{
							minWidth: orderedColumns[index].minWidth,
							background: cellIsActive(worker.isDeleted, column.columnType, columnSettings),
							height: '100%'
						}}
					>
						<div className='dnd-table-cell-inner'>
							<div className={'profile-link'}>{worker.workerFullName}</div>
							<Icon
								className='icon more_horiz'
								icon={'more_horiz'}
								onClick={(event: any) => {
									event.stopPropagation()
									setIsOpenDialog(
										isOpenDialog &&
											isOpenDialog.workerId === worker.workerID &&
											isOpenDialog.contractId === worker.contractID
											? null
											: {
													workerId: worker.workerID,
													contractId: worker.contractID
											  }
									)
								}}
							/>
							{isOpenDialog &&
								isOpenDialog.workerId === worker.workerID &&
								isOpenDialog.contractId === worker.contractID && (
									<div
										className='dialog'
										onClick={event => {
											event.stopPropagation()
										}}
									>
										<div className='dialog_item'>
											<Link
												className='item'
												to={{
													pathname:
														PagesRoutes.WORKERS + PagesRoutes.WORKER_CARD + `/${worker.workerID}/${worker.requestID}`,
													state: history.location.state
													// : `/public-worker-profile/${worker.workerID}/${worker.contractID}`,
												}}
											>
												<Icon className='icon account_circle' icon={'account_circle'} />
												<div>Карточка сотрудника</div>
											</Link>
										</div>
										<div className='dialog_item'>
											<div
												className='item'
												onClick={() =>
													history.push({
														pathname: PagesRoutes.WORKERS + PagesRoutes.CONTRACT_CARD + `/${worker.contractID}`,
														state: history.location.state
													})
												}
											>
												<Icon className='icon description' icon={'description'} />
												<div>Карточка договора</div>
											</div>
										</div>
										<div className='dialog_item'>
											<a
												className='item'
												href={`mailto:${worker.contactEmail}`}
												style={{ pointerEvents: `${worker.isDeleted ? 'none' : 'auto'}` }}
											>
												<Icon className='icon account_circle' icon={'history_edu'} />
												<div>Написать письмо</div>
											</a>
										</div>
										<div className='dialog_item'>
											<div
												className='item'
												style={{ pointerEvents: `${worker.isDeleted ? 'none' : 'auto'}` }}
												onClick={() => {
													if (!worker.isDeleted) {
														chatCTX.sendMessage(worker)
													}
												}}
											>
												<Icon className='icon description' icon={'description'} />
												<div>Написать в чат</div>
											</div>
										</div>
									</div>
								)}
						</div>
					</div>
				)
			} else if (column.columnType === 'mobilizationStatus' && column.visibility) {
				return (
					<TableCell
						key={column.columnType + index}
						devider
						style={{
							minWidth: orderedColumns[index].minWidth,
							background: cellIsActive(worker.isDeleted, column.columnType, columnSettings),
							height: '100%'
						}}
					>
						{worker.mobilizationStatus && t('mobilizationStatus.' + worker.mobilizationStatus)}
					</TableCell>
				)
			} else if (column.columnType === 'schedule' && column.visibility) {
				return (
					<TableCell
						key={column.columnType + index}
						devider
						style={{
							minWidth: orderedColumns[index].minWidth,
							background: cellIsActive(worker.isDeleted, column.columnType, columnSettings),
							height: '100%'
						}}
					>
						{'№ ' + worker.schedule.id + ' - ' + worker.schedule.shortName}
					</TableCell>
				)
			} else if (column.columnType === 'contactEmail' && column.visibility) {
				return (
					<TableCell
						key={column.columnType + index}
						devider
						style={{
							minWidth: orderedColumns[index].minWidth,
							background: cellIsActive(worker.isDeleted, column.columnType, columnSettings),
							height: '100%'
						}}
					>
						<a
							href={`mailto:${worker.contactEmail}`}
							className='dnd-first-cell-link'
							style={{
								pointerEvents: `${worker.isDeleted ? 'none' : 'auto'}`,
								textDecoration: 'none',
								color: '#354C62'
							}}
						>
							{worker.contactEmail}
						</a>
					</TableCell>
				)
			} else if (column.columnType === 'workerActualAddress' && column.visibility) {
				return (
					<TableCell
						key={column.columnType + index}
						devider
						style={{
							minWidth: orderedColumns[index].minWidth,
							background: cellIsActive(worker.isDeleted, column.columnType, columnSettings),
							height: '100%'
						}}
					>
						{worker.workerActualAddress?.address || ' '}
					</TableCell>
				)
			} else if (column.columnType === 'countTime' && column.visibility) {
				return (
					<TableCell
						key={column.columnType + index}
						devider
						style={{
							minWidth: orderedColumns[index].minWidth,
							background: cellIsActive(worker.isDeleted, column.columnType, columnSettings),
							height: '100%'
						}}
					>
						{worker.countTime.hours}
					</TableCell>
				)
			} else if (column.columnType === 'mainProfession' && column.visibility) {
				return (
					<TableCell
						key={column.columnType + index}
						devider
						style={{
							minWidth: orderedColumns[index].minWidth,
							background: cellIsActive(worker.isDeleted, column.columnType, columnSettings),
							height: '100%'
						}}
					>
						{worker.mainProfession && worker.mainProfession.name}
					</TableCell>
				)
			} else if (column.columnType === 'number' && column.visibility) {
				return (
					<TableCell
						key={column.columnType + index}
						devider
						style={{
							width: '40px',
							minWidth: orderedColumns[index].minWidth,
							background: cellIsActive(worker.isDeleted, column.columnType, columnSettings),
							height: '100%'
						}}
						index={0}
					>
						{workerIndex + 1}
					</TableCell>
				)
			} else if (column.columnType && column.visibility) {
				return (
					<TableCell
						key={column.columnType + index}
						devider
						style={{
							minWidth: orderedColumns[index].minWidth,
							background: cellIsActive(worker.isDeleted, column.columnType, columnSettings),
							height: '100%'
						}}
					>
						<>{worker[column.columnType]}</>
					</TableCell>
				)
			}
		})
	}

	if (workers) {
		return (
			<>
				{workers.map((worker: IWorker, index) => {
					return (
						<TableRow className='worker-row dnd-table-body' type='row' key={`${worker.workerID} ${index}`}>
							{renderRow(worker, orderedColumns, index)}
						</TableRow>
					)
				})}
			</>
		)
	} else return null
}
