import { MobilizationPage } from 'pages/mobilization-page/mobilization-page'
import { MobilizeSubPage } from 'pages/mobilization-page/subPages/mobilize-subpage/mobilize-subpage'
import { Dump } from 'pages/public-worker-profile/dump/dump'
import { PublicWorkerProfilePage } from 'pages/public-worker-profile/public-worker-profile'
import { EditTemplateSubpage } from 'pages/Settings/components/EditTemplateSubpage/EditTemplateSubpage'
import { TimeKeepingReport } from 'pages/TimeKeepingReport/TimeKeepingReport'
import { WorkerCardPage } from 'pages/WorkerCardPage/WorkerCardPage'
import { WorkerExperiensePage } from 'pages/WorkerExperiencePage/WorkerExperiencePage'
import { WorkersList } from 'pages/WorkersList/WorkersList'
import {Personnel} from 'pages/Personnel/Personnel'
import { Route } from './routes-def'
import { initialWebRoles, PagesRoutes } from './routes-utils'
import TimeKeepingPage from "../../pages/TimeKeeping/TimeKeeping";

export const workerRoutes: Route[] = [
	//Работники
	{
		title: 'Работники',
		path: PagesRoutes.WORKERS,
		component: <WorkersList />,
		navBar: true,
		icon: 'groups',
		availableRoles: initialWebRoles
	},
	{
		title: 'Публичный профиль',
		path: PagesRoutes.WORKERS + PagesRoutes.PUBLIC_PROFILE + '/:searchID?/:recordID?',
		component: <PublicWorkerProfilePage />,
		navBar: false,
		availableRoles: [...initialWebRoles, 'moderator']
	},
	{
		title: 'Опыт сотрудничества',
		path: PagesRoutes.WORKERS + PagesRoutes.PUBLIC_PROFILE + '/exp/:searchID?/:recordID?',
		component: <WorkerExperiensePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Карточка сотрудника',
		path: PagesRoutes.WORKERS + PagesRoutes.WORKER_CARD + '/:searchID?/:recordID?',
		component: <Dump />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Опыт сотрудничества',
		path: PagesRoutes.WORKERS + PagesRoutes.WORKER_CARD + '/exp/:searchID?/:recordID?',
		component: <WorkerExperiensePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Карточка договора',
		path: PagesRoutes.WORKERS + PagesRoutes.CONTRACT_CARD + '/:searchID?',
		component: <WorkerCardPage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	
	//Работники / Мобилизация
	{
		title: 'Мобилизация',
		path: PagesRoutes.WORKERS + PagesRoutes.MOBILIZATION,
		component: <MobilizationPage />,
		navBar: false,
		icon: 'checklist',
		availableRoles: initialWebRoles
	},
	{
		title: 'Публичный профиль',
		path: PagesRoutes.WORKERS + PagesRoutes.MOBILIZATION + PagesRoutes.PUBLIC_PROFILE + '/:searchID?/:recordID?',
		component: <PublicWorkerProfilePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Опыт сотрудничества',
		path: PagesRoutes.WORKERS + PagesRoutes.MOBILIZATION + PagesRoutes.PUBLIC_PROFILE + '/exp/:searchID?/:recordID?',
		component: <WorkerExperiensePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Карточка сотрудника',
		path: PagesRoutes.WORKERS + PagesRoutes.MOBILIZATION + PagesRoutes.WORKER_CARD + '/:searchID?/:recordID?',
		component: <Dump />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Опыт сотрудничества',
		path: PagesRoutes.WORKERS + PagesRoutes.MOBILIZATION + PagesRoutes.WORKER_CARD + '/exp/:searchID?/:recordID?',
		component: <WorkerExperiensePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Карточка договора',
		path: PagesRoutes.WORKERS + PagesRoutes.MOBILIZATION + PagesRoutes.CONTRACT_CARD + '/:searchID?',
		component: <WorkerCardPage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Процесс мобилизации',
		path: PagesRoutes.WORKERS + PagesRoutes.MOBILIZATION + '/mobilize/:searchID?/:recordID?/:status?',
		component: <MobilizeSubPage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Процесс демобилизации',
		path: PagesRoutes.WORKERS + PagesRoutes.MOBILIZATION + '/demobilize/:searchID?/:recordID?/:status?',
		component: <MobilizeSubPage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Редактировать шаблон мобил.',
		path: PagesRoutes.WORKERS + PagesRoutes.MOBILIZATION + '/edit-template/mobilize',
		component: <EditTemplateSubpage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Редактировать шаблон демобил.',
		path: PagesRoutes.WORKERS + PagesRoutes.MOBILIZATION + '/edit-template/demobilize',
		component: <EditTemplateSubpage />,
		navBar: false,
		availableRoles: initialWebRoles
	},

	//Работники / Персонал
	{
		title: 'Персонал',
		path: PagesRoutes.WORKERS + PagesRoutes.PERSONAL,
		component: <Personnel />,
		navBar: false,
		icon: 'group',
		availableRoles: initialWebRoles
	},
	{
		title: 'Публичный профиль',
		path: PagesRoutes.WORKERS + PagesRoutes.PERSONAL + PagesRoutes.PUBLIC_PROFILE + '/:searchID?/:recordID?',
		component: <PublicWorkerProfilePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Опыт сотрудничества',
		path: PagesRoutes.WORKERS + PagesRoutes.PERSONAL + PagesRoutes.PUBLIC_PROFILE + '/exp/:searchID?/:recordID?',
		component: <WorkerExperiensePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Карточка сотрудника',
		path: PagesRoutes.WORKERS + PagesRoutes.PERSONAL + PagesRoutes.WORKER_CARD + '/:searchID?/:recordID?',
		component: <Dump />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Опыт сотрудничества',
		path: PagesRoutes.WORKERS + PagesRoutes.PERSONAL + PagesRoutes.WORKER_CARD + '/exp/:searchID?/:recordID?',
		component: <WorkerExperiensePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Карточка договора',
		path: PagesRoutes.WORKERS + PagesRoutes.PERSONAL + PagesRoutes.CONTRACT_CARD + '/:searchID?',
		component: <WorkerCardPage />,
		navBar: false,
		availableRoles: initialWebRoles
	},

	//Учет рабочего времен
	{
		title: 'Учет рабочего времени',
		path: PagesRoutes.TIME_KEEPING,
		component: <TimeKeepingPage />,
		navBar: true,
		icon: 'engineering',
		availableRoles: initialWebRoles
	},
	{
		title: 'Публичный профиль',
		path: PagesRoutes.TIME_KEEPING + PagesRoutes.PUBLIC_PROFILE + '/:searchID?/:recordID?',
		component: <PublicWorkerProfilePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Опыт сотрудничества',
		path: PagesRoutes.TIME_KEEPING + PagesRoutes.PUBLIC_PROFILE + '/exp/:searchID?/:recordID?',
		component: <WorkerExperiensePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Карточка сотрудника',
		path: PagesRoutes.TIME_KEEPING + PagesRoutes.WORKER_CARD + '/:searchID?/:recordID?',
		component: <Dump />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Опыт сотрудничества',
		path: PagesRoutes.TIME_KEEPING + PagesRoutes.WORKER_CARD + '/exp/:searchID?/:recordID?',
		component: <WorkerExperiensePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Карточка договора',
		path: PagesRoutes.TIME_KEEPING + PagesRoutes.CONTRACT_CARD + '/:searchID?',
		component: <WorkerCardPage />,
		navBar: false,
		availableRoles: initialWebRoles
	},

	//Табель
	{
		title: 'Табель',
		path: '/timesheet',
		component: <TimeKeepingReport />,
		navBar: true,
		icon: 'assignment',
		availableRoles: initialWebRoles
	}
]
