import React from "react";
import './DragAndDropCells.style.sass'
import {IDragAndDropCells} from "./DragAndDropCells.types";
import {Icon} from "@rmwc/icon";
import {ISortingAndFilteringArray} from "../../WorkersList.types";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {DialogFilter} from "../DialogFilter";

export const DragAndDropCells = ({
                                     newOrderColumns,
                                     orderedColumns,
                                     columnSettings,
                                     toggleIsVisibleSettings,
                                     isVisibleSettingsOpen,
                                     toggleIsVisibleColumn,
                                     onChangeSortValues,
                                     sortingAndFilteringArray,
                                     onChangeFilterValues,
                                 }: IDragAndDropCells) => {

    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list, (item) => ({...item}))
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        for (let i = 0; i < result.length; i++) {
            result[i].sortOrder = i
        }
        return result.sort((a, b) => a.sortOrder - b.sortOrder)
    }

    function dropHandler(event: DropResult) {
        let {source, destination} = event
        if (source.index !== 0 && destination?.index !== 0) {
            if (destination) {
                newOrderColumns(
                    reorder(orderedColumns, source.index, destination.index),
                    reorder(columnSettings, source.index, destination.index)
                )
            }
        }
    }

    const getItemStyle = (isDragging: any, draggableStyle: any, currentSortedColumns: ISortingAndFilteringArray[], columnType: string, index: number) => {
        if (index === 0) {
            return {
                width: '40px',
                background: isDragging ? '#F1F1F1' : isActive(currentSortedColumns, columnType) ? '#F2F5F9' : '#FFFFFF',
                ...draggableStyle,
            }
        } else {
            return {
                userSelect: 'none',
                background: isDragging ? '#F1F1F1' : isActive(currentSortedColumns, columnType) ? '#F2F5F9' : '#FFFFFF',
                ...draggableStyle,
            }
        }
    };

    const isSorted = (currentSortedColumns: ISortingAndFilteringArray[], columnType: string, sortType: string) => {
        const sortedBy = currentSortedColumns.find(item => item.columnType === columnType)
        if (sortedBy) return sortedBy.sortType === sortType
        else return false
    }

    const isFiltered = (currentSortedColumns: ISortingAndFilteringArray[], columnType: string) => {
        const filterBy = currentSortedColumns.find(item => item.columnType === columnType)
        if (filterBy) return filterBy.filterType !== null
        else return false
    }

    const isActive = (currentSortedColumns: ISortingAndFilteringArray[], columnType: string) => {
        const sortedBy = currentSortedColumns.find(item => item.columnType === columnType)
        if (sortedBy) {
            return sortedBy.sortType !== 'NONE' || sortedBy.filterType
        } else return false
    }

    return (
        <DragDropContext
            onDragEnd={((event, ev) => dropHandler(event))}
        >
            <Droppable droppableId={'workers'} direction="horizontal">
                {(provided) => (
                    <div className="headings heading undefined drag_and_drop_cells"
                         style={{minWidth: 'auto'}}
                         ref={provided.innerRef}
                         {...provided.droppableProps}>

                        {orderedColumns.map((cell, index) => {
                                if (cell.isVisible) {
                                    return (
                                        <Draggable draggableId={`${cell.id}`} index={index} key={cell.id}>
                                            {(provided, snapshot) => (
                                                <div
                                                    key={cell.id}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    style={
                                                        {
                                                            minWidth: cell.minWidth,
                                                            ...getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style,
                                                                sortingAndFilteringArray, cell.columnType, index
                                                            )
                                                        }
                                                    }
                                                    className={"devider drag_and_drop"}
                                                >
                                                    <div className="table-cell-inner drag_and_drop-inner"
                                                         style={index === 0 ? {width: '30px'} : {}}>
                                                        {index !== 0 &&
                                                            <div className='expands'>
                                                                <Icon className={`icon expand `}
                                                                      style={{color: isSorted(sortingAndFilteringArray, cell.columnType, 'ASC') ? 'red' : ''}}
                                                                      icon={"arrow_drop_up"}
                                                                      onClick={(event) => {
                                                                          event.stopPropagation()
                                                                          onChangeSortValues(cell.columnType, cell.title, 'ASC')
                                                                      }}/>
                                                                <Icon className={`icon expand `}
                                                                      style={{color: isSorted(sortingAndFilteringArray, cell.columnType, 'DESC') ? 'red' : ''}}
                                                                      icon={"arrow_drop_down"}
                                                                      onClick={(event) => {
                                                                          event.stopPropagation()
                                                                          onChangeSortValues(cell.columnType, cell.title, 'DESC')
                                                                      }}/>
                                                            </div>
                                                        }
                                                        <div className='name'>{cell.title}</div>
                                                        {index !== 0 &&
                                                            <div className='icons'>
                                                                {isFiltered(sortingAndFilteringArray, cell.columnType)
                                                                    ?
                                                                    <Icon className='icon filter_alt' icon={"filter_alt"}/>
                                                                    : <div className='plug'/>
                                                                }
                                                                <Icon className='icon more_horiz' icon={"more_horiz"}
                                                                      onClick={(event) => {
                                                                          event.stopPropagation()
                                                                          toggleIsVisibleSettings(isVisibleSettingsOpen === cell.columnType ? 'none' : cell.columnType)
                                                                      }}/>
                                                            </div>
                                                        }
                                                    </div>
                                                    {
                                                        isVisibleSettingsOpen === cell.columnType &&
                                                        <DialogFilter
                                                            isVisibleSettingsOpen={isVisibleSettingsOpen}
                                                            columnSettings={columnSettings}
                                                            toggleIsVisibleColumn={toggleIsVisibleColumn}
                                                            columnType={cell.columnType}
                                                            columnName={cell.title}
                                                            onChangeFilterValues={onChangeFilterValues}
                                                            filterType={cell.filterType}
                                                        />
                                                    }
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                }
                            }
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}