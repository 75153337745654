export const sendMessageSettingOptions = [
    {
        value: 'forbidden',
        label: 'Нельзя писать',
    },
    {
        value: 'always',
        label: 'В любой момент',
    },
    {
        value: 'pending',
        label: 'С получением статуса «Направлен отклик»',
    },
    {
        value: 'documents_preparation',
        label: 'С получением статуса «Подготовка документов»',
    },
    {
        value: 'signature_awaiting',
        label: 'С получением статуса «Требуется подпись»',
    },
    {
        value: 'documents_undelivered',
        label: 'С получением статуса «Документы не получены»',
    },
    {
        value: 'documents_received',
        label: 'С получением статуса «Документы получены»',
    },
    {
        value: 'signed',
        label: 'С получением статуса «Документы подписаны»',
    },
    {
        value: 'accepted',
        label: 'С получением статуса «Принят на работу»',
    },
]