import {DialogOnCloseEventT, SimpleDialog} from '@rmwc/dialog'
import {Icon} from '@rmwc/icon'
import {IconButton} from '@rmwc/icon-button'
import {TableCell} from 'components/Table/TableCell/TableCell'
import {TableHeading} from 'components/Table/TableHeading/TableHeading'
import {TableRow} from 'components/Table/TableRow/TableRow'
import {format, parseISO} from 'date-fns'
import {ChatContext} from 'global/context.chat'
import {InterfaceContext} from 'global/context.interface'
import {UserContext} from 'global/context.user'
import {sendYandexMetric} from 'global/metrics'
import {PagesRoutes} from 'global/routes'
import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import {Table, Form, PageTitle, ProgressPage, Avatar, NameAndStatus, Period} from 'components'
import {authorName, req} from '../../global/common'
import documentIcon from '../../misc/email.svg'
import {Info, Params} from './RespondedWorkers.types'
import './RespondedWorkers.style.sass'

export default function RespondedWorkers() {
    const history = useHistory()
    const userCTX = useContext(UserContext)
    const chatCTX = useContext(ChatContext)
    const {searchID} = useParams() as Params
    const interfaceCTX = useContext(InterfaceContext)
    const {t} = useTranslation('common')
    const [load, setLoad] = React.useState<boolean | null>(null)
    const [update, setUpdate] = React.useState(false)
    const [info, setInfo] = React.useState({} as Info)
    const [state, setState] = React.useState([] as any[])
    const [totalResponsesQty, setTotalResponsesQty] = React.useState(0)
    const [delID, setDelID] = React.useState()

    let timeoutForBtn: NodeJS.Timeout

    // получение откликов
    React.useEffect(() => {
        const query = {limit: 0, offset: 0}
        req
            .get(`/requests/organisation/${searchID}/responses-list`, query)
            .then(({data}) => {
                setTotalResponsesQty(data.total)
                // if (!data.total) throw new Error()
                setState(data.data)
                setInfo(data.request)
                if (data.total > 0) {
                    setLoad(true)
                } else {
                    setLoad(false)
                }
            })
            .catch(e => {
                setLoad(false)
                interfaceCTX.showMessage({
                    title: 'Ошибка',
                    body: t(e.response?.data)
                })
            })
    }, [searchID, update])

    // отказ в отклике
    const dialogHandler = (e: DialogOnCloseEventT) => {
        if (e.detail.action === 'accept') {
            req
                .post(`/response/organisation/${delID}/decline`)
                .then(() => setUpdate((state: boolean) => !state))
                .catch(e => {
                    interfaceCTX.showMessage({
                        title: 'Ошибка',
                        body: t(e.response?.data)
                    })
                })
        }
        setDelID(undefined)
    }

    function isDocBtnDisabled(dataValue: any) {
        let docArr = ['cancelled']
        return info.state === 'closed_completed' || userCTX.userHasRole('organizer') || docArr.includes(dataValue)
    }

    function isDeclineBtnDisabled(dataValue: any) {
        let declineArr = ['rejected', 'accepted', 'cancelled']
        return info.state === 'closed_completed' || userCTX.userHasRole('organizer') || declineArr.includes(dataValue)
    }

    function isAcceptBtnDisabled(dataValue: any) {
        let acceptArr = ['signature_awaiting', 'accepted', 'cancelled', 'documents_awaiting']
        return (
            info.state === 'closed_completed' ||
            userCTX.userHasRole('organizer') ||
            acceptArr.includes(dataValue) ||
            dataValue.isDeleted
        )
    }

    const requestDocumentsFromTheWorker = (id: number) => {
        req.post(`/response/organisation/${id}/request-docs`).then(() => {
            setUpdate((state: boolean) => !state)
        })
    }

    const checkStatus = (status: string) => {
        return status === 'accepted' || status === 'cancelled' || status === 'documents_awaiting'
        // || status === 'rejected'
    }

    // принятие отклика
    const accept = React.useCallback((id: number, status: string) => {
        clearTimeout(timeoutForBtn)
        let url: string
        switch (status) {
            default:
            case 'pending':
                url = `/response/organisation/${id}/accept`
                break
            case 'documents_preparation':
                url = `/response/organisation/${id}/request-sign`
                break
            case 'documents_undelivered':
                url = `/response/organisation/${id}/request-sign`
                break
            case 'signed':
                url = `/response/organisation/${id}/confirm-sign`
                break
        }
        timeoutForBtn = setTimeout(
            () =>
                req
                    .post(url)
                    .then(data => {
                        if (status === 'pending') {
                            // '13 - pending: Откликнулся -> documents_preparation: Оформление документов'
                            sendYandexMetric('reachGoal', 'WORKER_AWAITING_FOR_DOCUMENTS')
                        } else if (status === 'documents_undelivered') {
                            // '14 - documents_undelivered: Документы не получены - signature_awaiting: Требуется подпись'
                            sendYandexMetric('reachGoal', 'WORKER_DID_NOT_RECEIVE_DOCUMENTS')
                        } else if (status === 'documents_preparation') {
                            // '15 - documents_preparation: Оформление документов - signature_awaiting: Требуется подпись'
                            sendYandexMetric('reachGoal', 'AWAITING_FOR_WORKER_TO_SIGN_DOCUMENTS')
                        } else if (status === 'signed') {
                            // '16 - signed: Документы подписаны - accepted: Принят на работы'
                            sendYandexMetric('reachGoal', 'DOCUMENTS_ARE_SIGNED_BY_EMPLOYER')
                        }
                        setUpdate((state: boolean) => !state)
                    })
                    .catch(e => {
                        interfaceCTX.showMessage({
                            title: 'Ошибка',
                            body: t(e.response.data)
                        })
                    }),
            500
        )
    }, [])

    return (
        <div className={'responded-workers-page'}>
            <PageTitle title='Список откликнувшихся работников' breadcrumbs={true}/>

            <Form
                name={'workers'}
                data={[
                    [
                        {
                            values: [
                                {type: 'text', title: 'Номер заявки', value: info.number},
                                {type: 'text', title: 'Дата заявки', value: info.date},
                                {type: 'text', title: 'Наименование организации', value: info.organisation}
                            ]
                        }
                    ]
                ]}
            />
            <ProgressPage
                state={load}
                alertTitle='Пока нет откликнувшихся работников'
                render={() => (
                    // <>
                    <Table>
                        <TableRow type='heading'>
                            <TableHeading devider style={{width: '12%'}}>
                                ФОТО
                            </TableHeading>
                            <TableHeading devider style={{width: '25%'}}>
                                ФИО
                            </TableHeading>
                            <TableHeading devider style={{width: '11%'}}>
                                Дата и время отклика
                            </TableHeading>
                            <TableHeading devider style={{width: '13%'}}>
                                Срок действия договора
                            </TableHeading>
                            <TableHeading devider style={{width: '10%'}}>
                                Заполненность профиля
                            </TableHeading>
                            <TableHeading devider style={{width: '8%'}}>
                                Документы
                            </TableHeading>
                            <TableHeading devider style={{width: '8%'}}>
                                Отклонить
                            </TableHeading>
                            <TableHeading devider style={{width: '7%'}}>
                                Принять
                            </TableHeading>
                            <TableHeading devider style={{width: '6%'}}>
                                Начать чат
                            </TableHeading>
                        </TableRow>
                        {state &&
                            state.map((respondedWorker: any, index: number) => (
                                <TableRow isProfileDeleted={respondedWorker.isDeleted} type='row' key={index}>
                                    <TableCell devider style={{width: '12%', padding: 0}}>
                                        <Avatar
                                            isProfileDeleted={respondedWorker.isDeleted}
                                            linkProps={{
                                                pathname:
                                                    respondedWorker.isDeleted && !authorName(respondedWorker).includes('null')
                                                        ? PagesRoutes.NEEDS_LIST +
                                                        PagesRoutes.NEEDS +
                                                        PagesRoutes.WORKER_CARD +
                                                        `/${respondedWorker.workerID}/${info.id}`
                                                        : PagesRoutes.NEEDS_LIST +
                                                        PagesRoutes.NEEDS +
                                                        PagesRoutes.PUBLIC_PROFILE +
                                                        `/${respondedWorker.workerID}/${respondedWorker.contractID}`,
                                                state: history.location.state
                                            }}
                                            // pathToProfile={respondedWorker.isDeleted && !authorName(respondedWorker).includes('null')
                                            //     ? `/public-worker-profile/dump/${respondedWorker.workerID}/${info.id}`
                                            //     : `/public-worker-profile/${respondedWorker.workerID}/${respondedWorker.contractID}`
                                            // }
                                            prevPath={history.location.pathname}
                                            previewHref={respondedWorker.avatar?.pathPreview || respondedWorker.avatar}
                                        />
                                    </TableCell>
                                    <TableCell devider style={{width: '25%'}}>
                                        <NameAndStatus
                                            isProfileDeleted={respondedWorker.isDeleted}
                                            authorName={authorName(respondedWorker)}
                                            status={respondedWorker.state}
                                            tooltip
                                        />
                                    </TableCell>
                                    <TableCell className='createdAt' devider style={{width: '11%'}}>
                                        <div
                                            className='date'>{format(parseISO(respondedWorker.createdAt), 'dd.MM.yyyy')}</div>
                                        <div
                                            className='time'>{format(parseISO(respondedWorker.createdAt), 'HH:mm')}</div>
                                    </TableCell>
                                    <TableCell devider style={{width: '13%'}}>
                                        <Period
                                            start={respondedWorker.workFrom}
                                            end={respondedWorker.workTo}
                                            unlimited={respondedWorker.workUnlimited}
                                        />
                                    </TableCell>
                                    <TableCell className='createdAt' devider style={{width: '10%'}}>
                                        <div
                                            className='responded-workers-page-btn'
                                            style={{
                                                pointerEvents: `${
                                                    checkStatus(respondedWorker.state) ||
                                                    userCTX.currentRole('organizer') ||
                                                    respondedWorker.isDeleted
                                                        ? 'none'
                                                        : 'auto'
                                                }`,
                                                opacity:
                                                    checkStatus(respondedWorker.state) ||
                                                    userCTX.currentRole('organizer') ||
                                                    respondedWorker.isDeleted
                                                        ? 0.38
                                                        : 1
                                            }}
                                            onClick={() => {
                                                if (
                                                    !(
                                                        checkStatus(respondedWorker.state) ||
                                                        userCTX.currentRole('organizer') ||
                                                        respondedWorker.isDeleted
                                                    )
                                                ) {
                                                    requestDocumentsFromTheWorker(respondedWorker.id)
                                                }
                                            }}
                                        >
                                            Запросить
                                        </div>
                                    </TableCell>
                                    <TableCell devider style={{width: '8%'}}>
                                        {respondedWorker.isDeleted || isDocBtnDisabled(respondedWorker.state) ? (
                                            <IconButton
                                                style={{
                                                    background: `url(${documentIcon}) no-repeat center center`,
                                                    opacity: respondedWorker.isDeleted || isDocBtnDisabled(respondedWorker.state) ? 0.38 : 1
                                                }}
                                                disabled={respondedWorker.isDeleted || isDocBtnDisabled(respondedWorker.state)}
                                            />
                                        ) : (
                                            <a className='contract-link'
                                               href={`mailto:${respondedWorker.contactEmail}`}>
                                                <IconButton
                                                    style={{background: `url(${documentIcon}) no-repeat center center`}}
                                                    disabled={respondedWorker.isDeleted || isDocBtnDisabled(respondedWorker.state)}
                                                />
                                            </a>
                                        )}
                                    </TableCell>
                                    <TableCell devider style={{width: '8%'}}>
                                        <IconButton
                                            icon='close'
                                            onClick={() => setDelID(respondedWorker.id)}
                                            disabled={isDeclineBtnDisabled(respondedWorker.state) || respondedWorker.isDeleted}
                                        />
                                    </TableCell>
                                    <TableCell devider style={{width: '7%'}}>
                                        <IconButton
                                            icon='done'
                                            onClick={() => {
                                                accept(respondedWorker.id, respondedWorker.state)
                                            }}
                                            disabled={isAcceptBtnDisabled(respondedWorker.state) || respondedWorker.isDeleted}
                                        />
                                    </TableCell>
                                    <TableCell devider
                                               style={{width: '6%', opacity: respondedWorker.isDeleted ? 0.38 : 1}}>
                                        <Icon
                                            className='icon'
                                            icon={'chat'}
                                            style={{cursor: respondedWorker.isDeleted ? '' : 'pointer'}}
                                            onClick={() => {
                                                if (!respondedWorker.isDeleted) {
                                                    chatCTX.sendMessage(respondedWorker)
                                                }
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </Table>
                )}
            />
            <SimpleDialog
                title='Отказ в приеме'
                body='Вы уверены что хотите отказать рабочему в приеме?'
                acceptLabel='Да'
                cancelLabel='Нет'
                open={delID !== undefined}
                onClose={dialogHandler}
            />
        </div>
    )
}
