import {Role} from "../../global/definitions/definitions"
import {WhoWorkerWrite, PublicOrganizationProfileTemplate, NeedTemplate, EditTemplateSubpage} from "./components";
import {ProfessionsGroups} from "../auth-as-moderator/settings/professions-groups/professions-groups";
import {
    SettingProfessionsList
} from "../auth-as-moderator/settings/setting-professions-list/setting-professions-list";

export {}

export interface ISideBarMenuItem {
    sectionTitle: string,
    sectionURL: string,
    icon: string
    navItems: {
        itemTitle: string,
        component: JSX.Element,
        url: string,
        visibleFor: Role[]
        navBar?: boolean
        description?: boolean
    }[]
}

export let navigationSections: ISideBarMenuItem[] = [
    {
        sectionTitle: 'Профиль организации',
        sectionURL: `organization-profile`,
        icon: 'home',
        navItems: [{
            itemTitle: 'Настройка шаблона',
            component: <PublicOrganizationProfileTemplate/>,
            url: 'profile-template',
            visibleFor: ['admin'],
            description: true,
            navBar: true
        }]
    },
    {
        sectionTitle: 'Потребность',
        sectionURL: `need`,
        icon: 'description',
        navItems: [
            {
                itemTitle: 'Настройка шаблона',
                component: <NeedTemplate/>,
                url: 'template-customization',
                visibleFor: ['admin'],
                description: true,
                navBar: true
            },
            {
                itemTitle: 'Чат с Работником',
                component: <WhoWorkerWrite/>,
                url: 'worker-write-customization',
                visibleFor: ['admin'],
                description: false,
                navBar: true
            }
        ]
    },
    {
        sectionTitle: 'Мобилизация',
        sectionURL: `mobilization`,
        icon: 'checklist_rtl',
        navItems: [
            {
                itemTitle: 'Настройка шаблона мобилизации',
                component: <EditTemplateSubpage/>,
                url: 'mobilization-template',
                visibleFor: ['admin', 'organizer'],
                navBar: true
            },
            {
                itemTitle: 'Настройка шаблона демобилизации',
                component: <EditTemplateSubpage/>,
                url: 'demobilization-template',
                visibleFor: ['admin', 'organizer'],
                navBar: true
            }
        ],
    },
    {
        sectionTitle: 'Справочники',
        sectionURL: `directory`,
        icon: 'home',
        navItems: [
            {
                itemTitle: 'Группы профессий',
                component: <ProfessionsGroups/>,
                url: 'groups',
                visibleFor: ['moderator'],
                navBar: true
            },
            {
                itemTitle: 'Профессии',
                component: <SettingProfessionsList/>,
                url: 'professions',
                visibleFor: ['moderator'],
                navBar: true
            },
            {
                itemTitle: 'Настройка группы профессий',
                component: <ProfessionsGroups/>,
                url: 'redact-group',
                visibleFor: ['moderator'],
                navBar: false
            },
            {
                itemTitle: 'Добавление профессий к группе',
                component: <SettingProfessionsList/>,
                url: 'profession-redact',
                visibleFor: ['moderator'],
                navBar: false
            },
        ],
    }
]


export function isActivePageAvailableForCurrentRole(role: Role, URL: string, navigationSections: ISideBarMenuItem[]) {
    let parsedURL = URL.split('/')
    let targetURLpart = parsedURL[parsedURL.length - 1]
    let targetNavItem: any = {}
    navigationSections.map(section => section.navItems.map(navItem => {
        if (navItem.url == targetURLpart) targetNavItem = {...navItem}
    }).length > 0)
    return targetNavItem.visibleFor.includes(role)
}