import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { ProgressPage, SpanButtons, PageTitle, Spoiler, Table, NameAndStatus, Period, Avatar } from 'components'
import './objects.sass'
import { authorName, req } from '../../global/common'
import { Elevation } from '@rmwc/elevation'
import { ObjectCardProps, ObjectFromAPI } from './objects-def'
import { WorkerObject } from '../Personnel/Personnel.service'
import { BrigadeAPI } from '../../global/definitions/definitions'
import { UserContext } from '../../global/context.user'
import { TextField } from '@rmwc/textfield'
import { TableRow } from '../../components/Table/TableRow/TableRow'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { TableCell } from '../../components/Table/TableCell/TableCell'
import { IconButton } from '@rmwc/icon-button'
import { TableHeading } from '../../components/Table/TableHeading/TableHeading'
import { PagesRoutes } from 'global/routes'

type FormattedObject = ObjectFromAPI & {
	brigades: (BrigadeAPI & {
		workers: WorkerObject[]
	})[]
}

export default function Objects() {
	const history = useHistory()
	const userCTX = useContext(UserContext)
	const [loaded, setLoaded] = useState<boolean>(null!)
	const [objects, setObjects] = useState<ObjectFromAPI[]>([])
	const [query, setQuery] = useState({
		limit: 0,
		offset: 0,
		search: ''
	})
	const [formattedObjects, setFormattedObjects] = useState<FormattedObject[]>([])

	let searchTimeout: NodeJS.Timeout

	useEffect(() => {
		req
			.get(`/objects/list`, query)
			.then(({ data }) => {
				setObjects(data.data)
				setFormattedObjects(makeFormattedObjects(data.data))
				if (data.data.length == 0) {
					setLoaded(false)
				} else {
					setLoaded(true)
				}
			})
			.catch(() => setLoaded(false))
	}, [query])

	const makeFormattedObjects = (data: ObjectFromAPI[]) =>
		data.map(o =>
			Object.assign(o, {
				brigades: o.brigades.map(b => Object.assign(b, { workers: b.workers.map(w => new WorkerObject(w)) }))
			})
		)

	return (
		<div className={'objects'}>
			<PageTitle title={'Объекты'} breadcrumbs={true}>
				{userCTX.userHasRole('admin') && (
					<SpanButtons
						data={[
							{
								label: 'Создать',
								to: {
									pathname: PagesRoutes.OBJECTS + PagesRoutes.CREATE_OBJECT,
									state: history.location.state
								}
							}
						]}
					/>
				)}
			</PageTitle>
			<TextField
				outlined
				maxLength={160}
				onChange={function (e: ChangeEvent<HTMLInputElement>) {
					clearTimeout(searchTimeout)

					searchTimeout = setTimeout(() => {
						setQuery({
							...query,
							search: e.target.value
						})
					}, 500)
				}}
				className='search-input'
				label='Поиск'
			/>
			<ProgressPage
				state={loaded}
				className={'objects'}
				render={() => (
					<>
						{formattedObjects.map(o => (
							<ObjectCard
								key={o.id + o.name}
								id={o.id}
								name={o.name}
								address={o.address}
								startDate={o.dateStart}
								endDate={o.dateEnd}
								brigades={o.brigades}
							/>
						))}
					</>
				)}
			/>
		</div>
	)
}

function ObjectCard({ id, name, address, startDate, endDate, brigades }: ObjectCardProps) {
	const userCTX = useContext(UserContext)
	const history = useHistory()
	return (
		<Elevation className={'object-card'} z={3}>
			<div className={'card-text'}>
				<div className={'card-header'}>
					<div>
						<div className={'title'}>{'Название'}</div>
						<div className={'under-text'}>{name}</div>
					</div>
					{userCTX.userHasRole('admin') && (
						<SpanButtons
							data={[
								{
									label: 'Редактировать',
									to: {
										pathname: PagesRoutes.OBJECTS + PagesRoutes.CREATE_OBJECT + `/${id}`,
										state: history.location.state
									}
								}
							]}
						/>
					)}
				</div>
				<div className={'text-row'}>
					<div className={'title'}>{'Адрес'}</div>
					<div className={'under-text'}>{address}</div>
				</div>
				<div className={'text-row'}>
					<div className={'title'}>{'Дата начала выполнения работ'}</div>
					<div className={'under-text'}>{startDate}</div>
				</div>
				<div className={'text-row'}>
					<div className={'title'}>{'Дата окончания выполнения работ'}</div>
					<div className={'under-text'}>{endDate}</div>
				</div>
			</div>
			<div className={'brigades'}>
				{brigades.map(
					(b, i) =>
						b.workers.length > 0 && (
							<Spoiler
								key={b.brigade?.id}
								title={b.brigade?.name!}
								caption={'(' + b.workers.length + ')'}
								render={history => (
									<Table className={'with-brigades'}>
										<TableRow type='heading'>
											<TableHeading devider style={{ minWidth: '12%' }}>
												ФОТО
											</TableHeading>
											<TableHeading devider style={{ width: '28%' }}>
												ФИО
											</TableHeading>
											<TableHeading devider style={{ width: '12%' }}>
												Должность
											</TableHeading>
											<TableHeading devider style={{ width: '12%' }}>
												Подразделение
											</TableHeading>
											<TableHeading devider style={{ width: '12%' }}>
												Договор
											</TableHeading>
											<TableHeading devider style={{ width: '12%' }}>
												Срок действия договора
											</TableHeading>
											<TableHeading devider style={{ width: '12%' }}>
												Потребность
											</TableHeading>
										</TableRow>
										{b.workers &&
											b.workers.map((worker: any, index: number) => (
												<TableRow type='row' key={index} isProfileDeleted={worker.isDeleted}>
													<TableCell devider style={{ width: '12%', padding: 0 }}>
														<Avatar
															isProfileDeleted={worker.isDeleted}
															linkProps={{
																pathname:
																	worker.isDeleted && !authorName(worker).includes('null')
																		? PagesRoutes.OBJECTS +
																		  PagesRoutes.WORKER_CARD +
																		  `/${worker.id}/${worker.requestID}`
																		: PagesRoutes.OBJECTS +
																		  PagesRoutes.PUBLIC_PROFILE +
																		  `/${worker.id}/${worker.recordID}`,
																state: history.location.state
															}}
															prevPath={history.location.pathname}
															previewHref={worker.avatar?.pathPreview || worker.avatar}
														/>
													</TableCell>
													<TableCell devider style={{ width: '28%' }}>
														<NameAndStatus
															authorName={worker.name}
															isProfileDeleted={worker.isDeleted}
														/>
													</TableCell>
													<TableCell devider style={{ width: '12%' }}>
														<div className='objects-text'>{worker.position}</div>
													</TableCell>
													<TableCell
														devider
														style={{
															width: '12%',
															fontWeight: 500,
															fontSize: '16px',
															letterSpacing: '0.3px',
															textAlign: 'center'
														}}
													>
														{worker.subdivision}
													</TableCell>
													<TableCell devider style={{ width: '12%', fontSize: '30px !important' }}>
														{worker.isDeleted ? (
															<IconButton
																style={{
																	opacity: worker.isDeleted ? 0.38 : 1,
																	fontSize: '35px',
																	width: '70px',
																	height: '70px',
																	color: 'rgba(18, 50, 79, 1)'
																}}
																disabled={worker.isDeleted}
																icon='description'
																className='contract-icon'
															/>
														) : (
															<Link
																className='contract-link'
																style={{ color: 'rgb(53, 76, 98)' }}
																to={{
																	pathname: PagesRoutes.OBJECTS + PagesRoutes.CONTRACT_CARD + `/${worker.recordID}`,
																	state: history.location.state
																}}
															>
																<IconButton
																	disabled={worker.isDeleted}
																	icon='description'
																	className='contract-icon'
																	style={{
																		fontSize: '35px',
																		width: '70px',
																		height: '70px',
																		color: 'rgba(18, 50, 79, 1)'
																	}}
																/>
															</Link>
														)}
													</TableCell>
													<TableCell devider style={{ width: '12%' }}>
														<Period start={worker.workFrom} end={worker.workTo} unlimited={worker.workUnlimited} />
													</TableCell>
													<TableCell
														devider
														style={{
															width: '12%',
															fontWeight: 500,
															fontSize: '22px',
															letterSpacing: '0.3px'
														}}
													>
														{worker.requestNumber}
													</TableCell>
												</TableRow>
											))}
									</Table>
								)}
							/>
						)
				)}
			</div>
		</Elevation>
	)
}
