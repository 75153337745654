import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import './WorkerCardPage.style.sass'
import { IFormData } from './WorkerCardPage.types'
import { Elevation } from '@rmwc/elevation'
import { ProgressPage, Form, PageTitle, DocumentsTable, ERequestDocumentFields, IDocumentsData, IRequestDocumentData } from 'components'
import { useTranslation } from 'react-i18next'
import { authorName, req } from '../../global/common'
import { useParams } from 'react-router'
import { Params } from '../public-profile/public-profile-def'
import { UserContext } from '../../global/context.user'
import { InterfaceContext } from '../../global/context.interface'
import { Role } from '../../global/definitions/definitions'
import parse from 'date-fns/parse'
import { differenceInCalendarDays, differenceInDays } from 'date-fns'

export function WorkerCardPage() {
	const { t } = useTranslation('common')

	const userCTX = React.useContext(UserContext)
	const interfaceCTX = React.useContext(InterfaceContext)

	const formRef = React.createRef() as MutableRefObject<HTMLFormElement>
	const checkboxRef = React.createRef() as MutableRefObject<HTMLInputElement>

	const { searchID } = useParams() as Params

	const [workerCard, setWorkerCard] = React.useState(null as any)
	const [foremen, setForemen] = React.useState(null as any)
	const [organizers, setOrganizers] = React.useState(null as any)
	const [admins, setAdmins] = React.useState(null as any)
	const [isWorkUnlimited, setIsWorkUnlimited] = React.useState(false)
	const [isSaved, setIsSaved] = React.useState(false)

	let organizationID = userCTX.user?.organisation.id

	React.useEffect(() => {
		setIsWorkUnlimited(workerCard && workerCard.workUnlimited)

		if (workerCard && workerCard.workTo != null && workerCard.workUnlimited != true) {
			if (checkboxRef.current) {
				checkboxRef.current.style.display = 'none !important'
			}
		}
	}, [workerCard])

	function getAndSetStaffListByRole(role: Role) {
		let query = {
			organizationID: organizationID,
			limit: 999,
			offset: 0,
			role: role
		}

		req
			.get(`/organisation/${organizationID}/staff`, query)
			.then(({ data }) => {
				let staffListOptions = data.data.map((staffItem: any, index: number) => {
					let options = {
						label: authorName(staffItem),
						value: staffItem.id
					}
					return options
				})
				if (role == 'foreman') {
					setForemen(staffListOptions)
				} else if (role == 'organizer') {
					setOrganizers(staffListOptions)
				} else {
					setAdmins(staffListOptions)
				}
			})
			.catch(() =>
				interfaceCTX.showMessage({
					body: `Не удалось загрузить данные. Обратитесь к администратору`,
					icon: 'report'
				})
			)
	}

	function hideWorkUnlimitedCheckbox(e: any) {
		if (e.formattedValue) {
			checkboxRef.current.style.display = 'none'
		} else {
			checkboxRef.current.style.display = 'flex'
		}
	}

	function saveWorkerCard(data: IFormData) {
		let body = { ...data }
		req
			.post(`/contracts/${searchID}/update`, body)
			.then(() =>
				interfaceCTX.showMessage({
					body: `Данные успешно сохранены`,
					icon: 'done'
				})
			)
			.then(() => setIsSaved(!isSaved))
			.catch(e =>
				interfaceCTX.showMessage({
					title: 'Ошибка',
					body: t(e.response.data),
					icon: 'report'
				})
			)
	}

	function inverseCheckboxValue(e: React.ChangeEvent<HTMLInputElement>) {
		setIsWorkUnlimited(!!e.currentTarget.checked)
	}

	function onSubmit(data: IFormData) {
		// Обязательные поля, их присваиваем без проверок
		data.scheduleID = Number(data.schedule.value)
		data.supervisorID = Number(data.supervisor.value)
		// Необязательный ответственный за мобилизацию. Проверяем
		data.organizerID = data.organizer && Number(data.organizer.value)
		data.position = data.positionOption.value

		// Полученные пустые строки или пробелы переделываем в null
		let editedData = Object.fromEntries(
			Object.entries(data).map(([key, value]) => {
				if (typeof value === 'string' && value.split(' ').join('') == '') {
					return [key, null]
				}
				// Превращаем бывшие select-ы в toDelete, чтобы потом удалить из объекта
				else if (typeof value === 'object') {
					return ['toDelete', '']
				} else {
					return [key, value]
				}
			})
		)

		let requestData = {
			...anthropometry,
			contractId: searchID
		}
		req
			.post(`/profile/worker/anthropometry/${workerCard.worker.id}`, requestData)
			.then(() => setIsAnthropometryChanged(false))

		//Удаляем значения СИЗ из формы
		delete editedData.bustGirth
		delete editedData.shoeSize
		delete editedData.headGirth
		delete editedData.height
		delete editedData.hipsGirth
		delete editedData.waistGirth

		//Удаляем значения, оставшиеся после обработки select-ов
		delete editedData.toDelete
		delete editedData.positionOption

		editedData.workUnlimited = isWorkUnlimited

		// Если стоит галочка "бессрочный договор", workTo делаем null
		if (editedData.workUnlimited) {
			editedData.workTo = null
		}

		//Строки преобразуем в даты для последующего сранвнеия
		let workFromParsed = parse(editedData.workFrom, 'dd.MM.yyyy', new Date())
		let workToParsed = parse(editedData.workTo, 'dd.MM.yyyy', new Date())

		if (workerCard != null) {
			if (workFromParsed > workToParsed) {
				req.get(`/contracts/${searchID}/get`).then(() =>
					interfaceCTX.showMessage({
						body: `Дата окончания работ не может быть меньше даты начала`,
						icon: 'report'
					})
				)
			}
			else {
				const differenceBtwDates = differenceInDays(workToParsed, workFromParsed)
				if (differenceBtwDates < 13) {
					req.get(`/contracts/${searchID}/get`).then(() =>
						interfaceCTX.showMessage({
							title: 'Ошибка',
							body: 'Минимальный срок действия договора - 14 дней',
							icon: 'report'
						})
					)
					return
				}
				saveWorkerCard(editedData as IFormData)
			}
		}
		return
	}

	function getSchedule(id?: string) {
		const options = [
			{ label: '№1 - обычный', value: '1' },
			{ label: '№2 - сокращённый', value: '2' },
			{ label: '№3 - сменный', value: '3' },
			{ label: '№4 - сменный', value: '4' },
			{ label: '№5 - вахта', value: '5' }
		]
		if (id) return options.find(v => v.value === id)
		return options
	}

	function scheduleDescription(value: any): JSX.Element | null {
		let rt
		switch (value?.value) {
			case '1':
				rt = 'Пятидневная рабочая неделя по 8 часов в день, № 40 ч.'
				break
			case '2':
				rt = 'Сокращённая пятидневная рабочая неделя по 7 часов в день, № 36 ч.'
				break
			case '3':
				rt = '2х-сменный, 4х-бригадный. Продолжительность рабочей смены 11,5 часов (день/ночь/48)'
				break
			case '4':
				rt = '1-сменный, 2х-бригадный. Продолжительность рабочей смены 11,5 часов (2x2)'
				break
			case '5':
				rt = 'Вахтовый. Продолжительность рабочей смены 11,5 часов (месяц через месяц)'
				break
		}
		if (!rt) return null
		return (
			<>
				<div className={'item-title item-title--view'}>Описание</div>
				<div className={'item-text'}>{rt}</div>
			</>
		)
	}

	const [anthropometryHistory, setAnthropometryHistory] = useState([])
	const [anthropometry, setAnthropometry] = useState<
		| {
				bustGirth: string
				contractId: number
				gender: 'male' | 'female'
				headGirth: string
				height: string
				hipsGirth: string
				shoeSize: string
				waistGirth: string
		  }
		| any
	>({} as any)

	const getAnthropometryFromOldUserContracts = (userId: number) => {
		req.get(`/contracts/get-anthropometry-from-old-user-contracts?userID=${userId}`).then(({ data }) => {
			setAnthropometryHistory(data)
		})
	}

	React.useEffect(() => {
		req
			.get(`/contracts/${searchID}/get`)
			.then(({ data }) => {
				setWorkerCard(data)
				setAnthropometry({
					bustGirth: data.anthropometry.bustGirth ? data.anthropometry.bustGirth : '',
					contractId: searchID,
					gender: data.anthropometry.gender ? data.anthropometry.gender : null,
					headGirth: data.anthropometry.headGirth ? data.anthropometry.headGirth : '',
					height: data.anthropometry.height ? data.anthropometry.height : '',
					hipsGirth: data.anthropometry.hipsGirth ? data.anthropometry.hipsGirth : '',
					shoeSize: data.anthropometry.shoeSize ? data.anthropometry.shoeSize : '',
					waistGirth: data.anthropometry.waistGirth ? data.anthropometry.waistGirth : ''
				})
				getAnthropometryFromOldUserContracts(data.worker.id)
				getDocumentsList(data.worker.id)
			})
			.catch(() => setWorkerCard(false))

		getAndSetStaffListByRole('foreman')
		getAndSetStaffListByRole('organizer')
		getAndSetStaffListByRole('admin')
	}, [isSaved])

	const defaultNewDocument: IRequestDocumentData = {
		beginDate: '',
		document: null,
		documentNumber: '',
		documentName: '',
		endDate: '',
		provisioningCenterName: ''
	}

	const [isAddNewDocument, setIsAddNewDocument] = useState(false)
	const [documents, setDocuments] = useState<IDocumentsData[] | null | any>(null)
	const [newDocument, setNewDocument] = useState<IRequestDocumentData>(defaultNewDocument)
	const [documentsIsChanged, setDocumentsIsChanged] = useState(false)
	const [existingDocumentsIsChanged, setExistingDocumentsIsChanged] = useState(false)
	const [newDocumentId, setNewDocumentId] = useState<any>()
	const [editedExistingDocument, setEditedExistingDocument] = useState<IRequestDocumentData | any>(
		{} as IRequestDocumentData
	)

	const onChangeSortValues = (fieldName: string, sortType: 'ASC' | 'DESC' | null) => {
		getDocumentsList(workerCard.worker.id, { [fieldName]: sortType })
	}

	const onChangeNewDocument = (field: ERequestDocumentFields, value: string | null) => {
		if (field === ERequestDocumentFields.DOCUMENT && value === null) {
			setNewDocumentId(null)
		}
		setDocumentsIsChanged(true)
		setNewDocument((prevState: IRequestDocumentData) => {
			return {
				...prevState,
				[field]: value
			}
		})
	}

	const getDocumentsList = (id: number, sortBy?: any) => {
		req
			.post(`/profile/worker/certificates-user-list?userID=${id}`, sortBy ? sortBy : { endDate: 'ASC' })
			.then(({ data }) => {
				setDocuments(data)
				setNewDocument(defaultNewDocument)
				setEditedExistingDocument({} as IRequestDocumentData)
			})
	}

	const uploadNewDocument = (file: File | null, callback: () => void) => {
		setDocumentsIsChanged(true)
		if (file) {
			let formData = new FormData()
			// file.name.split(" ").join('_')
			formData.append('file', file)
			req.post(`/profile/worker/certificates-upload?userID=${workerCard.worker.id}`, formData).then(({ data }) => {
				setNewDocumentId(data.id)
				setNewDocument((prevState: any) => ({ ...prevState, document: data.id }))
				setEditedExistingDocument((prevState: any) => ({ ...prevState, document: data.id }))
				callback()
			})
		}
	}

	const checkDate = (beginDate: string[], endDate: string[]) => {
		const currentDate = new Date(new Date()).toLocaleString('ru-RU').split(',')[0].split('.')
		if (
			differenceInCalendarDays(
				parse(beginDate.join('.'), 'dd.MM.yyyy', new Date()).getTime(),
				parse(endDate.join('.'), 'dd.MM.yyyy', new Date()).getTime()
			) > 0
		) {
			interfaceCTX.showMessage({
				body: `Срок действия документа не может быть меньше, чем Дата выдачи`,
				icon: 'report'
			})
			return true
		} else if (endDate.length !== 0 && endDate[2] < String(Number(currentDate[2]) - 65)) {
			interfaceCTX.showMessage({
				body: `Год срока действия не может быть менее ${String(Number(currentDate[2]) - 65)}`,
				icon: 'report'
			})
			return true
		} else if (beginDate[2] < String(Number(currentDate[2]) - 65)) {
			interfaceCTX.showMessage({
				body: `Год даты выдачи не может быть менее ${String(Number(currentDate[2]) - 65)}`,
				icon: 'report'
			})
			return true
		} else if (
			differenceInCalendarDays(
				parse(beginDate.join('.'), 'dd.MM.yyyy', new Date()).getTime(),
				parse(endDate.join('.'), 'dd.MM.yyyy', new Date()).getTime()
			) === 0
		) {
			interfaceCTX.showMessage({
				body: 'Дата выдачи и срок действия документа не могут быть равны',
				icon: 'report'
			})
			return true
		} else return false
	}

	const checkFields = (
		beginDate: string,
		documentNumber: string,
		documentName: string,
		provisioningCenterName: string
	) => {
		if (documentNumber === '') {
			interfaceCTX.showMessage({
				body: 'Необходимо заполнить поле "Номер документа"',
				icon: 'report'
			})
			return true
		} else if (beginDate.length < 10) {
			interfaceCTX.showMessage({
				body: 'Необходимо заполнить поле "Дата выдачи"',
				icon: 'report'
			})
			return true
		} else if (documentName === '') {
			interfaceCTX.showMessage({
				body: 'Необходимо заполнить поле "Наименование"',
				icon: 'report'
			})
			return true
		} else if (provisioningCenterName === '') {
			interfaceCTX.showMessage({
				body: 'Необходимо заполнить поле "Центр дополнительной профессиональной подготовки"',
				icon: 'report'
			})
			return true
		} else return false
	}

	const saveNewDocument = () => {
		if (!newDocumentId) {
			interfaceCTX.showMessage({
				body: 'Сначала выберите документ',
				icon: 'report'
			})
			return
		}

		if (isAddNewDocument) {
			let beginDate = [
				newDocument.beginDate.split('-')[2],
				newDocument.beginDate.split('-')[1],
				newDocument.beginDate.split('-')[0]
			].join('.')
			let endDate =
				newDocument.endDate !== '' && newDocument.endDate !== null
					? [
							newDocument.endDate.split('-')[2],
							newDocument.endDate.split('-')[1],
							newDocument.endDate.split('-')[0]
					  ].join('.')
					: ''
			if (checkDate(beginDate.split('.'), endDate.split('.'))) return
			if (
				checkFields(beginDate, newDocument.documentNumber, newDocument.documentName, newDocument.provisioningCenterName)
			)
				return
			let requestData = {
				...newDocument,
				document: newDocumentId,
				userID: workerCard.worker.id,
				beginDate: beginDate,
				endDate: endDate.length < 10 ? null : endDate
			}
			req.post('/profile/worker/certificates/add', requestData).then(data => {
				setDocumentsIsChanged(false)
				setIsAddNewDocument(false)
				interfaceCTX.checkIsFormChanged(false, 'contract')
				setNewDocumentId(null)
				getDocumentsList(workerCard.worker.id)
			})
		}
		if (existingDocumentsIsChanged) {
			let beginDate = editedExistingDocument.beginDate.includes('-')
				? [
						editedExistingDocument.beginDate.split('-')[2],
						editedExistingDocument.beginDate.split('-')[1],
						editedExistingDocument.beginDate.split('-')[0]
				  ].join('.')
				: editedExistingDocument.beginDate
			let endDate = editedExistingDocument.endDate
				? editedExistingDocument.endDate.includes('-')
					? [
							editedExistingDocument.endDate.split('-')[2],
							editedExistingDocument.endDate.split('-')[1],
							editedExistingDocument.endDate.split('-')[0]
					  ].join('.')
					: editedExistingDocument.endDate
				: editedExistingDocument.endDate
			if (checkDate(beginDate.split('.'), endDate ? endDate.split('.') : [''])) return
			if (
				checkFields(
					beginDate,
					editedExistingDocument.documentNumber,
					editedExistingDocument.documentName,
					editedExistingDocument.provisioningCenterName
				)
			)
				return
			let requestData = {
				...editedExistingDocument,
				document: newDocumentId,
				userID: workerCard.worker.id,
				beginDate: beginDate,
				endDate: endDate ? (endDate.length < 10 ? null : endDate) : endDate
			}
			req.post('/profile/worker/certificates/add', requestData).then(data => {
				setExistingDocumentsIsChanged(false)
				setNewDocumentId(null)
				interfaceCTX.checkIsFormChanged(false, 'contract')
				getDocumentsList(workerCard.worker.id)
			})
		}
	}

	const deleteDocument = (id: number) => {
		if (window.confirm('Вы уверены, что хотите удалить документ?')) {
			req.delete(`/profile/worker/delete?certId=${id}`).then(() => {
				getDocumentsList(workerCard.worker.id)
			})
		}
	}

	const onChangeExistingDocument = (field: ERequestDocumentFields, value: string | null) => {
		if (field === ERequestDocumentFields.DOCUMENT && value === null) {
			setNewDocumentId(null)
		}
		setExistingDocumentsIsChanged(true)
		setEditedExistingDocument((prevState: IRequestDocumentData) => {
			return {
				...prevState,
				[field]: value
			}
		})
	}

	useEffect(() => {
		if (documents?.length !== 0 && documents !== null) {
			dateIsEnd(documents.map((item: IDocumentsData) => item.endDate))
		}
	}, [documents])

	const startChangeDocument = (document: IDocumentsData, index: number) => {
		interfaceCTX.checkIsFormChanged(true, 'contract')
		setIsAddNewDocument(false)
		setDocuments((prevState: IDocumentsData[]) => {
			return prevState.map((item, i) => {
				if (i === index) {
					return { ...item, isChanged: true }
				} else return { ...item, isChanged: false }
			})
		})
		setNewDocumentId(document.document.id)
		setEditedExistingDocument({
			id: document.id,
			beginDate: document.beginDate,
			document: document.document,
			documentNumber: document.documentNumber,
			documentName: document.documentName,
			endDate: document.endDate,
			provisioningCenterName: document.provisioningCenterName,
			userID: workerCard.worker.id
		})
	}

	const stopChangeDocument = () => {
		interfaceCTX.checkIsFormChanged(false, 'contract')
		setDocuments((prevState: IDocumentsData[]) => {
			return prevState.map((item, i) => {
				return { ...item, isChanged: false }
			})
		})
		setNewDocumentId(null)
		setEditedExistingDocument({} as IRequestDocumentData)
		setExistingDocumentsIsChanged(false)
	}

	const [isAnthropometryChanged, setIsAnthropometryChanged] = useState(false)

	const onChangeAnthropometry = (value: string, field: string) => {
		setIsAnthropometryChanged(true)
		setAnthropometry((prevState: any) => {
			return {
				...prevState,
				[field]: value
			}
		})
	}

	const selectAnthropometryValues = (e: { value: string; label: string }) => {
		setIsAnthropometryChanged(true)
		let newAnthropometryValue: any = anthropometryHistory.find((item: any) => item.contractInfo.contractId === e.value)
		if (!!newAnthropometryValue) {
			let newState: any = {
				contractId: searchID,
				gender: newAnthropometryValue.gender ? newAnthropometryValue.gender : null,
				bustGirth: newAnthropometryValue.bustGirth,
				headGirth: newAnthropometryValue.headGirth,
				height: newAnthropometryValue.height,
				shoeSize: newAnthropometryValue.shoeSize,
				waistGirth: null,
				hipsGirth: null
			}
			if (workerCard.anthropometry.gender === 'male') {
				newState.waistGirth = newAnthropometryValue.waistGirth
			} else if (workerCard.anthropometry.gender === 'female') {
				newState.hipsGirth = newAnthropometryValue.hipsGirth
			} else {
				newState.waistGirth = newAnthropometryValue.waistGirth
			}
			setAnthropometry(newState)
		} else {
			let state = {
				contractId: '',
				gender: null,
				bustGirth: '',
				headGirth: '',
				height: '',
				shoeSize: '',
				waistGirth: '',
				hipsGirth: ''
			}
			setAnthropometry(state)
		}
	}

	const colorRef = useRef(false)

	const dateIsEnd = (dateList: string[]) => {
		if (dateList === null || dateList.length === 0) return
		const currentDate = new Date(new Date()).toLocaleString('ru-RU').split(',')[0].split('.')
		dateList.forEach((date: string) => {
			if (date !== null && date !== '') {
				if (
					differenceInCalendarDays(
						parse(date, 'dd.MM.yyyy', new Date()).getTime(),
						parse(currentDate.join('.'), 'dd.MM.yyyy', new Date()).getTime()
					) < 0
				) {
					colorRef.current = true
				}
			}
		})
	}

	return (
		<div className='worker-card-page'>
			{workerCard && (
				<PageTitle
					title={'Карточка договора > ' + authorName(workerCard.worker)}
					breadcrumbs={true}
				/>
			)}
			<Elevation className='worker-card-wrapper'>
				<ProgressPage
					state={workerCard}
					render={() => (
						<div className='worker-card-inner'>
							<Form
								name={'contract'}
								useRef={formRef}
								isDropDown={true}
								hideButtons={userCTX.userHasRole('hr') || userCTX.userHasRole('admin') ? true : false}
								buttons={[
									[
										{
											callback: e => {
												formRef.current.dispatchEvent(
													new Event('submit', {
														cancelable: true,
														bubbles: true
													})
												)
											},
											key: 'saveForm',
											type: 'button',
											title: 'Сохранить'
										}
									],
									[
										{
											callback: e => {
												formRef.current.dispatchEvent(
													new Event('submit', {
														cancelable: true,
														bubbles: true
													})
												)
											},
											key: 'saveContract',
											type: 'button',
											title: 'Сохранить'
										}
									],
									[
										{
											callback: (e: { value: string; label: string }) => selectAnthropometryValues(e),
											type: 'selector',
											key: 'anthropometryHistory',
											placeholder: 'Скопировать данные из предыдущей потребности',
											//@ts-ignore
											defaultValue: Number(searchID),
											options: anthropometryHistory
										},
										{
											callback: e => {
												formRef.current.dispatchEvent(
													new Event('submit', {
														cancelable: true,
														bubbles: true
													})
												)
											},
											type: 'button',
											key: 'saveAnthropometry',
											title: 'Сохранить',
											isVisible: isAnthropometryChanged
										}
									],
									[
										{
											callback: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
												e.preventDefault()
												saveNewDocument()
											},
											isVisible: documentsIsChanged || existingDocumentsIsChanged,
											type: 'button',
											key: 'saveDocuments',
											title: 'Сохранить'
										},
										{
											callback: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
												e.preventDefault()
												stopChangeDocument()
											},
											isVisible: !!documents?.find((item: any) => item.isChanged === true),
											type: 'button',
											key: 'undoEditing',
											title: 'Отменить редактирование '
										},
										{
											callback: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
												e.preventDefault()
												if (!existingDocumentsIsChanged) {
													setIsAddNewDocument((prevState: boolean) => !prevState)
													setNewDocumentId(null)
													setNewDocument(defaultNewDocument)
													isAddNewDocument && setDocumentsIsChanged(false)
													setDocuments((prevState: IDocumentsData[]) =>
														prevState.map(item => ({
															...item,
															isChanged: false
														}))
													)
												} else if (
													existingDocumentsIsChanged &&
													window.confirm('Текущие изменения в документе не сохранятся, вы уверены?')
												) {
													setIsAddNewDocument((prevState: boolean) => !prevState)
													interfaceCTX.checkIsFormChanged(false, 'contract')
													setExistingDocumentsIsChanged(false)
													setNewDocumentId(null)
													setNewDocument(defaultNewDocument)
													isAddNewDocument && setDocumentsIsChanged(false)
													setDocuments((prevState: IDocumentsData[]) =>
														prevState.map(item => ({
															...item,
															isChanged: false
														}))
													)
												}
											},
											type: 'button',
											key: 'addNewDocument',
											isVisible: !workerCard.worker.isDeleted,
											title: isAddNewDocument ? 'Скрыть новый документ' : 'Добавить новый документ'
										}
									]
								]}
								dropDownBlocks={[
									{ headName: '', isOpen: false },
									{ headName: 'Договор', isOpen: true },
									{ headName: 'Спец. одежда', isOpen: false },
									{
										headName: 'Документы оформлены Работодателем',
										isOpen: false,
										icon: {
											name: 'priority_high',
											visible: colorRef
										}
									}
								]}
								onSubmit={onSubmit}
								viewMode={userCTX.userHasRole('hr') || userCTX.userHasRole('admin') ? false : true}
								data={[
									[
										{
											values: [
												{
													type: 'text',
													title: 'Номер потребности',
													value: workerCard && workerCard.requestNumber
												},
												{
													type: 'text',
													title: 'Дата заключения договора',
													value: workerCard && workerCard.requestAcceptDate
												}
											]
										}
									],
									[
										{
											values: [
												{
													type: 'select',
													title: 'График работ',
													var: 'schedule',
													defaultValue:
														workerCard.schedule &&
														(getSchedule(String(workerCard.schedule.id)) as { label: string; value: string }),
													options: getSchedule() as { label: string; value: string }[],
													validate: { required: true }
												},
												{
													type: 'other',
													watchVar: 'schedule',
													value: (_: any, v: any) => scheduleDescription(v)
												},
												{
													type: 'text',
													title: 'Дата готовности к выполнению работ',
													value: workerCard && workerCard.mobilizedAt
												},
												isWorkUnlimited
													? {
															type: 'period',
															title: 'Cрок действия договора',
															values: [
																{
																	label: 'c',
																	var: 'workFrom',
																	defaultValue: workerCard && workerCard.workFrom,
																	validate: { required: true },
																	errors: { pattern: 'Введите дату в формате ДД.ММ.ГГГГ' }
																}
															]
													  }
													: {
															type: 'period',
															title: 'Cрок действия договора',
															values: [
																{
																	label: 'c',
																	var: 'workFrom',
																	defaultValue: workerCard && workerCard.workFrom,
																	validate: { required: true },
																	errors: { pattern: 'Введите дату в формате ДД.ММ.ГГГГ' }
																},
																{
																	label: 'по',
																	var: 'workTo',
																	defaultValue: workerCard && workerCard.workTo,
																	validate: { required: true },
																	errors: { pattern: 'Введите дату в формате ДД.ММ.ГГГГ' },
																	onChange: hideWorkUnlimitedCheckbox
																}
															]
													  },
												{
													type: 'checkbox',
													label: 'Бессрочный договор',
													var: 'workUnlimited',
													value: isWorkUnlimited,
													onChange: inverseCheckboxValue,
													ref: checkboxRef
												},
												{
													title: 'Табельный номер работника',
													var: 'employeeIDNumber',
													defaultValue: workerCard && workerCard.employeeIDNumber,
													validate: { maxLength: 30 },
													errors: { maxLength: 'Введите не более 30 символов' }
												},
												{
													type: 'text',
													title: 'Бригада',
													value: workerCard.brigade && workerCard.brigade.name
												}
											]
										},
										{
											values: [
												{
													type: 'select',
													title: 'Непосредственный руководитель',
													var: 'supervisor',
													defaultValue: {
														label: workerCard.supervisor && authorName(workerCard.supervisor),
														value: workerCard.supervisor && workerCard.supervisor.id
													},
													options: foremen,
													validate: { required: true },
													info: {
														title:
															'Смена непосредственного Руководителя приведет к исключению Работника из действующей бригады',
														icon: {
															color: '#F2C94C',
															name: 'warning'
														}
													}
												},
												{
													type: 'select',
													title: 'Ответственный за мобилизацию',
													var: 'organizer',
													isClearable: true,
													defaultValue: workerCard.organizer
														? {
																label: workerCard.organizer && authorName(workerCard.organizer),
																value: workerCard.organizer && workerCard.organizer.id
														  }
														: null,
													options: organizers && organizers.concat(admins)
												},
												{
													type: 'async-select',
													title: 'Должность',
													var: 'positionOption',
													defaultValue: workerCard.position
														? {
																label: workerCard.position,
																value: workerCard.position
														  }
														: undefined,
													url: '/info/professions',
													toText: v => ({ label: v.name, value: v.name }),
													validate: { required: true }
												},
												{
													title: 'Подразделение',
													var: 'subdivision',
													defaultValue: workerCard && workerCard.subdivision,
													validate: { maxLength: 120 },
													errors: { maxLength: 'Введите не более 120 символов' }
												},
												{
													title: 'Разряд',
													var: 'grade',
													defaultValue: workerCard && workerCard.grade,
													validate: { maxLength: 30 },
													errors: { maxLength: 'Введите не более 30 символов' }
												},
												{
													type: 'text',
													title: 'Дата прекращения трудовых отношений',
													value: workerCard && workerCard.demobilizedAt
												}
											]
										}
									],
									[
										{
											values: [
												{
													title: 'Обхват головы, см',
													var: 'headGirth',
													validate: { pattern: /^\d+(\.\d)?$/, min: 52, max: 64 },
													errors: { pattern: 'Пример допустимых значений 55 или 55.6' },
													isUseValue: true,
													onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
														onChangeAnthropometry(e.currentTarget.value, 'headGirth'),
													defaultValue:
														userCTX.user?.currentRole === 'organizer' && !anthropometry.headGirth
															? 'Не указано'
															: anthropometry.headGirth
												},
												{
													title: 'Обхват груди, см',
													var: 'bustGirth',
													validate:
														anthropometry.gender === 'male'
															? {
																	pattern: /^\d+(\.\d)?$/,
																	min: 86,
																	max: 158
															  }
															: { pattern: /^\d+(\.\d)?$/, min: 78, max: 158 },
													errors: { pattern: 'Пример допустимых значений 80 или 80.6' },
													isUseValue: true,
													defaultValue:
														userCTX.user?.currentRole === 'organizer' && !anthropometry.bustGirth
															? 'Не указано'
															: anthropometry.bustGirth,
													onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
														onChangeAnthropometry(e.currentTarget.value, 'bustGirth')
												},
												{
													title: anthropometry.gender === 'male' ? 'Обхват талии, см' : 'Обхват бедер, см',
													var: anthropometry.gender === 'male' ? 'waistGirth' : 'hipsGirth',
													type: 'textfield',
													isUseValue: true,
													defaultValue:
														userCTX.user?.currentRole === 'organizer' &&
														(!anthropometry.waistGirth || !anthropometry.hipsGirth)
															? 'Не указано'
															: anthropometry.gender === 'male'
															? anthropometry.waistGirth
															: anthropometry.hipsGirth,
													validate:
														anthropometry.gender === 'male'
															? {
																	pattern: /^\d+(\.\d)?$/,
																	min: 76,
																	max: 148
															  }
															: { pattern: /^\d+(\.\d)?$/, min: 84, max: 164 },
													errors: { pattern: 'Пример допустимых значений 90 или 90.6' },
													onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
														onChangeAnthropometry(
															e.currentTarget.value,
															anthropometry.gender === 'male' ? 'waistGirth' : 'hipsGirth'
														)
												}
											]
										},
										{
											values: [
												{
													title: 'Рост, см',
													var: 'height',
													isUseValue: true,
													validate:
														anthropometry.gender === 'male'
															? {
																	pattern: /^\d+(\.\d)?$/,
																	min: 158,
																	max: 203
															  }
															: { pattern: /^\d+(\.\d)?$/, min: 143, max: 191 },
													errors: { pattern: 'Пример допустимых значений 165 или 165.6' },
													onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
														onChangeAnthropometry(e.currentTarget.value, 'height'),
													defaultValue:
														userCTX.user?.currentRole === 'organizer' && !anthropometry.height
															? 'Не указано'
															: anthropometry.height
												},
												{
													title: 'Размер ноги, см',
													validate: { pattern: /^\d+(\.\d)?$/, min: 30, max: 70 },
													errors: { pattern: 'Пример допустимых значений 40 или 40.6' },
													var: 'shoeSize',
													isUseValue: true,
													onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
														onChangeAnthropometry(e.currentTarget.value, 'shoeSize'),
													defaultValue:
														userCTX.user?.currentRole === 'organizer' && !anthropometry.shoeSize
															? 'Не указано'
															: anthropometry.shoeSize
												}
											]
										}
									],
									[
										{
											values: [
												{
													type: 'other',
													title: '',
													colSpan: 5,
													value: () => (
														<DocumentsTable
															documents={documents}
															onChangeSortValues={onChangeSortValues}
															isAddNewDocument={isAddNewDocument}
															onChangeNewDocument={onChangeNewDocument}
															uploadNewDocument={uploadNewDocument}
															deleteDocument={deleteDocument}
															startChangeDocument={startChangeDocument}
															editedExistingDocument={editedExistingDocument}
															onChangeExistingDocument={onChangeExistingDocument}
															newDocument={newDocument}
															isEdited={userCTX.userHasRole('hr') || userCTX.userHasRole('admin')}
														/>
													)
												}
											]
										}
									]
								]}
							/>
							{/*<FixedButtons length={4}*/}
							{/*              buttons={[*/}
							{/*                  {*/}
							{/*                      label: "Cохранить",*/}
							{/*                      primary: true,*/}
							{/*                      onClick: () => formRef.current.dispatchEvent(new Event('submit', {*/}
							{/*                          cancelable: true,*/}
							{/*                          bubbles: true*/}
							{/*                      })),*/}
							{/*                      hide: userCTX.userHasRole('hr') || userCTX.userHasRole('admin') ? false : true*/}
							{/*                  },*/}
							{/*              ]}*/}
							{/*/>*/}
						</div>
					)}
				/>
			</Elevation>

			{/* <SimpleDialog
                open={openDialog.supervisorChanged}
                body={`Если Вы измените ответственного руководителя,
                это приведет к исключению работника из действующей бригады.
                Вы уверены?`}
                cancelLabel={'Нет'}
                acceptLabel={'Да'}
                onClosed={(e) => {
                    if (e.detail.action === 'accept') {
                        setOpenDialog({
                            ...openDialog,
                            supervisorChanged: false
                        })
                        saveWorkerCard(formData)
                    }
                    else {
                        setOpenDialog({
                            ...openDialog,
                            supervisorChanged: false
                        })
                    }

                }}
            /> */}
		</div>
	)
}
