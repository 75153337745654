import {SpanButtons, SelectOption, Form} from 'components'
import {differenceInCalendarDays} from 'date-fns'
import parse from 'date-fns/parse'
import {req} from 'global/common'
import {getFormattedSnils} from 'global/utils'
import {checkInputedAge} from 'pages/auth-as-worker/auth-as-worker-utils'
import React, {MutableRefObject, useCallback, useEffect, useRef, useState} from 'react'
import {Controller} from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'
import {WorkerProfileImage} from '../worker-profile-image/worker-profile-image'
import {DocumentImage} from './components/document-image/document-image'
import {NumberField} from './components/number-field/number-field'
import {DocumentsFormProps} from './documents-form-def'
import './documents-form.sass'
import {checkINN} from "../../../../public-profile/public-profile-utils";

export const DocumentsForm: React.FC<DocumentsFormProps> = ({
                                                                documents,
                                                                personalInfo,
                                                                registrationAddress,
                                                                actualAddress,
                                                                onSubmit,
                                                                onClickClose
                                                            }) => {
    const formRef = useRef() as MutableRefObject<HTMLFormElement>

    const [isAddressesMatch, setIsAddressMatch] = useState(
        registrationAddress && actualAddress && registrationAddress.address === actualAddress.address
    )
    const [selectOptions, setSelectOptions] = useState<SelectOption[]>([])

    useEffect(() => {
        getOptions(documents.passport?.subdivisionCode || '')
    }, [])

    function isGpsValid(gps: string) {
        gps = String(gps).replace(/[^0-9]+/g, '');
        if (gps.length == 11) {
            var checksum = 0;
            for (var i = 0; i < 9; i++) {
                checksum += parseInt(gps.charAt(i)) * (9 - i);
            }
            if (checksum > 101) {
                checksum = checksum % 101;
            }
            if (checksum == 100 || checksum == 101) {
                checksum = 0;
            }
            return checksum == parseInt(gps.substr(9));
        }
        return false;
    }

    const getOptions = useCallback(
        (subCode: string) => {
            if (subCode) {
                req.get(`/info/fms?search=${subCode}`).then(response => {
                    const mappedData = response.data.data.map((value: string) => {
                        const splitValue = value.split(',')
                        return {
                            label: splitValue[1],
                            value: splitValue[1]
                        }
                    })
                    setSelectOptions(mappedData)
                })
            }
        },
        [setSelectOptions]
    )

    const handleClickCancel = useCallback(() => {
        onClickClose(2)
    }, [])

    const handleSubmit = useCallback(
        values => {
            values.passportMedia = values.passportMedia ? values.passportMedia : []

            const media = [
                {
                    ...values.innMedia,
                    type: 'inn',
                    id: values.innMedia.id ? values.innMedia.id : -1 * Date.now()
                },
                {
                    ...values.snilsMedia,
                    type: 'snils',
                    id: values.snilsMedia.id ? values.snilsMedia.id : -1.1 * Date.now()
                },

                ...values.passportMedia
            ]
            onSubmit?.({
                personalInfo: {
                    ...values.personalInfo
                },
                documents: {
                    ...values.documents,
                    snils: values.documents.snils.replace(/[^0-9]/g, ''),
                    passport: {
                        ...values.documents.passport,
                        issuedBy: values.documents.passport.issuedBy.label
                    },
                    media
                },
                registrationAddress: values.registrationAddress,
                actualAddress: isAddressesMatch ? values.registrationAddress : values.actualAddress
            })
        },
        [onSubmit]
    )

    return (
        <div className='documents-form-wrapper'>
            <Form
                className='documents-form'
                elevation={0}
                name='workerProfile'
                onSubmit={handleSubmit}
                useRef={formRef}
                data={[
                    [
                        {
                            values: [
                                {
                                    type: 'other',
                                    colSpan: -1,
                                    value: () => (
                                        <div className='documents-form-buttons-save'>
                                            <div className='button-save'>
                                                <SpanButtons
                                                    data={[
                                                        {
                                                            label: 'Не сохранять',
                                                            onClick: handleClickCancel
                                                        }
                                                    ]}
                                                />
                                            </div>
                                            <SpanButtons
                                                data={[
                                                    {
                                                        label: 'Сохранить изменения',
                                                        onClick: () => {
                                                            formRef.current.dispatchEvent(
                                                                new Event('submit', {
                                                                    cancelable: true,
                                                                    bubbles: true
                                                                })
                                                            )
                                                        }
                                                    }
                                                ]}
                                            />
                                        </div>
                                    )
                                }
                            ]
                        }
                    ],
                    [
                        {
                            values: [
                                {
                                    type: 'other',
                                    watchVar: 'citizenship',
                                    value: (_: any, v: any) => (
                                        <div>
                                            <div
                                                style={{
                                                    fontWeight: '600',
                                                    marginBottom: '8px'
                                                }}
                                            >
                                                Гражданство<span style={{color: 'red'}}>*</span>
                                            </div>
                                            <div
                                                style={{
                                                    marginLeft: '4px'
                                                }}
                                            >
                                                Российская Федерация
                                            </div>
                                        </div>
                                    )
                                },
                                {
                                    type: 'other',
                                    value: (_, currentValue, {setValue, control}) => (
                                        <NumberField
                                            title='ИНН'
                                            name='documents.inn'
                                            defaultValue={documents.inn}
                                            control={control}
                                            format='############'
                                            placeholder='0000000000'
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Укажите Ваш ИНН'
                                                },
                                                maxLength: 12,
                                                minLength: 12,
                                                validate: value => {
                                                    if (value.length > 12) {
                                                        return 'Вы указали некорректный ИНН'
                                                    }
                                                    const isValidAge = checkINN(value)

                                                    if (isValidAge) {
                                                        return true
                                                    } else {
                                                        return 'Вы указали некорректный ИНН'
                                                    }
                                                },
                                                pattern: {
                                                    value: /^\d{12}$/,
                                                    message: 'Вы указали некорректный ИНН'
                                                }
                                            }}
                                        />
                                    )
                                },
                                {
                                    type: 'other',
                                    watchVar: 'innMedia',
                                    defaultValue: documents.media?.filter(item => item.type === 'inn')[0],
                                    value: (_, innImage, {setValue, control}) => (
                                        <Controller
                                            name='innMedia.path'
                                            control={control}
                                            render={({field: {name, onChange}}) => (
                                                <WorkerProfileImage
                                                    id={name}
                                                    image={innImage}
                                                    onImageDelete={item => {
                                                        onChange(null)
                                                        setValue('innMedia.delete', null)
                                                    }}
                                                    onImageChange={file => {
                                                        setValue('innMedia.path', URL.createObjectURL(file))
                                                        onChange(URL.createObjectURL(file))
                                                    }}
                                                    className='qualification-documents-image-field'
                                                />
                                            )}
                                        />
                                    )
                                },
                                {
                                    type: 'other',
                                    value: (_, currentValue, {control}) => (
                                        <NumberField
                                            title='СНИЛС'
                                            name='documents.snils'
                                            defaultValue={getFormattedSnils(documents.snils)}
                                            control={control}
                                            format='###-###-### ##'
                                            placeholder='000-000-000 00'
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Укажите номер СНИЛС'
                                                },
                                                validate: value => {

                                                    const isValidAge = isGpsValid(value)

                                                    if (isValidAge) {
                                                        return true
                                                    } else {
                                                        return 'Вы указали некорректный номер СНИЛС'
                                                    }
                                                },
                                                pattern: {
                                                    value: /^\d{3}-\d{3}-\d{3} \d{2}$/,
                                                    message: 'Вы указали некорректный номер СНИЛС'
                                                }
                                            }}
                                        />
                                    )
                                },
                                {
                                    type: 'other',
                                    watchVar: 'snilsMedia',
                                    defaultValue: documents.media?.filter(item => item.type === 'snils')[0],
                                    value: (_, snilsImage, {setValue, control}) => (
                                        <Controller
                                            name='snilsMedia.path'
                                            control={control}
                                            render={({field: {name, onChange}}) => (
                                                <WorkerProfileImage
                                                    id={name}
                                                    image={snilsImage}
                                                    onImageDelete={() => {
                                                        onChange(null)
                                                    }}
                                                    onImageChange={file => {
                                                        setValue('snilsMedia.path', URL.createObjectURL(file))
                                                        onChange(URL.createObjectURL(file))
                                                    }}
                                                    className='qualification-documents-image-field'
                                                />
                                            )}
                                        />
                                    )
                                }
                            ]
                        },
                        {
                            values: [
                                {
                                    type: 'other',
                                    watchVar: 'pas',
                                    value: (_: any, v: any) => (
                                        <div>
                                            <div
                                                style={{
                                                    fontWeight: '600',
                                                    marginBottom: '8px'
                                                }}
                                            >
                                                Документ, удостоверяющий личность
                                            </div>
                                            <div
                                                style={{
                                                    marginLeft: '4px'
                                                }}
                                            >
                                                Паспорт гражданина РФ
                                            </div>
                                        </div>
                                    )
                                },
                                {
                                    type: 'other',
                                    value: (_, currentValue, {control}) => (
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <NumberField
                                                title='Серия'
                                                name='documents.passport.series'
                                                defaultValue={documents.passport?.series}
                                                control={control}
                                                format='####'
                                                placeholder='0000'
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'Укажите серию паспорта'
                                                    },
                                                    pattern: {
                                                        value: /^\d{4}$/,
                                                        message: 'Введите данные в требуемой форме'
                                                    }
                                                }}
                                            />
                                            <NumberField
                                                title='Номер'
                                                name='documents.passport.number'
                                                defaultValue={documents.passport?.number}
                                                control={control}
                                                format='######'
                                                placeholder='000000'
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'Укажите номер паспорта'
                                                    },
                                                    pattern: {
                                                        value: /^\d{6}$/,
                                                        message: 'Введите данные в требуемой форме'
                                                    }
                                                }}
                                            />
                                        </div>
                                    )
                                },
                                {
                                    type: 'other',
                                    value: (_, currentValue, {control}) => (
                                        <NumberField
                                            title='Код подразделения'
                                            name='documents.passport.subdivisionCode'
                                            defaultValue={documents.passport?.subdivisionCode}
                                            control={control}
                                            format='###-###'
                                            placeholder='000-000'
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Укажите код подразделения'
                                                },
                                                pattern: {
                                                    value: /^\d{3}-\d{3}$/,
                                                    message: 'Введите данные в требуемой форме'
                                                }
                                            }}
                                            onChange={e => {
                                                getOptions(e.target.value)
                                            }}
                                        />
                                    )
                                },
                                {
                                    type: 'other',
                                    watchVar: 'documents.passport.issuedBy',
                                    defaultValue: {
                                        label: documents.passport?.issuedBy || '',
                                        value: documents.passport?.issuedBy || ''
                                    },
                                    value: (_, value, {setValue, control}) => (
                                        <Controller
                                            name='documents.passport.issuedBy'
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Укажите наименование подразделения, выдавшего паспорт'
                                                }
                                            }}
                                            render={({field}) => (
                                                <>
                                                    <div className='form-field-title'>
                                                        Кем выдан<span className='required-field'>*</span>
                                                    </div>
                                                    <CreatableSelect
                                                        {...field}
                                                        options={selectOptions}
                                                        formatCreateLabel={value => `Создать ${value}`}
                                                    />
                                                </>
                                            )}
                                        />
                                    )
                                },
                                {
                                    type: 'other',
                                    watchVar: 'documents.passport.date',
                                    value: (_, currentValue, {setValue, control}) => (
                                        <NumberField
                                            title='Дата выдачи'
                                            name='documents.passport.date'
                                            defaultValue={documents.passport?.date}
                                            control={control}
                                            format={'##.##.####'}
                                            placeholder='ДД.ММ.ГГГГ'
                                            mask={['Д', 'Д', 'М', 'М', 'Г', 'Г', 'Г', 'Г']}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Укажите дату выдачи паспорта'
                                                },
                                                pattern: {
                                                    value: /^(0?[1-9]|[12][0-9]|3[01])[\/\.](0?[1-9]|1[012])[\/\.]\d{4}$/,
                                                    message: 'Укажите корректную дату выдачи паспорта'
                                                },
                                                validate: value => {
                                                    const currentDate = new Date(new Date()).toLocaleString('ru-RU').split(',')[0]
                                                    if ((parse(value, 'dd.MM.yyyy', new Date()) as unknown as string) == 'Invalid Date') {
                                                        return 'Укажите корректную дату выдачи паспорта'
                                                    }
                                                    if (
                                                        differenceInCalendarDays(
                                                            parse(value, 'dd.MM.yyyy', new Date()).getTime(),
                                                            parse(currentDate, 'dd.MM.yyyy', new Date()).getTime()
                                                        ) >= 1
                                                    ) {
                                                        return 'Укажите корректную дату выдачи паспорта'
                                                    }
                                                    return true
                                                }
                                            }}
                                        />
                                    )
                                },
                                {
                                    type: 'other',
                                    watchVar: 'personalInfo.birthDate',
                                    value: (_, currentValue, {setValue, control}) => (
                                        <NumberField
                                            title='Дата рождения'
                                            name='personalInfo.birthDate'
                                            defaultValue={personalInfo.birthDate}
                                            control={control}
                                            format={'##.##.####'}
                                            placeholder='ДД.ММ.ГГГГ'
                                            mask={['Д', 'Д', 'М', 'М', 'Г', 'Г', 'Г', 'Г']}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Укажите Вашу дату рождения'
                                                },
                                                pattern: {
                                                    value: /^(0?[1-9]|[12][0-9]|3[01])[\/\.](0?[1-9]|1[012])[\/\.]\d{4}$/,
                                                    message: 'Укажите корректную дату рождения'
                                                },
                                                validate: value => {
                                                    const isValidAge = checkInputedAge(value)

                                                    if ((parse(value, 'dd.MM.yyyy', new Date()) as unknown as string) == 'Invalid Date') {
                                                        return 'Укажите корректную дату рождения'
                                                    }
                                                    if (isValidAge) {
                                                        return true
                                                    } else {
                                                        return 'Ваш возраст должен быть старше 18 лет и меньше 65 лет'
                                                    }
                                                }
                                            }}
                                        />
                                    )
                                },
                                {
                                    className: 'documents-form-field-title',
                                    type: 'textfield',
                                    var: 'registrationAddress.address',
                                    colSpan: 1,
                                    title: 'Адрес регистрации',
                                    defaultValue: registrationAddress ? registrationAddress.address : '',
                                    validate: {
                                        required: true,
                                        maxLength: 128,
                                        minLength: 2
                                    },
                                    errors: {
                                        required: 'Укажите ваш регистрационный адрес',
                                        minLength: 'Введите больше 2 символов',
                                        maxLength: 'Введите не больше 128 символов'
                                    }
                                },
                                {
                                    type: 'other',
                                    value: (_, currentValue, {control}) => (
                                        <NumberField
                                            title='Индекс адреса регистрации'
                                            name='registrationAddress.index'
                                            defaultValue={registrationAddress ? registrationAddress.index : ''}
                                            control={control}
                                            format='######'
                                            placeholder='000000'
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Укажите индекс регистрационного адреса'
                                                },
                                                pattern: {
                                                    value: /^\d{6}$/,
                                                    message: 'Введите данные в требуемой форме'
                                                }
                                            }}
                                        />
                                    )
                                },
                                {
                                    type: 'checkbox',
                                    title: 'Совпадает с адресом регистрации',
                                    var: 'isAddressesMatch',
                                    value: isAddressesMatch,
                                    onChange: e => setIsAddressMatch((prevState: boolean) => !prevState)
                                },
                                {
                                    className: 'documents-form-field-title',
                                    type: 'textfield',
                                    colSpan: 1,
                                    title: 'Адрес проживания',
                                    defaultValue: actualAddress ? actualAddress.address : '',
                                    var: 'actualAddress.address',
                                    hidden: isAddressesMatch,
                                    validate: {
                                        required: true,
                                        pattern: /[А-я]/g,
                                        maxLength: 100,
                                        minLength: 6
                                    },
                                    errors: {
                                        required: 'Укажите Ваш адрес проживания',
                                    }
                                },
                                {
                                    type: 'other',
                                    hidden: isAddressesMatch,
                                    value: (_, currentValue, {control}) => (
                                        <NumberField
                                            title='Индекс адреса проживания'
                                            name='actualAddress.index'
                                            defaultValue={actualAddress ? actualAddress.index : ''}
                                            control={control}
                                            format='######'
                                            placeholder='000000'
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Укажите индекс адреса проживания'
                                                },
                                                maxLength: 6,
                                                minLength: 6,
                                                pattern: {
                                                    value: /^\d{6}$/,
                                                    message: 'Введите данные в требуемой форме'
                                                }
                                            }}
                                        />
                                    )
                                },
                                {
                                    type: 'other',
                                    watchVar: 'passportMedia',
                                    defaultValue: documents.media?.filter(item => item.type === 'passport'),
                                    value: (e, currentPassportImage, {setValue, control}) => {
                                        return (
                                            <DocumentImage
                                                control={control}
                                                value={currentPassportImage ? currentPassportImage : []}
                                                onChange={newPassportImage => {
                                                    setValue('passportMedia', newPassportImage)
                                                }}
                                            />
                                        )
                                    }
                                }
                            ]
                        }
                    ]
                ]}
            />
        </div>
    )
}
