import { useParams } from 'react-router-dom'
import React, { MutableRefObject } from 'react'
import { UserContext } from '../../global/context.user'
import { ProgressPage, FixedButtons, PageTitle, Raiting, Form } from 'components'
import { req } from '../../global/common'
import { InterfaceContext } from '../../global/context.interface'
import './../public-profile/public-profile.sass'
import { useTranslation } from 'react-i18next'
import { Portal } from '@rmwc/base'
import { PublicProfile, PublicProfileSend } from '../public-profile/public-profile-def'
import { CompanyAvatar } from './companyAvatar/company-avatar'
import {checkINN, checkKPP, checkOGRN} from '../public-profile/public-profile-utils'

export function PublicProfileModeratorPage() {
	const { orgID, key } = useParams() as { orgID: string; key: string }
	const { t } = useTranslation('common')
	const userCTX = React.useContext(UserContext)
	const interfaceCTX = React.useContext(InterfaceContext)

	const form = React.createRef() as MutableRefObject<HTMLFormElement>
	const [loaded, setLoaded] = React.useState<boolean | null>(null)
	const [profile, setProfile] = React.useState({} as PublicProfile)
	const [viewMode, setViewMode] = React.useState(true)
	const [organizationRate, setOrganizationRate] = React.useState(0)
	const [organizationSpec, setOrganizationSpec] = React.useState(null as any)
	const [orgSpecFiltered, setOrgSpecFiltered] = React.useState(null as any)
	const [chosenOption, setChosenOption] = React.useState(null as any)
	const [chosenMultiOption, setChosenMultiOption] = React.useState([] as any[])
	const [chosenOptions, setChosenOptions] = React.useState({} as any)

	React.useEffect(() => {
		// if (userCTX.user) {
		req.get(`/info/organisation_types`).then(({ data }) => {
			let options = data.data.map((spec: any) => {
				return {
					label: spec.name,
					options: spec.content.map((s: any) => {
						return {
							label: s,
							value: s
						}
					})
				}
			})
			setOrganizationSpec(options)
			setOrgSpecFiltered(options)
		})

		req
			.get(`/reviews/organisation/${orgID}/list?limit=0&offset=0`)
			.then(data => data.data.average && setOrganizationRate(data.data.average))
			.catch(() =>
				interfaceCTX.showMessage({
					icon: 'report',
					body: 'Рейтинг организации загрузить не удалось. Обратитесь к администратору'
				})
			)
	}, [])

	const changeOrganizationReviewStatus = (newStatus: 'rejected' | 'accepted') => {
		req
			.post(`/moderation/set-status`, {
				accessKey: key,
				moderationStatus: newStatus,
				organisationId: orgID
			})
			.then(({ data }) => {
				setProfile(new PublicProfile(data))
			})
			.catch(() => {})
	}

	React.useEffect(() => {
		let chosenOptionsObj: any = {}
		chosenMultiOption && chosenMultiOption.forEach((opt: any) => (chosenOptionsObj[opt.value] = opt.value))
		if (chosenOption != null) {
			setChosenOptions({
				[chosenOption.value]: chosenOption.value,
				...chosenOptionsObj
			})
		}
	}, [chosenMultiOption, chosenOption])

	React.useEffect(() => {
		let values = Object.values(chosenOptions)
		organizationSpec &&
			setOrgSpecFiltered(
				organizationSpec.map((category: any) => {
					return {
						label: category.label,
						options: category.options.filter((opt: any) => values.every((v: any) => v != opt.value))
					}
				})
			)
	}, [chosenOptions])

	React.useEffect(() => {
		setLoaded(null)
		if (orgID === undefined) return
		req
			.post(`/moderation/get`, {
				key: key
			})
			.then(({ data }) => {
				setProfile(new PublicProfile(data))
				setLoaded(true)
			})
			.catch(() => setLoaded(false))
	}, [orgID, viewMode])

	const onSubmit = (data: PublicProfile) => {
		req
			.post(`/moderation/update`, {
				moderationRequest: {
					accessKey: key,
					organisationId: Number(orgID)
				},
				organisationUpdateRequest: new PublicProfileSend(data)
			})
			.then(() => {
				interfaceCTX.showMessage({
					body: 'Изменения сохранены',
					icon: 'done'
				})
				interfaceCTX.refreshTopBar()
				setProfile(data)
				setViewMode(true)
			})
			.catch(e => {
				interfaceCTX.showMessage({
					title: 'Ошибка',
					body: t('error.' + e.response.data)
				})
			})
	}

	return (
		<ProgressPage
			state={loaded}
			className={'public-profile-page'}
			render={() => (
				<>
					<PageTitle title={'Профиль организации'} breadcrumbs={true}>
						<div className='public-profile-page-status'>{t('organization_status.' + profile.moderationPassed)}</div>
					</PageTitle>
					<div className={'form-header'}>
						<CompanyAvatar image={profile.logoPreview} viewMode={viewMode} />
					</div>
					<Form
						name={'publicProfile'}
						onSubmit={onSubmit}
						viewMode={viewMode}
						useRef={form}
						style={{ marginTop: '220px' }}
						data={[
							[
								{
									values: [
										{
											type: 'other',
											title: `${String(userCTX.user?.organisation?.id) !== orgID ? 'Рейтинг организации' : ''}`,
											colSpan: -1,
											value: viewMode => {
												if (String(userCTX.user?.organisation?.id) !== orgID) {
													return <Raiting value={organizationRate} withNumber />
												} else {
													return <></>
												}
											}
										},
										{
											title: 'Полное наименование',
											colSpan: 2,
											var: 'fullName',
											defaultValue: profile.fullName,
											validate: { required: true, maxLength: 300 },
											errors: { maxLength: 'Введите не более 300 символов' }
										},
										{
											title: 'Краткое наименование',
											colSpan: 2,
											var: 'shortName',
											defaultValue: profile.shortName,
											validate: { required: true, maxLength: 50 },
											errors: { maxLength: 'Введите не более 50 символов' }
										},
										{
											title: 'Юридический адрес',
											colSpan: 2,
											var: 'addressRegistration',
											defaultValue: profile.addressRegistration,
											validate: { maxLength: 256 },
											errors: { maxLength: 'Введите не более 256 символов' },
											requiredIcon: true
										},
										{
											title: 'Фактический адрес',
											colSpan: 2,
											var: 'addressActual',
											defaultValue: profile.addressActual,
											validate: { maxLength: 256 },
											errors: { maxLength: 'Введите не более 256 символов' },
											requiredIcon: true
										}
									]
								}
							],
							[
								{
									title: 'Контактная информация',
									values: [
										{
											type: 'phone',
											title: 'Контактный телефон',
											caption: '+ 7 (9XX) XXX XX XX',
											var: 'phone',
											defaultValue: profile.phone,
											validate: {
												pattern: /\+7\x20\(9[\d]{2}\)\x20[\d]{3}\x20[\d]{2}\x20[\d]{2}/gm
											},
											errors: {
												pattern: 'Введите номер в формате + 7 (9XX) XXX XX XX'
											},
											withEye: profile.hidden?.phone,
											requiredIcon: true
										},
										{
											title: 'Контактный e-mail',
											link: 'mailto',
											var: 'email',
											defaultValue: profile.email,
											validate: {
												pattern: interfaceCTX.emailValidationPattern,
												maxLength: 256
											},
											errors: {
												pattern: 'Введите валидный e-mail',
												maxLength: 'Введите не более 256 символов'
											},
											withEye: profile.hidden?.email,
											requiredIcon: true
										},
										{
											title: 'Сайт',
											link: 'link',
											var: 'www',
											defaultValue: profile.www,
											validate: { maxLength: 256 },
											errors: { maxLength: 'Введите не более 256 символов' },
											withEye: profile.hidden?.www
										}
									]
								},
								{
									title: 'Социальные сети',
									values: [
										{
											title: 'Twitter',
											link: 'link',
											var: 'socialTwitter',
											defaultValue: profile.socialTwitter,
											validate: { maxLength: 256 },
											errors: { maxLength: 'Введите не более 256 символов' },
											withEye: profile.hidden?.socialTwitter
										},
										// {
										//     title: 'Facebook',
										//     link: 'link',
										//     var: 'socialFacebook',
										//     defaultValue: profile.socialFacebook,
										//     validate: { maxLength: 256 },
										//     errors: { maxLength: "Введите не более 256 символов" }
										// },
										{
											title: 'Telegram',
											link: 'link',
											var: 'socialTelegram',
											defaultValue: profile.socialTelegram,
											validate: { maxLength: 256 },
											errors: { maxLength: 'Введите не более 256 символов' },
											withEye: profile.hidden?.socialTelegram
										}
									]
								}
							],
							[
								{
									values: [
										{
											type: 'textarea',
											title: 'О компании',
											colSpan: 2,
											defaultValue: profile.about,
											var: 'about',
											validate: { maxLength: 512 },
											errors: { maxLength: 'Введите не более 512 символов' },
											withEye: profile.hidden?.about
										}
									]
								}
							],
							[
								{
									values: [
										{
											type: 'select',
											title: 'Основной вид деятельности',
											options: orgSpecFiltered,
											// toText: (data: Spec) => new Specialization(data),
											defaultValue: profile.specialization,
											var: 'specialization',
											getValue: setChosenOption,
											requiredIcon: true
										},
										{
											type: 'select',
											title: 'Дополнительный вид деятельности',
											options: orgSpecFiltered,
											// toText: (data: Spec) => new Specialization(data),
											defaultValue: profile.subSpecializations,
											var: 'subSpecializations',
											isMulti: true,
											getValue: setChosenMultiOption
										}
									]
								}
							],
							[
								{
									title: 'Реквизиты',
									values: [
										{
											title: 'ОГРН / ОГРНИП',
											defaultValue: profile.ogrn,
											var: 'ogrn',
											validate: { validate: checkOGRN },
											errors: { validate: 'Укажите валидный номер ОГРН' },
											requiredIcon: true
										},
										{
											title: 'ИНН',
											defaultValue: profile.inn,
											var: 'inn',
											validate: { validate: checkINN },
											errors: { validate: 'Укажите валидный номер ИНН' },
											requiredIcon: true
										},
										{
											title: 'КПП',
											defaultValue: profile.kpp,
											var: 'kpp',
											validate: { validate: checkKPP },
											errors: { validate: 'Укажите валидный номер КПП' }
										}
									]
								}
							],
							[
								{
									title: 'Банковские реквизиты',
									values: [
										{
											title: 'Р/С',
											defaultValue: profile.checkingAccount,
											var: 'checkingAccount',
											validate: { maxLength: 256, pattern: /^\d{0,}$/gm },
											errors: {
												maxLength: 'Введите не более 256 символов',
												pattern: 'Введите только цифры'
											},
											requiredIcon: true
										},
										{
											title: 'К/С',
											defaultValue: profile.correspondentAccount,
											var: 'correspondentAccount',
											validate: { maxLength: 256, pattern: /^\d{0,}$/gm },
											errors: {
												maxLength: 'Введите не более 256 символов',
												pattern: 'Введите только цифры'
											},
											requiredIcon: true
										},
										{
											title: 'Наименование банка',
											defaultValue: profile.bankName,
											var: 'bankName',
											validate: { maxLength: 256 },
											errors: { maxLength: 'Введите не более 256 символов' },
											requiredIcon: true
										},
										{
											title: 'БИК',
											defaultValue: profile.bankCode,
											var: 'bankCode',
											validate: { maxLength: 256 },
											errors: { maxLength: 'Введите не более 256 символов' },
											requiredIcon: true
										}
									]
								}
							]
						]}
					/>
					<FixedButtons
						length={3}
						buttons={[
							{
								label: 'Сохранить',
								onClick: () =>
									form.current.dispatchEvent(
										new Event('submit', {
											cancelable: true,
											bubbles: true
										})
									),
								primary: true,
								hide: viewMode
							},
							{
								label: 'Отменить',
								onClick: () => setViewMode(true),
								primary: true,
								hide: viewMode
							},
							{
								label: 'Редактировать',
								primary: true,
								onClick: () => setViewMode(false),
								hide: !viewMode
							},
							{
								label: 'Модерация пройдена',
								primary: true,
								onClick: () => changeOrganizationReviewStatus('accepted'),
								hide: !viewMode || (profile.moderationPassed !== 'expectation' && profile.moderationPassed === 'accepted')
							},
							{
								label: 'Модерация не пройдена',
								primary: true,
								onClick: () => changeOrganizationReviewStatus('rejected'),
								hide: !viewMode || profile.moderationPassed === 'rejected'
							}
						]}
					/>
					<Portal />
				</>
			)}
		/>
	)
}
