import { Elevation } from '@rmwc/elevation'
import { TextField } from '@rmwc/textfield'
import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {FixedButtons, Raiting, SpanButtons, Star} from 'components'
import { authorName, req } from '../../../../global/common'
import { InterfaceContext } from '../../../../global/context.interface'
import { UserContext } from '../../../../global/context.user'
import { Params } from '../../../public-profile/public-profile-def'
import { LineProps, Props } from './reviews-def'
import './reviews.sass'

export default function Reviews({ workerProfile, reviews, mode, reload, setMode, modeID }: Props) {
	const { t } = useTranslation('common')
	const interfaceCTX = useContext(InterfaceContext)
	const { searchID, recordID: workerRecordID } = useParams<Params>()

	const [txtAreaHeight, setTxtAreaHeight] = useState(80)
	const [score, setScore] = useState(1)
	const [comment, setComment] = useState('')
	const [reviewsForRender, setReviewsForRender] = useState({
		...reviews,
		data: [...reviews.data]
	})
	const [userComment, setUserComment] = useState<any>(null!)

	useEffect(() => {
		setReviewsForRender({ ...reviews, data: [...reviews.data] })
	}, [reviews, mode])

	useEffect(() => {
		const index = reviewsForRender.data.findIndex(d => d.id === modeID)
		const shifted = mode === 'edit' ? reviewsForRender.data.splice(index, 1) : null
		setUserComment(shifted?.[0])
		setComment(shifted?.[0].comment ?? '')
	}, [reviewsForRender])

	const onSubmit = () => {
		if (score !== 5 && comment.replace(/\s/g, '').length === 0) {
			interfaceCTX.showMessage({
				title: 'Ошибка',
				body: 'При оценке менее 5-ти вы должны заполнить поле с текстом отзыва.'
			})
			return
		}

		if (mode === 'add') {
			req
				.post(`/reviews/person/${searchID}/create`, {
					comment,
					score,
					workerRecordID
				})
				.then(() => {
					reload()
				})
				.catch(e => {
					interfaceCTX.showMessage({
						title: 'Ошибка',
						body: t('error.' + e.response.data)
					})
					reload()
				})
		} else {
			req
				.post(`/reviews/review/${userComment.id}/update`, { comment, score })
				.then(() => {
					setMode(null!)
					reload()
				})
				.catch(e => {
					interfaceCTX.showMessage({
						title: 'Ошибка',
						body: t('error.' + e.response.data)
					})
					setMode(null!)
				})
		}
	}

	const onDelete = () => {
		req
			.delete(`/reviews/review/${userComment.id}/delete`)
			.then(() => {
				setMode(null!)
				reload()
			})
			.catch(e => {
				interfaceCTX.showMessage({
					title: 'Ошибка',
					body: t('error.' + e.response.data)
				})
				setMode(null!)
				reload()
			})
	}

	const onTextFieldChange = (e: FormEvent<HTMLInputElement>) => {
		const { value, scrollHeight } = e.target as any
		setComment(value)
		scrollHeight === txtAreaHeight ? null : setTxtAreaHeight(scrollHeight) // eslint-disable-line
	}

	return (
		<>
			{mode === 'add' && (
				<Elevation className={'add-review'} z={3}>
					<InteractiveRating changeScore={n => setScore(n)} defaultValue={1} />
					<div className={'text-area'}>
						<TextField
							value={comment}
							onChange={(e: any) => onTextFieldChange(e)}
							onKeyDown={e => (e.code == 'Enter' || e.code == 'NumpadEnter') && e.preventDefault()}
							outlined
							textarea
							maxLength={160}
							placeholder={'Отзыв'}
							style={{ height: `${txtAreaHeight}px`, width: '100%' }}
						/>
					</div>
					<FixedButtons
						length={3}
						buttons={[
							{
								label: 'Оставить отзыв',
								primary: true,
								onClick: () => onSubmit()
							}
						]}
					/>
				</Elevation>
			)}

			{mode === 'edit' && (
				<Elevation className={'add-review'} z={3}>
					<InteractiveRating changeScore={n => setScore(n)} defaultValue={userComment?.score ?? 1} />
					<div className={'text-area'}>
						<TextField
							value={comment}
							onChange={(e: any) => onTextFieldChange(e)}
							onKeyDown={e => {
								;(e.code == 'Enter' || e.code == 'NumpadEnter') && e.preventDefault()
							}}
							outlined
							textarea
							maxLength={160}
							placeholder={'Отзыв'}
							style={{ height: `${txtAreaHeight}px`, width: '100%' }}
						/>
					</div>
					<ul className='btn-panel-list'>
						<li
							className='btn-panel-item'
							onClick={() => {
								onSubmit()
							}}
						>
							<div
								className='btn'
								style={{
									color: '#F5F5F8',
									backgroundColor: '#12324F'
								}}
							>
								Сохранить изменения
							</div>
						</li>
						<li
							className='btn-panel-item'
							onClick={() => {
								onDelete()
							}}
						>
							<div
								className='btn'
								style={{
									border: '1px solid #12324F'
								}}
							>
								Удалить
							</div>
						</li>
					</ul>
					{/*<FixedButtons*/}
					{/*  length={3}*/}
					{/*  buttons={[*/}
					{/*    {*/}
					{/*      label: 'Сохранить изменения',*/}
					{/*      primary: true,*/}
					{/*      onClick: () => onSubmit(),*/}
					{/*    },*/}
					{/*    {*/}
					{/*      label: 'Удалить',*/}
					{/*      onClick: () => onDelete(),*/}
					{/*    },*/}
					{/*  ]}*/}
					{/*/>*/}
				</Elevation>
			)}

			<Elevation className={'reviews'} z={3}>
				{reviewsForRender.data.map(rev => (
					<ReviewLine
						key={rev.id}
						date={rev.date}
						workFrom={rev.workFrom}
						workTo={rev.workTo}
						author={rev.author}
						authorName={rev.authorProfile ? authorName(rev.authorProfile) : null}
						score={rev.score}
						comment={rev.comment}
						authorOrganization={rev.organisation ? rev.organisation : null}
						canEdit={rev.canEdit}
						setMode={b => setMode(b)}
						id={rev.id}
					/>
				))}
			</Elevation>
		</>
	)
}

function ReviewLine({
	date,
	workFrom,
	workTo,
	author,
	authorName,
	authorOrganization,
	score,
	comment,
	canEdit,
	setMode,
	id
}: LineProps) {
	const UserCTX = useContext(UserContext)

	const isUserTrusted = UserCTX.user?.organisation.id === authorOrganization?.id ?? false

	const history = useHistory()

	return (
		<div className={'review'}>
			<div>
				<span>{date}</span>
				<Raiting value={score} />
				<span className={'employment-time'}>{`Период работы\n${workFrom}` + (workTo ? ` -\n${workTo}` : '')}</span>
			</div>
			<div>
				<div className={'author-header'}>
					<span className={'name'}>
						{isUserTrusted && authorName ? (
							authorName
						) : authorOrganization ? (
							<Link
								className={'link-to-public-profile'}
								to={{
									pathname: `/public-profile/${authorOrganization.id}`,
									state: history.location.state
								}}
							>
								{authorOrganization.fullName}
							</Link>
						) : (
							<Link
								className={'link-to-public-profile'}
								to={{
									pathname: `/public-profile/${authorOrganization!.id}`,
									state: history.location.state
								}}
							>
								{'Не указано'}
							</Link>
						)}
					</span>
					<span className={'role'}>{author}</span>
				</div>
				<div className={'comment'}>{comment}</div>
			</div>
			{canEdit && (
				<SpanButtons
					data={[
						{
							label: 'Редактировать',
							onClick: () => setMode(id),
							hide: !UserCTX.currentRole('organizer')
						}
					]}
				/>
			)}
		</div>
	)
}

interface InteractiveRateProps {
	changeScore: (score: number) => void
	defaultValue: number
}

function InteractiveRating({ changeScore, defaultValue }: InteractiveRateProps) {
	const [filled, setFilled] = useState<Set<string>>(new Set())
	const [rateFixed, setRateFixed] = useState<Set<string>>(null!)

	useEffect(() => {
		changeScore(rateFixed === null ? 1 : rateFixed.size / 2)
	}, [rateFixed])

	useEffect(() => {
		if (defaultValue !== 1) {
			const set = new Set<string>()

			for (let i = 0; i < defaultValue; i++) {
				set.add(`${i}_left`)
				set.add(`${i}_right`)
			}

			setFilled(set)
			setRateFixed(set)
		}
	}, [defaultValue])

	const onOver = (e: React.MouseEvent<HTMLDivElement>) => {
		const id = (e.target as any).id
		const [index, side] = id.split('_')
		const set = new Set<string>()

		// uncomment and remove '=' from condition statement for half Star rating (deprecated!! not working!!)
		for (let i = 0; i <= index; i++) {
			set.add(`${i}_left`)
			set.add(`${i}_right`)
		}
		/* if (side === 'left') {
          set.add(`${index.ts}_left`);
        } else {
          set.add(`${index.ts}_left`);
          set.add(`${index.ts}_right`);
        } */

		setFilled(set)
	}

	const onOut = () => {
		if (rateFixed) {
			setFilled(rateFixed)
			return
		}
		setFilled(new Set())
	}

	const onFixed = () => {
		if (!rateFixed) {
			setRateFixed(filled)
		} else if (rateFixed.size !== filled.size) {
			setRateFixed(filled)
		} else {
			setRateFixed(null!)
		}
	}

	let stars = Array(5)
		.fill(null)
		.map((_, i) => (
			<div key={i} className={'star'}>
				<div
					id={i + '_left'}
					onMouseOver={e => onOver(e)}
					onMouseOut={() => onOut()}
					onClick={() => onFixed()}
					className={'left-trigger'}
				></div>
				<div
					id={i + '_right'}
					onMouseOver={e => onOver(e)}
					onMouseOut={() => onOut()}
					onClick={() => onFixed()}
					className={'right-trigger'}
				></div>
				<Star fill={filled.has(i + '_right') ? 'full' : filled.has(i + '_left') ? 'half' : 'none'} />
			</div>
		))

	return <div className={'stars'}>{stars}</div>
}
