import React, { useContext } from 'react'
import { req, authorName } from '../../global/common'
import { ProgressPage, Avatar, PageTitle } from 'components'
import { IconButton } from '@rmwc/icon-button'
import { Link, useHistory } from 'react-router-dom'
import { UserContext } from '../../global/context.user'
import { Icon } from '@rmwc/icon'
import { TextField } from '@rmwc/textfield'
import { CircularProgress } from '@rmwc/circular-progress'
import { InterfaceContext } from '../../global/context.interface'
import { TableHeading } from '../../components/Table/TableHeading/TableHeading'
import { TableRow } from '../../components/Table/TableRow/TableRow'
import { Table } from '../../components/Table/Table'
import { TableCell } from '../../components/Table/TableCell/TableCell'
import { NameAndStatus } from '../../components/NameAndStatus/NameAndStatus'
import { Period } from '../../components/Period/Period'
import './mobilization-page.sass'
import { PagesRoutes } from 'global/routes'

export function MobilizationPage() {
	const userCTX = React.useContext(UserContext)

	const [workers, setWorkers] = React.useState(null as any)
	const [search, setSearch] = React.useState('')
	const [workersTotal, setWorkersTotal] = React.useState(0)

	const [offsetForServer, setOffsetForServer] = React.useState(0)
	const [loading, setLoading] = React.useState(false)
	const [filter, setFilter] = React.useState('')

	const history = useHistory()
	const interfaceCTX = useContext(InterfaceContext)

	let breakPointOffset = 10
	let searchTimeout: NodeJS.Timer

	let filterOffStyle = {
		backgroundColor: 'transparent',
		outline: 'none'
	}

	React.useEffect(() => {
		let targetValue = interfaceCTX.scrollTop + interfaceCTX.offsetHeight
		if (
			interfaceCTX.scrollHeight > 0 &&
			interfaceCTX.scrollHeight - 20 <= targetValue &&
			workers &&
			workers.length < workersTotal &&
			search == ''
		) {
			interfaceCTX.resetScrollValues()
			setOffsetForServer((state: number) => (state += breakPointOffset))
		}
	}, [interfaceCTX.scrollTop])

	function getWorkers(limit: number, offset: number, search: string, filter: string) {
		if (workers != null) {
			setLoading(true)
		}

		let query = {
			limit: limit,
			offset: offset,
			search: search,
			status: filter
		}
		req
			.get('/hr/mobilization/list', query)
			.then(({ data }) => {
				if (!data.total) {
					throw new Error()
				}
				let newWorkers: any[] = []
				setWorkersTotal(data.total)
				data.data.forEach((worker: any) => newWorkers.push(worker))
				setWorkers(newWorkers)
			})
			.then(() => setLoading(false))
			.catch(() => {
				setWorkers(false)
				setLoading(false)
			})
	}

	React.useEffect(() => {
		setWorkers(null)
		if (search == '') {
			getWorkers(breakPointOffset, 0, search, filter)
		} else {
			getWorkers(0, 0, search, filter)
		}
	}, [search, filter])

	React.useEffect(() => {
		if (workers != null && offsetForServer < workersTotal) {
			setLoading(true)
		}

		let query = {
			limit: breakPointOffset,
			offset: offsetForServer,
			search: search,
			status: filter
		}
		req
			.get('/hr/mobilization/list', query)
			.then(({ data }) => {
				if (!data.total) {
					throw new Error()
				}
				let newWorkers: any[] = []
				if (workers != null) {
					newWorkers = workers!.splice(0)
				}
				setWorkersTotal(data.total)
				data.data.forEach((worker: any) => newWorkers.push(worker))

				setWorkers(newWorkers)
			})
			.then(() => setLoading(false))
			.catch(() => setWorkers(false))
	}, [offsetForServer])

	function initFilter(status: 'mobilized' | 'demobilized' | 'pending' | 'deleted_user') {
		setWorkers(null)
		setOffsetForServer(0)
		if (filter == status) {
			setFilter('')
		} else {
			setFilter(status)
		}
	}

	return (
		<div className='mobilization-page'>
			{loading && (
				<div className='loading-wrapper'>
					<div className='loading-inner'>
						<CircularProgress className='circular-progress' />
						<div>Загрузка</div>
					</div>
				</div>
			)}

			<PageTitle title={'Мобилизация'} breadcrumbs={true}>
				{/* {userCTX.user && userCTX.userHasRole('admin') ?
                    <div className="title-link-panel">
                        <Link className="link-to-template" to={'/mobilization/edit-template/mobilize'}>
                            Шаблон мобилизации
                        </Link>
                        <Link className="link-to-template" to={'/mobilization/edit-template/demobilize/'}>
                            Шаблон дeмобилизации
                        </Link>
                    </div>
                    :
                    ""
                } */}
			</PageTitle>

			<TextField
				outlined
				maxLength={160}
				onChange={function (e) {
					clearTimeout(searchTimeout)

					searchTimeout = setTimeout(() => {
						setOffsetForServer(0)
						setSearch((e.target as HTMLInputElement).value)
					}, 500)
				}}
				className='search-input'
				label='Поиск по фамилии'
			>
				<Icon icon={'search'} className='search-icon' />
			</TextField>

			<div className={'sort-btns-panel'}>
				<div
					onClick={() => initFilter('pending')}
					className={'sort-btn pending'}
					style={filter == 'pending' ? {} : filterOffStyle}
				>
					<div className={'sort-btn-text'}>Ожидает мобилизации</div>
					<Icon style={filter == 'pending' ? {} : { display: 'none' }} className={'close-sort-btn'} icon={'close'} />
				</div>
				<div
					onClick={() => initFilter('mobilized')}
					className={'sort-btn mobilize'}
					style={filter == 'mobilized' ? {} : filterOffStyle}
				>
					<div className={'sort-btn-text'}>Мобилизован</div>
					<Icon style={filter == 'mobilized' ? {} : { display: 'none' }} className={'close-sort-btn'} icon={'close'} />
				</div>
				<div
					onClick={() => initFilter('demobilized')}
					className={'sort-btn demobilize'}
					style={filter == 'demobilized' ? {} : filterOffStyle}
				>
					<div className={'sort-btn-text'}>Демобилизован</div>
					<Icon
						style={filter == 'demobilized' ? {} : { display: 'none' }}
						className={'close-sort-btn'}
						icon={'close'}
					/>
				</div>
				<div
					onClick={() => initFilter('deleted_user')}
					className={'sort-btn deleted_user'}
					style={filter == 'deleted_user' ? {} : filterOffStyle}
				>
					<div className={'sort-btn-text'}>Профиль удален</div>
					<Icon
						style={filter == 'deleted_user' ? {} : { display: 'none' }}
						className={'close-sort-btn'}
						icon={'close'}
					/>
				</div>
			</div>

			<ProgressPage
				state={workers}
				render={
					() => (
						<Table className='mobilization-table'>
							<TableRow type='heading'>
								<TableHeading devider style={{ width: '12%' }}>
									ФОТО
								</TableHeading>
								<TableHeading devider style={{ width: '28%' }}>
									ФИО
								</TableHeading>
								<TableHeading devider style={{ width: '15%' }}>
									Статус
								</TableHeading>
								<TableHeading devider style={{ width: '12.5%' }}>
									Договор
								</TableHeading>
								<TableHeading devider style={{ width: '12.5%' }}>
									Отзывы
								</TableHeading>
								<TableHeading devider style={{ width: '12.5%' }}>
									Мобилизация
								</TableHeading>
								<TableHeading devider style={{ width: '12.5%' }}>
									Демобилизация
								</TableHeading>
								<TableHeading devider style={{ width: '12.5%' }}>
									Срок действия договора
								</TableHeading>
							</TableRow>
							{workers &&
								workers.map((dataItem: any, index: number) => {
									let mobilizeStatus: { color: any; text: any }

									if (dataItem.status == 'mobilized') {
										mobilizeStatus = {
											color: '#00FF0A',
											text: 'Мобилизован'
										}
									} else if (dataItem.status == 'demobilized') {
										mobilizeStatus = {
											color: '#FF0000',
											text: 'Демобилизован'
										}
									} else {
										mobilizeStatus = {
											color: '#FFA800',
											text: 'Ожидает мобилизации'
										}
									}
									return (
										// <>
										<TableRow isProfileDeleted={dataItem.worker.profile.isDeleted} type='row' key={index}>
											<TableCell devider style={{ width: 'calc(12% + 5px)', padding: '0px' }}>
												<Avatar
													isProfileDeleted={dataItem.worker.profile.isDeleted}
													linkProps={{
														pathname:
															dataItem.worker.profile.isDeleted && !authorName(dataItem.worker.profile).includes('null')
																? PagesRoutes.WORKERS +
																  PagesRoutes.MOBILIZATION +
																  PagesRoutes.WORKER_CARD +
																  `/${dataItem.worker.profile.id}/${dataItem.worker.request.id}`
																: PagesRoutes.WORKERS +
																  PagesRoutes.MOBILIZATION +
																  PagesRoutes.PUBLIC_PROFILE +
																  `/${dataItem.worker.profile.id}/${dataItem.worker.recordID}`,
														state: history.location.state
													}}
													// pathToProfile={dataItem.worker.profile.isDeleted && !authorName(dataItem.worker.profile).includes('null')
													//     ? `/Personnel/mobilization/worker-card/${dataItem.worker.profile.id}/${dataItem.worker.request.id}`
													//     : `/Personnel/mobilization/public-profile/${dataItem.worker.profile.id}/${dataItem.worker.recordID}`
													// }
													// pathToProfile={`/public-worker-profile/${dataItem.worker.profile.id}/${dataItem.worker.recordID}`}
													prevPath={history.location.pathname}
													previewHref={dataItem.worker.profile.avatar?.pathPreview || dataItem.worker.profile.avatar}
												/>
											</TableCell>
											<TableCell devider style={{ width: '28%' }}>
												<NameAndStatus
													isProfileDeleted={dataItem.worker.profile.isDeleted}
													authorName={authorName(dataItem.worker.profile)}
												/>
											</TableCell>
											<TableCell className='mobilize-status' devider style={{ width: '15%' }}>
												<div className='mobilize-status-wrapper'>
													<div
														className='mobilize-status-circle'
														style={{ backgroundColor: mobilizeStatus.color }}
													></div>
													<div className='mobilize-status'>{mobilizeStatus.text}</div>
												</div>
												{dataItem.mobilizedAt && dataItem.status == 'mobilized' && (
													<div className='mobilize-at'>{dataItem.mobilizedAt}</div>
												)}
												{dataItem.demobilizedAt && dataItem.status == 'demobilized' && (
													<div className='mobilize-at'>{dataItem.demobilizedAt}</div>
												)}
											</TableCell>
											<TableCell devider style={{ width: '12.5%' }}>
												{!dataItem.worker.profile.isDeleted ? (
													<div
														className='item'
														style={{
															cursor: 'pointer'
														}}
														onClick={() => {
															history.push({
																pathname:
																	PagesRoutes.WORKERS +
																	PagesRoutes.MOBILIZATION +
																	PagesRoutes.CONTRACT_CARD +
																	`/${dataItem.worker.recordID}`,
																state: history.location.state
															})
														}}
													>
														<Icon className='icon description' icon={'description'} />
													</div>
												) : (
													<div className='item' style={{ opacity: 0.38 }}>
														<Icon className='icon description' icon={'description'} />
													</div>
												)}
											</TableCell>
											<TableCell devider style={{ width: '12.5%' }}>
												{dataItem.worker.profile.isDeleted ? (
													<IconButton
														style={{ opacity: dataItem.worker.profile.isDeleted ? 0.38 : 1 }}
														disabled={dataItem.worker.profile.isDeleted}
														icon='thumb_up_off_alt'
													/>
												) : (
													<Link
														className='mobilize-link'
														style={{ color: 'rgb(53, 76, 98)' }}
														to={{
															pathname:
																dataItem.worker.profile.isDeleted &&
																!authorName(dataItem.worker.profile).includes('null')
																	? PagesRoutes.WORKERS +
																	  PagesRoutes.MOBILIZATION +
																	  PagesRoutes.WORKER_CARD +
																	  `/${dataItem.worker.profile.id}/${dataItem.worker.request.id}`
																	: PagesRoutes.WORKERS +
																	  PagesRoutes.MOBILIZATION +
																	  PagesRoutes.PUBLIC_PROFILE +
																	  `/${dataItem.worker.profile.id}/${dataItem.worker.recordID}`,
															search: '?reviews=4',
															state: history.location.state
														}}
													>
														<IconButton disabled={dataItem.worker.profile.isDeleted} icon='thumb_up_off_alt' />
													</Link>
												)}
											</TableCell>
											<TableCell devider style={{ width: '12.5%' }}>
												<IconButton
													icon='person_add_alt'
													label=''
													onClick={() =>
														history.push({
															pathname:
																PagesRoutes.WORKERS +
																PagesRoutes.MOBILIZATION +
																`/mobilize/${dataItem.worker.profile.id}/${dataItem.worker.recordID}/${dataItem.status}`,
															state: history.location.state
														})
													}
													disabled={dataItem.worker.profile.isDeleted}
												></IconButton>
											</TableCell>
											<TableCell devider style={{ width: '12.5%' }}>
												<IconButton
													icon='person_remove_alt'
													onClick={() =>
														history.push({
															pathname:
																PagesRoutes.WORKERS +
																PagesRoutes.MOBILIZATION +
																`/demobilize/${dataItem.worker.profile.id}/${dataItem.worker.recordID}/${dataItem.status}`,
															state: history.location.state
														})
													}
													disabled={
														dataItem.worker.profile.isDeleted
															? false
															: dataItem.status == 'pending' && !dataItem.worker.profile.isDeleted
													}
												/>
											</TableCell>
											<TableCell devider style={{ width: '12.5%' }}>
												<Period start={dataItem.workFrom} end={dataItem.workTo} unlimited={dataItem.workUnlimited} />
											</TableCell>
										</TableRow>
										// </>
									)
								})}
						</Table>
					)

					// <ul className="Personnel-Table">
					//     <li className="row heading-row">
					//         <div className="cell heading heading-Avatar">Фото</div>
					//         <div className="cell heading">ФИО</div>
					//         <div className="cell heading">Статус</div>
					//         <div className="cell heading heading-reviews">Отзывы</div>
					//         <div className="cell heading heading-mobilize">Мобилизация</div>
					//         <div className="cell heading heading-demobilize">Демобилизация</div>
					//         <div className="cell heading">Срок действия контракта</div>
					//         {/* <div className="cell heading">Предполагаемая дата выхода кандидата</div> */}
					//     </li>
					//     {workersTable}
					// </ul>
				}
			/>
		</div>
	)
}
