import './messages-page.sass'
import { PageTitle } from "components";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {ChatContext} from "../../global/context.chat";
import {IChatPage, IRoomMeta} from "./messages-page-def";
import {Button} from "@rmwc/button";
import {Elevation} from '@rmwc/elevation';
import {MatrixClient, PushRuleActionName, Room} from "matrix-js-sdk";
import {Rooms} from "./components/rooms/rooms";
import {useHistory} from "react-router-dom";
import {PushRuleKind} from "matrix-js-sdk/lib/@types/PushRules";
import {PushRuleAction, PushRuleCondition, RuleId} from "matrix-js-sdk/src/@types/PushRules";
import {Checkbox} from "@rmwc/checkbox";
import {useCustomEventListener} from "react-custom-events";
import {CircularProgress} from '@rmwc/circular-progress';
//@ts-ignore
import audio from "../../misc/audio/message.mp3";
import {parsedRoomsData} from "./messages-page-utils";

export const MessagesPage: React.FC<IChatPage> = () => {
    const {client, isConnected, notificationSettings, setNotificationSettings, rooms} = useContext(ChatContext);
    // const initialized = useRef(false)    const [loading, setLoading] = useState(true)
    // const [settings, setSettings] = useState<any>(notificationSettings)
    const history = useHistory();
    const [roomsList, setRoomsList] = useState<IRoomMeta[]>([])
    var initialized = false;
    const [loading, setLoading] = useState(false)

    // const updateRooms = (client: MatrixClient) => {
    //     // if (!initialized) return;
    //     // client.stopPeeking();
    //     //
    //     // let promise = new Promise(function (resolve, reject) {
    //     //     setTimeout(() => resolve("done!"), 1000);
    //     // });
    //     // promise.then(() => {
    //     //     const list = client?.getVisibleRooms().filter((room: any) => {
    //     //         return !room.hasMembershipState(client?.getUserId(), "leave");
    //     //     })
    //     //     list.forEach(room => {
    //     //         room.getMembers().forEach(member => {
    //     //             if (member.membership === 'invite' && member.userId === client.getUserId()) {
    //     //                 client.joinRoom(room.roomId);
    //     //             }
    //     //         })
    //     //     })
    //     //     parsedRoomsData(list, client)
    //     //         .then((data) => {
    //     //             setRoomsList(data)
    //     //             setLoading(false)
    //     //         })
    //     // })
    // };

    useEffect(() => {
        // if (isConnected) {
            // initialized = true;
            // updateRooms(client as MatrixClient);
            setRoomsList(rooms)
        // }
    }, [isConnected, rooms]);

    // useCustomEventListener("roomUpdate", () => {
    //     updateRooms(client as MatrixClient);
    // });
    //
    // useCustomEventListener("typing", () => {
    //     updateRooms(client as MatrixClient);
    // });
    //
    // useCustomEventListener("new_message", (data: any) => {
    //     if (data.event.event.sender !== client?.getUserId() && data.event.event.type === 'm.room.message') {
    //         // _playAudio()
    //     }
    //     updateRooms(client as MatrixClient);
    // });

    const getArchivedRooms = (rooms: IRoomMeta[]) => {
        return rooms.length > 0 ? rooms.filter(item => item.isArchiveOrder) : []
    }

    const getIgnoredRooms = (rooms: IRoomMeta[]) => {
        return rooms.length > 0 ? rooms.filter(item => item.isIgnored) : []
    }

    const getAllRooms = (rooms: IRoomMeta[]) => {
        return rooms.length > 0 ? rooms.filter(item => !item.isIgnored && !item.isArchiveOrder) : []
    }

    return (
        <div className='messages-page'>
            {loading &&
                <CircularProgress style={{width: '150px', height: '150px'}}
                              className={` ${loading && 'circular-progress--center '}`}/>
            }

            {!loading && history.location.pathname === "/messages/archive" &&
                <>
                    <div className='messages-page-header'>
                        <PageTitle
                            title={'Архив сообщений'}
                            back={() => history.goBack()}
                        />
                    </div>
                    <Elevation z={4} className={`control-panel`}>
                        <div className='messages-page-list'>
                            <Rooms list={getArchivedRooms(roomsList)}/>
                        </div>
                    </Elevation>
                </>
            }
            {!loading && history.location.pathname === "/messages/ignored" &&
                <>
                    <div className='messages-page-header'>
                        <PageTitle
                            title={'Черный список'}
                            back={() => history.goBack()}
                        />
                    </div>
                    <Elevation z={4} className={`control-panel`}>
                        <div className='messages-page-list'>
                            <Rooms list={getIgnoredRooms(roomsList)}/>
                        </div>
                    </Elevation>
                </>
            }
            {!loading && history.location.pathname === "/messages" &&
                <>
                    <div className='messages-page-header'>
                        <PageTitle
                            title={'Сообщения'}
                        />
                        <div style={{
                            width: '660px',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <div className='messages-page-checkbox'>
                                <Checkbox
                                    checked={notificationSettings}
                                    onChange={(e) => {
                                        //@ts-ignore
                                        //@ts-ignore
                                        client?.setPushRuleEnabled('global', 'override' as PushRuleKind, '.m.rule.master', notificationSettings, (err, data) => {
                                            // setSettings(!settings)
                                            setNotificationSettings(!notificationSettings)
                                        })
                                    }}
                                />
                                <div className='messages-page-checkbox-text'>
                                    Получать звуковые сообщения
                                </div>
                            </div>
                            <Button className=''
                                    disabled={false}
                                    raised={true}
                                    onClick={() => {
                                        history.push('/messages/archive')
                                    }}>

                                {'Архив'}
                            </Button>
                            <Button className=''
                                    disabled={false}
                                    raised={true}
                                    onClick={() => {
                                        history.push('/messages/ignored')
                                    }}>
                                {'Черный список'}
                            </Button>
                        </div>
                    </div>
                    <Elevation z={4} className={`control-panel`}>
                        <div className='messages-page-list'>
                            <Rooms list={getAllRooms(roomsList)}/>
                        </div>
                    </Elevation>
                </>

            }
        </div>
    )
}