import './summary-table-by-needs.sass'
import {ISummaryTableByNeeds, ISummaryTableByNeedsItem} from "./summary-table-by-needs-def";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Checkbox} from "@rmwc/checkbox";

const columnSizes = [
    {minWidth: '178px'},
    {minWidth: '157px'},
    {minWidth: '105px'},
    {minWidth: '108px'},
    {minWidth: '119px'},
    {minWidth: '98px'},
    {minWidth: '122px'},
    {minWidth: '101px'},
    {minWidth: '109px'},
    {minWidth: '109px'},
    {minWidth: '109px'},
    {minWidth: '70px'}
]
export const SummaryTableByNeeds: React.FC<ISummaryTableByNeeds> = ({summaryTableByNeeds}) => {
    const {t} = useTranslation('common')
    const [deleteIsVisible, setDeleteIsVisible] = useState(true)
    const [state, setState] = useState(summaryTableByNeeds)

    const renderRow = (item: ISummaryTableByNeedsItem) => {
        return (
            <div className='summary-table-body-row' key={item.row}
                 style={{background: item.row === "total" ? '#E1E1E7' : '#FFFFFF'}}>
                <div className='summary-table-cell' style={{minWidth: columnSizes[0].minWidth}}>
                    <div className='summary-table-cell-inner status'>{t('status_recruitment.' + item.row)}</div>
                </div>
                <div className='summary-table-cell' style={{minWidth: columnSizes[1].minWidth}}>
                    <div className='summary-table-cell-inner'>{item.totalRequests}</div>
                </div>
                <div className='summary-table-cell bordered-line' style={{minWidth: columnSizes[2].minWidth}}>
                    <div className='summary-table-cell-inner'>{item.quantity}</div>
                </div>
                <div className='summary-table-cell' style={{minWidth: columnSizes[3].minWidth}}>
                    <div className='summary-table-cell-inner'>{item.acceptOnWork}</div>
                </div>
                <div className='summary-table-cell-group'>
                    <div className='summary-table-cell' style={{minWidth: columnSizes[4].minWidth}}>
                        <div
                            className='summary-table-cell-inner'>{item.inTheProcessOfNegotiations.responded}</div>
                    </div>
                    <div className='summary-table-cell' style={{minWidth: columnSizes[5].minWidth}}>
                        <div
                            className='summary-table-cell-inner'>{item.inTheProcessOfNegotiations.retractedResponses}</div>
                    </div>
                    <div className='summary-table-cell' style={{minWidth: columnSizes[6].minWidth}}>
                        <div
                            className='summary-table-cell-inner'>{item.inTheProcessOfNegotiations.documentsPreparation}</div>
                    </div>
                    <div className='summary-table-cell' style={{minWidth: columnSizes[7].minWidth}}>
                        <div
                            className='summary-table-cell-inner'>{item.inTheProcessOfNegotiations.signatureAwaiting}</div>
                    </div>
                    <div className='summary-table-cell' style={{minWidth: columnSizes[8].minWidth}}>
                        <div
                            className='summary-table-cell-inner'>{item.inTheProcessOfNegotiations.signedDocuments}</div>
                    </div>
                    <div className='summary-table-cell' style={{minWidth: columnSizes[8].minWidth}}>
                        <div
                            className='summary-table-cell-inner'>{item.inTheProcessOfNegotiations.documentsAwaiting}</div>
                    </div>
                    <div className='summary-table-cell' style={{minWidth: columnSizes[8].minWidth}}>
                        <div
                            className='summary-table-cell-inner'>{item.inTheProcessOfNegotiations.documentsConfirmed}</div>
                    </div>
                </div>
                <div className='summary-table-cell' style={{minWidth: columnSizes[9].minWidth}}>
                    <div className='summary-table-cell-inner'>{item.inTheProcessOfNegotiations.totalInProcess}</div>
                </div>
            </div>
        )
    }

    const isVisibleDeleteValues = (items: ISummaryTableByNeedsItem[], isVisible: boolean) => {
        if (isVisible) {
            setState(items.map(item => {
                if (item.row === 'total') {
                    return {
                        ...item,
                        totalRequests: item.totalRequests - items[4].totalRequests,
                        quantity: item.quantity - items[4].quantity
                    }
                } else {
                    return item
                }
            }))
        } else {
            setState(summaryTableByNeeds)
        }
    }

    return (
        <div className='summary-table-by-needs'>
            <div className='summary-table-by-needs-table'>
                <div className='summary-table-header'>
                    <div className='summary-table-cell' style={{minWidth: columnSizes[0].minWidth}}>
                        <div className='summary-table-cell-inner'>Статус заявки на подбор персонала</div>
                    </div>
                    <div className='summary-table-cell' style={{minWidth: columnSizes[1].minWidth}}>
                        <div className='summary-table-cell-inner'>Заявки на подбор персонала</div>
                    </div>
                    <div className='summary-table-cell bordered-line' style={{minWidth: columnSizes[2].minWidth}}>
                        <div className='summary-table-cell-inner'>Вакансий в заявках</div>
                    </div>

                    <div className='summary-table-cell' style={{minWidth: columnSizes[3].minWidth}}>
                        <div className='summary-table-cell-inner'>Принят на работы</div>
                    </div>

                    <div className='summary-table-cell-groups'>
                        <div className='summary-table-cell-group-first'>
                            <div className='summary-table-cell' style={{minWidth: columnSizes[4].minWidth +
                                columnSizes[5].minWidth +
                                columnSizes[6].minWidth +
                                columnSizes[7].minWidth +
                                columnSizes[8].minWidth +
                                columnSizes[9].minWidth +
                                columnSizes[10].minWidth}}>
                                <div className='summary-table-cell-inner'>В стадии переговоров</div>
                            </div>
                        </div>
                        <div className='summary-table-cell-group'>
                            <div className='summary-table-cell' style={{minWidth: columnSizes[4].minWidth}}>
                                <div className='summary-table-cell-inner'>Откликнулся</div>
                            </div>
                            <div className='summary-table-cell' style={{minWidth: columnSizes[5].minWidth}}>
                                <div className='summary-table-cell-inner'>Отозвано откликов</div>
                            </div>
                            <div className='summary-table-cell' style={{minWidth: columnSizes[6].minWidth}}>
                                <div className='summary-table-cell-inner'>Оформление документов</div>
                            </div>
                            <div className='summary-table-cell' style={{minWidth: columnSizes[7].minWidth}}>
                                <div className='summary-table-cell-inner'>Требуется подпись</div>
                            </div>
                            <div className='summary-table-cell' style={{minWidth: columnSizes[8].minWidth}}>
                                <div className='summary-table-cell-inner'>Документы подписаны</div>
                            </div>
                            <div className='summary-table-cell' style={{minWidth: columnSizes[9].minWidth}}>
                                <div className='summary-table-cell-inner'>Документы запрошены</div>
                            </div>
                            <div className='summary-table-cell' style={{minWidth: columnSizes[10].minWidth}}>
                                <div className='summary-table-cell-inner'>Документы заполнены</div>
                            </div>
                        </div>
                    </div>
                    <div className='summary-table-cell' style={{minWidth: columnSizes[11].minWidth}}>
                        <div className='summary-table-cell-inner'>Итого в стадии переговров</div>
                    </div>
                </div>
                <div className='summary-table-body'>
                    {state.map((item, index) => {
                        return item.row === 'deleted' && !deleteIsVisible ? null : renderRow(item)
                    })}
                </div>
            </div>
            <div className='summary-table-visible-deleted'>
                <Checkbox
                    checked={deleteIsVisible}
                    onChange={(e) => {
                        setDeleteIsVisible((prevState: boolean) => {
                            isVisibleDeleteValues(summaryTableByNeeds, prevState)
                            return !prevState
                        })
                    }}
                />
                <div>Показывать информацию по заявкам в статусе “Удалена”</div>
            </div>
        </div>
    )
}

