import { Button } from '@rmwc/button'
import { Dialog, DialogContent } from '@rmwc/dialog'
import { AsyncFormSelect, CheckboxOption, FormField } from 'components'
import { infoApi } from 'global/api'
import { Option } from 'global/definitions/definitions'
import { CSSProperties, FC, useCallback } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { components, GroupBase, OptionsOrGroups } from 'react-select'
import { DelimitedArrayParam, NumberParam, StringParam, useQueryParams } from 'use-query-params'
import { RequestFilterDialogFormFields, RequestFilterDialogProps } from './request-filter-dialog-def'
import './request-filter-dialog.sass'

export const RequestFilterDialog: FC<RequestFilterDialogProps> = ({ open, onClose, onSubmit }): JSX.Element => {
	const [queryParams] = useQueryParams({
		city: StringParam,
		organisation: StringParam,
		mainProfession: StringParam,
		salaryNetMin: NumberParam,
		salaryNetMax: NumberParam,
		subProfessions: DelimitedArrayParam
	})

	const {
		formState: { errors },
		control,
		register,
		handleSubmit,
		getValues,
		reset
	} = useForm<RequestFilterDialogFormFields>({
		mode: 'onChange',
		defaultValues: {
			city: queryParams.city ?? '',
			mainProfession: queryParams.mainProfession ?? '',
			organisation: queryParams.organisation ?? '',
			salaryNetMin: queryParams.salaryNetMin ?? 0,
			salaryNetMax: queryParams.salaryNetMax ?? 0,
			subProfessions: queryParams.subProfessions?.map(profession => ({ label: profession!, value: profession! }))
		}
	})

	const loadOptions = useCallback(async (search: string, loadedOptions: OptionsOrGroups<Option, GroupBase<Option>>) => {
		const options = await infoApi.getProfessions(50, loadedOptions.length, search).then(response =>
			response.data.data.map(profession => ({
				label: profession.name,
				value: profession.name
			}))
		)

		return {
			options: options,
			hasMore: options.length > 0
		}
	}, [])

	const resetFormHandler = () => {
		reset({ city: '', mainProfession: '', organisation: '', salaryNetMax: 0, salaryNetMin: 0, subProfessions: [] })
	}

	const submitFormHandler: SubmitHandler<RequestFilterDialogFormFields> = data => {
		onSubmit({
			city: data.city || null,
			organisation: data.organisation || null,
			salaryNetMin: data.salaryNetMin || null,
			salaryNetMax: data.salaryNetMax ? +data.salaryNetMax : null,
			mainProfession: data.mainProfession || undefined,
			subProfessions: (data.subProfessions && data.subProfessions.map(s => s.value)) || null
		})
	}

	const MoreSelectedBadge = ({ items }: { items: any }) => {
		const style: CSSProperties = {
			marginLeft: 'auto',
			background: '#d4eefa',
			borderRadius: '4px',
			fontSize: '11px',
			padding: '3px',
			order: 99,
			position: 'absolute',
			right: 0
		}

		const title = items.join(', ')
		const length = items.length
		const label = `+ ${length}`

		return (
			<div style={style} title={title}>
				{label}
			</div>
		)
	}

	const MultiValue = (props: any) => {
		const maxToShow = 4
		const overflow = props
			.getValue()
			.slice(maxToShow)
			.map((x: any) => x.label)

		return props.index < maxToShow ? (
			<components.MultiValue {...props} />
		) : props.index === maxToShow ? (
			<MoreSelectedBadge items={overflow} />
		) : null
	}

	return (
		<Dialog className='request-filter-dialog' open={open} onClose={onClose}>
			<DialogContent>
				<form onSubmit={handleSubmit(submitFormHandler)}>
					<FormField
						className='request-filter-dialog-field'
						title='Наименование организации'
						error={errors.organisation?.message}
						fullWidth
						{...register('organisation', {
							maxLength: {
								value: 60,
								message: 'Максимальное количество символов: 60'
							},
							validate: value => {
								if (value && value.trim().length === 0) return 'Поле не может состоять только из пробелов'
							}
						})}
					/>
					<FormField
						className='request-filter-dialog-field'
						title='Должность кандидата'
						error={errors.mainProfession?.message}
						fullWidth
						{...register('mainProfession', {
							maxLength: {
								value: 128,
								message: 'Максимальное количество символов: 128'
							},
							validate: value => {
								if (value && value.trim().length === 0) return 'Поле не может состоять только из пробелов'
							}
						})}
					/>
					<Controller
						name='subProfessions'
						control={control}
						render={({ field: { value, ...rest } }) => (
							<AsyncFormSelect
								className='request-filter-dialog-field'
								styles={{
									menuList: provided => ({
										...provided,
										maxHeight: '270px'
									}),
									multiValueLabel: provided => ({
										...provided,
										maxWidth: '125px',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis'
									})
								}}
								title='Дополнительная профессия'
								placeholder=''
								value={value}
								debounceTimeout={300}
								loadOptions={loadOptions}
								components={{ Option: CheckboxOption, MultiValue }}
								isOptionDisabled={(option: any) =>
									value
										? value.findIndex(fieldOption => fieldOption.value === option.value) === -1 && value.length >= 5
										: false
								}
								isMulti
								closeMenuOnSelect={false}
								hideSelectedOptions={false}
								{...rest}
							/>
						)}
					/>
					<FormField
						className='request-filter-dialog-field'
						title='Место производства работ, город РФ'
						error={errors.city?.message}
						fullWidth
						{...register('city', {
							maxLength: {
								value: 30,
								message: 'Максимальное количество символов: 30'
							},
							validate: value => {
								if (value && value.trim().length === 0) return 'Поле не может состоять только из пробелов'
							}
						})}
					/>
					<p className='form-field-title'>Заработная плата (на руки), руб</p>
					<div className='request-filter-dialog-salary-wrapper'>
						<div className='request-filter-dialog-row-field'>
							<p className='form-field-title request-filter-dialog-row-field-title'>От</p>
							<Controller
								name='salaryNetMin'
								control={control}
								render={({ field }) => (
									//@ts-ignore
									<NumberFormat
										customInput={FormField}
										error={errors.salaryNetMin?.message}
										decimalScale={2}
										isAllowed={({ value }) => +value < 999999999}
										allowNegative={false}
										fullWidth
										{...field}
									/>
								)}
							/>
						</div>
						<div className='request-filter-dialog-row-field'>
							<p className='form-field-title request-filter-dialog-row-field-title'>До</p>
							<Controller
								name='salaryNetMax'
								control={control}
								rules={{
									validate: () => {
										if (Number(getValues('salaryNetMax')) < Number(getValues('salaryNetMin')))
											return `Введите число больше ${getValues('salaryNetMin')}`
									}
								}}
								render={({ field }) => (
									//@ts-ignore
									<NumberFormat
										customInput={FormField}
										error={errors.salaryNetMax?.message}
										decimalScale={2}
										isAllowed={({ value }) => +value < 999999999}
										allowNegative={false}
										fullWidth
										{...field}
									/>
								)}
							/>
						</div>
					</div>
					<div className='request-filter-dialog-actions'>
						<Button type='button' onClick={resetFormHandler} outlined>
							Сбросить
						</Button>
						<Button type='submit' raised>
							Применить
						</Button>
					</div>
				</form>
			</DialogContent>
		</Dialog>
	)
}
