import matrix, { MatrixClient } from 'matrix-js-sdk'
import React, { useEffect, useRef, useState } from 'react'
import { req } from './common'
import { useHistory } from 'react-router-dom'
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events'
import Matrix from 'matrix-js-sdk'
//@ts-ignore
import audio from '../misc/audio/message.mp3'
import { parsedRoomsData } from '../pages/messages-page/messages-page-utils'

interface ChatContextProps {
	notificationSettings?: boolean
	ignoredUsers: string[]
	disconnect: () => void
	client: null | matrix.MatrixClient
	isConnected: boolean
	connect: () => void
	sendMessage: (data: any) => void
	sendIgnoredUsers: (userId: string, roomId: string, client: MatrixClient) => Promise<void>
	setNotificationSettings: (value: boolean) => void
	rooms: any[]
	isNotification: boolean
}

//user : "@rl79385046868:pragma.workforce.dev",

//password : "adasdasdAA23!!#",

//user: "@cu79020000004:pragma.workforce.dev"

//@fefusk79990000180:pragma.workforce.dev

type ChatContextValues = Omit<
	ChatContextProps,
	'sendMessage' | 'sendIgnoredUsers' | 'connect' | 'disconnect' | 'setNotificationSettings'
>
export const ChatContext = React.createContext<ChatContextProps>({} as ChatContextProps)
export const ChatContextProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
	const ref = useRef(false)
	const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()
	var initialized = false

	const [values, setValues] = useState<ChatContextValues>({
		ignoredUsers: [],
		client: null,
		isConnected: false,
		rooms: [],
		isNotification: false
	})

	const disconnect = () => {
		if (values.client != null) {
			values.client.stopClient()
		}
		localStorage.clear()
		setValues((prevState: ChatContextValues) => ({
			...prevState,
			ignoredUsers: [],
			client: null,
			isConnected: false,
			rooms: []
		}))
	}

	useCustomEventListener('roomUpdate', () => {
		updateRooms(values.client)
	})

	useCustomEventListener('new_message', () => {
		updateRooms(values.client)
	})

	const updateRooms = async (client: any) => {
		if (client == null) return
		client.stopPeeking()
		const list = client?.getVisibleRooms().filter((room: any) => {
			return !room.hasMembershipState(client?.getUserId(), 'leave')
		})
		list.forEach((room: any) => {
			room.getMembers().forEach((member: any) => {
				if (member.membership === 'invite' && member.userId === client.getUserId()) {
					client.joinRoom(room.roomId)
				}
			})
		})

		const rooms = await parsedRoomsData(list, client)
		const isNotification = rooms.some(item => item.count > 0)

		setValues(prevState => ({
			...prevState,
			rooms,
			isNotification
		}))
	}

	const connect = async () => {
		if (values.client) return
		let clientData: any
		if (localStorage.getItem('matrix_accessToken')) {
			clientData = {
				accessToken: localStorage.getItem('matrix_accessToken'),
				userId: localStorage.getItem('matrix_userId'),
				baseUrl: process.env.REACT_APP_CHAT_API_URL
			}
		} else {
			// const { data } = await req.get(`/chat/account/get-login-user-data`)
			// clientData = {
			// 	accessToken: data.identifier.access_token,
			// 	userId: data.identifier.user,
			// 	baseUrl: process.env.REACT_APP_CHAT_API_URL
			// }
			// localStorage.setItem('matrix_accessToken', data.identifier.access_token)
			// localStorage.setItem('matrix_userId', data.identifier.user)
		}
		const client = Matrix.createClient(clientData)
		await client.startClient()
		//@ts-ignore
		client.on('sync', (state, prevState, data) => {
			switch (state) {
				case 'PREPARED':
					addEvents(client)
					break
			}
		})
		await updateRooms(client)
		let promise = new Promise(function (resolve, reject) {
			setTimeout(() => resolve('done!'), 1000)
		})
		promise.then(() => {
			const ignoredUsers = client.getIgnoredUsers()
			setValues((prevState: ChatContextValues) => {
				//@ts-ignore
				return {
					...prevState,
					client: client,
					isConnected: true,
					//@ts-ignore
					notificationSettings: !client?.pushRules?.global?.override[0].enabled,
					ignoredUsers: ignoredUsers
				}
			})
		})
	}

	const setNotificationSettings = (value: boolean) => {
		setValues((prevState: ChatContextValues) => ({
			...prevState,
			notificationSettings: value
		}))
	}

	const _playAudio = async (notificationSettings: boolean) => {
		if (notificationSettings) {
			let audioElement = new Audio(audio)
			audioElement.volume = 0.5
			document.body.appendChild(audioElement)
			const promise = audioElement?.play()

			if (promise !== undefined) {
				promise.then(_ => {}).catch(error => {})
			}
		}
	}

	const roomEvent = (event: any, roomState: any) => {
		emitCustomEvent('roomUpdate')
	}

	const accountDataUpdate = (event: any, roomState: any) => {
		// if (event.event.type === 'm.ignored_user_list') {
		//     setValues((prevState: ChatContextValues) => ({
		//         ...prevState,
		//         ignoredUsers: prevState.client?.getIgnoredUsers()
		//     }))
		// }
	}

	const roomTimelineUpdate = (event: any, room: any, toStartOfTimeline: any, client: any) => {
		if (toStartOfTimeline) return
		if (event.event.type === 'm.room.message') {
			emitCustomEvent('new_message', { event, room })
		}
		if (event.event.sender !== client?.getUserId() && event.event.type === 'm.room.message') {
			//@ts-ignore
			_playAudio(!client?.pushRules.global.override[0].enabled as boolean)
		}
	}
	const autoJoinRooms = (event: any, member: any, client: MatrixClient) => {
		if (member.membership === 'invite' && member.userId === client?.getUserId()) {
			client?.joinRoom(member.roomId)
		}
	}
	const clearEventListeners = (client: MatrixClient) => {
		//@ts-ignore
		client.off('event', event => {
			emitCustomEvent('roomUpdate')
		})
		//@ts-ignore
		client?.off('Room', roomEvent)
		// @ts-ignore
		client?.off('Room.timeline', (event: any, room: any, toStartOfTimeline: any) =>
			roomTimelineUpdate(event, room, toStartOfTimeline, client)
		)
		// @ts-ignore
		client.off('RoomMember.membership', (event: any, member: any) => autoJoinRooms(event, member, client))
	}
	const addEvents = (client: MatrixClient) => {
		if (client === null) return
		if (ref.current) return
		ref.current = true
		//@ts-ignore
		client.on('event', event => {
			emitCustomEvent('roomUpdate')
		})
		//@ts-ignore
		client?.on('Room', roomEvent)
		// @ts-ignore
		client?.on('Room.timeline', (event: any, room: any, toStartOfTimeline: any) =>
			roomTimelineUpdate(event, room, toStartOfTimeline, client)
		)
		// @ts-ignore
		client.on('RoomMember.membership', (event: any, member: any) => autoJoinRooms(event, member, client))
		//@ts-ignore
	}

	const sendMessage = (worker: any) => {
		if (worker.chatCredentials === values.client?.getUserId()) {
			return
		}
		if (isLoading) return
		setIsLoading(true)
		// @ts-ignore
		const memberList: any[] = values.client
			?.getVisibleRooms()
			.filter((room: any) => {
				return !room.hasMembershipState(values.client?.getUserId(), 'leave')
			})
			.map(item => item.getMembers().filter(item => item.userId !== values.client?.getUserId()))
			.map(item => {
				return {
					userId: item[0].userId,
					roomId: item[0].roomId
				}
			})
		if (worker.chatCredentials && !!memberList.find((item: any) => item.userId === worker.chatCredentials)) {
			history.push(`/messages/${memberList.find((item: any) => item.userId === worker.chatCredentials)?.roomId}`)
			setIsLoading(false)
		} else if (worker.chatCredentials) {
			let options = {
				invite: [worker.chatCredentials]
			}
			// @ts-ignore
			values.client.createRoom(options, (err, data) => {
				history.push(`/messages/${data.room_id}`)
				setIsLoading(false)
			})
		}
	}

	const sendIgnoredUsers = async (userId: string, roomId: string, client: MatrixClient) => {
		const ignoredUsers = client.getIgnoredUsers()
		let newIgnoredUsers: string[] = [...ignoredUsers]
		let content = {
			ignored_users: {}
		}
		if (ignoredUsers.includes(userId)) {
			newIgnoredUsers = newIgnoredUsers.filter(item => item !== userId)
			await client?.deleteRoomTag(roomId, 'ignore')
		} else {
			newIgnoredUsers?.push(userId)
			await client?.setRoomTag(roomId, 'ignore', { order: 1 })
		}
		newIgnoredUsers?.forEach(item => {
			content = {
				...content,
				ignored_users: { ...content.ignored_users, [item]: {} }
			}
		})
		setValues((prevState: ChatContextValues) => ({
			...prevState,
			ignoredUsers: newIgnoredUsers
		}))
		await values.client?.setAccountData('m.ignored_user_list', content)
	}

	useEffect(() => {
		if (values.isConnected) {
			initialized = true
			updateRooms(values.client)
		}
	}, [values.isConnected])

	useEffect(() => {
		return () => {
			if (values.client) {
				clearEventListeners(values.client)
			}
		}
	}, [])

	return (
		<ChatContext.Provider
			value={{
				...values,
				disconnect: disconnect,
				connect: connect,
				sendMessage: sendMessage,
				sendIgnoredUsers: sendIgnoredUsers,
				setNotificationSettings: setNotificationSettings
			}}
		>
			{children}
		</ChatContext.Provider>
	)
}
