import React, {ChangeEvent, useEffect, useState} from 'react'
import {TextField} from '@rmwc/textfield'
import {req} from '../../global/common'
import {useHistory} from 'react-router-dom'
import './needs-list.sass'
import {useTranslation} from 'react-i18next'
import {UserContext} from '../../global/context.user'
import {InterfaceContext} from '../../global/context.interface'
import {NeedCard, FixedButtons, LoadingProgress, PageTitle, ProgressPage} from 'components'
import {SummaryTableByNeeds} from './components/summary-table-by-needs'
import {ISummaryTableByNeedsItem} from './components/summary-table-by-needs-def'
import {Icon} from '@rmwc/icon'
import {Button} from '@rmwc/button'
import { PagesRoutes } from 'global/routes'
import {useAutoCloseDialog} from "../../custom-hooks";

type Needs = null | false | Need[]

class Need {
    id: number
    state: string
    date: string
    number: string
    organization: string
    author: {
        firstName: string
        lastName: string
        middleName: string
    }
    position: string | null
    quantity: number
    responses: {
        pending: number
        documents_preparation: number
        signature_awaiting: number
        accepted: number
        documents_undelivered: number
        signed: number
        documents_awaiting: number
        documents_confirmed: number
    }

    constructor(data: any) {
        this.id = data.id
        this.state = data.state
        this.date = data.date
        this.number = data.number
        this.organization = data.organization
        this.author = data.author
        this.position = data.position
        this.quantity = data.quantity
        const responses = Object.assign({}, ...data.responses.map((v: any) => ({[v.type]: v.count})))
        this.responses = {
            pending: responses.pending ?? 0,
            documents_preparation: responses.documents_preparation ?? 0,
            signature_awaiting: responses.signature_awaiting ?? 0,
            accepted: responses.accepted ?? 0,
            documents_undelivered: responses.documents_undelivered ?? 0,
            signed: responses.signed ?? 0,
            documents_awaiting: responses.documents_awaiting ?? 0,
            documents_confirmed: responses.documents_confirmed ?? 0
        }
    }
}

interface IQuery {
    limit: number
    offset: number
    type?: 'pending' | 'published' | 'archived' | 'active'
    author?: string
}

export default function NeedsListPage() {
    let offsetLimitForPagination = 30
    const history = useHistory()
    const {t} = useTranslation('common')
    const [needs, setNeeds] = React.useState([] as Needs)
    const [needsTotal, setNeedsTotal] = React.useState(null)
    // const [search, setSearchAuthor] = React.useState('')
    const [listFilter, setListFilter] = React.useState('active')
    const [summaryTableByNeeds, setSummaryTableByNeeds] = useState<ISummaryTableByNeedsItem[]>([])
    const [query, setQuery] = React.useState({
        limit: offsetLimitForPagination,
        offset: 0,
        type: listFilter
    } as IQuery)
    const [loading, setLoading] = React.useState(false)
    const [isVisibleSummaryTable, setIsVisibleSummaryTable] = useState(false)
    const userCTX = React.useContext(UserContext)
    const interfaceCTX = React.useContext(InterfaceContext)
    let timeoutForSearch: NodeJS.Timeout

    async function getNeeds(query: IQuery) {
        setLoading(true)
        await req
            .get('/requests/organisation/list', query)
            .then(({data}) => {
                setNeedsTotal(data.total)
                let newNeeds = data.data.map((need: any) => new Need(need))
                if (data.total == 0) {
                    setNeeds(false)
                    return
                } else if ((query.author && query.author.length == 0) || query.offset > 0) {
                    let needsCopy = (needs! as Need[]).splice(0)
                    let needsToPush = needsCopy.concat(newNeeds)
                    setNeeds(needsToPush)
                } else {
                    setNeeds(newNeeds)
                }
            })
            .then(() => setLoading(false))
    }

    const [isReceiveLetters, setIsReceiveLetters] = useState<{
            hrGetRequestsOnApproval: boolean
            receiveInApprovalStatus: boolean
        }>({
        hrGetRequestsOnApproval: false,
        receiveInApprovalStatus: false
    })

    const saveIsReceiveLetters = (payload: {
        hrGetRequestsOnApproval?: boolean
        receiveInApprovalStatus?: boolean
    }) => {
        req
            .post('/profile/settings/save', payload)
            .then(({data}) => {
            })
    }

    React.useEffect(() => {
        req.get('/requests/organisation/list', query).then(({data}) => {
            let newNeeds = data.data.map((need: any) => new Need(need))
            setNeeds(newNeeds)
        })
        req.get('/requests/organisation/stats').then(({data}) => {
            setSummaryTableByNeeds(data.data)
        })
        req.get('/profile/settings/change-code').then(({data}) => {
            setIsReceiveLetters(data)
        })
    }, [])

    React.useEffect(() => {
        getNeeds(query)
    }, [query])

    React.useEffect(() => {
        if (
            interfaceCTX.scrollTop > 0 &&
            interfaceCTX.scrollTop + interfaceCTX.offsetHeight > interfaceCTX.scrollHeight - 10
        ) {
            setQuery({
                ...query,
                offset: query.offset + offsetLimitForPagination
            })
        }
        // if (interfaceCTX.scrollTop == 0 && interfaceCTX.offsetHeight == interfaceCTX.scrollHeight) {
        //     setQuery({
        //         ...query,
        //         offset: 0
        //     })
        // }
    }, [interfaceCTX.scrollTop])

    const createResponse = () => {
        req.post(`/requests/organisation/create`, {}).then(({data}) =>
            history.push({
                pathname: PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + `/${data.id}`,
                state: history.location.state
            })
        )
    }

    const [moderationStatus, setModerationStatus] = useState('')

    useEffect(() => {
        req.get(`/organisation/${userCTX.user?.organisation.id}/get`, query)
            .then(({data}) => {
                setModerationStatus(data.moderationPassed)
            })
            .catch(() => {
            })
    }, [])

    const [isOpenMessageMenu, setIsOpenMessageMenu] = useState(false)

    useAutoCloseDialog(() => {
        setIsOpenMessageMenu(false)
    }, [])

    return (
        <div className='needs-list-page'>
            <PageTitle
                title={query.type === 'archived' ? 'Архив' : 'Список потребностей'}
                breadcrumbs={true}
            />
            {summaryTableByNeeds.length > 1 && isVisibleSummaryTable && (
                <SummaryTableByNeeds summaryTableByNeeds={summaryTableByNeeds}/>
            )}
            <div className='card-grid card-grid--title'>
                <TextField
                    outlined
                    label='Поиск по инициатору'
                    maxLength={160}
                    trailingIcon='search'
                    // Пришлось реализацию поиска именно так организовать, именно так объявить (не стрелочно), ибо если я выношу функцию
                    // за пределы onChange, timeout-ы не стираются, а становятся в очередь. И вместо того, чтобы отрабатывать 1 раз и с теми данными,
                    // что пользователь успел ввести без перерерыва на более чем в 500ms, он отрабатывает каждое нажатие
                    onChange={function (e: ChangeEvent<HTMLInputElement>) {
                        clearTimeout(timeoutForSearch)
                        timeoutForSearch = setTimeout(() => {
                            setQuery({
                                ...query,
                                offset: 0,
                                author: e.target.value
                            })
                        }, 500)
                    }}
                />
                <div className='needs-list-page-message'>
                    <div className='needs-list-page-message-block'>
                        <div
                            style={{display: 'flex', cursor: 'pointer'}}
                            onClick={(event) => {
                                event.stopPropagation()
                                setIsOpenMessageMenu((prevState: boolean) => !prevState)
                            }}
                        >
                        <div className='needs-list-page-message-text'>
                            Получение писем
                        </div>
                        <Icon icon={'expand_more'}/>
                        </div>
                        {isOpenMessageMenu &&
                            <div className='needs-list-page-message-menu'
                                 onClick={(event) => {
                                     event.stopPropagation()
                                 }}>
                                <div className={'needs-list-page-message-menu-item'}
                                     onClick={() => {
                                         saveIsReceiveLetters({
                                             hrGetRequestsOnApproval: !isReceiveLetters.hrGetRequestsOnApproval
                                         })
                                         setIsReceiveLetters((prevState: any) => ({
                                             ...prevState,
                                             hrGetRequestsOnApproval: !isReceiveLetters.hrGetRequestsOnApproval
                                         }))
                                     }}
                                >
                                    {isReceiveLetters.hrGetRequestsOnApproval ? <Icon icon={'done'}/> :
                                        <div className='needs-list-page-message-menu-item-plug'/>}
                                    <div className='needs-list-page-message-menu-item-text'>
                                        Получать письма об откликах на потребность
                                    </div>
                                </div>
                                <div className={'needs-list-page-message-menu-item'}
                                     onClick={() => {
                                         saveIsReceiveLetters({
                                             receiveInApprovalStatus: !isReceiveLetters.receiveInApprovalStatus
                                         })
                                         setIsReceiveLetters((prevState: any) => ({
                                             ...prevState,
                                             receiveInApprovalStatus: !isReceiveLetters.receiveInApprovalStatus
                                         }))
                                     }}
                                >
                                    {isReceiveLetters.receiveInApprovalStatus ? <Icon icon={'done'}/> :
                                        <div className='needs-list-page-message-menu-item-plug'/>}
                                    <div className='needs-list-page-message-menu-item-text'>
                                        Получать письма о потребности в статусе
                                        “Согласование”
                                    </div>
                                </div>
                                <div className={'needs-list-page-message-menu-item'}
                                     onClick={() => {
                                         saveIsReceiveLetters({
                                             receiveInApprovalStatus: !isReceiveLetters.receiveInApprovalStatus,
                                             hrGetRequestsOnApproval: !isReceiveLetters.receiveInApprovalStatus
                                         })
                                         setIsReceiveLetters((prevState: any) => ({
                                             ...prevState,
                                             receiveInApprovalStatus: !isReceiveLetters.receiveInApprovalStatus,
                                             hrGetRequestsOnApproval: !isReceiveLetters.receiveInApprovalStatus
                                         }))
                                     }}
                                >
                                    {(isReceiveLetters.receiveInApprovalStatus && isReceiveLetters.hrGetRequestsOnApproval) ? <Icon icon={'done'}/> :
                                        <div className='needs-list-page-message-menu-item-plug'/>}
                                    <div className='needs-list-page-message-menu-item-text'>
                                        Получать все письма
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={'fixed-buttons-right'}>
                    <Button
                        className='restart_settings'
                        raised={true}
                        onClick={() => {
                            setIsVisibleSummaryTable((prevState: boolean) => !prevState)
                        }}
                        label={isVisibleSummaryTable ? 'Скрыть сводную таблицу' : 'Показать сводную таблицу'}
                        style={{marginRight: '19px'}}
                    ></Button>
                    {query.type === 'archived' && (
                        <FixedButtons
                            length={1}
                            buttons={[
                                {
                                    label: 'Список потребностей',
                                    primary: true,
                                    onClick: () => {
                                        setQuery({
                                            ...query,
                                            type: 'active',
                                            offset: 0
                                        })
                                        setNeeds([])
                                    }
                                }
                            ]}
                        />
                    )}
                    {query.type !== 'archived' && (
                        <FixedButtons
                            length={2}
                            buttons={
                                userCTX.userHasRole('organizer')
                                    ? [
                                        {
                                            label: 'Архив',
                                            primary: true,
                                            onClick: () => {
                                                setQuery({
                                                    ...query,
                                                    type: 'archived',
                                                    offset: 0
                                                })
                                                setNeeds([])
                                            }
                                        }
                                    ]
                                    : [
                                        {
                                            label: 'Создать',
                                            primary: true,
                                            onClick: createResponse,
                                            disabled: moderationStatus !== "accepted",
                                            tooltip: moderationStatus !== "accepted" ? 'Необходимо пройти модерацию' : undefined
                                        },
                                        {
                                            label: 'Архив',
                                            primary: true,
                                            onClick: () => {
                                                setQuery({
                                                    ...query,
                                                    type: 'archived',
                                                    offset: 0
                                                })
                                                setNeeds([])
                                            }
                                        }
                                    ]
                            }
                        />
                    )}
                </div>
            </div>
            <ProgressPage
                state={needs}
                render={() => (
                    <div className='card-grid'>
                        {needs &&
                            (needs as any[]).map((need, nIndex) => (
                                <NeedCard
                                    key={need.id}
                                    need={need}
                                    onClick={() =>
                                        history.push({
                                            pathname: PagesRoutes.NEEDS_LIST + PagesRoutes.NEEDS + `/${need.id}`,
                                            state: history.location.state
                                        })
                                    }
                                />
                            ))}
                    </div>
                )}
            />
            {loading && <LoadingProgress/>}
        </div>
    )
}
