import {BaseSyntheticEvent} from "react"
import axios, { AxiosPromise } from 'axios'

export const req = new class {
    private getHeaders() {
        const token = localStorage.getItem('token')
        return token ? { 'Authorization': 'Bearer ' + token } : undefined
    }

    get(url: string, params?: Object, token?: {Authorization: string}): AxiosPromise {
        return axios(
            process.env.REACT_APP_API_URL + url, { params, headers: token ?? this.getHeaders() }
        )
    }

    post(url: string, params?: Object, token?: {Authorization: string}): AxiosPromise {
        return axios.post(
            process.env.REACT_APP_API_URL + url, params, { headers: token ?? this.getHeaders() }
        )
    }

    put(url: string, params?: Object, token?: {Authorization: string}): AxiosPromise {
        return axios.put(
            process.env.REACT_APP_API_URL + url, params, { headers: token ?? this.getHeaders() }
        )
    }

    delete(url: string, params?: Object, token?: {Authorization: string}): AxiosPromise {
        return axios.delete(
            process.env.REACT_APP_API_URL + url, { params, headers: token ?? this.getHeaders() }
        )
    }
}()

interface Author {
    firstName: string,
    lastName: string,
    middleName: string
}

export function authorName(author: Author) {
    return author.lastName + ' ' + author.firstName + ' ' + author.middleName
}

let timeout: NodeJS.Timeout
export function timeoutInputHandler(event: BaseSyntheticEvent, setValue: (value: string) => unknown) {
    if (timeout !== undefined) clearTimeout(timeout)
    const ms = !event.target.value ? 0 : 500
    timeout = setTimeout(() => {
        setValue(event.target.value)
    }, ms)
}

type AttrValue = string|Object|Function
type AttrObject = { [attr: string]: AttrValue }
type AttrObjectStorage = { [attr: string]: AttrValue[] }

export function attrList(...attrs: [string, Boolean | undefined | AttrValue][]): AttrObject | {} {
    const match = attrs.filter(value => value[1])
    if (!match.length) return {}

    const attrStorage: AttrObjectStorage = Object.assign({},
        ...match.map(value => {
            return {
                [value[0]]: match.filter(v => v[0] === value[0]).map(v => v[1])
            }
        })
    )
    const rt: AttrObject = {}
    for (const [key, value] of Object.entries(attrStorage)) {
        switch (typeof value[0]) {
            case 'string': rt[key] = value.join(' '); break
            case 'object': rt[key] = Object.assign({}, ...value); break
            case 'function': rt[key] = value[0]; break
        }
    }

    return rt
}


export function classList(...classes: (string|undefined|boolean)[]): {className: string} | {} {
    const match = classes.filter(cl => cl)
    if (!match.length) return {}
    return {className: match.join(' ')}
}

export function formatDate(dateString: string, withTime?: boolean) {
    const [date, time] = new Date(dateString).toLocaleString('ru-RU').split(', ')
    if (withTime) return date + ' ' + time
    return date
}

export function formatTime(dateString: any) {
    const [date, time] = new Date(dateString).toLocaleString('ru-RU').split(', ')
    return [time.split(':')[0], time.split(':')[1]].join(':')
}

export function formatSecondsToTime(date: string | number | Date) {
    return new Date(Number(date) * 1000).toISOString().substr(11, 8)
}

export function isDateValid(value: string) {
    const [day, month, year] = value.split('.').map(v => Number(v))
    let today = new Date()
    today.setDate(today.getDate() - 1)
    return new Date(year, month - 1, day) > today
}

type ValueType<T> =
    T extends NumberConstructor ? string :
    T extends StringConstructor ? number :
    never

type ReturnValueType<T> =
    T extends string ? number :
    T extends number ? string :
    never

export function toTypeOrUndefined<T extends NumberConstructor | StringConstructor>(value: ValueType<T> | undefined, type: T): ReturnValueType<ValueType<T>> | undefined {
    if (value == null || value == "") return undefined
    return type(value) as ReturnValueType<ValueType<T>>
}

export function getIntAndFl(number: string | number): [number, number] | [number, undefined] {
    const [int, fl] = String(number).split('.')
    return [Number(int), fl ? Number(fl) : undefined]
}

function* genGenerator() {
    let index = 0;
    while (true) yield index++;
}

export const uidGenerator = genGenerator();