import { Role } from "global/definitions/definitions";

export const initialWebRoles: Role[] = ['admin', 'hr', 'organizer']

export enum PagesRoutes {
	WORKER = '/worker',
	WORKERS = '/workers',
	PUBLIC_PROFILE = '/public-profile',
	WORKER_PROFILE = '/worker-profile',
	WORKER_CARD = '/worker-card',
	CONTRACT_CARD = '/contract-card',
	CONTRACT_LIST = '/contract-list',
	MOBILIZATION = '/mobilization',
	PERSONAL = '/personal',
	NEEDS_LIST = '/needs-list',
	NEEDS = '/needs',
	TIME_KEEPING = '/time-keeping-test',
	OBJECTS = '/objects',
	CREATE_OBJECT = '/create-object',
	USERS = '/users',
	ARCHIVE = '/archive',
	CREATE_USER = '/create-user',
	EDIT_USER = '/edit-user',
	DELETE_USER = '/delete-user',
	RESTORE_USER = '/restore-user',
	WORKER_NEED_LIST = '/worker-need-list'
}
