import './groups-list.sass'
import React, {useContext, useEffect, useState} from "react";
import {req} from "../../../../../../global/common";
import {columns, declOfNum} from "./groups-list-utils";
import {Icon} from "@rmwc/icon";
import {TableCell} from "../../../../../../components/Table/TableCell/TableCell";
import {CustomDialog, MenuFilter, ProgressPage, Table, FixedButtons} from "components";
import {TableRow} from 'components/Table/TableRow/TableRow';
import {Checkbox} from "@rmwc/checkbox";
import {InterfaceContext} from "../../../../../../global/context.interface";
import {useTranslation} from "react-i18next";
import {Radio} from "@rmwc/radio";
import {IFilterOrSortingValues, TCurrentFilterValues} from './groups-list-def';
import {Tooltip} from "@rmwc/tooltip";
import {useHistory} from "react-router";
import {IProfessionGroup} from "../../professions-groups-def";
import {useAutoCloseDialog} from "../../../../../../custom-hooks";

export const GroupsList = () => {
    const [headCells, setHeadCells] = useState(columns)
    const [isMenuFilterOpen, setIsMenuFilterOpen] = useState('')
    const [currentFilter, setCurrentFilter] = useState<TCurrentFilterValues>([])
    const [filterOrSortValues, setFilterOrSortValues] = useState<IFilterOrSortingValues>({
        filter: {},
        sort: {}
    })
    const [selectGroup, setSelectGroup] = useState<any>({})
    const [deleteValues, setDeleteValues] = useState<number[]>([])
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
    const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false)
    const [groups, setGroups] = useState<IProfessionGroup[]>([])
    const interfaceCTX = useContext(InterfaceContext)
    const {t} = useTranslation('common')
    const history = useHistory()

    const isSorted = (fieldName: string, sortType: 'ASC' | 'DESC') => {
        const sortedBy = headCells.find((item: any) => item.fieldName === fieldName)
        if (sortedBy) return sortedBy?.sortType === sortType
        else return false
    }

    const sortTable = (title: string, fieldName: string, sortType: 'ASC' | 'DESC' | 'NONE') => {
        setHeadCells((prevState: any[]) => {
            return prevState.map((item: any) => {
                if (item.fieldName === fieldName && item.sortType === sortType) {
                    onChangeSortValues(title, fieldName, 'NONE')
                    return {...item, sortType: "NONE"}
                } else if (item.fieldName === fieldName) {
                    onChangeSortValues(title, fieldName, sortType)
                    return {...item, sortType: sortType}
                } else return {...item, sortType: 'NONE'}
            })
        })
    }

    const onChangeSortValues = (title: string, fieldName: string, sortType: 'ASC' | 'DESC' | 'NONE') => {
        const alreadyExists = currentFilter.length > 0 && currentFilter.find(item => item.fieldName === fieldName)
        if (alreadyExists) {
            setCurrentFilter(prevState => {
                return [...prevState].map(item => {
                    if (item.fieldName === fieldName) {
                        return {...item, sortType: sortType}
                    } else return {...item, sortType: 'NONE'}
                })
            })
        } else {
            setCurrentFilter(prevState => {
                const result: TCurrentFilterValues = [...prevState].map(item => ({...item, sortType: 'NONE'}))
                return [...result, {title: title, fieldName: fieldName, sortType: sortType, filterValue: null}]
            })
        }
        setFilterOrSortValues(prevState => ({
            ...prevState,
            sort: {
                [fieldName]: sortType === "NONE" ? null : sortType
            }
        }))
    }

    const onChangeFilterValues = (fieldName: string, title: string, filterValue: string[] | null) => {
        const alreadyExists = currentFilter.length > 0 && currentFilter.find(item => item.fieldName === fieldName)
        if (alreadyExists) {
            setCurrentFilter(prevState => {
                return [...prevState].map(item => {
                    if (item.fieldName === fieldName) {
                        return {...item, filterValue: filterValue}
                    } else return {...item}
                })
            })
        } else {
            setCurrentFilter(prevState => {
                const result: TCurrentFilterValues = [...prevState]
                return [...result, {title: title, fieldName: fieldName, sortType: 'NONE', filterValue: filterValue}]
            })
        }
        setFilterOrSortValues(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [fieldName]: filterValue
            }
        }))
    }

    const deleteFilter = (fieldName: string) => {
        setCurrentFilter(prevState => {
            return [...prevState].map(item => {
                if (item.fieldName === fieldName) {
                    return {...item, filterValue: null}
                } else return {...item}
            })
        })
        setFilterOrSortValues(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [fieldName]: null
            }
        }))
    }

    const renderFilterValue = (filterValue: null | string[], fieldName: string) => {
        let values: string = ''
        if (!!filterValue) {
            if (fieldName === 'category') {
                values = filterValue.map(item => t('menu_filter.' + item)).join(', ')
                return values
            } else {
                values = filterValue.join(', ')
            }
            return values
        }
        return values
    }

    const deleteSelectedValues = () => {
        req.post('/moderator/settings/groups/delete', {
            ids: deleteValues
        })
            .then(({data}) => {
                setDeleteValues([])
                setIsOpenDeleteDialog(false)
                getGroupsList()
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: 'Ошибка',
                    body: t('error.' + e.response?.data)
                })
                setIsOpenDeleteDialog(false)
            })
    }

    const getCurrentFilterValues = (fieldName: string) => {
        const result = currentFilter.find(item => item.fieldName === fieldName)?.filterValue
        if (!!result) {
            return result
        } else return []
    }

    const getGroupsList = () => {
        req.post('/moderator/settings/groups/all', filterOrSortValues)
            .then(({data}) => {
                setGroups(data)
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: 'Ошибка',
                    body: t('error.' + e.response?.data)
                })
            })
    }

    useEffect(() => {
        getGroupsList()
    }, [filterOrSortValues])


    useAutoCloseDialog(() => {
        setIsMenuFilterOpen('')
    }, [])

    return (
        <div className={'groups-list'}>

            <ProgressPage
                state={groups}
                render={() => (
                    <>
                        <div className={'professions-top'}>
                            <div className='sorted-value'>
                                {currentFilter.map((item, index) => {
                                    if (item.sortType !== 'NONE') {
                                        return (
                                            <div className={'sort'} key={item.fieldName + index + 'sort'}>
                                                <div className='sorted-item'>
                                                    <div className='column_name'>{item.title}:</div>
                                                    <div
                                                        className='type'>({item.sortType === 'ASC' ? 'А - Я' : 'Я - А'})
                                                    </div>
                                                </div>
                                                <Icon
                                                    className='cancel'
                                                    icon={'cancel'}
                                                    onClick={() => sortTable(item.title, item.fieldName, item.sortType)}
                                                />
                                            </div>
                                        )
                                    }
                                })}
                                {currentFilter.map((item, index) => {
                                    if (item.filterValue) {
                                        return (
                                            <div className='sort' key={item.fieldName + index + 'filter'}>
                                                <div className='sorted-item'>
                                                    <div className='column_name'>{item.title}:</div>
                                                    <Tooltip
                                                        content={
                                                            <div
                                                                style={{
                                                                    maxWidth: '250px',
                                                                    maxHeight: '100px',
                                                                    overflow: 'hidden'
                                                                }}
                                                            >
                                                                {renderFilterValue(item.filterValue, item.fieldName)}
                                                            </div>
                                                        }
                                                    >
                                                        <div className='type' style={{cursor: 'help'}}>
                                                            {renderFilterValue(item.filterValue, item.fieldName)}
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                                <Icon
                                                    className='cancel'
                                                    icon={'cancel'}
                                                    onClick={() => deleteFilter(item.fieldName)}
                                                />
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <FixedButtons
                                className={'buttons'}
                                length={1}
                                buttons={[
                                    {
                                        label: 'Создать группу',
                                        primary: true,
                                        disabled: false,
                                        onClick: () => {
                                            setIsOpenCreateDialog(true)
                                        }
                                    }
                                ]}
                            />
                        </div>
                        <div className='custom-table-wrapper'>
                            <Table className="custom-table">
                                <div className="headings heading custom-table-header"
                                     style={{minWidth: 'auto'}}>
                                    {headCells.map((cell, index) => {
                                        return (
                                            <div key={cell.id} className={"devider custom-table-header-item"}
                                                 style={{minWidth: cell.minWidth}}>
                                                <div className="table-cell-inner custom-table-header-inner">
                                                    {index === 1 &&
                                                        <div className='expands'>
                                                            <Icon className={`icons expand `}
                                                                  icon={"arrow_drop_up"}
                                                                  style={{color: isSorted(cell.fieldName, 'ASC') ? 'red' : ''}}
                                                                  onClick={(event) => {
                                                                      sortTable(cell.title, cell.fieldName, "ASC")
                                                                  }}/>
                                                            <Icon className={`icons expand `}
                                                                  icon={"arrow_drop_down"}
                                                                  style={{color: isSorted(cell.fieldName, 'DESC') ? 'red' : ''}}
                                                                  onClick={(event) => {
                                                                      sortTable(cell.title, cell.fieldName, 'DESC')
                                                                  }}/>
                                                        </div>
                                                    }
                                                    <div className='name'>{cell.title}</div>
                                                    {(index === 1 || index === 2) &&
                                                        <Icon className={`icons`}
                                                              icon={"more_horiz"}
                                                              onClick={(event) => {
                                                                  event.stopPropagation()
                                                                  setIsMenuFilterOpen(prevState => prevState === cell.fieldName ? '' : cell.fieldName)
                                                              }}/>
                                                    }
                                                    {index === 1 &&
                                                        <>
                                                            {isMenuFilterOpen === cell.fieldName &&
                                                                <MenuFilter
                                                                    isMenuFilterOpen={isMenuFilterOpen}
                                                                    onCloseMenuFilterOpen={() => {
                                                                        setIsMenuFilterOpen('')
                                                                    }}
                                                                    onChangeFilterValues={onChangeFilterValues}
                                                                    uniqueValues={groups.map(item => (item.professionGroupName))}
                                                                    columnTitle={cell.title}
                                                                    columnType={cell.fieldName}
                                                                    currentFilter={getCurrentFilterValues(cell.fieldName)}
                                                                />
                                                            }
                                                        </>
                                                    }
                                                    {index === 2 &&
                                                        <>
                                                            {isMenuFilterOpen === cell.fieldName &&
                                                                <MenuFilter
                                                                    isMenuFilterOpen={isMenuFilterOpen}
                                                                    onCloseMenuFilterOpen={() => {
                                                                        setIsMenuFilterOpen('')
                                                                    }}
                                                                    onChangeFilterValues={onChangeFilterValues}
                                                                    uniqueValues={['worker', 'itr']}
                                                                    columnTitle={cell.title}
                                                                    columnType={cell.fieldName}
                                                                    isWithSearch={false}
                                                                    currentFilter={getCurrentFilterValues(cell.fieldName)}
                                                                />
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {groups.map((group: IProfessionGroup, index) => {
                                    return (
                                        <TableRow type='row' key={`${group.professionGroupName} ${index}`}
                                                  style={{minHeight: '65px'}}>
                                            <TableCell
                                                devider
                                                style={{
                                                    minWidth: columns[0].minWidth
                                                }}
                                            >
                                                <Checkbox
                                                    onChange={e => {
                                                        if (deleteValues.includes(group.id)) {
                                                            setDeleteValues(prevState => ([...prevState.filter((num: number) => num !== group.id)]))
                                                        } else {
                                                            setDeleteValues(prevState => ([...prevState, group.id]))
                                                        }
                                                    }}
                                                    checked={deleteValues.includes(group.id)}
                                                />
                                            </TableCell>
                                            <TableCell
                                                devider
                                                style={{
                                                    minWidth: columns[1].minWidth
                                                }}
                                            >
                                                <div style={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    wordWrap: 'break-word'

                                                }}
                                                >
                                                    {group.professionGroupName}
                                                </div>
                                                <Icon icon={'edit'}
                                                      style={{paddingLeft: '10px', cursor: 'pointer'}}
                                                      onClick={() => {
                                                          setSelectGroup({
                                                              id: group.id,
                                                              category: group.category as 'worker' | 'itr'
                                                          })
                                                          history.replace({
                                                              pathname: 'redact-group', state: [{
                                                                  id: group.id,
                                                                  category: group.category as 'worker' | 'itr'
                                                              }]
                                                          })
                                                      }}/>
                                            </TableCell>
                                            <TableCell
                                                devider
                                                style={{
                                                    minWidth: columns[2].minWidth
                                                }}
                                            >
                                                {t('menu_filter.' + group.category)}
                                            </TableCell>
                                            <TableCell
                                                devider
                                                style={{
                                                    minWidth: columns[3].minWidth
                                                }}
                                            >
                                                {group.professionsCount + declOfNum(group.professionsCount, [' профессия', ' профессии', ' профессий'])}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </Table>
                        </div>
                        <div className={'professions-bottom'}>
                            {!!deleteValues.length ? <FixedButtons
                                    className={'buttons'}
                                    length={1}
                                    buttons={[
                                        {
                                            label: 'Удалить',
                                            primary: false,
                                            disabled: !deleteValues.length,
                                            className: 'button',
                                            onClick: () => {
                                                setIsOpenDeleteDialog(true)
                                            }
                                        }
                                    ]}
                                />
                                : <div style={{height: '60px'}}/>}
                        </div>
                        <CustomDialog
                            isOpen={isOpenDeleteDialog}
                            onClose={() => setIsOpenDeleteDialog(false)}
                            dialogTitle={'Удалить группы?'}
                            buttons={[{
                                callback: () => setIsOpenDeleteDialog(false),
                                outlined: true,
                                raised: false,
                                text: 'Нет'
                            }, {
                                callback: deleteSelectedValues,
                                outlined: true,
                                raised: true,
                                text: 'Да'
                            }]}
                        >
                            <div className={'professions-dialog-description'}>
                                Вы уверены, что хотите удалить выбранные группы?
                            </div>
                        </CustomDialog>
                        <CustomDialog
                            isOpen={isOpenCreateDialog}
                            onClose={() => setIsOpenCreateDialog(false)}
                            dialogTitle={'Укажите категорию группы'}
                            buttons={[{
                                callback: () => setIsOpenCreateDialog(false),
                                outlined: true,
                                raised: false,
                                text: 'Отменить'
                            }, {
                                callback: () => {
                                    history.replace({pathname: 'redact-group', state: [{...selectGroup}]})
                                },
                                outlined: true,
                                raised: true,
                                disabled: !selectGroup.category,
                                text: 'Применить'
                            }]}
                        >
                            <div className={'professions-dialog-description'}>
                                <div className={'professions-dialog-item'}
                                     onClick={() => {
                                         if (selectGroup.category === 'worker') {
                                             setSelectGroup({})
                                         } else {
                                             setSelectGroup({
                                                 category: 'worker'
                                             })
                                         }
                                     }}
                                >
                                    <Radio
                                        className={'item-check-radio'}
                                        checked={selectGroup.category === 'worker'}
                                        onChange={() => {
                                        }}
                                    />
                                    <div className={'item-title'}>
                                        Рабочий
                                    </div>
                                </div>
                                <div className={'professions-dialog-item'}
                                     onClick={() => {
                                         if (selectGroup.category === 'itr') {
                                             setSelectGroup({})
                                         } else {
                                             setSelectGroup({
                                                 category: 'itr'
                                             })
                                         }
                                     }}
                                >
                                    <Radio
                                        className={'item-check-radio'}
                                        checked={selectGroup.category === 'itr'}
                                        onChange={() => {
                                        }}
                                    />
                                    <div className={'item-title'}>
                                        ИТР
                                    </div>
                                </div>
                            </div>
                        </CustomDialog>
                    </>
                )}
            />
        </div>
    )
}