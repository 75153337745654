import {format} from "date-fns";
import {formatDataToReq} from "./BrigadeRender.service";
import {Author} from "../../../global/definitions/definitions";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { NumberFormatValues } from "react-number-format";
export type {Shift, ShiftListRes, Worker, Foreman}
export {Brigade, FormattedTracking, FormattedTrackingSend}

interface ShiftListRes {
    data: Shift[]
}

interface Shift {
    id: number
    date: string
    open: boolean
    brigadeCount: number
}

interface Foreman {
    label: string,
    value: number
}

interface Worker {
    foremanID?: number
    profile: Author
    endTime: string | null
    startTime: string | null
    workerRecordID: number
    requestID: number
}

class Brigade {
    id: number
    date: string
    name: string
    workers: Worker[]
    shiftType: string | null
    object: {
        id: number
        name: string
        adress: string
        responsible: Author
    } | null

    constructor(data: any, date: string) {
        this.date = date
        this.id = data.brigade.id
        this.name = data.brigade.name
        this.workers = data.tracking
        this.shiftType = data.shiftType

        this.object = data.objectResponse
            ? {
                id: data.objectResponse.id,
                name: data.objectResponse.name,
                adress: data.objectResponse.address,
                responsible: data.brigade.foreman
            }
            : null
    }
}

class FormattedTracking {
    foremanID: number
    profile: Author
    endTime: string
    startTime: string
    workerRecordID: number

    constructor(data: Worker) {
        this.foremanID = data.foremanID as number
        this.profile = data.profile
        this.endTime = data.endTime ? format(new Date(data.endTime), 'dd.MM.yyyy HH:mm') : ''
        this.startTime = data.startTime ? format(new Date(data.startTime), 'dd.MM.yyyy HH:mm') : ''
        this.workerRecordID = data.workerRecordID
    }
}

class FormattedTrackingSend {
    foremanID: number
    endTime: string | null | false
    startTime: string | null | false
    workerRecordID: number

    constructor(data: FormattedTracking) {
        this.foremanID = data.foremanID
        this.endTime = data.endTime && formatDataToReq(data.endTime)
        this.startTime = data.startTime && formatDataToReq(data.startTime)
        this.workerRecordID = data.workerRecordID
    }
}

export interface IWorkerTrackingInputProps {
    worker: any,
    startTime?: string
    trackingType?: 'startTime' | 'endTime'
    methods: UseFormReturn<FieldValues, object>
    currentDay: string,
    isEditMode: boolean,
    changeTrackingForm: (worker: any, output: NumberFormatValues, operationType: "startTime" | "endTime") => void
}


