import { ErrorPage } from 'pages'
import OrganizationAuthorization from 'pages/organization-authorization/organization-authorization'
import ProfilePublicPage from 'pages/public-profile/public-profile'
import {Signin} from 'pages/Signin/Signin'
import { Route } from './routes-def'
import { initialWebRoles } from './routes-utils'
import ProfilePage from "../../pages/profile/profile";

export const commonRoutes: Route[] = [
	// { title: '7357', path: '/brigade-render', component: <TestPage />, navBar: false },
	{
		title: 'Вход',
		path: '/signin',
		component: <Signin />,
		navBar: false
	},
	{
		title: 'Вход',
		path: '/organization-signin',
		component: <OrganizationAuthorization />,
		navBar: false
	},
	{
		title: 'Профиль',
		path: '/profile/',
		component: <ProfilePage />,
		navBar: false,
		icon: 'account_circle',
		availableRoles: initialWebRoles
	},
	{
		title: 'Профиль организации',
		path: '/public-profile/:searchID?/:status?',
		component: <ProfilePublicPage />,
		navBar: false,
		icon: 'home',
		availableRoles: initialWebRoles
	},
	{
		title: 'Страница не найдена',
		path: '/404',
		component: <ErrorPage code='404' />,
		navBar: false,
		availableRoles: initialWebRoles
	}
]
