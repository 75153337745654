import React from 'react'

import './anthropometry.sass'
import {IAnthropometrySubPage} from "./anthropometry-def";
import {InfoBlock} from "../../../../components/InfoBlock/InfoBlock";


const whetherIndicated = (value: any) => value ? value : 'Не указано'

export const AnthropometrySubPage = ({anthropometry}: IAnthropometrySubPage) => {

    if(anthropometry) {
        return (
            <div className='anthropometry-subpage-container'>
                <div className='section'>
                    <div className="anthropometry-subpage-list">
                        <div className="info-wrapper">
                            <InfoBlock
                                title="Размер головы, см"
                                content={whetherIndicated(anthropometry.headGirth)}
                            />
                        </div>
                        <div className="info-wrapper">
                            <InfoBlock
                                title="Рост, см"
                                content={whetherIndicated(anthropometry.height)}
                            />
                        </div>
                        <div className="info-wrapper">
                            <InfoBlock
                                title="Обхват груди, см"
                                content={whetherIndicated(anthropometry.bustGirth)}
                            />
                        </div>
                        {/*<div className="info-wrapper">*/}
                        {/*    <InfoBlock*/}
                        {/*        title="Размер одежды"*/}
                        {/*        content={whetherIndicated(clothingSizePicker(anthropometry.bustGirth, (anthropometry.hipsGirth || anthropometry.waistGirth), anthropometry.gender))}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {anthropometry.hipsGirth &&
                            <div className="info-wrapper">
                                <InfoBlock
                                    title="Обхват бедер, см"
                                    content={whetherIndicated(anthropometry.hipsGirth)}
                                />
                            </div>
                        }
                        {anthropometry.waistGirth &&
                            <div className="info-wrapper">
                                <InfoBlock
                                    title="Обхват талии, см"
                                    content={whetherIndicated(anthropometry.waistGirth)}
                                />
                            </div>
                        }
                        <div className="info-wrapper">
                            <InfoBlock
                                title="Размер ноги, см"
                                content={whetherIndicated(anthropometry.shoeSize)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    } else return <div></div>

}