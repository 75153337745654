import React from 'react'
import { Drawer, DrawerContent } from '@rmwc/drawer'
import { useHistory, useLocation } from 'react-router-dom'
import { List, ListItem, ListItemText } from '@rmwc/list'
import { PagesRoutes, routes } from 'global/routes'
import { parse } from 'path-to-regexp'
import { Icon } from '@rmwc/icon'
import { Link } from 'react-router-dom'
import { InterfaceContext } from '../../global/context.interface'

import '@rmwc/list/styles'
import '@rmwc/drawer/styles'
import './Navbar.style.sass'
import { UserContext } from '../../global/context.user'
import { ChatContext } from '../../global/context.chat'
import { Route } from 'global/routes'

export default function Navbar() {
	const history = useHistory()
	const location = useLocation()
	const [currentPath, setCurrentPath] = React.useState(null as any)
	const userCTX = React.useContext(UserContext)
	const chatCTX = React.useContext(ChatContext)
	const interfaceCTX = React.useContext(InterfaceContext)
	const version = interfaceCTX.version
	const [pages, setPages] = React.useState<Route[]>([])

	React.useEffect(() => {
		setCurrentPath(history.location.pathname)
	}, [location])

	React.useEffect(() => {
		setPages([...routes])
		return () => {
			setPages([...[]])
		}
	}, [userCTX.user])

	if (!currentPath) return null
	return (
		<Drawer id='navbar' className={interfaceCTX.showMenu ? 'nav-opened' : 'nav-closed'}>
			<DrawerContent>
				<Icon
					onClick={() => interfaceCTX.showHideNav()}
					className={interfaceCTX.showMenu ? 'show-hide-menu' : 'show-hide-menu-closed'}
					icon={interfaceCTX.showMenu ? 'menu_open' : 'double_arrow'}
				></Icon>
				{userCTX.user && (
					<List>
						{routes
							.map(route =>
								!localStorage.getItem('isHaveContracts') && route.path === PagesRoutes.CONTRACT_LIST
									? { ...route, navBar: false }
									: route
							)
							.filter(
								v =>
									(v.navBar && (!v.availableRoles || v.availableRoles.includes(userCTX!.user!.roles[0]))) ||
									(v.navBar && userCTX!.user!.roles.length == 0 && v.availableRoles?.includes('pre-worker'))
							)
							.map((v, i) => {
								return (
									<div className='list-item' key={'list-item-' + i}>
										<Link
											to={{
												pathname: String(parse(v.path)[0]),
												state: [{ path: v.path, url: v.path, title: v.title }]
											}}
										>
											<ListItem activated={v.path.includes('/' + currentPath.split('/')[1])}>
												{'/messages' === String(parse(v.path)[0]) && chatCTX.isNotification && (
													<div className='navbar-notice' />
												)}
												<div className='icon'>
													{/*<span className="number">99+</span> icon--number*/}
													<Icon icon={v.icon ? v.icon : 'circle'} />
												</div>
												<ListItemText>{v.title}</ListItemText>
											</ListItem>
										</Link>
									</div>
								)
							})}
					</List>
				)}
				<a
					className={
						'support ' +
						`${interfaceCTX.showMenu ? '' : 'version-mini'} ${
							interfaceCTX.instructionValue.isOpen && interfaceCTX.instructionValue.step === 9 ? 'support-active' : ''
						}`
					}
					href={'mailto:' + interfaceCTX.getMailToArr()}
				>
					{interfaceCTX.showMenu ? 'Связаться с техподдержкой' : <Icon icon='support_agent'></Icon>}
				</a>
				{userCTX.user?.currentRole !== 'worker' && userCTX.user?.currentRole !== 'moderator' && (
					<div
						className={'support-lamp ' + `${interfaceCTX.showMenu ? 'version' : 'version-mini'}`}
						onClick={() => {
							interfaceCTX.setInstructionValue(true, 0)
						}}
					>
						{interfaceCTX.showMenu ? 'Подсказка' : <Icon icon='emoji_objects'></Icon>}
					</div>
				)}
				<div className={interfaceCTX.showMenu ? 'version' : 'version-mini'}>{`Версия: ${version}`}</div>
			</DrawerContent>
		</Drawer>
	)
}
