import React, {FormEvent, useState} from 'react'
import {IArrayOfValues, IMenuFilter} from './MenuFilter.types'
import './MenuFilter.style.sass'
import {TextField} from '@rmwc/textfield'
import {Icon} from '@rmwc/icon'
import {useDebounceEffect} from 'custom-hooks'
import {Checkbox} from '@rmwc/checkbox'
import {req} from 'global/common'
import {useTranslation} from 'react-i18next'
import {ProgressPage} from 'components'
import {isArray} from "util";

export const MenuFilter: React.FC<IMenuFilter> = ({
                                                      isMenuFilterOpen,
                                                      onCloseMenuFilterOpen,
                                                      columnType,
                                                      columnTitle,
                                                      onChangeFilterValues,
                                                      isWithSearch = true,
                                                      uniqueValues,
                                                      currentFilter,
                                                      getApiUrl,
                                                      isNumber = false,
                                                      request = {}
                                                  }) => {
    const [textValue, setTextValue] = useState<string>('')
    const {t} = useTranslation('common')
    const [filterValues, setFilterValues] = useState<string[]>(currentFilter ? currentFilter : [])
    const [isSelectAllFilterValues, setIsSelectAllFilterValues] = useState(false)
    const [arrayOfValues, setArrayOfValues] = useState<IArrayOfValues>(null as unknown as IArrayOfValues)

    const [searchValue, setSearchValue] = useState<any>({})

    const selectAllFilterValues = (isSelected: boolean) => {
        if (isSelected) {
            setFilterValues(arrayOfValues.statics)
            setIsSelectAllFilterValues(true)
        } else if (!isSelected) {
            setFilterValues([])
            setIsSelectAllFilterValues(false)
        }
    }

    const getValues = () => {
        setArrayOfValues(null as unknown as IArrayOfValues)
        req.post(`${getApiUrl}?limit=20&offset=0`, {
            ...searchValue,
            filter: {
                ...searchValue.filter,
                ...request
            }
        })
            .then(({data}) => {
                let arrayValues: any
                if (Array.isArray(data.data)) {
                    arrayValues = data.data.map((item: any) => (item[columnType]))
                } else {
                    arrayValues = data.map((item: any) => (item[columnType]))
                }
                setArrayOfValues((prevState: IArrayOfValues) => ({
                    ...prevState,
                    statics: arrayValues,
                    mutable: arrayValues
                }))
            })
            .catch((error) => {
            })
    }

    React.useEffect(() => {
        if (getApiUrl) {
            getValues()
        } else {
            setArrayOfValues((prevState: IArrayOfValues) => ({
                ...prevState,
                statics: uniqueValues.filter((item: any) => !!item),
                mutable: uniqueValues.filter((item: any) => !!item)
            }))
        }
    }, [searchValue])

    const searchFilterValues = (value: string) => {
        if (value !== '') {
            setArrayOfValues((prevState: IArrayOfValues) => ({
                ...prevState,
                mutable: prevState.statics.filter(item => !!item && item.toString().toLowerCase().includes(value.toLowerCase()))
            }))
        } else {
            setArrayOfValues((prevState: IArrayOfValues) => ({
                ...prevState,
                mutable: prevState.statics
            }))
        }
    }

    const renderFilterValue = (item: string) => {
        if (!isWithSearch) {
            return t('menu_filter.' + item)
        } else return item
    }

    useDebounceEffect(() => {
        if (getApiUrl) {
            setSearchValue({
                filter: {
                    ...request,
                    [columnType]: textValue === "" ? null : [textValue]
                    // [columnType]: textValue === "" ? null : [isNumber ? +textValue : textValue]
                },
            })
        } else if (arrayOfValues) {
            searchFilterValues(textValue)
        }
    }, 300, [textValue])

    if (isMenuFilterOpen) {
        return (
            <div
                className={`menu-filter`}
                onClick={event => {
                    event.stopPropagation()
                }}
            >
                {isWithSearch &&
                    <div className='menu-filter-input'>
                        <TextField
                            value={textValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value
                                setTextValue(value)
                            }}
                            outlined
                            type='text'
                            style={{width: '100%'}}
                            icon='search'
                            maxLength={columnType === 'countTime' || columnType === 'countShifts' ? 5 : 160}
                        />
                    </div>
                }
                <div className='menu-filter-list'>
                    <div className='items'>
                        <div className='item'>
                            <Checkbox
                                className={isSelectAllFilterValues ? 'horosiy-checkbox' : 'off-checkbox'}
                                checked={isSelectAllFilterValues}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    selectAllFilterValues(!isSelectAllFilterValues)
                                }}
                            />
                            <div className='item-text'>Выбрать все</div>
                        </div>
                        <ProgressPage
                            state={arrayOfValues}
                            render={() => (
                                <>
                                    {arrayOfValues.mutable &&
                                        arrayOfValues.mutable.map(item => {
                                            return (
                                                <div className='item' key={item}>
                                                    <Checkbox
                                                        key={item}
                                                        className={filterValues.includes(item) ? 'horosiy-checkbox' : 'off-checkbox'}
                                                        checked={filterValues ? filterValues.includes(item) : false}
                                                        onChange={(e: FormEvent<HTMLInputElement>) => {
                                                            setFilterValues((prevState: string[]) => {
                                                                let alreadyHas = !!prevState && prevState.find(value => value === item)
                                                                let newState: string[] = []
                                                                if (alreadyHas) {
                                                                    newState = [...prevState.filter(value => value !== item)]
                                                                    setIsSelectAllFilterValues(false)
                                                                } else {
                                                                    newState = [...prevState, item]
                                                                }
                                                                return newState
                                                            })
                                                        }}
                                                    />
                                                    <div className='item-text'>
                                                        {renderFilterValue(item)}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </>
                            )}
                        />
                    </div>
                    <div className='dialog-buttons'>
                        <div
                            className='dialog-btn'
                            onClick={() => {
                                onChangeFilterValues(columnType, columnTitle, filterValues.length > 0 ? filterValues : null)
                                onCloseMenuFilterOpen()
                            }}
                        >
                            <Icon className='done' icon={'done'}/>
                            <div>ПРИМЕНИТЬ</div>
                        </div>
                        <div
                            className='dialog-btn'
                            onClick={() => {
                                setFilterValues([])
                                onChangeFilterValues(columnType, columnTitle, null)
                            }}
                        >
                            <Icon className='close' icon={'close'}/>
                            <div>ОТМЕНИТЬ</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else return null
}
