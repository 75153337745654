import {
    addDays,
    differenceInCalendarDays, differenceInMinutes,
    format,
    parse,
} from "date-fns";
export {formatDataToReq}


function formatDataToReq(data: string) {
    if (!data) return null
    try {
        return format(
            parse(data, 'dd.MM.yyyy HH:mm', new Date()),
            "yyyy-MM-dd'T'HH:mm:ssXXXX"
        )
    } catch {
        return false
    }
}

export function checkStartTime(v: string | undefined | null, currentDay: string) {

    if (v == '') {
        return true
    }
    if (v != undefined && !v.includes('_')) {
        if (parse(v, 'dd.MM.yyyy HH:mm', new Date()) as unknown as string == "Invalid Date") {
            return "Неверная дата"
        }
        return v && v.split(' ')[0] === currentDay || `Только ${currentDay}`
    } else if (v != undefined && v.includes('_')) return "Введите дату и время"

}

export function checkEndTime(v: string | undefined | null, currentDay: string) {

    if (v != undefined && !v.includes('_')) {
        let chosenDay = parse(currentDay, 'dd.MM.yyyy', new Date())
        let closeShiftDay = parse(v.split(' ')[0], 'dd.MM.yyyy', new Date());
        if (v == '') {
            return true
        }
        if (differenceInCalendarDays(closeShiftDay, chosenDay) > 1) {
            return `Не позже ${format(addDays(chosenDay, 1), 'dd.MM.yyyy')}`
        }
        if (differenceInCalendarDays(closeShiftDay, chosenDay) < 0) {
            return `Не ранее ${currentDay}`
        }
        if (parse(v, 'dd.MM.yyyy HH:mm', new Date()) as unknown as string == "Invalid Date") {
            return "Неверная дата"
        }
    } else if (v != undefined && v.includes('_')) return "Введите дату и время"
}

export const checkDate = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) {
        return true
    } else {
        let startDay = parse(startDate.split(' ').join('T'), "dd.MM.yyyy'T'HH:mm", new Date()).valueOf()
        let endDay = parse(endDate.split(' ').join('T'), "dd.MM.yyyy'T'HH:mm", new Date()).valueOf()
        if (differenceInMinutes(endDay, startDay) === 0) {
            return 'Время совпадает'
        } else if (differenceInMinutes(endDay, startDay) < 0) {
            return 'Время начала смены не может быть позднее времени завершения'
        }
    }
}
