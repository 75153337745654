import React from "react";
import {IDialogSettings} from "./MenuSettings.types";
import {Switch} from "@rmwc/switch";
import './MenuSettings.style.sass'
import {Icon} from "@rmwc/icon";


export const MenuSettings: React.FC<IDialogSettings> = ({
                                                              columnSettings,
                                                              toggleIsVisibleColumn,
                                                              defaultVisibleColumns,
                                                              numbered
                                                          }) => {

    const checkIsLast = () => {
        let count: number = 0
        let order: number = 99
        for (const column of columnSettings) {
            if (column.visibility) {
                count += 1;
                order = column.sortOrder
            }
        }

        return {order, isLast: count === 1}
    }

    return (
        <div className='dialog-settings' onClick={(event) => {
            event.stopPropagation()
        }}>
            <div className='dialog-items'>
                {columnSettings.map((column, index) => {
                    if(!numbered && column.columnType === 'number') return <></>
                    return (
                        <div key={`${index} ${column.visibility}`} className='dialog-switch'>
                            <Switch
                                disabled={checkIsLast().order === column.sortOrder && checkIsLast().isLast}
                                checked={column.visibility}
                                onChange={(evt) => toggleIsVisibleColumn(!!evt.currentTarget.checked, column.columnType)}
                                label={columnSettings[index].title}
                            />
                        </div>
                    );
                })}
            </div>
            <div className='dialog-button'
                 onClick={() => defaultVisibleColumns(columnSettings)}
            >
                <Icon className='icon visibility' icon={"visibility"}/>
                <div>ПОКАЗАТЬ ВСЕ</div>
            </div>
        </div>
    )
}