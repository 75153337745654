import './professions-list.sass'
import {Icon} from "@rmwc/icon";
import {Checkbox} from "@rmwc/checkbox";
import React, {useContext, useEffect, useRef, useState} from "react";
import {columns, defaultProfessionValues, downloadEXCELTable, renderGroups} from "./professions-list-utils";
import {Tooltip} from "@rmwc/tooltip";

import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {ProgressPage, FixedButtons, Table, IFilterOrSortingValues, CustomDialog, MenuFilter} from "components";
import {TCurrentFilterValues} from "../../../professions-groups/components/groups-list/groups-list-def";
import {InterfaceContext} from "../../../../../../global/context.interface";
import {TableRow} from "../../../../../../components/Table/TableRow/TableRow";
import {TableCell} from "../../../../../../components/Table/TableCell/TableCell";
import {req} from "../../../../../../global/common";
import {ICurrentProfessionItem, IProfessionItem} from "../../setting-professions-list-def";
import {TextField} from "@rmwc/textfield";
import {Radio} from "@rmwc/radio";
import {useAutoCloseDialog} from "../../../../../../custom-hooks";
import {CircularProgress} from "@rmwc/circular-progress";


export const ProfessionsList = () => {
    const [headCells, setHeadCells] = useState(columns)
    const [isMenuFilterOpen, setIsMenuFilterOpen] = useState('')
    const [professionsList, setProfessionsList] = useState<IProfessionItem[] | null>(null)
    const [currentFilter, setCurrentFilter] = useState<TCurrentFilterValues>([])
    const [filterOrSortValues, setFilterOrSortValues] = useState<IFilterOrSortingValues>({
        filter: {},
        sort: {}
    })
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
    const [isOpenSelectMenu, setIsOpenSelectMenu] = useState<boolean>(false)
    const [currentProfession, setCurrentProfession] = useState<ICurrentProfessionItem>({} as ICurrentProfessionItem)
    const [deleteValues, setDeleteValues] = useState<number[]>([])
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(50)
    const [isLoadingEXCELDownload, setIsLoadingEXCELDownload] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [isFirstStart, setIsFirstStart] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isChangingProfession, setIsChangingProfession] = useState(false)
    const interfaceCTX = useContext(InterfaceContext)
    const {t} = useTranslation('common')

    const history = useHistory()

    const isSorted = (fieldName: string, sortType: 'ASC' | 'DESC') => {
        const sortedBy = headCells.find((item: any) => item.fieldName === fieldName)
        if (sortedBy) return sortedBy?.sortType === sortType
        else return false
    }

    const sortTable = (title: string, fieldName: string, sortType: 'ASC' | 'DESC' | 'NONE') => {
        setHeadCells((prevState: any[]) => {
            return prevState.map((item: any) => {
                if (item.fieldName === fieldName && item.sortType === sortType) {
                    onChangeSortValues(title, fieldName, 'NONE')
                    return {...item, sortType: "NONE"}
                } else if (item.fieldName === fieldName) {
                    onChangeSortValues(title, fieldName, sortType)
                    return {...item, sortType: sortType}
                } else return {...item, sortType: 'NONE'}
            })
        })
    }

    const onChangeSortValues = (title: string, fieldName: string, sortType: 'ASC' | 'DESC' | 'NONE') => {
        const alreadyExists = currentFilter.length > 0 && currentFilter.find(item => item.fieldName === fieldName)
        if (alreadyExists) {
            setCurrentFilter(prevState => {
                return [...prevState].map(item => {
                    if (item.fieldName === fieldName) {
                        return {...item, sortType: sortType}
                    } else return {...item, sortType: 'NONE'}
                })
            })
        } else {
            setCurrentFilter(prevState => {
                const result: TCurrentFilterValues = [...prevState].map(item => ({...item, sortType: 'NONE'}))
                return [...result, {title: title, fieldName: fieldName, sortType: sortType, filterValue: null}]
            })
        }
        setFilterOrSortValues(prevState => ({
            ...prevState,
            sort: {
                [fieldName]: sortType === "NONE" ? null : sortType
            }
        }))
    }

    const renderChangeProfessionRow = () => {
        if (!!currentProfession) {
            return (
                <TableRow type={"row"} className=''>
                    {columns.map(item => {
                        if ('professionName' === item.fieldName) {
                            return (
                                <TableCell key={item.fieldName} devider style={{
                                    minWidth: item.minWidth,
                                }}>
                                    <TextField
                                        maxLength={100}
                                        outlined
                                        //@ts-ignore
                                        value={currentProfession[item.fieldName]}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = e.target.value
                                            if (value === " ") return
                                            if (value !== '' && !(/^[?!,/\-.а-яА-ЯёЁ\s]+$/).test(value)) return
                                            interfaceCTX.checkIsFormChanged(true, 'settings')
                                            setCurrentProfession(prevState => ({
                                                ...prevState,
                                                [item.fieldName]: value
                                            }))
                                        }}
                                    />
                                </TableCell>
                            )
                        } else if ('professionCode' === item.fieldName) {
                            return (
                                <TableCell key={item.fieldName} devider style={{
                                    minWidth: item.minWidth,
                                }}>
                                    <TextField
                                        outlined
                                        maxLength={15}
                                        //@ts-ignore
                                        value={currentProfession[item.fieldName]}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = e.target.value
                                            if (value === " ") return
                                            if (!Number(value) && value !== '') return
                                            if (value.includes('+') || value.includes('-') || value.includes('.')) return
                                            interfaceCTX.checkIsFormChanged(true, 'settings')
                                            setCurrentProfession(prevState => ({
                                                ...prevState,
                                                [item.fieldName]: value.trim()
                                            }))
                                        }}
                                    />
                                </TableCell>
                            )
                        } else if (item.fieldName === 'category') {
                            return (
                                <TableCell className={'select_category'} key={item.fieldName} devider style={{
                                    minWidth: item.minWidth,
                                }}>
                                    <div>{t('menu_filter.' + currentProfession.category)}</div>
                                    {!currentProfession.id &&
                                        <Icon icon={'more_horiz'}
                                              style={{marginLeft: 'auto', cursor: 'pointer'}}
                                              onClick={(event: any) => {
                                                  event.stopPropagation()
                                                  setIsOpenSelectMenu(prevState => !prevState)
                                              }}/>
                                    }
                                    {isOpenSelectMenu && !currentProfession.id &&
                                        <div className={'dialog'}
                                             onClick={(event: any) => {
                                                 event.stopPropagation()
                                             }}
                                        >
                                            <div className={'dialog_item'}
                                                 onClick={() => {
                                                     interfaceCTX.checkIsFormChanged(true, 'settings')
                                                     setCurrentProfession(prevState => ({
                                                         ...prevState,
                                                         category: 'worker'
                                                     }))
                                                 }}
                                            >
                                                <div className={'item'}>
                                                    Рабочий
                                                </div>
                                                <Radio
                                                    className={'item-check-radio'}
                                                    checked={currentProfession.category === 'worker'}
                                                />
                                            </div>
                                            <div className={'dialog_item'}
                                                 onClick={() => {
                                                     interfaceCTX.checkIsFormChanged(true, 'settings')
                                                     setCurrentProfession(prevState => ({
                                                         ...prevState,
                                                         category: 'itr'
                                                     }))
                                                 }}
                                            >
                                                <div className={'item'}>
                                                    ИТР
                                                </div>
                                                <Radio
                                                    className={'item-check-radio'}
                                                    checked={currentProfession.category === 'itr'}
                                                />
                                            </div>

                                        </div>
                                    }
                                </TableCell>
                            )
                        } else if (item.fieldName === 'select') {
                            return (
                                <TableCell key={item.fieldName} devider style={{
                                    minWidth: item.minWidth,
                                }}>
                                    <Checkbox
                                        checked={false}
                                        disabled={true}
                                    />
                                </TableCell>
                            )
                        } else if (item.fieldName === 'isActive') {
                            return (
                                <TableCell key={item.fieldName} devider style={{
                                    minWidth: item.minWidth,
                                }}>
                                    <Checkbox
                                        onChange={e => {
                                            interfaceCTX.checkIsFormChanged(true, 'settings')
                                            setCurrentProfession(prevState => ({
                                                ...prevState,
                                                isActive: !prevState.isActive
                                            }))
                                        }}
                                        checked={currentProfession.isActive}
                                    />
                                </TableCell>
                            )
                        } else if (item.fieldName === 'groups') {
                            return (
                                <TableCell key={item.fieldName} devider style={{
                                    minWidth: item.minWidth,
                                }}>
                                    {/*{renderGroups(currentProfession.group)}*/}
                                </TableCell>
                            )
                        }
                    })}
                </TableRow>

            )
        }
    }

    const onChangeFilterValues = (fieldName: string, title: string, filterValue: string[] | null) => {
        const alreadyExists = currentFilter.length > 0 && currentFilter.find(item => item.fieldName === fieldName)
        if (alreadyExists) {
            setCurrentFilter(prevState => {
                return [...prevState].map(item => {
                    if (item.fieldName === fieldName) {
                        return {...item, filterValue: filterValue}
                    } else return {...item}
                })
            })
        } else {
            setCurrentFilter(prevState => {
                const result: TCurrentFilterValues = [...prevState]
                return [...result, {title: title, fieldName: fieldName, sortType: 'NONE', filterValue: filterValue}]
            })
        }
        if (fieldName === 'isActive') {
            setFilterOrSortValues(prevState => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    isActive: filterValue ? [filterValue[0] === 'available'] : null
                }
            }))
        } else {
            setFilterOrSortValues(prevState => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    [fieldName]: filterValue
                }
            }))
        }
    }

    const deleteFilter = (fieldName: string) => {
        setCurrentFilter(prevState => {
            return [...prevState].map(item => {
                if (item.fieldName === fieldName) {
                    return {...item, filterValue: null}
                } else return {...item}
            })
        })
        setFilterOrSortValues(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                [fieldName]: null
            }
        }))
    }

    const renderFilterValue = (filterValue: null | string[], fieldName: string) => {
        let values: string = ''
        if (!!filterValue) {
            if (['category', 'isActive'].includes(fieldName)) {
                values = filterValue.map(item => t('menu_filter.' + item)).join(', ')
                return values
            } else {
                values = filterValue.join(', ')
            }
            return values
        }
        return values
    }

    const deleteSelectedValues = () => {
        req.post('/moderator/settings/professions/delete', {
            ids: deleteValues
        })
            .then(({data}) => {
                setDeleteValues([])
                setIsOpenDeleteDialog(false)
                getProfessionsList()
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: 'Ошибка',
                    body: t('error.' + e.response?.data)
                })
                setIsOpenDeleteDialog(false)
            })
    }

    const getCurrentFilterValues = (fieldName: string) => {
        const result = currentFilter.find(item => item.fieldName === fieldName)?.filterValue
        if (!!result) {
            return result
        } else return []
    }


    useEffect(() => {
        if (fetching && isFirstStart) {
            const newLimit = limit + 20
            req
                .post(`/moderator/settings/professions/all?limit=${newLimit}&offset=0`, filterOrSortValues)
                .then(({data}) => {
                    setProfessionsList(data.data)
                    setTotal(data.total)
                    setLimit(newLimit)
                })
                .catch((e) => {
                    interfaceCTX.showMessage({
                        title: 'Ошибка',
                        body: t('error.' + e.response?.data)
                    })
                })
                .finally(() => setFetching(false))
        }

    }, [fetching])

    useEffect(() => {
        getProfessionsList()
        setIsFirstStart(true)
    }, [filterOrSortValues])

    const getProfessionsList = () => {
        setIsLoading(true)
        req.post(`/moderator/settings/professions/all?limit=50&offset=0`, filterOrSortValues)
            .then(({data}) => {
                setProfessionsList(data.data)
                setTotal(data.total)
                setIsLoading(false)
                setLimit(50)
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: 'Ошибка',
                    body: t('error.' + e.response?.data)
                })
            })
    }

    const saveUpdatedProfession = () => {
        let professionData: Partial<ICurrentProfessionItem> = {
            category: currentProfession.category,
            groupsIds: currentProfession.groupsIds,
            isActive: currentProfession.isActive,
            //@ts-ignore
            professionCode: +currentProfession.professionCode,
            professionName: currentProfession.professionName
        }
        if (currentProfession.id) {
            professionData = {...professionData, id: currentProfession.id}
        }

        req.post('/moderator/settings/professions/set', professionData)
            .then(({data}) => {
                getProfessionsList()
                interfaceCTX.checkIsFormChanged(false, 'settings')
                interfaceCTX.showMessage({
                    body: `Профессия успешно сохранена`,
                    icon: 'done'
                })
                setCurrentProfession({} as ICurrentProfessionItem)
                setIsChangingProfession(false)
            })
            .catch((e) => {
                interfaceCTX.showMessage({
                    title: 'Ошибка',
                    body: t('error.' + e.response?.data)
                })
            })
    }

    const scroller = useRef<HTMLInputElement>(null)

    const scrollHandler = (e: React.UIEvent<HTMLElement>) => {
        //@ts-ignore
        if (e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + scroller.current.offsetHeight) < 100) {
            if (!professionsList) return
            if (total > professionsList.length) {
                setFetching(true)
            }
        }
    }

    useAutoCloseDialog(() => {
        setIsMenuFilterOpen('')
        setIsOpenSelectMenu(false)
    }, [])

    useEffect(() => {
        return () => {
            interfaceCTX.checkIsFormChanged(false, 'settings')
        }
    }, [])

    return (
        <div className={'professions-list'}>
            <ProgressPage
                state={true}
                render={() => (
                    <>
                        <div className={'professions-top'}>
                            <div className='sorted-value'>
                                {currentFilter.map((item, index) => {
                                    if (item.sortType !== 'NONE') {
                                        return (
                                            <div className={'sort'} key={item.fieldName + index + 'sort'}>
                                                <div className='sorted-item'>
                                                    <div className='column_name'>{item.title}:</div>
                                                    <div
                                                        className='type'>({item.sortType === 'ASC' ? 'А - Я' : 'Я - А'})
                                                    </div>
                                                </div>
                                                <Icon
                                                    className='cancel'
                                                    icon={'cancel'}
                                                    onClick={() => sortTable(item.title, item.fieldName, item.sortType)}
                                                />
                                            </div>
                                        )
                                    }
                                })}
                                {currentFilter.map((item, index) => {
                                    if (item.filterValue) {
                                        return (
                                            <div className='sort' key={item.fieldName + index + 'filter'}>
                                                <div className='sorted-item'>
                                                    <div className='column_name'>{item.title}:</div>
                                                    <Tooltip
                                                        content={
                                                            <div
                                                                style={{
                                                                    maxWidth: '250px',
                                                                    maxHeight: '100px',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {renderFilterValue(item.filterValue, item.fieldName)}
                                                            </div>
                                                        }
                                                    >
                                                        <div className='type' style={{cursor: 'help'}}>
                                                            {renderFilterValue(item.filterValue, item.fieldName)}
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                                <Icon
                                                    className='cancel'
                                                    icon={'cancel'}
                                                    onClick={() => deleteFilter(item.fieldName)}
                                                />
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <FixedButtons
                                className={'buttons'}
                                length={1}
                                buttons={[
                                    {
                                        label: 'СОХРАНИТЬ  EXCEL',
                                        primary: true,
                                        disabled: isLoadingEXCELDownload,
                                        onClick: () => {
                                            setIsLoadingEXCELDownload(true)
                                            req.post(`/moderator/settings/professions/all`, filterOrSortValues)
                                                .then(({data}) => {
                                                    downloadEXCELTable(data.data, currentFilter, t)
                                                })
                                                .catch((e) => {
                                                    interfaceCTX.showMessage({
                                                        title: 'Ошибка',
                                                        body: t('error.' + e.response?.data)
                                                    })
                                                })
                                                .finally(() => {
                                                    setIsLoadingEXCELDownload(false)
                                                })
                                        }
                                    }
                                ]}
                            />
                        </div>
                        <div className='custom-table-wrapper' ref={scroller} onScroll={scrollHandler}>
                            {fetching && (
                                <div className='loading-wrapper'>
                                    <div className='loading-inner'>
                                        <CircularProgress className='circular-progress'/>
                                        <div>Загрузка</div>
                                    </div>
                                </div>
                            )}
                            <Table className="custom-table">
                                <div className="headings heading custom-table-header"
                                    // style={{minWidth: 'auto'}}
                                >
                                    {headCells.map((cell, index) => {
                                        return (
                                            <div key={cell.id} className={"devider custom-table-header-item"}
                                                 style={{minWidth: cell.minWidth}}>
                                                <div className="table-cell-inner custom-table-header-inner">
                                                    {(index !== 0 && index !== 5) &&
                                                        <div className='expands'>
                                                            <Icon className={`icons expand `}
                                                                  icon={"arrow_drop_up"}
                                                                  style={{color: isSorted(cell.fieldName, 'ASC') ? 'red' : ''}}
                                                                  onClick={(event) => {
                                                                      sortTable(cell.title, cell.fieldName, "ASC")
                                                                  }}/>
                                                            <Icon className={`icons expand `}
                                                                  icon={"arrow_drop_down"}
                                                                  style={{color: isSorted(cell.fieldName, 'DESC') ? 'red' : ''}}
                                                                  onClick={(event) => {
                                                                      sortTable(cell.title, cell.fieldName, 'DESC')
                                                                  }}/>
                                                        </div>
                                                    }
                                                    <div className='name'>{cell.title}</div>
                                                    {(index !== 0 && index !== 5) &&
                                                        <>
                                                            <Icon className={`icons`}
                                                                  icon={"more_horiz"}
                                                                  onClick={(event) => {
                                                                      event.stopPropagation()
                                                                      setIsMenuFilterOpen(prevState => prevState === cell.fieldName ? '' : cell.fieldName)
                                                                  }}/>
                                                            {isMenuFilterOpen === cell.fieldName && !['category', 'isActive'].includes(cell.fieldName) &&
                                                                <MenuFilter
                                                                    isMenuFilterOpen={isMenuFilterOpen}
                                                                    onCloseMenuFilterOpen={() => {
                                                                        setIsMenuFilterOpen('')
                                                                    }}
                                                                    onChangeFilterValues={onChangeFilterValues}
                                                                    uniqueValues={[]}
                                                                    columnTitle={cell.title}
                                                                    columnType={cell.fieldName}
                                                                    currentFilter={getCurrentFilterValues(cell.fieldName)}
                                                                    getApiUrl={'/moderator/settings/professions/all'}
                                                                />
                                                            }
                                                            {isMenuFilterOpen === cell.fieldName && cell.fieldName === 'isActive' &&
                                                                <MenuFilter
                                                                    isMenuFilterOpen={isMenuFilterOpen}
                                                                    onCloseMenuFilterOpen={() => {
                                                                        setIsMenuFilterOpen('')
                                                                    }}
                                                                    onChangeFilterValues={onChangeFilterValues}
                                                                    uniqueValues={['available', 'not_available']}
                                                                    columnTitle={cell.title}
                                                                    columnType={cell.fieldName}
                                                                    currentFilter={getCurrentFilterValues(cell.fieldName)}
                                                                    isWithSearch={false}
                                                                />
                                                            }
                                                            {isMenuFilterOpen === cell.fieldName && cell.fieldName === 'category' &&
                                                                <MenuFilter
                                                                    isMenuFilterOpen={isMenuFilterOpen}
                                                                    onCloseMenuFilterOpen={() => {
                                                                        setIsMenuFilterOpen('')
                                                                    }}
                                                                    onChangeFilterValues={onChangeFilterValues}
                                                                    uniqueValues={['worker', 'itr']}
                                                                    columnTitle={cell.title}
                                                                    columnType={cell.fieldName}
                                                                    currentFilter={getCurrentFilterValues(cell.fieldName)}
                                                                    isWithSearch={false}
                                                                />
                                                            }
                                                        </>
                                                    }
                                                </div>

                                            </div>
                                        )
                                    })}
                                </div>
                                {!currentProfession.id && currentProfession.isCreate &&
                                    renderChangeProfessionRow()
                                }
                                {isLoading
                                    ? <CircularProgress
                                        className='circular-progress'
                                        style={{
                                            position: 'absolute',
                                            top: '290px',
                                            left: '50%'
                                        }}
                                    />
                                    : <>
                                        {professionsList && professionsList.map((profession: IProfessionItem, index) => {
                                            if (profession.id === currentProfession.id) {
                                                return renderChangeProfessionRow()
                                            } else return (
                                                <TableRow type='row' key={`${profession.id} ${index}`}
                                                          style={{minHeight: '65px'}}>
                                                    <TableCell
                                                        devider
                                                        style={{
                                                            minWidth: columns[0].minWidth
                                                        }}
                                                    >
                                                        <Checkbox
                                                            onChange={e => {
                                                                if (deleteValues.includes(profession.id)) {
                                                                    setDeleteValues(prevState => ([...prevState.filter((num: number) => num !== profession.id)]))
                                                                } else {
                                                                    setDeleteValues(prevState => ([...prevState, profession.id]))
                                                                }
                                                            }}
                                                            checked={deleteValues.includes(profession.id)}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        devider
                                                        style={{
                                                            minWidth: columns[1].minWidth
                                                        }}
                                                    >
                                                        <div style={{
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                            wordWrap: 'break-word'
                                                        }}
                                                        >
                                                            {profession.professionName}
                                                        </div>
                                                        <Icon icon={'edit'}
                                                              style={{paddingLeft: '10px', cursor: 'pointer'}}
                                                              onClick={() => {
                                                                  if (interfaceCTX.isFormChanged.settings) {
                                                                      if (window.confirm('Все несохраненные данные будут удалены. Вы уверены?')) {
                                                                          setCurrentProfession({
                                                                              category: profession.category,
                                                                              id: profession.id,
                                                                              isActive: profession.isActive,
                                                                              professionCode: String(profession.professionCode),
                                                                              professionName: profession.professionName,
                                                                              groupsIds: profession.group.map(item => item.id),
                                                                              isCreate: false,
                                                                          })
                                                                          interfaceCTX.checkIsFormChanged(false, 'settings')
                                                                          setIsChangingProfession(true)
                                                                      }
                                                                  } else {
                                                                      setCurrentProfession({
                                                                          category: profession.category,
                                                                          id: profession.id,
                                                                          isActive: profession.isActive,
                                                                          professionCode: String(profession.professionCode),
                                                                          professionName: profession.professionName,
                                                                          groupsIds: profession.group.map(item => item.id),
                                                                          isCreate: false,
                                                                      })
                                                                      setIsChangingProfession(true)
                                                                  }
                                                              }}/>
                                                    </TableCell>
                                                    <TableCell
                                                        devider
                                                        style={{
                                                            minWidth: columns[2].minWidth
                                                        }}
                                                    >
                                                        <Checkbox
                                                            disabled
                                                            checked={profession.isActive}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        devider
                                                        style={{
                                                            minWidth: columns[3].minWidth,
                                                        }}
                                                    >
                                                        {t('menu_filter.' + profession.category)}
                                                    </TableCell>
                                                    <TableCell
                                                        devider
                                                        style={{
                                                            minWidth: columns[4].minWidth
                                                        }}
                                                    >
                                                        <div
                                                            style={{cursor: 'pointer'}}
                                                            onClick={() => {
                                                            }}>
                                                        </div>
                                                        {profession.professionCode}
                                                    </TableCell>
                                                    <TableCell
                                                        devider
                                                        style={{
                                                            minWidth: columns[5].minWidth
                                                        }}
                                                    >
                                                        <div
                                                            style={{cursor: 'pointer'}}
                                                            onClick={() => {
                                                            }}>
                                                        </div>
                                                        {renderGroups(profession.group)}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </>
                                }
                            </Table>
                        </div>
                        <div className={'professions-bottom'}>
                            <FixedButtons
                                className={'buttons'}
                                length={3}
                                buttons={[
                                    {
                                        label: 'СОХРАНИТЬ',
                                        primary: true,
                                        disabled: (!currentProfession?.professionName || !currentProfession?.professionCode),
                                        className: 'button',
                                        onClick: () => {
                                            saveUpdatedProfession()
                                        }
                                    },
                                    {
                                        label: 'ДОБАВИТЬ',
                                        primary: false,
                                        disabled: (!!currentProfession.professionName || !!currentProfession.professionCode) && !currentProfession.id,
                                        hide: isChangingProfession,
                                        className: 'button',
                                        onClick: () => {
                                            if (interfaceCTX.isFormChanged.settings) {
                                                if (window.confirm('Все несохраненные данные будут удалены. Вы уверены?')) {
                                                    setCurrentProfession(defaultProfessionValues)
                                                    interfaceCTX.checkIsFormChanged(false, 'settings')
                                                    setIsChangingProfession(true)
                                                }
                                            } else {
                                                setCurrentProfession(defaultProfessionValues)
                                                setIsChangingProfession(true)
                                            }
                                        }
                                    },
                                    {
                                        label: 'ОТМЕНИТЬ',
                                        primary: false,
                                        hide: !isChangingProfession,
                                        className: 'button',
                                        onClick: () => {
                                            if (window.confirm('Все несохраненные данные будут удалены. Вы уверены?')) {
                                                setCurrentProfession({} as ICurrentProfessionItem)
                                                interfaceCTX.checkIsFormChanged(false, 'settings')
                                                setIsChangingProfession(false)
                                            }
                                        }
                                    },
                                    {
                                        label: 'Удалить',
                                        primary: false,
                                        disabled: !deleteValues.length,
                                        hide: !deleteValues.length,
                                        className: 'button',
                                        outlined: false,
                                        onClick: () => {
                                            setIsOpenDeleteDialog(true)
                                        }
                                    }
                                ]}
                            />
                        </div>
                        <CustomDialog
                            isOpen={isOpenDeleteDialog}
                            onClose={() => setIsOpenDeleteDialog(false)}
                            dialogTitle={'Удалить профессии?'}
                            buttons={[{
                                callback: () => setIsOpenDeleteDialog(false),
                                outlined: true,
                                raised: false,
                                text: 'Нет'
                            }, {
                                callback: deleteSelectedValues,
                                outlined: true,
                                raised: true,
                                text: 'Да'
                            }]}
                        >
                            <div className={'professions-dialog-description'}>
                                Вы уверены, что хотите удалить выбранные профессии?
                            </div>
                        </CustomDialog>
                    </>
                )}
            />
        </div>

    )
}