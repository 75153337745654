import {useTranslation} from "react-i18next";
import React, {MutableRefObject} from "react";
import {UserContext} from "../../global/context.user";
import {InterfaceContext} from "../../global/context.interface";
import {add, endOfISOWeek, format, startOfISOWeek, sub} from "date-fns";
import {
	Brigade,
	Foreman,
	Shift,
	ShiftListRes,
	BrigadeRender
} from "./BrigadeRender";
import {authorName, req} from "../../global/common";
import {PageTitle, WeekScroller, FixedButtons, Form, SpanButtons, SelectOption} from "components";
import Select from "react-select";
import {ListDivider} from "@rmwc/list";
import {SimpleDialog} from "@rmwc/dialog";
import './TimeKeeping.style.sass'


export default function TimeKeepingPage() {
	const {t} = useTranslation('common')
	const userCTX = React.useContext(UserContext)
	const interfaceCTX = React.useContext(InterfaceContext)
	const [page, setPage] = React.useState(0)
	let organizationID = userCTX.user?.organisation.id
	let request = ''

	const [objects, setObjects] = React.useState(null as unknown as SelectOption[])

	const [refresh, setRefresh] = React.useState<number>()
	const refreshF = () => setRefresh(Date.now())

	const [currentDay, setCurrentDay] = React.useState(format(new Date(), 'dd.MM.yyyy'))
	const [shiftsArray, setShiftsArray] = React.useState<Shift[]>([])
	const [shift, setShift] = React.useState<Brigade[] | null>([])
	const [filter, setFilter] = React.useState('all')
	const [foremen, setForemen] = React.useState([] as Foreman[])
	const [openDialog, setOpenDialog] = React.useState({
		openShift: false,
		closeShift: false
	})


	const shiftFormRef = React.createRef() as MutableRefObject<HTMLFormElement>

	function submitShiftForm(data: any) {
		if (request == 'new-shift') {
			req
				.post(`/shift/${currentDay}/admin/${request}?foremanID=${data.foreman.value}`)
				.then(() => {
					interfaceCTX.showMessage({
						body: `Смены для руководителя "${data.foreman.label}" - открыты`,
						icon: 'done'
					})
					refreshF()
				})
				.catch(e => {
					interfaceCTX.showMessage({
						body: t(e.response.data),
						icon: 'report'
					})
				})
		} else {
			req
				.delete(`/shift/${currentDay}/admin/${request}?foremanID=${data.foreman.value}`)
				.then(() => {
					interfaceCTX.showMessage({
						body: `Смены для руководителя "${data.foreman.label}" - удалены`,
						icon: 'done'
					})
					refreshF()
				})
				.catch(e => {
					interfaceCTX.showMessage({
						body: t(e.response.data),
						icon: 'report'
					})
				})
		}
	}

	function openNewShift() {
		request = 'new-shift'
		shiftFormRef?.current?.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}))
	}

	function closeShift() {
		request = 'delete-shift'
		shiftFormRef?.current?.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}))
	}

	function getForemenList() {
		let query = {
			organizationID: organizationID,
			limit: 999,
			offset: 0,
			role: 'foreman'
		}

		req
			.get(`/organisation/${organizationID}/staff`, query)
			.then(({data}) => {
				let staffListOptions = data.data.map((staffItem: any, index: number) => {
					let options = {
						label: authorName(staffItem),
						value: staffItem.id
					}
					return options
				})
				setForemen(staffListOptions)
			})
			.catch(() =>
				interfaceCTX.showMessage({
					body: `Не удалось загрузить данные. Обратитесь к администратору`,
					icon: 'report'
				})
			)
	}

	React.useEffect(() => {
		const query = {
			dateStart: format(startOfISOWeek(sub(new Date(), {weeks: 1 - page})), 'dd.MM.yyyy'),
			dateEnd: format(endOfISOWeek(add(new Date(), {weeks: 1 + page})), 'dd.MM.yyyy')
		}
		req.get('/shift/list', query).then(({data}: { data: ShiftListRes }) => setShiftsArray(data.data))
	}, [page, refresh])

	React.useEffect(() => {
		// setShift(null)
		if (!currentDay) return
		req
			.get(`/shift/${currentDay}`)
			.then(({data}) => {
				const arr = []
				const resData = (data.data as any[]).filter(v => v.brigades.length)
				for (const dt of resData) {
					for (const rawBrigade of dt.brigades) {
						const brigade = new Brigade(rawBrigade, dt.date)
						if (filter !== 'all') {
							if (brigade.shiftType !== filter) continue
						}
						arr.push(brigade)
						// Cортировка по имени бригады
						arr.sort((prev, next) =>
							prev.object! && next.object! && authorName(prev.object.responsible) > authorName(next.object.responsible)
								? 1
								: -1
						)
						// console.log(arr)
					}
				}

				setShift(arr)
			})
			.catch(e => {
				interfaceCTX.showMessage({
					body: t(e.response?.data),
					icon: 'report'
				})
			})
		req
			.get(`/objects/list?date=${currentDay}&limit=0&offset=0`)
			.then(({data}) =>
				setObjects(
					data.data.map((object: any) => {
						return {
							label: object.name,
							value: object.id
						}
					})
				)
			)
			.catch(e => {
				interfaceCTX.showMessage({
					body: t(e.response.data),
					icon: 'report'
				})
			})
	}, [refresh, filter])

	React.useEffect(() => {
		setShift(null)
		if (!currentDay) return
		req
			.get(`/shift/${currentDay}`)
			.then(({data}) => {
				const arr = []
				const resData = (data.data as any[]).filter(v => v.brigades.length)
				for (const dt of resData) {
					for (const rawBrigade of dt.brigades) {
						const brigade = new Brigade(rawBrigade, dt.date)
						if (filter !== 'all') {
							if (brigade.shiftType !== filter) continue
						}
						arr.push(brigade)
						// Cортировка по имени бригады
						arr.sort((prev, next) =>
							prev.object! && next.object! && authorName(prev.object.responsible) > authorName(next.object.responsible)
								? 1
								: -1
						)
						// console.log(arr)
					}
				}

				setShift(arr)
			})
			.catch(e => {
				interfaceCTX.showMessage({
					body: t(e.response?.data),
					icon: 'report'
				})
			})
		req
			.get(`/objects/list?date=${currentDay}&limit=0&offset=0`)
			.then(({data}) =>
				setObjects(
					data.data.map((object: any) => {
						return {
							label: object.name,
							value: object.id
						}
					})
				)
			)
			.catch(e => {
				interfaceCTX.showMessage({
					body: t(e.response.data),
					icon: 'report'
				})
			})
	}, [currentDay])

	React.useEffect(() => {
		getForemenList()
	}, [])

	return (
		<div className={'time-keeping-page'}>
			<PageTitle
				title={
					<>
						<h2>Учёт рабочего времени</h2>
						<Select
							className={'shift-select-filter'}
							classNamePrefix={'select'}
							placeholder={''}
							onChange={v => setFilter(v!.value)}
							isSearchable={false}
							defaultValue={{label: 'Все', value: 'all'}}
							options={[
								{label: 'Все', value: 'all'},
								{label: 'Дневная', value: 'day'},
								{label: 'Ночная', value: 'night'}
							]}
						/>
					</>
				}
			>
				<SpanButtons
					data={[
						{
							label: format(new Date(), 'dd.MM.yyyy'),
							onClick: () => {
								setPage(0)
								setCurrentDay(format(new Date(), 'dd.MM.yyyy'))
							}
						}
					]}
				/>
			</PageTitle>
			<ListDivider/>
			<WeekScroller
				currentDay={currentDay}
				currentPage={page}
				shifts={shiftsArray}
				onSetCurrentDay={setCurrentDay}
				onSetCurrentPage={setPage}
			/>
			{userCTX.userHasRole('admin') && (
				<div className={'close-open-shift-wrapper'}>
					<FixedButtons
						length={1}
						buttons={[
							{
								label: 'Открыть смены',
								primary: true,
								onClick: () => {
									setOpenDialog({
										...openDialog,
										openShift: true
									})
								}
							}
						]}
					/>
					<Form
						name={'addOrCloseShiftByAdmin'}
						useRef={shiftFormRef}
						onSubmit={submitShiftForm}
						viewMode={false}
						data={[
							[
								{
									values: [
										{
											type: 'select',
											title: 'Непосредственный руководитель',
											var: 'foreman',
											options: foremen,
											validate: {required: true}
										}
									]
								}
							]
						]}
					/>
					<FixedButtons
						length={1}
						buttons={[
							{
								label: 'Удалить смены',
								primary: false,
								onClick: () =>
									setOpenDialog({
										...openDialog,
										closeShift: true
									})
							}
						]}
					/>
				</div>
			)}
			{shift &&
				shift.map((brigade: any, index) => {
					return (
						<BrigadeRender
							key={brigade.id + currentDay + index}
							brigade={brigade}
							objects={objects}
							currentDay={currentDay}
							refreshPage={setRefresh}
							refresh={!!refresh}
						/>
					)
				})}

			<SimpleDialog
				open={openDialog.closeShift}
				body={
					<div>
						Вы уверены, что хотите удалить смены?
						<br/>
						Это приведет к удалению данных по трекингу времени
					</div>
				}
				cancelLabel={'Нет'}
				acceptLabel={'Да'}
				onClosed={e => {
					if (e.detail.action === 'accept') {
						closeShift()
						setOpenDialog({
							...openDialog,
							closeShift: false
						})
					} else {
						setOpenDialog({
							...openDialog,
							closeShift: false
						})
					}
				}}
			/>
			<SimpleDialog
				open={openDialog.openShift}
				body={`Вы уверены, что хотите открыть смены?`}
				cancelLabel={'Нет'}
				acceptLabel={'Да'}
				onClosed={e => {
					if (e.detail.action === 'accept') {
						openNewShift()
						setOpenDialog({
							...openDialog,
							openShift: false
						})
					} else {
						setOpenDialog({
							...openDialog,
							openShift: false
						})
					}
				}}
			/>
		</div>
	)
}


