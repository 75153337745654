import React, {FormEvent, useEffect, useState} from 'react'
import {IArrayOfValues, IDialogFilter} from './MenuFilter.types'
import './MenuFilter.style.sass'
import {TextField} from '@rmwc/textfield'
import {Icon} from '@rmwc/icon'
import {newDateValues} from './MenuFilter.service'
import {Tooltip} from '@rmwc/tooltip'
import {useDebounceEffect} from 'custom-hooks'
import {Checkbox} from '@rmwc/checkbox'
import {Button} from '@rmwc/button'
import {req} from 'global/common'
import {useTranslation} from 'react-i18next'
import {ProgressPage} from 'components'

export const MenuFilter: React.FC<IDialogFilter> = ({
                                                        isVisibleSettingsOpen,
                                                        columnSettings,
                                                        toggleIsVisibleColumn,
                                                        columnType,
                                                        columnTitle,
                                                        onChangeFilterValues,
                                                        filterType,
                                                        getUniqueColumnValuesURL,
                                                        customFilterValues,
                                                        columnLang,
                                                        toggleIsVisibleSettings
                                                    }) => {
    const [dateValues, setDateValues] = useState<string[]>(
        filterType && columnType.toLowerCase().includes('date')
            ? newDateValues(filterType)
            : ['', '']
    )
    const [textValue, setTextValue] = useState<string>('')
    const {t} = useTranslation('common')
    const [filterValues, setFilterValues] = useState<string[]>(!!filterType ? filterType : [])
    const [isSelectAllFilterValues, setIsSelectAllFilterValues] = useState(false)
    const [arrayOfValues, setArrayOfValues] = useState<IArrayOfValues>(null as unknown as IArrayOfValues)
    const [isLoaded, setIsLoaded] = useState(false)

    const checkIsLast = () => {
        let count: number = 0
        let order: number = 99
        for (const column of columnSettings) {
            if (column.visibility) {
                count += 1
                order = column.sortOrder
            }
        }
        return {order, isLast: count === 1}
    }

    const selectAllFilterValues = (isSelected: boolean) => {
        if (isSelected) {
            setFilterValues(arrayOfValues.statics)
            setIsSelectAllFilterValues(true)
        } else if (!isSelected) {
            setFilterValues([])
            setIsSelectAllFilterValues(false)
        }
    }

    React.useEffect(() => {
        if (columnType !== 'contractBeginDate' && columnType !== 'contractEndDate') {
            req
                .get(`${getUniqueColumnValuesURL}?type=${columnType}`)
                .then(({data}) => {
                    setArrayOfValues((prevState: IArrayOfValues) => ({
                        ...prevState,
                        statics: data.data.filter((item: any) => !!item),
                        mutable: data.data.filter((item: any) => !!item)
                    }))
                    setIsLoaded(true)
                })
                .catch(() => {
                })
        }
    }, [])

    const searchFilterValues = (value: string) => {
        if (value !== '') {
            setArrayOfValues((prevState: IArrayOfValues) => ({
                ...prevState,
                mutable: prevState.statics.filter(item => !!item && item.toLowerCase().includes(value.toLowerCase()))
            }))
        } else {
            setArrayOfValues((prevState: IArrayOfValues) => ({
                ...prevState,
                mutable: prevState.statics
            }))
        }
    }

    const renderFilterValue = (item: string) => {
        if (customFilterValues[columnType]) {
            return customFilterValues[columnType](item)
        } else if (columnLang) {
            return t('extended_custom_table.' + item)
        } else return item
    }

    useDebounceEffect(() => {
        if (!columnType.toLowerCase().includes('date') && arrayOfValues) {
            searchFilterValues(textValue)
        }
    }, 300, [textValue])

    if (isVisibleSettingsOpen) {
        return (
            <div
                className={`dialog-filter${checkIsLast().isLast ? '-last' : ''}`}
                onClick={event => {
                    event.stopPropagation()
                }}
            >
                <div className='dialog-filter-input'>
                    {!columnType.toLowerCase().includes('date') && !columnLang && !customFilterValues[columnType] && (
                        <TextField
                            value={textValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value
                                setTextValue(value)
                            }}
                            outlined
                            type='text'
                            style={{width: '100%'}}
                            icon='search'
                            maxLength={columnType === 'countTime' || columnType === 'countShifts' ? 5 : 160}
                        />
                    )}
                    {columnType.toLowerCase().includes('date') &&
                        <div className='dialog-date'>
                            <div className='dialog-date-fields'>
                                <TextField
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e.target.value.length > 10) return
                                        setDateValues((prevState: string[]) => {
                                            const newState = [...prevState]
                                            newState[0] = e.target.value
                                            return newState
                                        })
                                    }}
                                    value={dateValues[0]}
                                    outlined
                                    placeholder='ДД.ММ.ГГГГ'
                                    type='date'
                                />
                                <TextField
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e.target.value.length > 10) return
                                        setDateValues((prevState: string[]) => {
                                            const newState = [...prevState]
                                            newState[1] = e.target.value
                                            return newState
                                        })
                                    }}
                                    value={dateValues[1]}
                                    outlined
                                    placeholder='ДД.ММ.ГГГГ'
                                    type='date'
                                />
                            </div>
                            <Tooltip content='Фильтровать'>
                                <Icon
                                    className='icon filter_alt'
                                    icon={'filter_alt'}
                                    onClick={() => {
                                        if (dateValues[0].length > 0 && dateValues[1].length > 0) {
                                            const newValues = []
                                            newValues[0] = [
                                                dateValues[0].split('-')[2],
                                                dateValues[0].split('-')[1],
                                                dateValues[0].split('-')[0]
                                            ].join('.')
                                            newValues[1] = [
                                                dateValues[1].split('-')[2],
                                                dateValues[1].split('-')[1],
                                                dateValues[1].split('-')[0]
                                            ].join('.')
                                            onChangeFilterValues(columnType, columnTitle, newValues)
                                        }
                                    }}
                                />
                            </Tooltip>
                        </div>
                    }
                </div>
                {!columnType.toLowerCase().includes('date') && (
                    <div className='dialog-filter-list'>
                        <div className='items'>
                            <div className='item'>
                                <Checkbox
                                    className={isSelectAllFilterValues ? 'horosiy-checkbox' : 'off-checkbox'}
                                    checked={isSelectAllFilterValues}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                        selectAllFilterValues(!isSelectAllFilterValues)
                                    }}
                                />
                                <div className='item-text'>Выбрать все</div>
                            </div>
                            <ProgressPage
                                state={arrayOfValues}
                                render={() => (
                                    <>
                                        {arrayOfValues.mutable &&
                                            arrayOfValues.mutable.map(item => {
                                                return (
                                                    <div className='item' key={item}>
                                                        <Checkbox
                                                            key={item}
                                                            className={filterValues.includes(item) ? 'horosiy-checkbox' : 'off-checkbox'}
                                                            checked={filterValues ? filterValues.includes(item) : false}
                                                            onChange={(e: FormEvent<HTMLInputElement>) => {
                                                                setFilterValues((prevState: string[]) => {
                                                                    let alreadyHas = !!prevState && prevState.find(value => value === item)
                                                                    let newState: string[] = []
                                                                    if (alreadyHas) {
                                                                        newState = [...prevState.filter(value => value !== item)]
                                                                        setIsSelectAllFilterValues(false)
                                                                    } else {
                                                                        newState = [...prevState, item]
                                                                    }
                                                                    return newState
                                                                })
                                                            }}
                                                        />
                                                        <div className='item-text'>
                                                            {renderFilterValue(item)}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </>
                                )}
                            />
                        </div>
                        <div className='dialog-buttons'>
                            <div
                                className='dialog-btn'
                                onClick={() => {
                                    toggleIsVisibleSettings('none')
                                    onChangeFilterValues(columnType, columnTitle, filterValues.length > 0 ? filterValues : null)
                                }}
                            >
                                <Icon className='done' icon={'done'}/>
                                <div>ПРИМЕНИТЬ</div>
                            </div>
                            <div
                                className='dialog-btn'
                                onClick={() => {
                                    setFilterValues([])
                                    onChangeFilterValues(columnType, columnTitle, null)
                                }}
                            >
                                <Icon className='close' icon={'close'}/>
                                <div>ОТМЕНИТЬ</div>
                            </div>
                        </div>
                    </div>
                )}
                {!checkIsLast().isLast && (
                    <div
                        className='dialog-button'
                        onClick={() => {
                            toggleIsVisibleColumn(false, columnType)
                        }}
                    >
                        <Icon className='icon visibility_off' icon={'visibility_off'}/>
                        <div>СКРЫТЬ СТОЛБЕЦ</div>
                    </div>
                )}
            </div>
        )
    } else return null
}
