import { ProgressPage, FixedButtons } from 'components'
import { req } from 'global/common'
import { InterfaceContext } from 'global/context.interface'
import { UserContext } from 'global/context.user'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { IWhoWorkerWriteSettings } from './WhoWorkerWrite.types'
import { sendMessageSettingOptions } from './WhoWorkerWrite.service'
import './WhoWorkerWrite.style.sass'

export function WhoWorkerWrite() {
	const interfaceCTX = React.useContext(InterfaceContext)
	const userCTX = React.useContext(UserContext)
	const [settings, setSettings] = useState<IWhoWorkerWriteSettings | null | any>(null)
	const { t } = useTranslation('common')

	useEffect(() => {
		req
			.get(`/requests/organisation/send-message-settings/get?organisationId=${userCTX.user?.organisation.id}`)
			.then(({ data }) => {
				setSettings({
					toEmploymentDecisionMaker: data.toEmploymentDecisionMaker,
					toResponsibleForMobilization: data.toResponsibleForMobilization,
					toResponsibleForThePreparationOfPersonnelDocuments: data.toResponsibleForThePreparationOfPersonnelDocuments,
					toSupervisor: data.toSupervisor
				})
			})
			.catch(e => {
				interfaceCTX.showMessage({
					title: 'Ошибка',
					body: t(e.response.data),
					icon: 'report'
				})
			})
		return () => {
			interfaceCTX.checkIsFormChanged(false, 'settings')
		}
	}, [])

	const saveSettings = (payload: IWhoWorkerWriteSettings) => {
		req
			.post('/requests/organisation/send-message-settings/update', payload)
			.then(({ data }) => {
				interfaceCTX.checkIsFormChanged(false, 'settings')
				interfaceCTX.showMessage({
					body: `Настройки успешно сохранены`,
					icon: 'done'
				})
			})
			.catch(e => {
				interfaceCTX.showMessage({
					title: 'Ошибка',
					body: t(e.response.data),
					icon: 'report'
				})
			})
	}

	return (
		<div className='who-worker-write'>
			<ProgressPage
				state={settings}
				render={() => (
					<div className='who-worker-write-inner'>
						<div className='description'>
							<div className='inner'>
								<div className='description-title'>Настройка получения сообщений ответственным лицом от Работника</div>
							</div>
						</div>
						<div className='who-worker-write-settings'>
							<div className='who-worker-write-item'>
								<div className='who-worker-write-item-inner'>
									<div className='who-worker-write-item-header'>Непосредственный руководитель</div>
									<Select
										className='who-worker-write-item-select'
										classNamePrefix={'select'}
										noOptionsMessage={() => 'Нет опций'}
										placeholder={''}
										defaultValue={{
											value: settings?.toSupervisor,
											label: t(('message_setting_options.' + settings?.toSupervisor) as string)
										}}
										//@ts-ignore
										options={sendMessageSettingOptions}
										onChange={e => {
											interfaceCTX.checkIsFormChanged(true, 'settings')
											setSettings((prevState: IWhoWorkerWriteSettings) => ({
												...prevState,
												toSupervisor: e?.value
											}))
										}}
									/>
								</div>
							</div>
							<div className='who-worker-write-item'>
								<div className='who-worker-write-item-inner'>
									<div className='who-worker-write-item-header'>Ответственный за мобилизацию</div>
									<Select
										className='who-worker-write-item-select'
										classNamePrefix={'select'}
										noOptionsMessage={() => 'Нет опций'}
										placeholder={''}
										defaultValue={{
											value: settings?.toResponsibleForMobilization,
											label: t(('message_setting_options.' + settings?.toResponsibleForMobilization) as string)
										}}
										//@ts-ignore
										options={sendMessageSettingOptions}
										onChange={evt => {
											interfaceCTX.checkIsFormChanged(true, 'settings')
											setSettings((prevState: IWhoWorkerWriteSettings) => ({
												...prevState,
												toResponsibleForMobilization: evt?.value
											}))
										}}
									/>
								</div>
							</div>
							<div className='who-worker-write-item'>
								<div className='who-worker-write-item-inner'>
									<div className='who-worker-write-item-header'>Ответственный за оформление кадровых документов</div>
									<Select
										className='who-worker-write-item-select'
										classNamePrefix={'select'}
										noOptionsMessage={() => 'Нет опций'}
										placeholder={''}
										defaultValue={{
											value: settings?.toResponsibleForThePreparationOfPersonnelDocuments,
											label: t(
												('message_setting_options.' +
													settings?.toResponsibleForThePreparationOfPersonnelDocuments) as string
											)
										}}
										//@ts-ignore
										options={sendMessageSettingOptions}
										onChange={evt => {
											interfaceCTX.checkIsFormChanged(true, 'settings')
											setSettings((prevState: IWhoWorkerWriteSettings) => ({
												...prevState,
												toResponsibleForThePreparationOfPersonnelDocuments: evt?.value
											}))
										}}
									/>
								</div>
							</div>
							<div className='who-worker-write-item'>
								<div className='who-worker-write-item-inner'>
									<div className='who-worker-write-item-header'>Принимающий решение о трудоустройстве</div>
									<Select
										className='who-worker-write-item-select'
										classNamePrefix={'select'}
										noOptionsMessage={() => 'Нет опций'}
										placeholder={''}
										defaultValue={{
											value: settings?.toEmploymentDecisionMaker,
											label: t(('message_setting_options.' + settings?.toEmploymentDecisionMaker) as string)
										}}
										//@ts-ignore
										options={sendMessageSettingOptions}
										onChange={evt => {
											interfaceCTX.checkIsFormChanged(true, 'settings')
											setSettings((prevState: IWhoWorkerWriteSettings) => ({
												...prevState,
												toEmploymentDecisionMaker: evt?.value
											}))
										}}
									/>
								</div>
							</div>
						</div>
						<FixedButtons
							className='who-worker-write-button'
							length={4}
							buttons={[
								{
									label: 'Сохранить',
									primary: true,
									onClick: () => {
										saveSettings(settings as IWhoWorkerWriteSettings)
									}
								}
							]}
						/>
					</div>
				)}
			/>
		</div>
	)
}
