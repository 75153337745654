import clsx from 'clsx'
import { FC } from 'react'
import { InfoBlockProps } from './InfoBlock.types'
import './InfoBlock.style.sass'

export const InfoBlock: FC<InfoBlockProps> = ({ className, title, content, viewMode = false, style }) => {
	return (
		<div className={clsx('info-block-wrapper', className)}>
			<div className={clsx(viewMode ? 'info-block-content' : 'info-block-title')} style={style ? style : {}}>
				{title}
			</div>
			<div className='info-block-content'>{content}</div>
		</div>
	)
}
