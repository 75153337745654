import { INeedTemplateDataRow } from "./NeedTemplate.types";

export const dataColumns: INeedTemplateDataRow[][] =
    [
        [
            {
                title: "Номер заявки",
                name: "number",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Дата заявки",
                name: "date",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Наименование организации",
                name: "organization",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Должность кандидата",
                name: "position",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Количество вакансий",
                name: "quantity",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Разряд",
                name: "grade",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "Категория",
                name: "category",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "Подразделение, в котором открыта вакансия",
                name: "subdivision",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            // {
            //     title: "Непосредственный руководитель кандидата",
            //     name: "supervisor",
            //     isAbleToBeHidden: false,
            //     isAbleToBeUnRequired: false
            // },
            // {
            //     title: "Ответственный за мобилизацию",
            //     name: "organizer",
            //     isAbleToBeHidden: false,
            //     isAbleToBeUnRequired: false
            // },
            {
                title: "Количество и наименование должностей в подчинении кандидата",
                name: "subordinates",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "Предполагаемый срок действия договора",
                name: "period",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Ответственный за оформление кадровых документов",
                name: "responsibleForThePreparationOfPersonnelDocuments",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "Ответственный за мобилизацию",
                name: "organizerp",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Непосредственный руководитель кандидата",
                name: "supervisorз",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Принимающий решение о трудоустройстве",
                name: "employmentDecisionMaker",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            // {
            //     title: "Кто проводит собеседование",
            //     name: "interviewers",
            //     isAbleToBeHidden: true,
            //     isAbleToBeUnRequired: true
            // },
        ],
        [
            {
                title: "Функциональные обязанности",
                name: "responsibilities",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "Адрес места работы",
                name: "address",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "Место производства работ",
                name: "city",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "График работы",
                name: "schedule",
                isAbleToBeHidden: false,
                isAbleToBeUnRequired: false
            },
            {
                title: "Испытательный срок",
                name: "probation",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            // {
            //     title: "Период испытательного срока, месяцев",
            //     name: "probationDuration",
            //     isAbleToBeHidden: true,
            //     isAbleToBeUnRequired: true
            // },
            {
                title: "Заработная плата (на руки), руб",
                name: "salaryFullNet",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            // {
            //     title: "Заработная плата на испытательный срок (на руки), руб",
            //     name: "salaryProbationNet",
            //     isAbleToBeHidden: true,
            //     isAbleToBeUnRequired: true
            // },
            {
                title: "Возраст",
                name: "age",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "Пол",
                name: "gender",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "Образование",
                name: "education",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "Причины появления потребности",
                name: "hiringReason",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
            {
                title: "Дополнительная информация",
                name: "additionalComments",
                isAbleToBeHidden: true,
                isAbleToBeUnRequired: true
            },
        ]
    ]


export class DataToSend {
    required: {
        [key: string]: boolean
    }
    hidden: {
        [key: string]: boolean
    }

    constructor(data: any) {
        this.required = {}
        this.hidden = {}
        let availableFields: any[] = []
        dataColumns.map(column => availableFields = availableFields.concat(column.filter(field => field.isAbleToBeHidden && field.isAbleToBeHidden)))
        availableFields.map(field => {
            this.required[field.name] = data[field.name + "_required"]
            this.hidden[field.name] = !data[field.name + "_hidden"]
        })
    }
}