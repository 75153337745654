import CreateObjectPage from 'pages/create-object/create-object'
import Documents from 'pages/documents-page/documents-page'
import Objects from 'pages/objects/objects'
import { Dump } from 'pages/public-worker-profile/dump/dump'
import { PublicWorkerProfilePage } from 'pages/public-worker-profile/public-worker-profile'
import { WorkerCardPage } from 'pages/WorkerCardPage/WorkerCardPage'
import { WorkerExperiensePage } from 'pages/WorkerExperiencePage/WorkerExperiencePage'
import { Route } from './routes-def'
import { initialWebRoles, PagesRoutes } from './routes-utils'

export const objectsRoutes: Route[] = [
	//Объекты
	{
		title: 'Объекты',
		path: PagesRoutes.OBJECTS,
		component: <Objects />,
		navBar: true,
		icon: 'maps_home_work',
		availableRoles: initialWebRoles
	},
	{
		title: 'Создание объекта',
		path: PagesRoutes.OBJECTS + PagesRoutes.CREATE_OBJECT + '/:searchID?',
		component: <CreateObjectPage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Публичный профиль',
		path: PagesRoutes.OBJECTS + PagesRoutes.PUBLIC_PROFILE + '/:searchID?/:recordID?',
		component: <PublicWorkerProfilePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Опыт сотрудничества',
		path: PagesRoutes.OBJECTS + PagesRoutes.PUBLIC_PROFILE + '/exp/:searchID?/:recordID?',
		component: <WorkerExperiensePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Карточка сотрудника',
		path: PagesRoutes.OBJECTS + PagesRoutes.WORKER_CARD + '/:searchID?/:recordID?',
		component: <Dump />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Опыт сотрудничества',
		path: PagesRoutes.OBJECTS + PagesRoutes.WORKER_CARD + '/exp/:searchID?/:recordID?',
		component: <WorkerExperiensePage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Карточка договора',
		path: PagesRoutes.OBJECTS + PagesRoutes.CONTRACT_CARD + '/:searchID?',
		component: <WorkerCardPage />,
		navBar: false,
		availableRoles: initialWebRoles
	},
	{
		title: 'Документы',
		path: '/documents',
		component: <Documents />,
		navBar: true,
		icon: 'folder',
		availableRoles: initialWebRoles
	}
	// {
	// 	// 	title: 'Отчёт',
	// 	// 	path: '/report',
	// 	// 	component: <ReportPage />,
	// 	// 	navBar: true,
	// 	// 	icon: 'list_alt',
	// 	// 	availableRoles: initialWebRoles
	// 	// },
]
