import { differenceInYears, parse } from 'date-fns'
import { WorkerProfileFilling } from 'global/definitions'

export const isAllProfileIsFilling = ({ docs, experience, personal }: WorkerProfileFilling) => {
	return docs + experience + personal === 3
}

export const checkInputedAge = (date: string, userData: any = {}, ageMin: number = 18, ageMax: number = 65) => {
	if (date === '') return true
	
	const dateParsed = parse(date, 'dd.MM.yyyy', new Date())
	const today = new Date()

	if (dateParsed) {
		return differenceInYears(today, dateParsed) >= ageMin && differenceInYears(today, dateParsed) <= ageMax
			? true
			: false
	} else {
		return true
	}
}

export const reformatToMask = (tel: string = '') => {
	if (tel != null) {
		tel = tel.replace(/[^\d]/g, '')
		let newTel = tel.split('')

		return newTel
			.map((number: string, index: number) => {
				if (index == 0) {
					return '+' + number
				}
				if (index == 1) {
					return ' (' + number
				}
				if (index == 4) {
					return ') ' + number
				}
				if (index == 7 || index == 9) {
					return ' ' + number
				}
				return number
			})
			.join('')
	}
}
