import {IRoomMeta} from "./messages-page-def";
import {MatrixClient, MatrixEvent, Room} from "matrix-js-sdk";

export const download = (url: string, fullname: string) => {
    let [ext, ...name] = fullname.split('.').reverse();

    fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `${name.reverse().join('.')}.${ext}`);
            document.body.appendChild(link);
            link.click();
        });
};

export const readLastMessage = (client: MatrixClient | null, roomId: string) => {
    if (client == null) return
    const currentRoom = client?.getRoom(roomId)
    if (!!currentRoom && !currentRoom.getUnreadNotificationCount()) return;
    const currentTimeline = currentRoom?.timeline

    if (currentTimeline == null) return;
    let event = new MatrixEvent({
        room_id: roomId,
        event_id: currentTimeline[currentTimeline?.length - 1].event.event_id,
    });
    client.sendReadReceipt(event)
}

export const parsedRoomsData = async (list: Room[], client: MatrixClient | null,) => {
    let roomMeta: IRoomMeta[] = [];
    for (const room of list) {
        let lastMessage = "";
        let lastMessageTS: number | undefined = undefined
        //@ts-ignore
        let otherUser: any | undefined = undefined
        let otherUserId = room.getMembers().filter(item => item.userId !== client?.getUserId())[0]?.userId
        await room.currentState.getMembers().forEach(user => {
            if (user.userId == client?.getUserId()) return;
            otherUser = {
                userId: otherUserId,
                isDeleted: false,
                avatarUrl: user.getAvatarUrl(
                    client?.getHomeserverUrl() as string, 390, 390, "scale", false, true),
                displayName: user.rawDisplayName,
            };
        });

        room.timeline
            .slice()
            .reverse()
            .forEach(msg => {
                if (lastMessage !== "") return;
                lastMessageTS = msg.getTs();
                if (msg.getType() !== "m.room.message") return;

                const roomMessageType = msg.getContent().msgtype;
                if (roomMessageType === "m.image") lastMessage = "Файл";
                else if (roomMessageType === "m.file") lastMessage = "Файл";
                //@ts-ignore
                else if (msg.event.content && msg.event.content.body) lastMessage = msg.event.content.body
                if (msg.event.content && !!msg.event.content['m.new_content']) lastMessage = ''
                if (msg.event.content && !msg.event.content.body) lastMessage = ''
            });

        roomMeta.push({
            count: room.getUnreadNotificationCount() as number,
            roomId: room.roomId,
            name: otherUser?.displayName,
            isDeleted: otherUser.isDeleted,
            userId: otherUser.userId,
            modified: lastMessageTS,
            isIgnored: !!room.tags.ignore,
            //@ts-ignore
            invite: room.hasMembershipState(client.getUserId(), "invite") ? room.getDMInviter() : "",
            lastMessage,
            //@ts-ignore
            isArchiveOrder: !!room.tags.archive?.order,
            //@ts-ignore
            isInvite: room.hasMembershipState(client.getUserId(), "invite"),
            getAvatar: otherUser?.avatarUrl,
        });


    }
    return roomMeta.sort((a: IRoomMeta, b: IRoomMeta) => {
        if (a.modified && b.modified) {
            if (a.modified < b.modified) return 1;
            if (a.modified > b.modified) return -1;
        }
        return 0;
    });
}