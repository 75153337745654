import { objectsRoutes } from './objects-routes'
import { chatRoutes } from './chat-routes'
import { commonRoutes } from './common-routes'
import { moderatorRoutes } from './moderator-routes'
import { needListRoutes } from './need-list'
import { workerRoutes } from './workers-routes'
import { userRoutes } from './users-routes'
import { workerProfileRoutes } from './worker-profile-routes'

export * from './routes-def'
export * from './routes-utils'
export const routes = [
	...commonRoutes,
	...moderatorRoutes,
	...needListRoutes,
	...workerRoutes,
	...chatRoutes,
	...objectsRoutes,
	...userRoutes,
	...workerProfileRoutes
]
