import { PageTitle, ProgressPage } from 'components'
import { profileApi } from 'global/api'
import { ProfileForm } from 'pages/profile/components'
import { ProfileState } from 'pages/profile/profile-def'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { UserProfilePageParams } from './UserProfilePage.types'
import './UserProfilePage.style.sass'

export const UserProfilePage: FC = (): JSX.Element => {
	const { userId } = useParams<UserProfilePageParams>()
	const [isSuccess, setIsSuccess] = useState<boolean | null>(null)
	const [profile, setProfile] = useState<ProfileState>({} as ProfileState)

	useEffect(() => {
		profileApi
			.getPublicProfile(+userId)
			.then(response => {
				setProfile(response.data)
				setIsSuccess(true)
			})
			.catch(e => {
				setIsSuccess(false)
			})
	}, [])

	return (
		<div>
			<PageTitle title='Профиль пользователя' breadcrumbs={true}/>
			<ProgressPage state={isSuccess} render={() => <ProfileForm profile={profile} mode={true} />} />
		</div>
	)
}
