import { Elevation } from '@rmwc/elevation'
import { reformatToMask } from 'pages/auth-as-worker/auth-as-worker-utils'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { DocumentsTable, Raiting, TabButtons, IDocumentsData, Avatar, PageTitle, ProgressPage, InfoBlock } from 'components'
import { authorName, req } from '../../global/common'
import { ChatContext } from '../../global/context.chat'
import { UserProfile } from '../../global/definitions/definitions'
import { Params } from '../public-profile/public-profile-def'
import { getValue } from './dump/dump-utils'
import { NotReviewed, ReviewBody, ReviewsObject } from './public-worker-profile-def'
import './public-worker-profile.sass'
import { AboutMeSubPage } from './subPages/about-me/about-me'
import { AnthropometrySubPage } from './subPages/anthropometry/anthropometry'
import { DocumentsSubPage } from './subPages/documents/documents'
import { ProfessionalExperienceSubPage } from './subPages/professional-experience/professional-experience'
import Reviews from './subPages/reviews/reviews'
import { UserContext } from '../../global/context.user'

export function PublicWorkerProfilePage() {
	const [loaded, setLoaded] = useState<boolean | null>(null)
	const { searchID, recordID } = useParams() as Params
	const chatCTX = useContext(ChatContext)
	const userCTX = useContext(UserContext)
	const [isDump, setIsDump] = React.useState(false)
	const [workerProfile, setWorkerProfile] = useState<UserProfile>(null!)
	const [reviews, setReviews] = useState<ReviewsObject>(null!)
	const [canAddReview, setCanAddReview] = useState<boolean>(null!)
	const [reviewsReload, setReviewsReload] = useState<boolean>(false)
	const [revMode, setRevMode] = useState<number>(null!)
	const [documents, setDocuments] = useState<IDocumentsData[] | null>(null)
	const history: any = useHistory()
	const [experienceQty, setExperienceQty] = useState(0)
	const { state } = useLocation()

    function getExperience() {
        let query = {
            limit: 0,
            offset: 0,
            userID: searchID
        }

        req
            .get('/contracts/public-history', query)
            .then(({data}) => (data.total > 99 ? '99+' : setExperienceQty(data.total)))
    }

    const query = new URLSearchParams(useLocation().search)

    // if ((state as any).prevPath.includes('response') && !isDump) {
    //     setIsDump(true)
    // }

    const onChangeSortValues = (fieldName: string, sortType: 'ASC' | 'DESC' | null) => {
        getDocumentsList(searchID, {[fieldName]: sortType})
    }

    const getDocumentsList = (id: string, sortBy?: any) => {
        req
            .post(`/profile/worker/certificates-user-list?userID=${id}`, sortBy ? sortBy : {endDate: 'ASC'})
            .then(({data}) => {
                setDocuments(data)
            })
    }

    React.useEffect(() => {
        req
            .get(`/profile/worker/${searchID}/public-profile`)
            .then(({data}) => {
                setWorkerProfile(data)
                getDocumentsList(searchID)
                setLoaded(true)
            })
            .catch(() => setLoaded(false))
				// Отключено для тестового запуска
        // getExperience()
        // }
    }, [reviewsReload])

    React.useEffect(() => {
        // if (!isDump) {
        req.get(`/reviews/person/${searchID}/list`).then(({data}) => {
            setCanAddReview(data.notCommented.data.findIndex((d: NotReviewed) => d.contract.id === +recordID) !== -1)
            const newData = Object.assign(data, {
                data: data.data.sort((a: ReviewBody, b: ReviewBody) => (a.canEdit === true ? -1 : b.canEdit === true ? 1 : 0))
            })
            setReviews(newData)
        })
        // }
    }, [reviewsReload])

    const reloadReviews = () => setReviewsReload((state: boolean) => !state)

    return (
        <ProgressPage
            className='public-worker-profile-page'
            state={loaded}
            render={() => (
                <>
                    <PageTitle
                        title={
                            !workerProfile.isDeleted
                                ? 'Публичный профиль > ' + authorName(workerProfile.personalInfo)
                                : 'Профиль удален'
                        }
                        // back={history.location.state && history.location.state.prevPath}
                        breadcrumbs={true}
                        // back={() => history.goBack()}
                    />
                    <Elevation z={3} className='worker-panel'>
                        <div className='inner-container'>
                            <div className='worker-info-wrapper'>
                                <div className='worker-avatar'>
                                    <Avatar
                                        linkDisabled
                                        previewHref={workerProfile.avatar?.pathPreview || (workerProfile.avatar as null)}
                                        isProfileDeleted={workerProfile.isDeleted}
                                    />
                                </div>

                                {/* <div className="worker-Avatar">
                                    {workerProfile.Avatar &&
                                        <img
                                            alt={'Avatar'}
                                            className="Avatar-preview"
                                            src={workerProfile.Avatar.path}
                                        />
                                    }
                                </div> */}
								{!workerProfile.isDeleted && (
									<ul className='worker-info-list'>
										<li className='info-item'>
											<InfoBlock
												title='Контактный телефон'
												content={
													<a className='telephone' href={`tel:${workerProfile.personalInfo.contactPhone}`}>
														{reformatToMask(workerProfile.personalInfo.contactPhone)}
													</a>
												}
											/>
										</li>
										<li className='profession-info-item info-item' style={{ display: 'flex' }}>
											<InfoBlock title='Базовая профессия' content={getValue(workerProfile.mainProfession?.name)} />
										</li>
										{/*<li className="info-item email">*/}
										{/*    <InfoBlock*/}
										{/*        title="Контактный e-mail"*/}
										{/*        content={*/}
										{/*            <a className="text email"*/}
										{/*               href={`mailto:${workerProfile.personalInfo.contactEmail}`}>*/}
										{/*                {workerProfile.personalInfo.contactEmail}*/}
										{/*            </a>*/}
										{/*        }*/}
										{/*    />*/}
										{/*    {workerProfile.personalInfo.contactEmailConfirmed*/}
										{/*        ? <div className='contactEmailConfirmed'>*/}
										{/*            <Icon className='check_circle' icon={"check_circle"}/>*/}
										{/*            <div className='confirmed'>Е-mail подтвержден</div>*/}
										{/*        </div>*/}
										{/*        : null*/}
										{/*    }*/}
										{/*</li>*/}
										<li className='info-item'>
											<InfoBlock
												title='Рейтинг'
												content={
													<div className='rating'>
														<Raiting value={workerProfile.rating && workerProfile!.rating!.average} withNumber />
													</div>
												}
											/>
										</li>
										<li className='info-item'>
											<InfoBlock
												title='Лояльность работника'
												content={
													<div className='loyalty'>
														{workerProfile.loyaltyStats && workerProfile!.loyaltyStats!.total}
													</div>
												}
											/>
										</li>
									</ul>
								)}
								{!workerProfile.isDeleted && userCTX.user?.currentRole !== 'moderator' && (
									<ul className='btn-panel-list'>
										<li
											className='btn-panel-item'
											onClick={() => {
												let arr = history.location.pathname.split('/')
												arr.splice(-2, 0, 'exp')
												history.push({
													pathname: arr.join('/'),
													state: history.location.state
												})
											}}
										>
											<div className='btn'>История сотрудничества ({experienceQty})</div>
										</li>
										<li
											className='btn-panel-item'
											onClick={() => {
												chatCTX.sendMessage(workerProfile)
											}}
										>
											<div className='btn'>Начать чат</div>
										</li>
									</ul>
								)}
							</div>
						</div>
					</Elevation>
					{!workerProfile.isDeleted && (
						<TabButtons
							data={{
								'О себе': () => <AboutMeSubPage workerProfile={workerProfile} />,
								'Профессиональный опыт': () => <ProfessionalExperienceSubPage workerProfile={workerProfile} />,
								Документы: () => <DocumentsSubPage workerProfile={workerProfile} workerId={searchID} />,
								'Документы оформлены Работодателем': () =>
									documents && documents?.length > 0 ? (
										<div
											style={{
												padding: '15px',
												backgroundColor: 'white',
												paddingTop: '1.5rem',
												paddingBottom: '1.5rem'
											}}
										>
											<DocumentsTable documents={documents} onChangeSortValues={onChangeSortValues} />
										</div>
									) : (
										<div></div>
									),
                                Отзывы: () =>
                                    reviews ? (
                                        <Reviews
                                            workerProfile={workerProfile}
                                            reviews={reviews}
                                            mode={(canAddReview ? (recordID ? true : false) : false) ? 'add' : !revMode ? 'watch' : 'edit'}
                                            modeID={revMode}
                                            reload={() => reloadReviews()}
                                            setMode={b => setRevMode(b)}
                                        />
                                    ) : (
                                        <div></div>
                                    ),
								'Спец. одежда': () => <AnthropometrySubPage anthropometry={workerProfile.anthropometry} />
							}}
							embed={[true, true, true, true, false, true]}
							open={query.get('reviews') ? Number(query.get('reviews')) : 0}
						/>
					)}
				</>
			)}
		/>
	)
}
