import { Button } from '@rmwc/button'
import { Dialog, DialogContent, DialogTitle } from '@rmwc/dialog'
import { Icon } from '@rmwc/icon'
import React from 'react'
import { ICustomDialog } from './CustomDialog.types'
import './CustomDialog.style.sass'

export const CustomDialog: React.FC<ICustomDialog> = ({ isOpen, onClose, dialogTitle, children, buttons }) => {
	return (
		<Dialog
			className='custom-dialog'
			open={isOpen}
			onClose={() => {
				onClose()
			}}
		>
			<DialogTitle className={'custom-dialog-title'}>
				{dialogTitle}
				<Icon
					icon={'close'}
					onClick={() => {
						onClose()
					}}
				/>
			</DialogTitle>
			<DialogContent className={'custom-dialog-content'}>
				<div className={'doc-container'}>{children}</div>
				{buttons && (
					<>
						<div className={'footer-btn'}>
							{buttons.map((item, index) => (
								<Button
									key={item.text}
									raised={item.raised}
									disabled={item.disabled}
									style={buttons.length > 1 ? { width: '200px', margin: '0 10px' } : { width: '200px' }}
									outlined={item.outlined}
									onClick={event => {
										event.preventDefault()
										item.callback()
									}}
								>
									{item.text}
								</Button>
							))}
						</div>
					</>
				)}
			</DialogContent>
		</Dialog>
	)
}
