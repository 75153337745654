import { Elevation } from '@rmwc/elevation'
import { Icon } from '@rmwc/icon'
import { ProgressPage, TabButtons, PageTitle, Raiting, DocumentsTable, IDocumentsData, InfoBlock, Avatar } from 'components'
import { authorName, req } from 'global/common'
import { ChatContext } from 'global/context.chat'
import { UserContext } from 'global/context.user'
import { UserProfile } from 'global/definitions/definitions'
import { reformatToMask } from 'pages/auth-as-worker/auth-as-worker-utils'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { Params } from '../../public-profile/public-profile-def'
import { AboutMeSubPage } from '../subPages/about-me/about-me'
import { DocumentsSubPage } from '../subPages/documents/documents'
import { ProfessionalExperienceSubPage } from '../subPages/professional-experience/professional-experience'
import Reviews from '../subPages/reviews/reviews'
import { NotReviewed, ReviewBody, ReviewsObject } from './dump-def'
import { getValue } from './dump-utils'
import './dump.sass'

export enum EAcceptValues {
	AWAIT_ACCEPT = 'await_accept',
	NONE = 'none',
	ACCEPTED = 'accepted',
	REFUSED = 'refused'
}

export interface IAcceptChangeResponse {
	about?: EAcceptValues
	actualAddress?: EAcceptValues
	citizenship?: EAcceptValues
	contactEmail?: EAcceptValues
	contactPhone?: EAcceptValues
	experience?: EAcceptValues
	grade?: EAcceptValues
	mainProfession?: EAcceptValues
	permissionDocuments?: EAcceptValues
	permissions?: EAcceptValues
	workSearchSchedule?: EAcceptValues
	workSearchStatus?: EAcceptValues
	snils?: EAcceptValues
	passport?: EAcceptValues
	inn?: EAcceptValues
	qualificationDocuments?: EAcceptValues
	subProfession?: EAcceptValues
}

export function Dump() {
	const [loaded, setLoaded] = useState<boolean | null>(null)
	const { searchID, recordID } = useParams() as Params
	const chatCTX = useContext(ChatContext)
	const userCTX = useContext(UserContext)
	// const [isDump, setIsDump] = React.useState(false)
	const [workerProfile, setWorkerProfile] = useState<UserProfile>(null!)
	const [reviews, setReviews] = useState<ReviewsObject>(null!)
	const [canAddReview, setCanAddReview] = useState<boolean>(null!)
	const [reviewsReload, setReviewsReload] = useState<boolean>(false)
	const [revMode, setRevMode] = useState<number>(null!)
	const [isVisibleProfileUpdate, setIsVisibleProfileUpdate] = useState(false)
	const [versionProfile, setVersionProfile] = useState<UserProfile>()
	const [acceptChangeResponse, setAcceptChangeResponse] = useState<IAcceptChangeResponse>()
	const history: any = useHistory()
	const [documents, setDocuments] = useState<IDocumentsData[] | null>(null)
	const [experienceQty, setExperienceQty] = useState(0)
	const { state } = useLocation()

	function getExperience() {
		let query = {
			limit: 0,
			offset: 0,
			userID: searchID
		}

		req
			.get('/contracts/public-history', query)
			.then(({ data }) => (data.total > 99 ? '99+' : setExperienceQty(data.total)))
	}

	const toggleAcceptValueInUserDumpProfile = (request: IAcceptChangeResponse) => {
		req
			.post(`/profile/version-user-accept-and-merge-changes?userID=${searchID}&requestID=${recordID}`, request)
			.then(() => {
				req.get(`/profile/worker/${searchID}/response-dump/${recordID}`).then(({ data }) => {
					setWorkerProfile(data)
					req.get(`/profile/version-user-profile-get?userID=${searchID}`).then(({ data }) => {
						setVersionProfile(data.workerProfileVersion)
						setAcceptChangeResponse(data.acceptChangeResponse)
					})
				})
			})
	}

	const query = new URLSearchParams(useLocation().search)

	const onChangeSortValues = (fieldName: string, sortType: 'ASC' | 'DESC' | null) => {
		getDocumentsList(searchID, { [fieldName]: sortType })
	}

	const getDocumentsList = (id: string, sortBy?: any) => {
		req
			.post(`/profile/worker/certificates-user-list?userID=${id}`, sortBy ? sortBy : { endDate: 'ASC' })
			.then(({ data }) => {
				setDocuments(data)
			})
	}

	React.useEffect(() => {
		req
			.get(`/profile/worker/${searchID}/response-dump/${recordID}`)
			.then(({ data }) => {
				setWorkerProfile(data)
				getDocumentsList(searchID)
				req.get(`/profile/version-user-profile-get?userID=${searchID}`).then(({ data }) => {
					setVersionProfile(data.workerProfileVersion)
					setAcceptChangeResponse(data.acceptChangeResponse)
				})
				setLoaded(true)
			})
			.catch(() => setLoaded(false))

		getExperience()
		// }
	}, [reviewsReload])

	React.useEffect(() => {
		// if (!isDump) {
		req.get(`/reviews/person/${searchID}/list`).then(({ data }) => {
			setCanAddReview(data.notCommented.data.findIndex((d: NotReviewed) => d.contract.id === +recordID) !== -1)
			const newData = Object.assign(data, {
				data: data.data.sort((a: ReviewBody, b: ReviewBody) => (a.canEdit === true ? -1 : b.canEdit === true ? 1 : 0))
			})
			setReviews(newData)
		})
		// }
	}, [reviewsReload])

	const reloadReviews = () => setReviewsReload((state: boolean) => !state)
	return (
		<ProgressPage
			className='public-worker-profile-page-dump'
			state={loaded}
			render={() => (
				<>
					<PageTitle
						title={'Карточка сотрудника > ' + authorName(workerProfile.personalInfo)}
						breadcrumbs={true}
						// back={history.location.state ? history.location.state.prevPath : () => history.goBack()}
						// back={() => history.goBack()}
					/>
					<Elevation z={3} className='worker-panel'>
						<div className='inner-container'>
							<div className='worker-info-wrapper'>
								<div className='worker-avatar'>
									<Avatar
										linkDisabled
										previewHref={workerProfile.avatar?.pathPreview || (workerProfile.avatar as null)}
										isProfileDeleted={workerProfile.isDeleted}
									/>
								</div>
								{/* {!workerProfile.isDeleted && */}
								<ul className='worker-info-list'>
									<li className='info-item'>
										<InfoBlock
											title='Контактный телефон'
											content={
												<a className='telephone' href={`tel:${workerProfile.personalInfo.contactPhone}`}>
													{reformatToMask(workerProfile.personalInfo.contactPhone)}
												</a>
											}
										/>
										{!isVisibleProfileUpdate && acceptChangeResponse?.contactPhone === EAcceptValues.AWAIT_ACCEPT && (
											<Icon className='edit_note' icon={'edit_note'} />
										)}
										{isVisibleProfileUpdate &&
											versionProfile &&
											acceptChangeResponse?.contactPhone === EAcceptValues.AWAIT_ACCEPT && (
												<>
													<Icon className='keyboard_double_arrow_right' icon={'keyboard_double_arrow_right'} />
													<div>
														<InfoBlock
															title='Контактный телефон'
															content={
																<>
																	<a
																		className='telephone info-item-version'
																		href={`tel:${versionProfile.personalInfo.contactPhone}`}
																	>
																		{reformatToMask(versionProfile.personalInfo.contactPhone)}
																	</a>
																	{userCTX.user?.currentRole !== 'organizer' && (
																		<div className='version-buttons'>
																			<Icon
																				className='done'
																				icon={'done'}
																				onClick={() => {
																					toggleAcceptValueInUserDumpProfile({ contactPhone: EAcceptValues.ACCEPTED })
																				}}
																			/>
																			<Icon
																				className='close'
																				icon={'close'}
																				onClick={() => {
																					toggleAcceptValueInUserDumpProfile({ contactPhone: EAcceptValues.REFUSED })
																				}}
																			/>
																		</div>
																	)}
																</>
															}
														/>
													</div>
												</>
											)}
									</li>
									<li
										className='profession-info-item info-item'
										style={{ display: 'flex', flexDirection: 'column', position: 'relative', alignItems: 'start' }}
									>
										<InfoBlock title='Базовая профессия' content={getValue(workerProfile.mainProfession?.name)} />
										{isVisibleProfileUpdate !== undefined &&
											!isVisibleProfileUpdate &&
											acceptChangeResponse?.mainProfession === EAcceptValues.AWAIT_ACCEPT && (
												<Icon
													className='edit_note'
													icon={'edit_note'}
													style={{ position: 'absolute', right: '-31px', top: '20px' }}
												/>
											)}
										{isVisibleProfileUpdate &&
											versionProfile &&
											acceptChangeResponse?.mainProfession === EAcceptValues.AWAIT_ACCEPT && (
												<>
													<Icon
														className='keyboard_double_arrow_right'
														style={{ marginLeft: '41%' }}
														icon={'keyboard_double_arrow_down'}
													/>
													<div>
														<InfoBlock
															title='Базовая профессия'
															content={
																<>
																	<div className='info-item-version'>
																		{versionProfile.mainProfession
																			? versionProfile.mainProfession.name
																			: 'Информация удалена'}
																	</div>
																	{userCTX.user?.currentRole !== 'organizer' && (
																		<div className='version-buttons'>
																			<Icon
																				className='done'
																				icon={'done'}
																				onClick={() => {
																					toggleAcceptValueInUserDumpProfile &&
																						toggleAcceptValueInUserDumpProfile({
																							mainProfession: EAcceptValues.ACCEPTED
																						})
																				}}
																			/>
																			<Icon
																				className='close'
																				icon={'close'}
																				onClick={() => {
																					toggleAcceptValueInUserDumpProfile &&
																						toggleAcceptValueInUserDumpProfile({
																							mainProfession: EAcceptValues.REFUSED
																						})
																				}}
																			/>
																		</div>
																	)}
																</>
															}
														/>
													</div>
												</>
											)}
									</li>
									<li className='info-item'>
										<InfoBlock
											title='Рейтинг'
											content={
												<div className='rating'>
													<Raiting value={workerProfile.rating && workerProfile!.rating!.average} withNumber />
												</div>
											}
										/>
									</li>
									<li className='info-item'>
										<InfoBlock
											title='Лояльность Работника'
											content={
												<div className='loyalty'>
													{workerProfile.loyaltyStats && workerProfile!.loyaltyStats!.total}
												</div>
											}
										/>
									</li>
								</ul>
								{/* } */}
								{/* {!workerProfile.isDeleted && */}
								<ul className='btn-panel-list'>
									{/*{userCTX.user?.currentRole !== 'organizer' &&*/}
									<li
										className='btn-panel-item'
										onClick={() => {
											setIsVisibleProfileUpdate((prevState: boolean) => !prevState)
										}}
									>
										<div className='btn'>{isVisibleProfileUpdate ? 'Скрыть изменения' : 'Посмотреть изменения'}</div>
									</li>
									{/*}*/}
									<li
										className='btn-panel-item'
										onClick={() => {
											let arr = history.location.pathname.split('/')
											arr.splice(-2, 0, 'exp')
											history.push({
												pathname: arr.join('/'),
												state: history.location.state
											})
										}}
									>
										<div className='btn'>История сотрудничества ({experienceQty})</div>
									</li>
									<li
										className='btn-panel-item'
										onClick={() => {
											chatCTX.sendMessage(workerProfile)
										}}
									>
										<div className='btn'>Начать чат</div>
									</li>
								</ul>
								{/* } */}
							</div>
						</div>
					</Elevation>
					<TabButtons
						isUpdatedTabs={[
							//"О себе"
							false,
							//"Профессиональный опыт"
							acceptChangeResponse?.mainProfession === EAcceptValues.AWAIT_ACCEPT ||
								acceptChangeResponse?.grade === EAcceptValues.AWAIT_ACCEPT ||
								acceptChangeResponse?.experience === EAcceptValues.AWAIT_ACCEPT ||
								acceptChangeResponse?.permissions === EAcceptValues.AWAIT_ACCEPT ||
								acceptChangeResponse?.qualificationDocuments === EAcceptValues.AWAIT_ACCEPT ||
								acceptChangeResponse?.permissionDocuments === EAcceptValues.AWAIT_ACCEPT ||
								acceptChangeResponse?.subProfession === EAcceptValues.AWAIT_ACCEPT,
							//"Документы"
							acceptChangeResponse?.inn === EAcceptValues.AWAIT_ACCEPT ||
								acceptChangeResponse?.snils === EAcceptValues.AWAIT_ACCEPT ||
								acceptChangeResponse?.passport === EAcceptValues.AWAIT_ACCEPT ||
								acceptChangeResponse?.contactEmail === EAcceptValues.AWAIT_ACCEPT,
							false,
							false
						]}
						data={{
							'О себе': () => <AboutMeSubPage workerProfile={workerProfile} />,
							'Профессиональный опыт': () => (
								<ProfessionalExperienceSubPage
									workerProfile={workerProfile}
									versionProfile={versionProfile}
									acceptChangeResponse={acceptChangeResponse}
									isVisibleProfileUpdate={isVisibleProfileUpdate}
									toggleAcceptValueInUserDumpProfile={toggleAcceptValueInUserDumpProfile}
								/>
							),
							'Документы': () => (
								<DocumentsSubPage
									workerProfile={workerProfile}
									workerId={searchID}
									versionProfile={versionProfile}
									acceptChangeResponse={acceptChangeResponse}
									isVisibleProfileUpdate={isVisibleProfileUpdate}
									toggleAcceptValueInUserDumpProfile={toggleAcceptValueInUserDumpProfile}
								/>
							),
							'Документы оформлены Работодателем': () =>
								documents && documents?.length > 0 ? (
									<div
										style={{
											padding: '15px',
											backgroundColor: 'white',
											paddingTop: '1.5rem',
											paddingBottom: '1.5rem'
										}}
									>
										<DocumentsTable documents={documents} onChangeSortValues={onChangeSortValues} />
									</div>
								) : (
									<div></div>
								),
							'Отзывы': () => <Reviews
										workerProfile={workerProfile}
										reviews={reviews}
										mode={(canAddReview ? (recordID ? true : false) : false) ? 'add' : !revMode ? 'watch' : 'edit'}
										modeID={revMode}
										reload={() => reloadReviews()}
										setMode={b => setRevMode(b)}
									/>
							// "Спец. одежда": () => <AnthropometrySubPage anthropometry={workerProfile.anthropometry}/>,
						}}
						embed={[true, true, true, false, true]}
						open={query.get('reviews') ? Number(query.get('reviews')) : 0}
					/>
					{/* } */}
				</>
			)}
		/>
	)
}
