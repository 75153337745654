import { Checkbox } from '@rmwc/checkbox'
import { ProgressPage, FixedButtons } from 'components'
import { req } from 'global/common'
import { InterfaceContext } from 'global/context.interface'
import React, { RefObject } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { INeedTemplateDataRow } from './NeedTemplate.types'
import { dataColumns } from './NeedTemplate.service'
import './NeedTemplate.style.sass'

export function NeedTemplate() {
	const form: RefObject<HTMLFormElement> = React.createRef()
	const interfaceCTX = React.useContext(InterfaceContext)
	const [template, setTemplate] = React.useState(null as any)
	const { t } = useTranslation('common')

	const methods = useForm({
		mode: 'all'
	})

	React.useEffect(() => {
		req
			.get('/requests/organisation/fields-settings/get')
			.then(data => {
				setTemplate(data.data)
			})
			.catch(e => {
				interfaceCTX.showMessage({
					title: 'Ошибка',
					body: t(e.response.data),
					icon: 'report'
				})
			})
		return () => {
			interfaceCTX.resetIsFormChanged()
		}
	}, [])

	function submitForm(data: any) {
		req
			.post('/requests/organisation/fields-settings/update', template)
			.then(() => {
				interfaceCTX.resetIsFormChanged()
				interfaceCTX.showMessage({
					body: 'Данные успешно сохранены',
					icon: 'done'
				})
			})
			.catch(e => {
				interfaceCTX.showMessage({
					title: 'Ошибка',
					body: t(e.response.data),
					icon: 'report'
				})
			})
	}

	return (
		<div className='need-template'>
			<ProgressPage
				state={template}
				render={() => (
					<div className='need-template-inner'>
						<FormProvider {...methods}>
							<div className='form-wrapper'>
								<form ref={form} className='form' onSubmit={methods.handleSubmit(submitForm)}>
									<div className='form-columns-wrapper'>
										{/* <div className="Form-column"> */}
										{template &&
											dataColumns.map((dataColumn: INeedTemplateDataRow[]) => (
												<div className='form-column'>
													{dataColumn.map((dataItem: INeedTemplateDataRow) => (
														<div className='option-row'>
															<Controller
																key={dataItem.name + '_hidden'}
																control={dataItem.isAbleToBeHidden ? methods.control : undefined}
																name={dataItem.name + '_hidden'}
																defaultValue={!template.hidden[dataItem.name]}
																render={({ field, fieldState }) => {
																	let disabled = !dataItem.isAbleToBeHidden
																	return (
																		<>
																			<Checkbox
																				className='hidden'
																				onChange={e =>
																					setTemplate((v: any) => {
																						interfaceCTX.checkIsFormChanged(true, 'settings')
																						return {
																							...v,
																							hidden: {
																								...v.hidden,
																								[dataItem.name]: !e.currentTarget.checked
																							},
																							required: {
																								...v.required,
																								[dataItem.name]: false
																							}
																						}
																					})
																				}
																				disabled={disabled}
																				checked={
																					dataItem.isAbleToBeHidden == false ? true : !template.hidden[dataItem.name]
																				}
																				// value={template.hidden[dataItem.name]}
																				// {...field}
																			/>
																		</>
																	)
																}}
															/>
															<Controller
																key={dataItem.name + '_required'}
																control={dataItem.isAbleToBeUnRequired ? methods.control : undefined}
																name={dataItem.name + '_required'}
																defaultValue={template.required[dataItem.name]}
																render={({ field, fieldState }) => {
																	let disabled = !dataItem.isAbleToBeUnRequired || template.hidden[dataItem.name]
																	return (
																		<Checkbox
																			className={`${
																				template.required[dataItem.name] == undefined
																					? 'required-default'
																					: 'required-by-user'
																			} ${template.required[dataItem.name] == false && 'unchecked'}`}
																			key={'_required' + dataItem.name}
																			onChange={e => {
																				interfaceCTX.checkIsFormChanged(true, 'settings')
																				setTemplate({
																					...template,
																					required: {
																						...template.required,
																						[dataItem.name]: !!e.currentTarget.checked
																					}
																				})
																			}}
																			// value={template.required[dataItem.name]}
																			disabled={disabled}
																			checked={
																				dataItem.isAbleToBeUnRequired == false || template.required[dataItem.name]
																			}
																			// {...field}
																		/>
																	)
																}}
															/>
															<div className='option-title'>{dataItem.title}</div>
														</div>
													))}
												</div>
											))}
									</div>
								</form>
								<FixedButtons
									length={4}
									buttons={[
										{
											label: 'Сохранить',
											primary: true,
											onClick: () => {
												// e.preventDefault()
												form.current!.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
											}
										}
									]}
								/>
							</div>
						</FormProvider>
					</div>
				)}
			/>
		</div>
	)
}
