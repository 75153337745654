import {Form} from 'components'
import { InterfaceContext } from 'global/context.interface'
import { checkInputedAge, reformatToMask } from 'pages/auth-as-worker/auth-as-worker-utils'
import { FC, useContext } from 'react'
import { ProfileFormProps } from './profile-form-def'

export const ProfileForm: FC<ProfileFormProps> = ({ formRef, mode, profile, onSubmit }): JSX.Element => {
	const { fioValidationPattern, emailValidationPattern } = useContext(InterfaceContext)

	return (
		<Form
			name={'profile'}
			useRef={formRef}
			viewMode={mode}
			onSubmit={onSubmit}
			data={[
				[
					{
						values: [
							{
								type: 'textfield',
								title: 'Фамилия',
								defaultValue: profile.lastName ?? '',
								var: 'lastName',
								validate: { required: true, maxLength: 30, pattern: fioValidationPattern },
								errors: {
									maxLength: 'Введите не больше 30 символов',
									pattern: `Только кириллица, знаки "-", "_" и пробел`
								}
							},
							{
								title: 'Имя',
								defaultValue: profile.firstName ?? '',
								var: 'firstName',
								validate: { required: true, maxLength: 30, pattern: fioValidationPattern },
								errors: {
									maxLength: 'Введите не больше 30 символов',
									pattern: `Только кириллица, знаки "-", "_" и пробел`
								}
							},
							{
								title: 'Отчество',
								defaultValue: profile.middleName ?? '',
								var: 'middleName',
								validate: { required: false, maxLength: 30, pattern: fioValidationPattern },
								errors: {
									maxLength: 'Введите не больше 30 символов',
									pattern: `Только кириллица, знаки "-", "_" и пробел`
								}
							}
						]
					},
					{
						values: [
							{
								title: 'Контактный e-mail',
								link: 'mailto',
								defaultValue: profile.contactEmail ?? '',
								var: 'contactEmail',
								validate: { required: true, maxLength: 60, pattern: emailValidationPattern },
								errors: { pattern: 'Введите валидную почту', maxLength: 'Введите не больше 60 символов' }
							},
							{
								type: 'phone',
								title: 'Контактный телефон',
								caption: '+ 7 (9XX) XXX XX XX',
								var: 'contactPhone',
								defaultValue: (profile.contactPhone && reformatToMask(profile.contactPhone)) || '',
								validate: {
									required: true
								},
								errors: {
									pattern: 'Введите номер в формате + 7 (9XX) XXX XX XX'
								}
							},
							{
								type: 'date',
								title: 'Дата рождения',
								defaultValue: profile.birthDate ?? '',
								var: 'birthDate',
								validate: { required: true, validate: checkInputedAge },
								errors: {
									pattern: 'Введите дату в формате ДД.ММ.ГГГГ',
									validate: 'Допустимый возраст от 18 до 65 лет'
								}
							}
						]
					}
				]
			]}
		/>
	)
}
