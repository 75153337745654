import React, {useState, useRef} from "react";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from "react-image-crop";
import {canvasPreview} from "./AvatarEditor.service";
import "react-image-crop/dist/ReactCrop.css";
import {useDebounceEffect} from "../../custom-hooks/useDebounceEffect";
import {IAvatarEditor} from "./AvatarEditor.types";
import "./AvatarEditor.style.sass";
import {Dialog} from "@rmwc/dialog";
import {Icon} from "@rmwc/icon";
import {Button} from "@rmwc/button";

const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspectRatio: number
) => {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 90,
            },
            aspectRatio,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
};

export const AvatarEditor: React.FC<IAvatarEditor> = ({
                                                          onImageSave,
                                                          aspectRatio,
                                                          setIsOpen,
                                                          isOpen,
                                                      }) => {
    const [imgSrc, setImgSrc] = useState("");
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>();

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined);
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                setImgSrc(!!reader.result ? reader.result.toString() : "")
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspectRatio) {
            const {width, height} = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspectRatio));
        }
    }

    const dataURLtoFile = (dataurl: string, filename: string) => {
        const arr = dataurl.split(",");
        //@ts-ignore
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) u8arr[n] = bstr.charCodeAt(n);

        return new File([u8arr], filename, {type: mime});
    };

    const saveImage = () => {
        if (
            completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current
        ) {
            canvasPreview(
                imgRef.current,
                previewCanvasRef.current,
                completedCrop,
                1,
                "getImage"
            ).then((data) => {
                onImageSave(dataURLtoFile(data as string, "image"));
            });
        }
    };

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    1,
                    "review"
                );
            }
        },
        100,
        [completedCrop, 1]
    );

    return (
        <Dialog
            open={isOpen}
            onClose={(evt) => {
                setIsOpen(false);
                setImgSrc("");
                setCompletedCrop(null);
            }}
        >
            <div className="avatar-editor">
                <div className="avatar-editor-text">
                    <h3>Выбор миниатюры</h3>
                </div>
                <div className="avatar-editor-headline">
                    <h5>Выберите область для маленькой фотографии</h5>
                </div>
                <div className="avatar-editor-avatars">
                    {Boolean(imgSrc) && (
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspectRatio}
                        >
                            <img ref={imgRef} alt="" src={imgSrc} onLoad={onImageLoad}/>
                        </ReactCrop>
                    )}
                    <div>
                        {Boolean(completedCrop) && (
                            <canvas
                                className="avatar-editor-canvas"
                                ref={previewCanvasRef}
                                style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: "150px",
                                    height: "150px",
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className="avatar-editor-controls">
                    <div className="avatar-editor-select">
                        {Boolean(imgSrc) ? (
                            <Button
                                type="button"
                                className="avatar-editor-btn"
                                raised={true}
                                onClick={saveImage}
                            >
                                <div className="avatar-editor-btn-text">Сохранить</div>
                            </Button>
                        ) : (
                            <>
                                <input
                                    className="avatar-editor-input"
                                    type="file"
                                    accept="image/*"
                                    onChange={onSelectFile}
                                />
                                <Button
                                    type="button"
                                    className="avatar-editor-btn"
                                    raised={true}
                                >
                                    <Icon className="file_download" icon={"file_download"}/>
                                    <div className="avatar-editor-btn-text">Выбрать файл</div>
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
