import { createContext, useState } from 'react'
import { Role, User } from './definitions/definitions'

interface ContextProps {
	user: User | null
	setUser: (user: User | null) => void
	isLSTokenValid: () => boolean
	userHasRole: (role: Role) => boolean
	changeRole: (role: Role) => void
	currentRole: (role: Role) => boolean
}

export const UserContext = createContext({} as ContextProps)

export const UserContextProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
	const [user, setUser] = useState<User | null | any>()

	const isLSTokenValid = () => {
		return localStorage.token && localStorage.tokenDeathDate && Number(localStorage.tokenDeathDate) > Date.now()
	}

	const userHasRole = (role: Role) => {
		return user?.roles.includes(role) ?? false
	}

	const currentRole = (role: Role) => {
		return user?.currentRole === role
	}

	const changeRole = (role: Role) => {
		let targetRole = [role]
		localStorage.setItem('currentRole', targetRole[0])
		setUser({
			...user,
			roles: targetRole
		})
	}

	return (
		<UserContext.Provider
			value={{
				user: user || null,
				setUser,
				isLSTokenValid,
				userHasRole,
				currentRole,
				changeRole
			}}
		>
			{children}
		</UserContext.Provider>
	)
}
