import React, { MutableRefObject, useRef } from 'react'
import { createSnackbarQueue, SnackbarQueueMessage } from '@rmwc/snackbar'
import { ArrayEmitter } from '@rmwc/base'
import { openInput } from '../components/hiddenInput'
import { UseFormGetValues, UseFormReset } from 'react-hook-form'

interface SnackbarMessage {
	title?: string
	body: string
	icon?: string
}

interface IFormValues {
	[key: string]: string
}

interface ContextProps {
	snackbarMessages: ArrayEmitter<SnackbarQueueMessage>
	previewImgURL: string | null
	isFormChanged: {
		[key: string]: boolean
	}
	instructionValue: { isOpen: boolean; step: number }
	setInstructionValue: (isOpen: boolean, step: number) => void
	scrollHeight: number
	scrollTop: number
	offsetHeight: number
	offsetForPagination: number
	topBarRefreshed: boolean
	emailValidationPattern: RegExp
	fioValidationPattern: RegExp
	showMenu: boolean
	supportEmails: string[]
	version: string
	showMessage: (msg: SnackbarMessage) => Promise<void>
	clearMsgQueue: () => void
	openInput: () => any
	getPreviewImgUrl: (arg0: string) => void
	erasePreviewImgUrl: () => void
	checkIsFormChanged: (arg0: boolean, formName: string) => void
	resetIsFormChanged: () => void
	getScrollEvent: (arg1: any) => void
	resetScrollValues: () => void
	setOffsetPagination: (offset: number) => void
	resetOffsetPaginiation: () => void
	refreshTopBar: () => void
	showHideNav: () => void
	formMethods: (reset: UseFormReset<Record<any, any>>, getValues: UseFormGetValues<Record<any, any>>) => void
	reset: UseFormReset<Record<any, any>> | null
	getValues: UseFormGetValues<Record<any, any>> | null
	getMailToArr: () => string
}

export const InterfaceContext = React.createContext({} as ContextProps)

export function InterfaceContextProvider({ children }: { children: JSX.Element }): JSX.Element {
	const messagesQueue = createSnackbarQueue()
	const [value, setValue] = React.useState({
		snackbarMessages: messagesQueue.messages,
		previewImgURL: null,
		isFormChanged: {},
		scrollHeight: 0,
		scrollTop: 0,
		instructionValue: { isOpen: false, step: -1 },
		offsetHeight: 0,
		offsetForPagination: 0,
		topBarRefreshed: false,
		fioValidationPattern: /^[а-яА-ЯЁё_\-\x20.]+$/gm,
		emailValidationPattern: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu,
		showMenu: true,
		reset: null,
		getValues: null,
		supportEmails: ['support@pragma.info'],
		version: '1.1.58'
	} as unknown as ContextProps)

	function clearMsgQueue() {
		messagesQueue.clearAll()

		setValue({
			...value,
			snackbarMessages: messagesQueue.messages
		})
	}

	function showMessage(message: SnackbarMessage): Promise<void> {
		clearMsgQueue()
		messagesQueue.messages.array.push({
			title: message.title,
			body: message.body,
			icon: message.icon ?? 'close',
			onClose: () => {
				messagesQueue.clearAll()
				setValue({
					...value,
					snackbarMessages: messagesQueue.messages
				})
			},
			timeout: 3000,
			dismissesOnAction: true,
			actions: [{ title: 'Ок' }]
		})

		setValue({
			...value,
			snackbarMessages: messagesQueue.messages
		})

		return new Promise(res => {
			setInterval(() => {
				if (messagesQueue.messages.array.length === 0) {
					res()
				}
			}, 250)
		})
	}

	function getPreviewImgUrl(URL: string) {
		setValue({
			...value,
			previewImgURL: URL
		})
	}

	function erasePreviewImgUrl() {
		setValue({
			...value,
			previewImgURL: null
		})
	}

	function setInstructionValue(isOpen: boolean, step: number) {
		setValue((prevState: any) => ({
			...prevState,
			instructionValue: { step: step, isOpen: isOpen }
		}))
	}

	function checkIsFormChanged(state: boolean, formName: string) {
		let newObj = Object.assign(value)
		newObj.isFormChanged[formName] = state
		setValue({
			...newObj
		})
	}

	function resetIsFormChanged() {
		let newObj = Object.assign(value)
		newObj.isFormChanged = {}
		setValue({
			...newObj
		})
	}

	function getScrollEvent(e: any) {
		setValue({
			...value,
			scrollHeight: Math.round(e.target && e.target.scrollHeight),
			scrollTop: Math.round(e.target && e.target.scrollTop),
			offsetHeight: Math.round(e.target && e.target.offsetHeight)
		})
	}

	function resetScrollValues() {
		setValue({
			...value,
			scrollHeight: 0,
			scrollTop: 0,
			offsetHeight: 0
		})
	}

	function setOffsetPagination(offset: number) {
		setValue({
			...value,
			offsetForPagination: (value.offsetForPagination += offset)
		})
	}

	function resetOffsetPaginiation() {
		setValue({
			...value,
			offsetForPagination: 0
		})
	}

	function refreshTopBar() {
		setValue({
			...value,
			topBarRefreshed: !value.topBarRefreshed
		})
	}

	function showHideNav() {
		setValue({
			...value,
			showMenu: !value.showMenu
		})
	}

	function formMethods(reset: UseFormReset<Record<any, any>>, getValues: UseFormGetValues<Record<any, any>>) {
		setValue({
			...value,
			reset: reset,
			getValues: getValues
		})
	}

	function getMailToArr() {
		let subject = `?subject=Pragmacore. Workforce. Support . Version: ${value.version} / Platform: Web ${window.navigator.platform}`
		let targetArr = value.supportEmails
			.map((email: string, index: number) => {
				if (index == 0) {
					return email
				} else if (index == 1) {
					return '&cc=' + email
				} else {
					return ';' + email
				}
			})
			.join('')
		return targetArr + subject
	}

	return (
		<InterfaceContext.Provider
			value={{
				...value,
				showMessage,
				clearMsgQueue,
				openInput,
				getPreviewImgUrl,
				erasePreviewImgUrl,
				checkIsFormChanged,
				resetIsFormChanged,
				getScrollEvent,
				resetScrollValues,
				setOffsetPagination,
				resetOffsetPaginiation,
				refreshTopBar,
				showHideNav,
				formMethods,
				getMailToArr,
				setInstructionValue,
			}}
		>
			{children}
		</InterfaceContext.Provider>
	)
}
