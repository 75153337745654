import {CircularProgress} from "@rmwc/circular-progress";
import {Elevation} from "@rmwc/elevation";
import {format} from "date-fns";
import getDaysInMonth from "date-fns/getDaysInMonth";
import React from "react";
import {MutableRefObject} from "react";
import {useTranslation} from "react-i18next";
import {FixedButtons, Form, PageTitle, Table} from "components";
import {TableCell} from "../../components/Table/TableCell/TableCell";
import {TableRow} from "../../components/Table/TableRow/TableRow";
import {req} from "../../global/common";
import {InterfaceContext} from "../../global/context.interface";
import {ObjectForTKReport} from "./components/components";
import {getYearOptions, monthOptions, tableColumnDimensions} from "./TimeKeepingReport.service";
import "./TimeKeepingReport.style.sass"


export function TimeKeepingReport() {

    interface ITKPQueryParams {
        year: number,
        month: number
    }

    const formRef = React.useRef() as MutableRefObject<HTMLFormElement>
    const interfaceCTX = React.useContext(InterfaceContext)
    const {t} = useTranslation('common')

    const [daysInChosenMonth, setDaysInChosenMonth] = React.useState(getDaysInMonth(new Date()))
    const [tableWidth, setTableWidth] = React.useState(0)
    const [objects, setObjects] = React.useState([])
    const [reportParams, setReportParams] = React.useState({
        year: new Date().getFullYear(),
        month: monthOptions[new Date().getMonth()].value
    } as ITKPQueryParams)
    const [loader, setLoader] = React.useState(false)

    React.useEffect(() => {
        getChosenReport(reportParams)
    }, [])

    React.useEffect(() => {
        setTableWidth(document.getElementsByClassName('headings')[0]?.scrollWidth)
    }, [daysInChosenMonth])

    function getChosenReport(queryParams: ITKPQueryParams) {
        setReportParams({
            ...reportParams,
            year: queryParams.year,
            month: queryParams.month
        })

        req.get('/shift/report/monthly', queryParams)
            .then((data) => {
                let _daysInChosenMonth = getDaysInMonth(new Date(queryParams.year, queryParams.month - 1))
                console.log(data)
                setDaysInChosenMonth(_daysInChosenMonth)
                setObjects(data.data.objects)
            })
    }

    function onSubmit(formData: any) {

        getChosenReport({
            year: formData.year.value,
            month: formData.month.value
        })
    }

    function getExcelReport(reportParams: ITKPQueryParams) {

        setLoader(true)

        req.get(`/shift/report/monthly/excel`, reportParams)
            .then(({data}) => {
                const blobUrl = window.URL.createObjectURL(
                    new Blob([Buffer.from(data, "base64")])
                );

                const link = document.createElement("a");
                link.href = blobUrl;
                link.setAttribute("download", `Табель. ${monthOptions[reportParams.month - 1].label}. Выгружен ${format(new Date, 'dd.MM.yyy')}.xlsx`);
                link.click();

                window.URL.revokeObjectURL(blobUrl);
            })
            .then(() => setLoader(false))
            .catch((e) => {
                setLoader(false)
                interfaceCTX.showMessage({
                    title: 'Ошибка',
                    body: t('error.' + e.response.data)
                })
            })
    }

    return (
        <div className="time-keeping-report-page">
            <CircularProgress className={`loader ${loader && 'open'}`}></CircularProgress>
            <PageTitle title={"Табель"}/>
            <Elevation z={1} className={`control-panel ${loader && 'blocked'}`}>

                <div className="filters">
                    <Form
                        name={'profile'}
                        useRef={formRef}
                        viewMode={false}
                        onSubmit={onSubmit}
                        data={[
                            [
                                {
                                    values: [
                                        {
                                            type: 'select',
                                            var: "year",
                                            title: 'Год',
                                            options: getYearOptions(2),
                                            defaultValue: {
                                                label: new Date().getFullYear(),
                                                value: new Date().getFullYear()
                                            }
                                        },
                                    ]
                                },
                                {
                                    values: [
                                        {
                                            type: 'select',
                                            var: "month",
                                            title: 'Месяц',
                                            options: monthOptions,
                                            defaultValue: monthOptions[new Date().getMonth()]
                                        },
                                    ]
                                }
                            ]
                        ]}
                    />
                </div>
                <div className="btns">

                    <FixedButtons length={2}
                                  buttons={[
                                      {
                                          label: 'Выполнить',
                                          disabled: loader,
                                          primary: true,
                                          onClick: () => formRef.current.dispatchEvent(new Event('submit', {
                                              cancelable: true,
                                              bubbles: true
                                          }))
                                      },
                                      {
                                          label: 'Сохранить Excel',
                                          disabled: loader,
                                          primary: true,
                                          onClick: () => getExcelReport(reportParams)
                                      }
                                  ]}
                    />
                </div>
            </Elevation>
            <div className="report-wrapper">
                <Table className="headings"
                       style={{minWidth: 65 + 300 + 300 + 300 + 150 + 150 + 150 + 140 + 140 + 140 + (daysInChosenMonth * 50)}}>
                    <TableRow type="heading">
                        <TableCell devider style={{...tableColumnDimensions.orderNumber}}>
                            № п/п
                        </TableCell>
                        <TableCell devider style={{...tableColumnDimensions.name}}>
                            Фамилия, инициалы
                        </TableCell>
                        <TableCell devider style={{...tableColumnDimensions.position}}>
                            Должность
                        </TableCell>
                        <TableCell devider style={{...tableColumnDimensions.responsible}}>
                            Ответственный
                        </TableCell>
                        <TableCell devider style={{...tableColumnDimensions.tabularNumber}}>
                            Табельный номер
                        </TableCell>
                        <TableCell devider style={{...tableColumnDimensions.grade}}>
                            Разряд
                        </TableCell>
                        <TableCell devider style={{...tableColumnDimensions.shift}}>
                            Смена
                        </TableCell>
                        {new Array(daysInChosenMonth).fill('s').map((day: number, index: number) =>
                            <TableCell devider style={{...tableColumnDimensions.day}}>
                                {index + 1}
                            </TableCell>
                        )}
                        <TableCell devider style={{...tableColumnDimensions.total}}>
                            Итого отработано смен
                        </TableCell>
                        <TableCell devider style={{...tableColumnDimensions.total}}>
                            Итого отработано часов
                        </TableCell>
                        <TableCell devider style={{...tableColumnDimensions.total}}>
                            Из них ночных (часов)
                        </TableCell>
                        {/* <TableCell devider style={{ ...tableColumnDimensions.total }}>
                            Кол-во неявок (дней)
                        </TableCell> */}
                        {/* <TableCell devider style={{ ...tableColumnDimensions.total }}>
                            КТУ
                        </TableCell> */}
                    </TableRow>

                </Table>

                {objects.map((object: any, index: number) => {
                        console.log(object)

                        return (
                            <ObjectForTKReport
                                object={object}
                                width={tableWidth}
                                daysInChosenMonth={daysInChosenMonth}
                            />
                        )
                    }
                )}

            </div>

        </div>
    )
}