import React, { useState } from 'react'
import { IArrayOfValues, IDialogFilter } from './DialogFilter.types'
import './DialogFilter.style.sass'
import { TextField } from '@rmwc/textfield'
import { Icon } from '@rmwc/icon'
import { newDateValues } from './DialogFilter.service'
import { Tooltip } from '@rmwc/tooltip'
import { useDebounceEffect } from 'custom-hooks'
import { Checkbox } from '@rmwc/checkbox'
import { req } from 'global/common'
import { useTranslation } from 'react-i18next'
import { schedules } from '../../WorkersList.service'
import { ProgressPage } from 'components'

export const DialogFilter: React.FC<IDialogFilter> = ({
	isVisibleSettingsOpen,
	columnSettings,
	toggleIsVisibleColumn,
	columnType,
	columnName,
	onChangeFilterValues,
	filterType
}) => {
	const [dateValues, setDateValues] = useState<string[]>(
		columnType === 'contractEndDate' || (columnType === 'contractBeginDate' && filterType)
			? newDateValues(filterType)
			: ['', '']
	)
	const [textValue, setTextValue] = useState<string>('')
	const { t } = useTranslation('common')
	const [filterValues, setFilterValues] = useState<string[]>(!!filterType ? filterType : [])
	const [isSelectAllFilterValues, setIsSelectAllFilterValues] = useState(false)
	const [arrayOfValues, setArrayOfValues] = useState<IArrayOfValues>(null as unknown as IArrayOfValues)
	const [isLoaded, setIsLoaded] = useState(false)

	const checkIsLast = () => {
		let count: number = 0
		let order: number = 99
		for (const column of columnSettings) {
			if (column.visibility) {
				count += 1
				order = column.sortOrder
			}
		}
		return { order, isLast: count === 1 }
	}

	const selectAllFilterValues = (isSelected: boolean) => {
		if (isSelected) {
			setFilterValues(arrayOfValues.statics)
			setIsSelectAllFilterValues(true)
		} else if (!isSelected) {
			setFilterValues([])
			setIsSelectAllFilterValues(false)
		}
	}

	const findFilterValue = (value: string) => {
		let findSchedule = schedules.find((item: { value: number; label: string }) => item.value === Number(value))
		return findSchedule ? findSchedule.label : ''
	}

	React.useEffect(() => {
		if (columnType !== 'contractBeginDate' && columnType !== 'contractEndDate') {
			req
				.get(`/contracts/hired-workers/get/unique?type=${columnType}`)
				.then(({ data }) => {
					setArrayOfValues((prevState: IArrayOfValues) => ({
						...prevState,
						statics: data.data.filter((item: any) => !!item),
						mutable: data.data.filter((item: any) => !!item)
					}))
					setIsLoaded(true)
				})
				.catch(() => {})
		}
	}, [])

	const searchFilterValues = (value: string) => {
		if (value !== '') {
			setArrayOfValues((prevState: IArrayOfValues) => ({
				...prevState,
				mutable: prevState.statics.filter(item => !!item && item.toLowerCase().includes(value.toLowerCase()))
			}))
		} else {
			setArrayOfValues((prevState: IArrayOfValues) => ({
				...prevState,
				mutable: prevState.statics
			}))
		}
	}

	useDebounceEffect(
		() => {
			if (columnType !== 'contractBeginDate' && columnType !== 'contractEndDate' && arrayOfValues) {
				searchFilterValues(textValue)
			}
		},
		300,
		[textValue]
	)

	if (isVisibleSettingsOpen) {
		return (
			<div
				className={`dialog-filter${checkIsLast().isLast ? '-last' : ''}`}
				onClick={event => {
					event.stopPropagation()
				}}
			>
				<div className='dialog-filter-input'>
					{columnType !== 'contractBeginDate' &&
						columnType !== 'contractEndDate' &&
						columnType !== 'schedule' &&
						columnType !== 'mobilizationStatus' && (
							<TextField
								value={textValue}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const value = e.target.value
									setTextValue(value)
								}}
								outlined
								type='text'
								style={{ width: '100%' }}
								icon='search'
								maxLength={columnType === 'countTime' || columnType === 'countShifts' ? 5 : 160}
							/>
						)}
					{(columnType === 'contractBeginDate' || columnType === 'contractEndDate') && (
						<div className='dialog-date'>
							<div className='dialog-date-fields'>
								<TextField
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setDateValues((prevState: string[]) => {
											const newState = [...prevState]
											newState[0] = e.target.value
											return newState
										})
									}
									value={dateValues[0]}
									outlined
									placeholder='ДД.ММ.ГГГГ'
									type='date'
								/>
								<TextField
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setDateValues((prevState: string[]) => {
											const newState = [...prevState]
											newState[1] = e.target.value
											return newState
										})
									}
									value={dateValues[1]}
									outlined
									placeholder='ДД.ММ.ГГГГ'
									type='date'
								/>
							</div>
							<Tooltip content='Фильтровать'>
								<Icon
									className='icon filter_alt'
									icon={'filter_alt'}
									onClick={() => {
										if (dateValues[0].length > 0 && dateValues[1].length > 0) {
											const newValues = []
											newValues[0] = [
												dateValues[0].split('-')[2],
												dateValues[0].split('-')[1],
												dateValues[0].split('-')[0]
											].join('.')
											newValues[1] = [
												dateValues[1].split('-')[2],
												dateValues[1].split('-')[1],
												dateValues[1].split('-')[0]
											].join('.')
											onChangeFilterValues(columnType, columnName, newValues)
										}
									}}
								/>
							</Tooltip>
						</div>
					)}
				</div>
				{columnType !== 'contractBeginDate' && columnType !== 'contractEndDate' && (
					<div className='dialog-filter-list'>
						<div className='items'>
							<div className='item'>
								<Checkbox
									checked={isSelectAllFilterValues}
									onChange={e => {
										selectAllFilterValues(!isSelectAllFilterValues)
									}}
								/>
								<div className='text'>Выбрать все</div>
							</div>
							<ProgressPage
								state={arrayOfValues}
								render={() => (
									<>
										{arrayOfValues.mutable &&
											arrayOfValues.mutable.map(item => {
												return (
													<div className='item' key={item}>
														<Checkbox
															key={item}
															className={filterValues.includes(item) ? 'horosiy-checkbox' : 'off-checkbox'}
															checked={filterValues ? filterValues.includes(item) : false}
															onChange={e => {
																setFilterValues((prevState: string[]) => {
																	let alreadyHas = !!prevState && prevState.find(value => value === item)
																	let newState: string[] = []
																	if (alreadyHas) {
																		newState = [...prevState.filter(value => value !== item)]
																	} else {
																		newState = [...prevState, item]
																	}
																	return newState
																})
															}}
														/>
														<div className='text'>
															{columnType === 'mobilizationStatus'
																? t('mobilizationStatus.' + item)
																: columnType === 'schedule'
																? findFilterValue(item)
																: item}
														</div>
													</div>
												)
											})}
									</>
								)}
							/>
						</div>
						<div className='dialog-buttons'>
							<div
								className='dialog-btn'
								onClick={() => {
									onChangeFilterValues(columnType, columnName, filterValues.length > 0 ? filterValues : null)
								}}
							>
								<Icon className='done' icon={'done'} />
								<div>ПРИМЕНИТЬ</div>
							</div>
							<div
								className='dialog-btn'
								onClick={() => {
									setFilterValues([])
									onChangeFilterValues(columnType, columnName, null)
								}}
							>
								<Icon className='close' icon={'close'} />
								<div>ОТМЕНИТЬ</div>
							</div>
						</div>
					</div>
				)}
				{!checkIsLast().isLast && (
					<div
						className='dialog-button'
						onClick={() => {
							toggleIsVisibleColumn(false, columnType)
						}}
					>
						<Icon className='icon visibility_off' icon={'visibility_off'} />
						<div>СКРЫТЬ СТОЛБЕЦ</div>
					</div>
				)}
			</div>
		)
	} else return null
}
