import { Icon } from '@rmwc/icon'
import { PageTitle, ProgressPage } from 'components'
import { PagesRoutes } from 'global/routes'
import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { InfoBlock } from '../../components/InfoBlock/InfoBlock'
import { req } from '../../global/common'
import { UserContext } from '../../global/context.user'
import { Params } from '../public-profile/public-profile-def'
import { WorkerExperience } from './WorkerExperiencePage.types'
import './WorkerExperiencePage.style.sass'

export function WorkerExperiensePage() {
	const { searchID, recordID } = useParams() as Params
	const [experience, setExperience] = useState(null as any)
	const [worker, setWorker] = useState(null as any)
	const history = useHistory()
	const userCTX = useContext(UserContext)

	React.useEffect(() => {
		let query = {
			limit: 0,
			offset: 0,
			userID: searchID
		}

		req
			.get('/contracts/public-history', query)
			.then(({ data }) => setExperience(data))
			.catch(() => setExperience(false))

		req.get(`/profile/worker/${searchID}/public-profile`).then(data => setWorker(data.data))
	}, [])

	let experienceItems = experience?.data?.map((exp: WorkerExperience, index: number) => {
		// let isContractActive
		// let workFromStatus
		// let workToStatus
		// let parsedWorkToDate = parse(exp.workTo, "dd.MM.yyyy", new Date())

		return (
			<>
				<li key={index} className='experience-item'>
					<InfoBlock
						title={'Организация'}
						content={
							<Link
								className='link-to-org-profile'
								to={{
									pathname: `/public-profile/${exp.organisation.id}`,
									state: history.location.state
								}}
							>
								{exp.organisation?.fullName}
							</Link>
						}
					/>

					<InfoBlock
						title={'Срок действия договора'}
						content={
							!exp.workFrom ? (
								'Не установлен'
							) : (
								<div className='workFrom'>
									{exp.workFrom} - {exp.workTo ? exp.workTo : 'Бессрочно'}
									{/*{exp.workFrom} - {exp.workUnlimited ? "Бессрочно" : !exp.workTo ? "Не установлен" : exp.workTo }*/}
								</div>
							)
						}
					/>
					{userCTX.user?.organisation.id === exp.organisation.id && (
						<div
							className='experience-item-contract-card'
							onClick={() => {
								let arr: string[] = []
								if (history.location.pathname.includes(PagesRoutes.WORKER_CARD)) {
									arr = history.location.pathname.split(PagesRoutes.WORKER_CARD)
								}
								if (history.location.pathname.includes(PagesRoutes.PUBLIC_PROFILE)) {
									arr = history.location.pathname.split(PagesRoutes.PUBLIC_PROFILE)
								}
								history.push({
									pathname: arr[0] + PagesRoutes.CONTRACT_CARD + `/${exp.id}`,
									state: history.location.state
								})
							}}
						>
							<Icon className='icon' icon='description'></Icon>
							<div>Договор</div>
						</div>
					)}
				</li>
			</>
		)
	})

	return (
		<div className='worker-experience-page'>
			<PageTitle
				title={'Опыт сотрудничества'}
				// back={()=>history.goBack()}
				breadcrumbs={true}
			/>
			<ProgressPage
				state={experience}
				render={() => (
					<ul className='experience-list'>
						{experience?.total > 0 ? experienceItems : 'У работника пока нет опыта сотрудничества'}
					</ul>
				)}
			/>
		</div>
	)
}
