import { Button } from '@rmwc/button'
import { CircularProgress } from '@rmwc/circular-progress'
import { Icon } from '@rmwc/icon'
import { Tooltip } from '@rmwc/tooltip'
import {PageTitle, ProgressPage, Table} from 'components'
import { PagesRoutes } from 'global/routes'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { req } from '../../global/common'
import { UserContext } from '../../global/context.user'
import { IColumnSettings, IFilterOrSortingValues, IHeaderCell, ISortingAndFilteringArray, IWorker } from './WorkersList.types'
import './WorkersList.style.sass'
import { columns, downloadTable, schedules, settings } from './WorkersList.service'
import {DialogSettings, DndTableBody, DragAndDropCells} from "./components/";
import {FixedButtons} from "components";

export function WorkersList() {
	const userId = React.useContext(UserContext).user?.id
	const [workers, setWorkers] = React.useState<IWorker[]>(null as unknown as any)
	const [isFirstStart, setIsFirstStart] = useState(false)
	const [delayedProcess, setDelayedProcess] = useState<boolean>(false)
	const [orderedColumns, setOrderedColumns] = useState<IHeaderCell[] | any>([])
	const [columnSettings, setColumnSettings] = useState<IColumnSettings[] | any>([])
	const [isVisibleSettingsOpen, setIsVisibleSettingsOpen] = useState('NONE')
	const [dialogSettingsIsVisible, setDialogSettingsIsVisible] = useState(false)
	const [filterOrSortValues, setFilterOrSortValues] = useState<IFilterOrSortingValues | any>({
		filter: {},
		sort: {}
	})
	const [sortingAndFilteringArray, setSortingAndFilteringArray] = useState<ISortingAndFilteringArray[] | any>([])
	const scroller = useRef<HTMLInputElement>(null)
	const [total, setTotal] = useState(0)
	const [limit, setLimit] = useState(20)
	const [fetching, setFetching] = useState(false)
	const history = useHistory()

	const { t } = useTranslation('common')

	const newOrderColumns = (headerCells: IHeaderCell[], newColumnSettings: IColumnSettings[]) => {
		setOrderedColumns(headerCells)
		setColumnSettings(newColumnSettings)
		saveColumnSettings(newColumnSettings)
	}

	const clearSettings = () => {
		saveColumnSettings(settings)
		applyColumnSettings(settings)
	}

	const defaultVisibleColumns = (columnSettings: IColumnSettings[]) => {
		const defaultVisibleSettings = [
			...columnSettings.map(item => ({
				...item,
				visibility: true
			}))
		]
		saveColumnSettings(defaultVisibleSettings)
		applyColumnSettings(defaultVisibleSettings)
	}

	const applyColumnSettings = (newSettings: IColumnSettings[]) => {
		let set = newSettings.length > 0 ? [...newSettings] : [...settings]
		set.sort((a: IColumnSettings, b: IColumnSettings) => a.startOrder - b.startOrder)
		setColumnSettings(
			(newSettings.length > 0 ? [...newSettings] : [...settings]).sort(
				(a: IColumnSettings, b: IColumnSettings) => a.sortOrder - b.sortOrder
			)
		)
		const filterType = (value: string | null) => {
			let result: string | null
			if (value !== null) {
				result = JSON.parse(value)
			} else {
				result = null
			}
			return result
		}
		const newColumns = columns
			.map((column, index) => {
				return {
					...column,
					sortOrder: set[index].sortOrder,
					isVisible: set[index].visibility,
					sortType: set[index].sortType,
					filterType: filterType(set[index].filterType)
				}
			})
			.sort((a, b) => a.sortOrder - b.sortOrder)
		setOrderedColumns(newColumns)
		setSortingAndFilteringArray(
			newColumns.map(item => {
				return {
					columnName: item.title,
					columnType: item.columnType,
					sortType: item.sortType,
					filterType: item.filterType,
					isVisible: item.isVisible
				}
			})
		)
		const filterAndSortValues: IFilterOrSortingValues = { filter: {}, sort: {} }
		newSettings.forEach(item => {
			if (item.filterType !== null) {
				filterAndSortValues.filter = {
					...filterAndSortValues.filter,
					[item.columnType]: JSON.parse(item.filterType)
				}
			}
			if (item.sortType !== 'NONE') {
				filterAndSortValues.sort = {
					...filterAndSortValues.sort,
					[item.columnType]: item.sortType
				}
			}
		})
		setFilterOrSortValues(filterAndSortValues)
		req
			.post(`/contracts/hired-workers/get?limit=${limit}&offset=0`, filterAndSortValues)
			.then(({ data }) => {
				setWorkers(data.data)
				setTotal(data.total)
			})
			.catch(() => {})
	}

	const saveColumnSettings = (newColumnSettings: IColumnSettings[]) => {
		req.post(`/organisation/save-settings?userId=${userId}`, newColumnSettings).then(({ data }) => {})
	}

	const toggleIsVisibleSettings = (columnType: string) => {
		setIsVisibleSettingsOpen(columnType)
	}

	const toggleIsVisibleColumn = (checked: boolean, ind: number | string) => {
		setColumnSettings((prevState: IColumnSettings[]) => {
			const newState = prevState.map((column, index) => {
				let newColumn = {} as IColumnSettings
				if (index === ind || column.columnType === ind) {
					newColumn = {
						...column,
						visibility: checked,
						filterType: null,
						sortType: 'NONE'
					}
				} else {
					newColumn = { ...column }
				}
				return newColumn
			})
			saveColumnSettings(newState)
			return newState
		})
		setOrderedColumns((prevState: IHeaderCell[]) =>
			prevState.map((column, index) => {
				let newColumn = {}
				if (index === ind || column.columnType === ind) {
					newColumn = {
						...column,
						isVisible: checked,
						filterType: null,
						sortType: 'NONE'
					}
				} else {
					newColumn = { ...column }
				}
				return newColumn
			})
		)
		setSortingAndFilteringArray((prevState: ISortingAndFilteringArray[]) =>
			prevState.map((column, index) => {
				let newColumn = {}
				if (index === ind || column.columnType === ind) {
					newColumn = {
						...column,
						isVisible: checked,
						filterType: null,
						sortType: 'NONE'
					}
				} else {
					newColumn = { ...column }
				}
				return newColumn
			})
		)
		setIsVisibleSettingsOpen('NONE')
	}

	const onChangeSortValues = (columnType: string, columnName: string, sortType: 'ASC' | 'DESC' | 'NONE') => {
		const alreadyExists =
			sortingAndFilteringArray.length > 0 && sortingAndFilteringArray.find((item: any) => item.columnType === columnType)
		const newColumnSettings = [
			...columnSettings.map((item: any) => {
				if (item.columnType === columnType) {
					return { ...item, sortType: sortType }
				} else {
					return { ...item, sortType: 'NONE' }
				}
			})
		]
		setColumnSettings(newColumnSettings)
		saveColumnSettings(newColumnSettings as IColumnSettings[])
		if (alreadyExists) {
			setSortingAndFilteringArray((prevState: ISortingAndFilteringArray[]) => [
				...prevState.map(item => {
					if (item.columnType === columnType) {
						return { ...item, sortType: sortType }
					} else if (item.columnType !== columnType) {
						return { ...item, sortType: 'NONE' }
					}
				})
			])
			setFilterOrSortValues((prevState: any[]) => ({
				...prevState,
				sort: {
					[columnType]: sortType
				}
			}))
			setDelayedProcess((prevState: boolean) => !prevState)
		} else if (!alreadyExists) {
			setSortingAndFilteringArray((prevState: ISortingAndFilteringArray[]) => [
				...prevState.map(item => ({ ...item, sortType: 'NONE' })),
				{
					columnType,
					columnName,
					sortType
				}
			])
			setFilterOrSortValues((prevState: any[]) => ({
				...prevState,
				sort: {
					[columnType]: sortType
				}
			}))
			setDelayedProcess((prevState: boolean) => !prevState)
		}
	}

	const deleteSortOrFilter = (columnType: string, command: 'sort' | 'filter', index: number) => {
		const newColumnSettings = [
			...columnSettings.map((item: any) => {
				if (item.columnType === columnType && command === 'sort') {
					return { ...item, sortType: 'NONE' }
				} else if (item.columnType === columnType && command === 'filter') {
					return { ...item, filterType: null }
				} else return item
			})
		]
		const newOrderedColumns = [
			...orderedColumns.map((item: any) => {
				if (item.columnType === columnType && command === 'filter') {
					return { ...item, filterType: null }
				} else if (item.columnType === columnType && command === 'sort') {
					return { ...item, sortType: 'NONE' }
				} else {
					return item
				}
			})
		]
		setOrderedColumns(newOrderedColumns)
		setColumnSettings(newColumnSettings)
		saveColumnSettings(newColumnSettings as IColumnSettings[])
		if (command === 'sort') {
			setFilterOrSortValues((prevState: IFilterOrSortingValues) => ({
				...prevState,
				sort: {
					...prevState.sort,
					[columnType]: null
				}
			}))
			setSortingAndFilteringArray((prevState: ISortingAndFilteringArray[]) => {
				const newState = Array.from(prevState)
				newState[index].sortType = 'NONE'
				return newState
			})
		} else if (command === 'filter') {
			setFilterOrSortValues((prevState: IFilterOrSortingValues) => ({
				...prevState,
				filter: {
					...prevState.filter,
					[columnType]: null
				}
			}))
			setSortingAndFilteringArray((prevState: ISortingAndFilteringArray[]) => {
				const newState = Array.from(prevState)
				newState[index].filterType = null
				return newState
			})
		}
		setDelayedProcess((prevState: boolean) => !prevState)
	}

	const onChangeFilterValues = (columnType: string, columnName: string, filterValue: string[] | null) => {
		const alreadyExists =
			sortingAndFilteringArray.length > 0 && sortingAndFilteringArray.find((item: any) => item.columnType === columnType)
		const newColumnSettings = [
			...columnSettings.map((item: any) => {
				if (item.columnType === columnType) {
					return { ...item, filterType: filterValue !== null ? JSON.stringify(filterValue) : null }
				} else {
					return item
				}
			})
		]
		const newOrderedColumns = [
			...orderedColumns.map((item: any) => {
				if (item.columnType === columnType) {
					return { ...item, filterType: filterValue }
				} else {
					return item
				}
			})
		]
		setOrderedColumns(newOrderedColumns)
		setColumnSettings(newColumnSettings)
		saveColumnSettings(newColumnSettings as IColumnSettings[])
		if (alreadyExists) {
			setSortingAndFilteringArray((prevState: ISortingAndFilteringArray[]) => [
				...prevState.map(item => {
					if (item.columnType === columnType) {
						return { ...item, filterType: filterValue }
					} else if (item.columnType !== columnType) {
						return item
					}
				})
			])
			setFilterOrSortValues((prevState: any[]) => ({
				...prevState,
				filter: {
					...prevState.filter,
					[columnType]: filterValue
				}
			}))
			setDelayedProcess((prevState: boolean) => !prevState)
		} else if (!alreadyExists) {
			setSortingAndFilteringArray((prevState: ISortingAndFilteringArray[]) => [
				...prevState,
				{
					columnType,
					columnName,
					filterValue
				}
			])
			setFilterOrSortValues((prevState: any[]) => ({
				...prevState,
				filter: {
					...prevState.filter,
					[columnType]: filterValue
				}
			}))
			setDelayedProcess((prevState: boolean) => !prevState)
		}
	}

	const renderFilterValue = (
		filterValue: ISortingAndFilteringArray['filterType'],
		columnType: IColumnSettings['columnType']
	) => {
		let values: string = ''
		if (!!filterValue) {
			if (columnType === 'mobilizationStatus') {
				values = filterValue.map(item => t('mobilizationStatus.' + item)).join(', ')
				return values
			} else if (columnType === 'schedule') {
				values = filterValue
					.map(item => schedules.find((obj: { value: number; label: string }) => obj.value === Number(item))?.label)
					.join(', ')
			} else if (columnType === 'contractBeginDate' || columnType === 'contractEndDate') {
				values = filterValue.join(' - ')
			} else {
				values = filterValue.join(', ')
			}
			return values
		}
		return values
	}

	const downloadWorkersEXCEL = (columnSettings: any, orderedColumns: any, t: any) => {
		req
			.post(`/contracts/hired-workers/get`, filterOrSortValues)
			.then(({ data }) => {
				downloadTable(data.data, columnSettings, orderedColumns, t)
			})
			.catch(() => {})
	}

	React.useEffect(() => {
		req.get(`/organisation/get-settings?userId=${userId}`).then(({ data }) => {
			setIsFirstStart(true)
			applyColumnSettings(data)
		})
	}, [])

	React.useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (isFirstStart) {
				req
					.post(`/contracts/hired-workers/get?limit=${limit}&offset=0`, filterOrSortValues)
					.then(({ data }) => {
						setWorkers(data.data)
						setTotal(data.total)
					})
					.catch(() => {})
			}
		}, 300)

		return () => clearTimeout(delayDebounceFn)
	}, [delayedProcess])

	React.useEffect(() => {
		if (fetching) {
			const newLimit = limit + 20
			req
				.post(`/contracts/hired-workers/get?limit=${newLimit}&offset=0`, filterOrSortValues)
				.then(({ data }) => {
					setWorkers(data.data)
					setTotal(data.total)
					setLimit(newLimit)
				})
				.finally(() => setFetching(false))
		}
	}, [fetching])

	const scrollHandler = (e: React.UIEvent<HTMLElement>) => {
		//@ts-ignore
		if (e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + scroller.current.offsetHeight) < 100) {
			if (total > workers.length) {
				setFetching(true)
			}
		}
	}

	React.useEffect(() => {
		function closeSearch(e: any) {
			if (document.activeElement?.tagName == 'BODY') {
				toggleIsVisibleSettings('none')
				setDialogSettingsIsVisible(false)
			}
		}

		document.getElementsByTagName('body')[0].addEventListener('click', closeSearch)
		return () => {
			document.getElementsByTagName('body')[0].removeEventListener('click', closeSearch)
		}
	}, [])

	return (
		<div className='workers-new-page'>
			{fetching && (
				<div className='loading-wrapper'>
					<div className='loading-inner'>
						<CircularProgress className='circular-progress' />
						<div>Загрузка</div>
					</div>
				</div>
			)}
			<PageTitle title={'Работники'} breadcrumbs={true} />
			<div className='workers-top'>
				<div className='filtered_or_sorted_items'>
					{sortingAndFilteringArray.map((item: any, index: number) => {
						if (item.filterType && item.isVisible) {
							return (
								<div className='filtered_or_sorted_item' key={item.columnName + index + 'filter'}>
									<div className='item'>
										<div className='column_name'>{item.columnName}:</div>
										<Tooltip
											content={
												<div style={{ maxWidth: '250px', maxHeight: '100px', overflow: 'hidden' }}>
													{renderFilterValue(item.filterType, item.columnType)}
												</div>
											}
										>
											<div className='type' style={{ cursor: 'help' }}>
												{renderFilterValue(item.filterType, item.columnType)}
											</div>
										</Tooltip>
									</div>
									<Icon
										className='cancel'
										icon={'cancel'}
										onClick={() => deleteSortOrFilter(item.columnType, 'filter', index)}
									/>
								</div>
							)
						}
					})}
					{sortingAndFilteringArray.map((item: any, index: number) => {
						if (item.sortType !== 'NONE' && item.isVisible) {
							return (
								<div className='filtered_or_sorted_item' key={item.columnName + index + 'sort'}>
									<div className='item'>
										<div className='column_name'>{item.columnName}:</div>
										<div className='type'>({item.sortType === 'ASC' ? 'А - Я' : 'Я - А'})</div>
									</div>
									<Icon
										className='cancel'
										icon={'cancel'}
										onClick={() => deleteSortOrFilter(item.columnType, 'sort', index)}
									/>
								</div>
							)
						}
					})}
				</div>
				<div className='buttons'>
					<Link
						to={{
							pathname: PagesRoutes.WORKERS + PagesRoutes.MOBILIZATION,
							state: history.location.state
						}}
					>
						<Tooltip content='Мобилизация'>
							<Button className='restart_settings' disabled={workers == null} raised={true}>
								<Icon className='settings' icon={{ icon: 'checklist', size: 'medium' }} style={{ color: '#FFFFFF' }} />
							</Button>
						</Tooltip>
					</Link>
					<Link
						to={{
							pathname: PagesRoutes.WORKERS + PagesRoutes.PERSONAL,
							state: history.location.state
						}}
					>
						<Tooltip content='Персонал'>
							<Button className='restart_settings' disabled={workers == null} raised={true}>
								<Icon className='settings' icon={{ icon: 'person', size: 'medium' }} style={{ color: '#FFFFFF' }} />
							</Button>
						</Tooltip>
					</Link>
					{/*<Tooltip content='Переключить режим'>*/}
					{/*	<Button*/}
					{/*		className='restart_settings'*/}
					{/*		disabled={Personnel == null}*/}
					{/*		raised={true}*/}
					{/*		onClick={() => setAr((prevState: any) => !prevState)}*/}
					{/*	>*/}
					{/*		<Icon className='settings' icon={'settings'} style={{ color: '#FFFFFF' }} />*/}
					{/*	</Button>*/}
					{/*</Tooltip>*/}
					<Tooltip content='Управление столбцами'>
						<Button
							className='restart_settings'
							disabled={workers == null}
							raised={true}
							onClick={() => setDialogSettingsIsVisible((prevState: any) => !prevState)}
						>
							<Icon className='settings' icon={'settings'} style={{ color: '#FFFFFF' }} />
						</Button>
					</Tooltip>
					<Tooltip content='Сбросить настройки'>
						<Button className='restart_settings' disabled={workers == null} raised={true} onClick={clearSettings}>
							<Icon className='restart_alt' icon={'restart_alt'} style={{ color: '#FFFFFF' }} />
						</Button>
					</Tooltip>
					<FixedButtons
						length={1}
						buttons={[
							{
								label: 'Сохранить EXCEL',
								primary: true,
								disabled: workers == null,
								onClick: () => downloadWorkersEXCEL(columnSettings, orderedColumns, t)
							}
						]}
					/>
				</div>
				{dialogSettingsIsVisible && (
					<DialogSettings
						columnSettings={columnSettings}
						toggleIsVisibleColumn={toggleIsVisibleColumn}
						defaultVisibleColumns={defaultVisibleColumns}
						orderedColumns={orderedColumns}
					/>
				)}
			</div>
			<ProgressPage
				state={workers}
				render={() => (
					<>
						<div className='table-wrapper' ref={scroller} onScroll={scrollHandler}>
							<Table className='workers-table'>
								<DragAndDropCells
									orderedColumns={orderedColumns}
									newOrderColumns={newOrderColumns}
									columnSettings={columnSettings}
									toggleIsVisibleSettings={toggleIsVisibleSettings}
									isVisibleSettingsOpen={isVisibleSettingsOpen}
									toggleIsVisibleColumn={toggleIsVisibleColumn}
									onChangeSortValues={onChangeSortValues}
									sortingAndFilteringArray={sortingAndFilteringArray}
									onChangeFilterValues={onChangeFilterValues}
								/>
								<DndTableBody columnSettings={columnSettings} orderedColumns={orderedColumns} workers={workers} />
							</Table>
						</div>
					</>
				)}
			/>
		</div>
	)
}
