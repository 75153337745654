import { IconButton } from '@rmwc/icon-button'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { TableCell } from '../../../../components/Table/TableCell/TableCell'
import { IWorkerTrackingInputProps } from '../BrigadeRender.types'
import { checkDate, checkEndTime, checkStartTime } from '../BrigadeRender.service'
import './WorkerTrackingInput.style.sass'

export function WorkerTrackingInput(props: IWorkerTrackingInputProps) {
	const [key, setKey] = React.useState<number | undefined>()
	const [startTImeRequired, setStartTimeRequired] = React.useState(false)
	React.useEffect(() => {
		setKey(Math.random())
		if (props.isEditMode) {
			setTimeout(() => props.methods.trigger(), 1)
		}
	}, [props.isEditMode])

	return (
		<>
			<TableCell className='tracking start' style={{ width: '15%', minWidth: '240px' }}>
				<div className='input-wrapper start'>
					<Controller
						key={props.worker.workerRecordID + 'startTime' + key}
						control={props.methods.control}
						name={'startTime' + props.worker.workerRecordID}
						defaultValue={props.worker.startTime && format(parseISO(props.worker.startTime), 'dd.MM.yyyy HH:mm')}
						rules={{
							required: {
								value: startTImeRequired,
								message: 'Заполните время начала'
							},
							validate: {
								isCurrentDates: v =>
									checkDate(v, props.worker.endTime && format(parseISO(props.worker.endTime), 'dd.MM.yyyy HH:mm')),
								isInputDateEqualChosenCurrentDay: v => checkStartTime(v, props.currentDay)
							}
						}}
						render={({ field, fieldState }) =>
							props.isEditMode ? (
								<div className='input-inner'>
									<div>
										{
											//@ts-ignore
										}
										<NumberFormat
											{...field}
											className={`input ${fieldState.error?.message && 'error'}`}
											format={'##.##.#### ##:##'}
											mask={'_'}
											value={field.value}
											onValueChange={output => {
												// console.log(output)
												// props.methods.trigger()
												// setValue({
												//     ...value,
												//     startTime: output.formattedValue
												// })
												props.changeTrackingForm(props.worker, output, 'startTime')
											}}
										/>
										{fieldState.invalid && <div className='tracking_input-error'>{fieldState.error?.message}</div>}
									</div>
									<IconButton
										onClick={e => {
											e.preventDefault()
											props.methods.setValue(field.name, '')
											setTimeout(() => props.methods.trigger(), 1)
										}}
										className='delete-btn'
										icon='delete'
									></IconButton>
								</div>
							) : (
								<div className='text'>
									{props.worker.startTime && format(parseISO(props.worker.startTime), 'dd.MM.yyyy HH:mm')}
								</div>
							)
						}
					/>
				</div>
			</TableCell>
			<TableCell className='tracking end' style={{ width: '15%', minWidth: '240px' }}>
				<div className='input-wrapper end'>
					<Controller
						key={props.worker.workerRecordID + 'endTime' + key}
						control={props.methods.control}
						name={'endTime' + props.worker.workerRecordID}
						defaultValue={props.worker.endTime && format(parseISO(props.worker.endTime), 'dd.MM.yyyy HH:mm')}
						rules={{
							validate: {
								isCurrentDates: v =>
									checkDate(props.worker.startTime && format(parseISO(props.worker.startTime), 'dd.MM.yyyy HH:mm'), v),
								isInputDateEqualChosenCurrentDay: v => checkEndTime(v, props.currentDay)
							}
						}}
						render={({ field, fieldState }) =>
							props.isEditMode ? (
								<div className='input-inner'>
									<div>
										{
											//@ts-ignore
										}
										<NumberFormat
											{...field}
											className={`input ${fieldState.error?.message && 'error'}`}
											format={'##.##.#### ##:##'}
											mask={'_'}
											value={field.value}
											onValueChange={output => {
												output.formattedValue.length > 0 ? setStartTimeRequired(true) : setStartTimeRequired(false)
												setTimeout(() => props.methods.trigger(), 1)
												props.changeTrackingForm(props.worker, output, 'endTime')
											}}
										/>
										{fieldState.invalid && <div className='tracking_input-error'>{fieldState.error?.message}</div>}
									</div>
									<IconButton
										onClick={e => {
											e.preventDefault()
											props.methods.setValue(field.name, '')
											setStartTimeRequired(false)
										}}
										className='delete-btn'
										icon='delete'
									></IconButton>
								</div>
							) : (
								<div className='text'>
									{props.worker.endTime && format(parseISO(props.worker.endTime), 'dd.MM.yyyy HH:mm')}
								</div>
							)
						}
					/>
				</div>
			</TableCell>
		</>
	)
}
