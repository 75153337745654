import {Elevation} from '@rmwc/elevation'
import {Icon} from '@rmwc/icon'
import {IconButton} from '@rmwc/icon-button'
import {SelectOption} from 'components/Form/Form.types'
import {NameAndStatus} from 'components/NameAndStatus/NameAndStatus'
import {TableCell} from 'components/Table/TableCell/TableCell'
import {TableRow} from 'components/Table/TableRow/TableRow'
import {Table} from 'components/Table/Table'
import {
    differenceInCalendarDays,
    parse,
} from 'date-fns'
import {authorName, req} from 'global/common'
import {InterfaceContext} from 'global/context.interface'
import {UserContext} from 'global/context.user'
import {PagesRoutes} from 'global/routes'
import React, {MutableRefObject, useContext} from 'react'
import {Controller, FormProvider, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {NumberFormatValues} from 'react-number-format'
import {useHistory} from 'react-router'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import {WorkerTrackingInput} from './components/WorkerTrackingInput'
import {
    FormattedTrackingSend,
} from './BrigadeRender.types'
import './BrigadeRender.style.sass'

interface IBrigadeProps {
    brigade: {
        id: number
        name: string
        shiftType: string
        object: {
            id: number
            adress: string
            name: string
            responsible: {
                id: number
                firstName: string
                middleName: string
                lastName: string
            }
        }
        workers: any[]
    }
    objects: SelectOption[]
    currentDay: string
    refresh: boolean | undefined
    refreshPage: React.Dispatch<any>
}

export function BrigadeRender(props: IBrigadeProps) {
    const userCTX = React.useContext(UserContext)
    const history = useHistory()
    const {t} = useTranslation('common')
    const methods = useForm({
        mode: 'all'
    })
    const interfaceCTX = useContext(InterfaceContext)

    const brigadeForm = React.useRef() as MutableRefObject<HTMLFormElement>

    const [open, setOpen] = React.useState(false)
    const [edit, setEdit] = React.useState(false)
    const [trackingForm, setTrackingForm] = React.useState<any>({} as unknown as any[])

    function changeTrackingForm(worker: any, output: NumberFormatValues, operationType: 'startTime' | 'endTime') {
        setTrackingForm({
            ...trackingForm,
            [worker.workerRecordID]: {
                ...trackingForm![worker.workerRecordID],
                workerRecordID: worker.workerRecordID,
                [operationType]: output.formattedValue,
                foremanID: props.brigade.object.responsible.id
            }
        })
    }

    const isAllowedSave = (values: any) => {
        let result = false
        if (!!values && !!values.endTime && !!values.startTime) {
            let startTime = values.startTime.split(' ')[1]
            let endTime = values.endTime.split(' ')[1]
            let startDay = parse(values.startTime.split(' ')[0], 'dd.MM.yyyy', new Date())
            let endDay = parse(values.endTime.split(' ')[0], 'dd.MM.yyyy', new Date())
            if (startTime >= endTime) {
                result = true
            }
            if (differenceInCalendarDays(startDay, endDay) === -1) {
                result = false
            }
        }
        return result
    }

    function saveChangedTracking() {
        const values = Object.values(trackingForm)[0]
        if (isAllowedSave(values)) {
            interfaceCTX.showMessage({
                icon: 'report',
                body: 'Введите корректное время'
            })
        } else {
            req
                .post(`/shift/${props.currentDay}/foreman/track-worker-mass`, {
                    data: Object.values(trackingForm).map((tracking: any) => {
                        const formattedTracking = new FormattedTrackingSend(tracking)
                        if (formattedTracking.startTime == '') {
                            formattedTracking.startTime = null
                        }
                        if (formattedTracking.endTime == '') {
                            formattedTracking.endTime = null
                        }
                        return formattedTracking
                    })
                })
                .catch(e => {
                    interfaceCTX.showMessage({
                        icon: 'report',
                        body: e.response.data
                    })
                })
                .finally(() => {
                    req
                        .post(`/shift/${props.currentDay}/foreman/brigades/${props.brigade.id}/update-brigade`, {
                            objectID: methods.getValues().object.value,
                            shiftType: methods.getValues().shiftType.value
                        })
                        .finally(() => {
                            props.refreshPage(!props.refresh)
                            setTrackingForm({...{}})
                            setEdit(false)
                        })
                        .catch(e => {
                            interfaceCTX.showMessage({
                                icon: 'report',
                                body: e.response.data
                            })
                        })
                })
        }
    }

    return (
        <Elevation key={props.brigade.id + props.currentDay} z={3} className='brigade-wrapper'>
            <div className='brigade-inner'>
                <div className='brigade-title' onClick={() => setOpen(!open)}>
                    <div className='brigade-name'>{props.brigade.name}</div>
                    <div className='brigade-workers-count'>{'(' + props.brigade.workers.length + ')'}</div>
                    <div className='brigade-foreman'>{authorName(props.brigade.object.responsible)}</div>
                    <Icon className='dropdown-arrow' icon='expand_more'></Icon>
                </div>
                <div className={'workers-wrapper' + ` ${open && ' open'}`}>
                    <FormProvider {...methods} key={props.brigade.id + props.currentDay}>
                        <Table useRef={brigadeForm} className='worker-table' tag='form'>
                            <TableRow type='heading'>
                                <TableCell className='worker-name' style={{width: '70%'}}>
                                    Работник
                                </TableCell>
                                <TableCell className='edit' style={{width: '15%'}}>
                                    {userCTX.currentRole('admin') && edit && (
                                        <IconButton
                                            className='control-btn'
                                            icon='close'
                                            onClick={() => {
                                                methods.reset({keepDefaultValues: true, keepValues: false})
                                                setTrackingForm({...{}})
                                                setEdit(false)
                                            }}
                                        />
                                    )}
                                    {userCTX.currentRole('admin') && edit && (
                                        <IconButton
                                            className='control-btn'
                                            icon='save'
                                            disabled={!methods.formState.isValid}
                                            onClick={e => {
                                                e.preventDefault()
                                                saveChangedTracking()
                                            }}
                                        />
                                    )}
                                    {userCTX.currentRole('admin') && !edit && (
                                        <IconButton
                                            className='control-btn'
                                            icon='edit'
                                            onClick={() => {
                                                setEdit(true)
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell className='start-time' style={{width: '15%', minWidth: '240px'}}>
                                    Начал
                                </TableCell>
                                <TableCell className='end-time' style={{width: '15%', minWidth: '240px'}}>
                                    Завершил
                                </TableCell>
                            </TableRow>
                            {props.brigade.workers.map((worker: any) => {
                                return (
                                    <TableRow className='worker-row' type='row'>
                                        <TableCell className='worker-name' style={{width: '70%'}}>
                                            <Link
                                                to={{
                                                    pathname: worker.profile.isDeleted
                                                        ? PagesRoutes.TIME_KEEPING +
                                                        PagesRoutes.WORKER_CARD +
                                                        `/${worker.profile.id}/${worker.requestID}`
                                                        : PagesRoutes.TIME_KEEPING +
                                                        PagesRoutes.PUBLIC_PROFILE +
                                                        `/${worker.profile.id}/${worker.workerRecordID}`,
                                                    state: history.location.state
                                                }}
                                            >
                                                <NameAndStatus authorName={authorName(worker.profile)}/>
                                            </Link>
                                        </TableCell>
                                        <TableCell style={{width: '15%'}}></TableCell>
                                        <WorkerTrackingInput
                                            key={worker.workerRecordID}
                                            changeTrackingForm={changeTrackingForm}
                                            isEditMode={edit}
                                            currentDay={props.currentDay}
                                            methods={methods}
                                            worker={worker}
                                        />
                                    </TableRow>
                                )
                            })}
                            <TableRow className='object-and-shift' type='row'>
                                <TableCell className='object select-wrapper' style={{width: '35%'}}>
                                    <div className='object-title title'>Название объекта</div>
                                    {edit ? (
                                        <Controller
                                            control={methods.control}
                                            name={'object'}
                                            defaultValue={{
                                                label: props.brigade.object.name,
                                                value: props.brigade.object.id
                                            }}
                                            render={({field, fieldState}) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        className='object-select select react-select-component'
                                                        classNamePrefix={'select'}
                                                        options={props.objects}
                                                    />
                                                </>
                                            )}
                                        />
                                    ) : (
                                        <div className='object-name text'>{props.brigade.object.name}</div>
                                    )}
                                </TableCell>
                                <TableCell className='shift select-wrapper' style={{width: '15%', minWidth: '150px'}}>
                                    <div className='shift-title title'>Смена</div>
                                    {edit ? (
                                        <Controller
                                            control={methods.control}
                                            name={'shiftType'}
                                            defaultValue={{
                                                label: t('shiftType.' + props.brigade.shiftType) as string,
                                                value: `${props.brigade.shiftType}`
                                            }}
                                            render={({field, fieldState}) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        className='object-select select react-select-component'
                                                        classNamePrefix={'select'}
                                                        options={[
                                                            {label: 'День', value: 'day'},
                                                            {label: 'Ночь', value: 'night'}
                                                        ]}
                                                    />
                                                </>
                                            )}
                                        />
                                    ) : (
                                        <div
                                            className='shift-name text'>{t('shiftType.' + props.brigade.shiftType)}</div>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className='adress-and-foreman' type='row'>
                                <TableCell className='adress select-wrapper' style={{width: '35%'}}>
                                    <div className='adress-title title'>Адрес объекта</div>
                                    <div className='adress text'>{props.brigade.object.adress}</div>
                                </TableCell>
                                <TableCell className='foreman select-wrapper' style={{width: '35%', minWidth: '350px'}}>
                                    <div className='foreman-title title'>Ответственный</div>
                                    <div
                                        className='foreman-name text'>{authorName(props.brigade.object.responsible)}</div>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </FormProvider>
                </div>
            </div>
        </Elevation>
    )
}

