import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import { Icon } from "@rmwc/icon";
import { Tooltip } from "@rmwc/tooltip";
import { attrList } from "../../global/common";
import "./FixedButtons.style.sass";
import {IFixedButtons} from "./FixedButtons.types";


export function FixedButtons({length, buttons, className}: IFixedButtons) {
    return (
        <div
            {...attrList(
                ["className", "fixed-buttons-container " + className],
                ["style", {gridTemplateColumns: "1fr ".repeat(length).trimEnd()}]
            )}
        >
            {buttons.map(
                (
                    {label, onClick, primary, hide, disabled, icon, type, className, tooltip, outlined= true},
                    index
                ) => {
                    if (hide) return null;
                    if (tooltip) {
                        return (
                            <Tooltip content={tooltip}
                                     key={"fixed-button-" + index}
                            >
                                <div>
                                    <Button
                                        type={type}
                                        className={className}
                                        label={label}
                                        {...attrList(
                                            ["onClick", onClick && (() => onClick())],
                                            ["raised", primary && "true"],
                                            ["outlined", !primary && "true"]
                                        )}
                                        disabled={disabled}
                                    >
                                        {icon && <Icon className={icon} icon={icon}/>}
                                    </Button>
                                </div>
                            </Tooltip>
                        )
                    } else {
                        return (
                            <Button
                                key={"fixed-button-" + index}
                                type={type}
                                className={className}
                                label={label}
                                {...attrList(
                                    ["onClick", onClick && (() => onClick())],
                                    ["raised", primary && "true"],
                                    ["outlined", outlined && !primary && "true"]
                                )}
                                disabled={disabled}
                            >
                                {icon && <Icon className={icon} icon={icon}/>}
                            </Button>
                        );
                    }
                }
            )}
        </div>
    );
}
