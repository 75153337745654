import BannerScr from "../../misc/banner-big.svg";
import React, {BaseSyntheticEvent, useState} from "react";
import {Button} from "@rmwc/button";
import {req} from "../../global/common";
import {SpanButtons} from "components";
import {InterfaceContext} from "../../global/context.interface";
import {UserContext} from "../../global/context.user";
import {useTranslation} from "react-i18next";
import {User} from "../../global/definitions/definitions";
import './SinginModerator.style.sass'
import {TextField} from "@rmwc/textfield";
import {AuthValidate} from "../Signin";


export default function SinginModeratorPage() {
    const userCTX = React.useContext(UserContext)
    const interfaceCTX = React.useContext(InterfaceContext)
    const {t} = useTranslation('common')
    const [passwordVisible, setPasswordVisible] = useState<'text' | 'password'>('password')
    const [state, setState] = React.useState({
        email: '',
        password: '',
    })

    const formSubmit = React.useCallback(
        (e: BaseSyntheticEvent) => {
            e.preventDefault()
            req.post('/auth/moderator-auth', state)
                .then(({data}: { data: AuthValidate }) => {
                    localStorage.setItem('tokenDeathDate', String(Date.now() + data.tokenDurationMs))
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('refreshToken', data.refreshToken)
                    req.get('/profile/get')
                        .then(({data}) => {
                            userCTX.setUser(new User(data))
                        })
                })
                .catch((e) => {
                    interfaceCTX.showMessage({
                        title: 'Ошибка',
                        body: t('error.' + e?.response?.data)
                    })
                })
        },
        [state]
    )


    return (
        <div className="singin-moderator-page">
            <h1>{"Вход"}</h1>
            <img src={BannerScr} alt="pragma"
                 style={{
                     height: '70px',
                     margin: '145px 0 30px 0'
                 }}
            />

            <form onSubmit={formSubmit} className="container">


                <div className="container-body">
                    <TextField
                        onChange={e => setState({
                            ...state,
                            email: e.currentTarget.value,
                        })}
                        outlined
                        placeholder={'E-mail'}
                        maxLength={30}
                        type="text"
                        className='container-body-field-input'
                    />

                    <TextField
                        onChange={e => setState({
                            ...state,
                            password: e.currentTarget.value,
                        })}
                        outlined
                        placeholder={'Пароль'}
                        maxLength={30}
                        type={passwordVisible}
                        className='container-body-field-input'
                        trailingIcon={{
                            icon: passwordVisible === 'password' ? 'visibility' : 'visibility_off',
                            tabIndex: 0,
                            onClick: () => {
                                setPasswordVisible(prevState => {
                                    if(prevState === 'password') {
                                        return 'text'
                                    } else return 'password'
                                })
                            }
                        }}
                    />
                    <Button raised
                            className='container-body-field-btn'
                            style={{color: '#FFF'}}
                            disabled={!state.email || !state.password}>
                        Войти
                    </Button>
                </div>
                <div className="footer-message">
                    <SpanButtons
                        data={[
                            {
                                label: 'Связаться с техподдержкой',
                                href: `${'mailto:' + interfaceCTX.getMailToArr()}`,
                                tag: 'span',
                                class: "support"
                            }
                        ]}
                    />
                </div>
            </form>
        </div>
    )
}