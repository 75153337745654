import {Elevation} from '@rmwc/elevation';
import {Tooltip} from '@rmwc/tooltip';
import {useEffect, useState, useContext} from 'react';
import {ProgressPage, SpanButtons, PageTitle} from 'components';
import {Icon} from '@rmwc/icon';
import {Dialog, DialogContent, DialogTitle} from '@rmwc/dialog';

import './documents-page.sass';
import {Button} from '@rmwc/button';
import {req} from '../../global/common';
import {DocType} from './documents-page-def';
import {InterfaceContext} from '../../global/context.interface';

import {fromBuffer} from 'file-type';

// ugly global declaration special for Safari 5-7
declare global {
    interface Blob {
        webkitSlice: Blob['slice'];
    }
}

export const allowedMIME = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/rtf',
];

export default function Documents() {
    const [loaded, setLoaded] = useState<boolean>(null!);
    const [uploadModal, setUploadModal] = useState<boolean>(false);
    const [over, setOver] = useState<boolean>(false);
    const [files, setFiles] = useState<File[]>([]);
    const [docs, setDocs] = useState<DocType[]>([]);
    const [reload, setReload] = useState<boolean>(false);

    const interfaceCTX = useContext(InterfaceContext);

    useEffect(() => {
        req
            .get('/organisation/documents/list')
            .then(({data}) => {
                setDocs(data.data);
                setLoaded(true);
            })
            .catch(() => setLoaded(false));
    }, [reload]);

    const deleteDoc = (docID: number, docName: string) => {
        if (window.confirm(`Вы уверены, что хотите удалить ${docName}?`)) {
            req
                .delete(`/organisation/documents/${docID}/delete`)
                .then(() => docsReload())
                .catch(() =>
                    interfaceCTX.showMessage({
                        body: 'Непридвиденная ошибка при удалении.',
                        icon: 'report',
                    }),
                );
        }
    };

    const download = (url: string, fullname: string) => {
        let [ext, ...name] = fullname.split('.').reverse();

        fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
                const blobUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', `${name.reverse().join('.')}.${ext}`);
                document.body.appendChild(link);
                link.click();
            });
    };

    const upload = () => {
        Promise.all(files).then((blobs) => {
            let paramsArr: FormData[] = Array(files.length).fill(null!);
            paramsArr = paramsArr.map((p, i) => {
                let formData = new FormData();
                formData.append('file', blobs[i]);
                return formData;
            });

            Promise.all(
                paramsArr.map((p) => req.post('/organisation/documents/create', p)),
            )
                .then(() => docsReload())
                .catch(() =>
                    interfaceCTX.showMessage({
                        body: 'Непридвиденная ошибка при загрузке.',
                        icon: 'report',
                    }),
                );
        });
    };

    const onDrop = async (list: FileList) => {
        setOver(false);
        if (list.length === 0) return;
        let f: File[] = [];
        let wrongFormats = false;
        for (let i = 0; i < list.length; i++) {
            let file = list[i];
            let firstBytes = (file.webkitSlice ?? file.slice).call(file, 0, 4100);
            let actualMIME = (await fromBuffer(await firstBytes.arrayBuffer()))?.mime.toString();
            console.log('Actual MIME: ' + actualMIME);
            if (!allowedMIME.includes(actualMIME ?? '')) {
                wrongFormats = true;
                continue
            }
            if (files.findIndex((ff) => ff.name === list[i].name) !== -1) continue;
            f.push(list[i]);
        }

        if (wrongFormats) {
            interfaceCTX.showMessage({
                body: 'Доступна загрузка форматов: .docx, .pdf, .xlsx, .rtf',
                icon: 'report',
            })
        }

        setFiles((fils: File[]) => [...fils, ...f]);
    };

    const docsReload = () => {
        setLoaded(null!);
        setFiles([]);
        setReload((state: boolean) => !state);
    };

    return (
        <ProgressPage
            state={loaded}
            className={'documents-page'}
            render={() => (
                <>
                    <div className={'header'}>
                        <PageTitle title={'Документы'}/>
                        <SpanButtons
                            data={[
                                {
                                    label: 'Загрузить файл',
                                    onClick: () => setUploadModal(true),
                                },
                            ]}
                        />
                    </div>
                    <Elevation className={'docs'} z={3}>
                        {docs.map((d) => (
                            <Doc
                                key={d.id}
                                name={d.name}
                                id={d.id}
                                del={(id) => deleteDoc(id, d.name)}
                                path={d.path}
                                download={(url) => download(url, d.name)}
                            />
                        ))}

                        <Dialog open={uploadModal} onClose={() => {
                            setFiles([]);
                            setUploadModal(false)
                        }}>
                            <DialogTitle className={'modal-title'}>
                                Загрузка документов
                                <Icon icon={'close'} onClick={() => {
                                    setFiles([]);
                                    setUploadModal(false)
                                }}/>
                            </DialogTitle>
                            <DialogContent className={'modal-content'}>
                                <>
                                    <div className={'doc-container'}>
                                        {files.map((f) => (
                                            <Doc key={f.name} name={f.name}/>
                                        ))}
                                    </div>
                                    <hr/>
                                    <div
                                        className={'dropzone' + (over ? ' dragover' : '')}
                                        onDrop={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            onDrop(e.dataTransfer.files)
                                        }}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDragEnter={(e) => setOver(true)}
                                        onDragExit={(e) => setOver(false)}>
                                        <Icon icon={{icon: 'upload_file', size: 'xlarge'}}/>
                                        <div>{'Перетащите или выберите файлы для загрузки'}</div>
                                        <input
                                            type='file'
                                            multiple
                                            onChange={(e) => onDrop(e.target.files ?? new FileList())}
                                        />
                                    </div>
                                    <div className={'footer-btn'}>
                                        <Button raised onClick={() => upload()}>
                                            Загрузить
                                        </Button>
                                    </div>
                                </>
                            </DialogContent>
                        </Dialog>
                    </Elevation>
                </>
            )}
        />
    );
}

interface DocProps {
    name: string;
    id?: number;
    del?: (id: number) => void;
    download?: (url: string) => void;
    downloads?: (url: string, fullname: string) => void;
    path?: string;
    mimetype?: string
}

export function Doc({name, id, del, path, download, downloads, mimetype}: DocProps) {
    let formattedName = name!.split('.');
    let fileName = formattedName.length !== 1 ? formattedName!.slice(0, formattedName.length - 1) : formattedName[0];
    let extension = formattedName.length !== 1 ? formattedName!.slice(formattedName.length - 1) : [];

    return (
        <div
            className={'doc-file' + (path ? ' download' : '')}
            onClick={() => {
                path && downloads ? downloads(path, name) : path && download && download(path)
            }}
        >
            {mimetype && mimetype.includes('image')
                ? <div className={'pic'}>
                    <div className='image' style={{backgroundImage: `url(${path})`}}/>
                    {del && <Icon
                        className={'close'}
                        icon={'close'}
                        onClick={(e) => {
                            e.stopPropagation();
                            del && del(id ?? 0);
                        }}
                    />}
                </div>
                : <div className={'pic'}>
                    <Icon
                        className={'idoc'}
                        icon={{icon: 'description', size: 'large'}}
                    />
                    {del && <Icon
                        className={'close'}
                        icon={'close'}
                        onClick={(e) => {
                            e.stopPropagation();
                            del && del(id ?? 0);
                        }}
                    />}
                </div>
            }

            <Tooltip
                content={
                    <div className="tooltip">
                        {name}
                    </div>
                }
            >
                <div className="doc-title">
                    <div className="doc-name">
                        {fileName}
                    </div>
                    <div className="doc-extension">
                        {extension.length !== 0 && `.${extension}`}
                    </div>
                </div>
            </Tooltip>


        </ div>
    );
}
